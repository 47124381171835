import React from 'react'
import "./navigation-link.css"

const NavigationLink = ({ onClick, Icon, label, active = false, disabled = false }) => {
  const className = `navigation-link__container
    ${active && !disabled ? " navigation-link__container--active" : ""}
    ${disabled ? " navigation-link__container--disabled" : ""}`
  return (
    <div 
      className={className}
      onClick={() => !disabled && onClick()}>
      <Icon style={{ marginRight: "1.5vh" }} size="2.5vh"/>
      {label}
    </div>
  )
}

export default NavigationLink