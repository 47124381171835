import React, { useEffect, useState } from 'react'
import LoadContracts from '../../components/dashboard-components/load-contracts/LoadContracts'
import { getAbis, getLastAbiUpdate } from '../../helpers/backend/get-requests/abi-data'
import { atomWithStorage } from 'jotai/utils'
import { useAtom, atom } from 'jotai'

const targetNetwork = 421613
const contractsInfoStorage = atomWithStorage('contractsInfo', {})
const blockchainDataAtom = atom({})
const contractsAtom = atom({})

const BlockchainData = () => {
  const [contractsInfo, setContractsInfo] = useAtom(contractsInfoStorage)
  const [proceed, setProceed] = useState(false)

  const contractNameToKey = {
    FighterFarmNoChainlink: "fighterFarm",
    // FighterMarketplace: "marketplace",
    GameItems: "gameItems",
    Intelligence: "intelligence",
    MergingPool: "mergingPool",
    Neuron: "neuron",
    RankedBattle: "rankedBattle",
    StakeAtRisk: "stakeAtRisk",
    VoltageManager: "voltageManager"
  }

  const getContractInfo = (data) => {
    return {
      address: data.networks[targetNetwork].address,
      abi: data.abi
    }
  }

  const getAllContractsInfo = (abisData) => {
    const allContractsInfo = { lastUpdate: Date.now() }
    const contractNames = Object.keys(abisData)
    for (var i = 0; i < contractNames.length; i++) {
      const name = contractNames[i]
      allContractsInfo[contractNameToKey[name]] = getContractInfo(abisData[name])
    }
    console.log("updating smart contracts")
    setContractsInfo(allContractsInfo)
  }


  useEffect(async () => {
    const result = await getLastAbiUpdate()
    const { lastUpdate } = contractsInfo
    const needsUpdate = lastUpdate === undefined || lastUpdate < result.lastUpdate 
    if (contractsInfo["fighterFarm"] === undefined || needsUpdate) {
      const abisData = await getAbis()
      getAllContractsInfo(abisData)
      setProceed(true)
    }
    else {
      setProceed(true)
    }
  }, [])
  
  return <>
    {
      proceed && contractsInfo.fighterFarm !== undefined &&
      <LoadContracts contractsInfo={contractsInfo}/>
    }
  </>
}

export default BlockchainData
export {
  blockchainDataAtom,
  contractsInfoStorage,
  contractsAtom,
  targetNetwork
}