import React, { useEffect, useRef } from "react"
import { setupChart } from "../../../helpers/charting/chart-setup"
import { renderChart } from "../../../helpers/charting/render-charts"
import { palette } from "../../../helpers/charting/color-palette"
import * as d3 from 'd3'
import './historical-elo.css'

const HistoricalElo = ({ eloTimeseries }) => {
  const refContainer = useRef()

  const startingElo = 1500
  var domain = [startingElo, startingElo]
  if (eloTimeseries !== undefined) {
    domain = d3.extent(eloTimeseries)
  }

  const negativeColor = palette.typography[4]
  const positiveColor = palette.typography[2]

  const horizontalSeperators = []
  if (domain[0] < startingElo) {
    horizontalSeperators.push({ 
      y: startingElo, 
      color: negativeColor, 
      bottomBorder: negativeColor, 
      topBorder: "#555" 
    })
  }
  if (domain[1] > startingElo) {
    horizontalSeperators.push({ 
      y: domain[1], 
      color: positiveColor, 
      bottomBorder: "transparent", 
      topBorder: positiveColor
    })
  }

  const chartMetadata = {
    chartType: "Line Chart",
    margin: { top: 0.05, right: 0.01, bottom: 0.1, left: 0.02 },
    labelPadding: { x: 0.1, y: 0.1 },
    labelText: { x: "Time", y: "ELO" },
    horizontalSeperators: horizontalSeperators
  }

  const getCleanEloData = () => {
    return eloTimeseries.map((elo, idx) => { return { x: idx, y: elo } })
  }

  useEffect(() => {
    if (refContainer.current !== undefined && refContainer.current !== null) {
      d3.select("#" + refContainer.current.id)
        .selectAll("svg")
        .remove()
    }   
    if (
      eloTimeseries !== undefined && 
      eloTimeseries.length > 1
    ) {
      setupChart("#" + refContainer.current.id, true, chartMetadata)
      renderChart(
        "#" + refContainer.current.id, 
        getCleanEloData(), 
        chartMetadata
      )
    }
  }, [eloTimeseries])

  return (
    <div id="historical-elo__container" ref={refContainer}>
      {
        (
          eloTimeseries === undefined || 
          eloTimeseries.length <= 1
        ) &&
        <p className="">NOT ENOUGH DATA</p>
      }
    </div>
  )
}

export default HistoricalElo