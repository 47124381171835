import React from "react"
import SignOutButton from "../sign-out-button/SignOutButton"
import "./unauthorized.css"

const Unauthorized = () => {
  return (
    <div id='unauthorized__container'>
      <div id='unauthorized__background' />
      <div id='unauthorized-banner'>
        <p id='unauthorized-banner__main' style={{ marginTop: "0.5vw" }}>
          ACCESS DENIED
        </p>
        <p id='unauthorized-banner__secondary' style={{ marginTop: "0.8vw" }}>
          Please sign out and use an authorized account
        </p>
        <SignOutButton />
      </div>
    </div>
  )
}

export default Unauthorized
