import React, { useEffect } from 'react'
import { userBehaviourConfigurationsAtom } from '../UserBehaviourConfig'
import { useAtom } from 'jotai'

const UserBehaviourConfigConfig = ({ aggregator }) => {
  const [config, setConfig] = useAtom(userBehaviourConfigurationsAtom)

  const updateConfigConfig = (property, value) => {
    setConfig((prevState) => {
      const newConfigConfig = { ...prevState.configConfig }
      newConfigConfig[property] = value
      return {
        ...prevState,
        configConfig: newConfigConfig
      }
    })
  }

  useEffect(() => {
    if (aggregator && !config.configConfig) {
      updateConfigConfig("aggregateProperty", aggregator.timeseriesKeys[0])
    }
  }, [aggregator])

  return (
    <></>
  )
}

export default UserBehaviourConfigConfig