import StatisticDisplay from "../../../generic-components/dashboard/statistic-display/StatisticDisplay"
import { getStatisticColor } from "../../../../helpers/user-behaviour-tracker/dashboardHelpers"

const PlaygroundTotalStatistic = ({ totalAggregate }) => {
  const STATS = [
    { label: "Matches", stat: totalAggregate.totalMatches },
    { label: "Wins", stat: totalAggregate.totalWins },
    { label: "Players", stat: totalAggregate.totalPlayers },
    { label: "Beaten beastmode", stat: totalAggregate.totalBeastmodeBeaten }
  ]

  return (
    <div className="playground-tracker__statistics-container">
      {
        STATS.map(({ label, stat }, idx) => {
          return (
            <StatisticDisplay 
              key={`playground-total-statistic--${idx}`}
              label={label} 
              stat={stat} 
              color={getStatisticColor(idx)} />
          )
        })
      }
    </div>
  )
}

export default PlaygroundTotalStatistic
