import React, { useEffect, useState } from "react";
import StatisticDisplay from "../../../generic-components/dashboard/statistic-display/StatisticDisplay";
import { 
  getMeanMedianCumulative, 
  getStatisticColor, 
  getStatisticsDisplay, 
  getTimeseriesDuration 
} from "../../../../helpers/user-behaviour-tracker/dashboardHelpers";

const SessionStatistic = ({ aggregator, statisticSelected }) => {
  const [statistics, setStatistics] = useState()

  const generateStatistics = () => {
    const { statistics } = aggregator
    const { sessionTime, focusedTime } = aggregator.timeseriesData
    const numPlayers = Object.keys(aggregator.userData).length

    const newStatistics = [
      {
        metric: "Total players activated",
        statistics: { cumulative: numPlayers, mean: numPlayers, median: numPlayers }
      },
      {
        metric: "Session time",
        statistics: {
          ...getMeanMedianCumulative(statistics.sessionTime, d => d.string),
        }
      },
      {
        metric: "Session time / day",
        skipStatisticSelect: true,
        statistics: aggregator.convertElapsedToString(
          Math.round(statistics.sessionTime.mean.int / getTimeseriesDuration(sessionTime))
        )
      },
      {
        metric: "Focused time",
        statistics: {
          ...getMeanMedianCumulative(statistics.focusedTime, d => d.string),
        }
      },
      {
        metric: "Focused time / day",
        skipStatisticSelect: true,
        statistics: aggregator.convertElapsedToString(
          Math.round(statistics.focusedTime.mean.int / getTimeseriesDuration(focusedTime))
        )
      }
    ]

    setStatistics(newStatistics)
  }

  useEffect(() => {
    generateStatistics()
  }, [aggregator])

  return (
    <>
      {statistics && getStatisticsDisplay(statistics, statisticSelected)
        .map(({metric, statistic}, idx) => {
          return (
            <StatisticDisplay
             key={`session-statistic-${metric}`} 
              label={metric} 
              stat={statistic} 
              color={getStatisticColor(idx)}/>
          )
        })}
    </>
  )
}

export default SessionStatistic