import { ethers } from "ethers"

const isAdmin = async (contract, inputs) => {
  // if (contract !== undefined && inputs !== undefined) {
    console.log(contract, inputs)
    const { isAdmin: { inputValue } } = inputs
    const isAdmin = await contract.isAdmin(inputValue)
    return isAdmin
  // }
}

const getWeeklyInflation = async (contract, inputs) => {
  const { getWeeklyInflation: { inputValue } } = inputs
  const getWeeklyInflation = await contract.getWeeklyInflation(Number(inputValue))
  const inflationAmount = getWeeklyInflation /  10**18
  console.log(inflationAmount)
  return inflationAmount;
}

const setupAirdrop = async (contract, inputs) => {
  const {setupAirdrop: { inputData: { recipients, amounts }}} = inputs
  console.log(inputs)
  if (recipients.length > 0 && amounts.length > 0 && recipients.length === amounts.length) {
    amounts[0] = ethers.utils.parseEther(amounts[0].toString())
    const setupAirdropData = await contract.setupAirdrop(recipients, amounts)
    console.log(setupAirdropData)
    return setupAirdropData
  }
}

const claim = async (contract, inputs) => {
  const { claim: {inputValue} } = inputs
  const claimData = await contract.claim(ethers.utils.parseEther(inputValue.toString()))
  return claimData
}

const allowance = async (contract, inputs) => {
  const { allowance: {inputValue} } = inputs
  const allowanceInputs = inputValue.split(",")
  let owner = allowanceInputs[0]
  let spender = allowanceInputs[1]
  const allowanceData = await contract.allowance(owner, spender)
  return Number(allowanceData)
}

const mint = async (contract, inputs) => {
  const {mint: { inputValue }} = inputs
  const mintInputs = inputValue.split(",")
  const to = mintInputs[0]
  const amount = ethers.utils.parseEther(mintInputs[1].toString())
  const mintData = await contract.mint(to, amount , {
    value: ethers.utils.parseEther("0.05")
  })// do i use ethers for quantity)
  mintData.wait()
  console.log(mintData)
  return mintData
}

const addMinter = async (contract, inputs) => {
  const { addMinter: { inputValue } } = inputs
  const addMinterData = await contract.addMinter(inputValue)
  console.log(addMinterData)
  return addMinterData
}

const addAdmin = async (contract, inputs) => {
  const { addAdmin: { inputValue } } = inputs
  const addAdminData = await contract.addAdmin(inputValue)
  console.log(addAdminData)
  return addAdminData
}

const carryForwardRankedInflation = async (contract, inputs) => {
  const { carryForwardRankedInflation: { inputValue } } = inputs
  const carryForwardRankedInflationData = await contract.carryForwardRankedInflation(Number(inputValue))
  console.log(carryForwardRankedInflationData)
  return carryForwardRankedInflationData
}

const setWeeklyInflation = async (contract, inputs) => {
  const {setWeeklyInflation: { inputValue }} = inputs
  const setWeeklyInflationInputs = inputValue.split(",")
  const newWeeklyInflation = ethers.utils.parseEther(setWeeklyInflationInputs[0].toString())
  const roundId = Number(setWeeklyInflationInputs[1])
  const setWeeklyInflationData = await contract.setWeeklyInflation(newWeeklyInflation, roundId)
  setWeeklyInflationData.wait()
  console.log(setWeeklyInflationData)
  return setWeeklyInflationData
}

const reloadTreasury = async (contract, inputs) => {
  const { reloadTreasury: { inputValue } } = inputs
  const reloadTreasuryData = await contract.reloadTreasury(Number(inputValue))
  console.log(reloadTreasuryData)
  return reloadTreasuryData
}

const burn = async (contract, inputs) => {
  const { burn: { inputValue } } = inputs
  const burnData = await contract.burn(ethers.utils.parseEther(inputValue.toString()))
  console.log(burnData)
  return burnData
}

const approveAllowanceForSpender = async (contract, inputs) => {
  const {approveAllowanceForSpender: { inputValue }} = inputs
  const approveAllowanceForSpenderInputs = inputValue.split(",")
  const account = approveAllowanceForSpenderInputs[0]
  const amount = ethers.utils.parseEther(approveAllowanceForSpenderInputs[1].toString())
  const approveAllowanceForSpenderData = await contract.approveAllowanceForSpender(account, amount)
  approveAllowanceForSpenderData.wait()
  console.log(approveAllowanceForSpenderData)
  return approveAllowanceForSpenderData
}

const approveStaker = async (contract, inputs) => {
  const {approveStaker: { inputValue }} = inputs
  const approveStakerInputs = inputValue.split(",")
  const owner = approveStakerInputs[0]
  const spender = approveStakerInputs[1]
  const amount = ethers.utils.parseEther(approveStakerInputs[2].toString())
  const approveStakerData = await contract.approveStaker(owner, spender, amount)
  approveStakerData.wait()
  console.log(approveStakerData)
  return approveStakerData
}

const burnFrom = async (contract, inputs) => {
  const {burnFrom: { inputValue }} = inputs
  const burnFromInputs = inputValue.split(",")
  const account = burnFromInputs[0]
  const amount = ethers.utils.parseEther(burnFromInputs[1].toString())
  const burnFromData = await contract.burnFrom(account, amount)
  burnFromData.wait()
  console.log(burnFromData)
  return burnFromData
}



const allNeuronFunctions = {
  isAdmin,
  getWeeklyInflation,
  setupAirdrop,
  claim,
  mint,
  addMinter,
  addAdmin,
  carryForwardRankedInflation,
  setWeeklyInflation,
  reloadTreasury,
  burn,
  approveAllowanceForSpender,
  approveStaker,
  burnFrom,
  allowance
}

export {
  allNeuronFunctions
}