import React from 'react'
import { 
  camelCaseToRegular, 
  getStatisticColor 
} from '../../../../helpers/user-behaviour-tracker/dashboardHelpers'
import "./playground-detailed-statistics.css"

const PlaygroundDetailedStatistic = ({ label, mainStat, stats, color, statColor}) => {
  return (
    <div className="playground-detailed-statistic__container" style={{ background: color}}>
      <div className="playground-detailed-statistic__label">
        {label}
      </div>
      <div className="playground-detailed-statistic__main-stat-container">
        <div className="playground-detailed-statistic__main-stat-label">
          {mainStat.label}
        </div>
        <div className="playground-detailed-statistic__main-stat" 
          style={{ color: statColor }}>
          {mainStat.stat}
        </div>
      </div>
      <div className="playground-detailed-statistic__sub-stat-container">
        {
          stats.map(({ label, stat }, idx) => {
            return (
              <div 
                key={`playground-detailed-statistic__sub-stat--${idx}`} 
                className="playground-detailed-statistic__sub-stat-row">
                <div className="playground-detailed-statistic__sub-stat-label">
                  {label}
                </div>
                <div className="playground-detailed-statistic__sub-stat">
                  {stat}
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

const PlaygroundDetailedStatistics = ({ selectedPlayer, property }) => {
  console.log(selectedPlayer, property)
  const getStatistics = () => {
    return Object.keys(selectedPlayer[`${property}Total`])
      .filter(value => value !== "undefined")
      .map(value => {
        const total = selectedPlayer[`${property}Total`][value]
        return {
          label: camelCaseToRegular(value),
          mainStat: { label: "Win percentage", stat: `${total.winPercentage}%` },
          stats: [
            { label: "Wins", stat: total.totalWins },
            { label: "Losses", stat: total.totalLosses },
            { label: "Matches", stat: total.totalMatches },
          ],
          statColor: total.winPercentage > 50 ? "#009732" : "#FF3333"
        }
      })
  }

  return (
    <div className="playground-tracker__statistics-container">
      {
        getStatistics().map(({ label, mainStat, stats, statColor }, idx) => {
          return (
            <PlaygroundDetailedStatistic 
              key={`playground-detailed-statistics--${idx}`}
              label={label} 
              mainStat={mainStat} 
              stats={stats} 
              color={getStatisticColor(idx)}
              statColor={statColor}/>
          )
        })
      }
    </div>
  )
}

export default PlaygroundDetailedStatistics