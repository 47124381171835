const discordMapping = {
  "0x338C8bA36ab46E9DF84BCe741F903D01895ACAD5": "uEugen#2654",
  "0x8F09EeF679904F64752523f95dB664039dF278e2": "Heyyawn#1025",
  "0xa68d61cC389FC895AdcB1A70f12E7B91147451BF": "PARKER#0619",
  "0xc93a77565EDfc2b057204Ff17C60154bA8952c8E": "Bound II#8088",
  "0xDA3963Df8e6230E7cBEb30Aa3e92b02aed96cD88": "Peter 300yo#8313",
  "0xe8179007766746285625c5C73B0365716CF4Acc6": "Frozensense#5833",
  "0x2ad4510F4FeBc4386E9732D79E08c4A15d5e1758": "Louiss#1455",
  "0xa5171632D7f9dcF9435E724ac3caE0e2bbfb636c": "<3 Bastian <3#1140",
  "0x5b19b06F10822e99cfDb043B2c2594BF99B62a2d": "Rezistol#3644",
  "0x72a30F3E45c0E677fDD340FEDDeFb4B61c14be98": "Gunslinger#5764",
  "0x6b0785b831d89B71d260Fb396e328B24a0072a24": "Elrond#0133",
  "0x73fAFB1E5903b717e4fc3767aDb90E973D64Ab36": "crypto_natsu#4225",
  "0x5003CD28DEFF84883658a69cC573FA66131c08aF": "Lizza🔴🪄#3801",
  "0x22f4eA342Afb24F68bBCF61FEBE069b1B43C4d37": "Hurma#1243",
  "0xFED1D620e1DF9ef3860D409D6B644a210c264cb6": "Odyvanchik#0449",
  "0xe83A18ad7c299aaacbF52BbaF21ea592cf895C2f": "greyolisa#1771",
  "0x49D1ad89ADB60241Bf2Cc003fe57bcfD4d3cfD95": "Yotensei#0383",
  "0x1842241D3A9Af5472F1954984AA4ef022db26619": "Success Emmanuel#9602",
  "0x4D8C52776ddF0E615e3758A15Ca766f9128ACDcb": "Gnuhtan#2562",
  "0xB6A3BCEddFDA728d3f1e6fdFc33ff649ae3F34Bf": "MBC#1767",
  "0xCCe1B2454186835171FC4a6E4cC03a66BF9B8Fb9": "Quantum#5057",
  "0x0427e510038603e14868f22C02869a81b92Ca1E9": "Amilya#9337",
  "0xc10Ff1871f2B476e0fB165eba3DC1A2279F26D9D": "!PєгŦєςt#8326",
  "0xBe950949E2F550258908425F503754ab70096699": "Cryptah#7783",
  "0x32F8A0Db8074fd54b72F0720b70EACd0aa5e1182": "SHAWTY ❤ KOMORI#6089",
  "0xe2C458D13A3383BbeDb7c109F35F109897AAB340": "leedar#6406",
  "0xf2A1cF1aB0462E3c353A1e34Bf74eC51fb162D08": "JamesRic#9999",
  "0x0B95f218d9032eBcb9ea928c7621e2EC7d19E390": "Emryx.Smol#3753",
  "0xBCa5F00f856538dDFa1aC416221232fDbBb60f13": "Xienon#8230",
  "0x87D281d0C523F7822Dc0Ecb20c5bdDd414631926": "10MG#7387",
  "0xca2a640a45f74c404D4De26e65145F3c67820d69": "Grossei 3 6 1#0361",
  "0x572238a09B9Ce7d6AcAd34647323A0505D4CB6bd": "BERKRİBAS#1111",
  "0x22290CF466a8458bDfB4634F8FA465520d32E526": "Afford#5247",
  "0xBf810ea3CE238777ba649298aac7dAE41e5AdED9": "yigit.0eth#4220",
  "0xe2724b88E93ee76d60E72c372317daDAdA0Db826": "tabgnuh#5015",
  "0x5ec4b3a9187C29a3152e6992B9b3F01957e353F1": "Matcha#3960",
  "0x39C4373e0A5333B27f4F11AC6BB9ea3EC5C25350": "MCG#7810",
  "0x34E3f9567aee97397Ac7A002dF2ef4f30193F1A6": "Dumb#4786",
  "0x41B4B977856F4706DD83eA15836B91BFaDa1e28d": "brawlerlife#3659",
  "0x73391c743b653ea9949ece4f42457b1d780d6c1d": "Dimastor#9853",
  "0xe794eAbFdE3d0057299240C1a8e793e2ffaE320D": "Ladydhie🔴✨#6999",
  "0xaAbFa8bBdA5DA1a92b7D6A50994431723AD43337": "付geij#0182",
  "0x1B510c98e9B28a4a900485DEC260FE203A56F4b1": "CHIZZY#0149",
  "0xd2A339e61A8d94e769B1C4611049Fa5049f383A9": "TokzOmonBoT ⚡#8920",
  "0xb099E9A4b4e9dEB1D238BF1eD04Ae4383BBAbFdB": "0xavier#5661",
  "0xfeAfdd268E7Be4506b1cA86Cd52ED5B14648e21D": "coutience.eth#8888",
  "0x909985Bbb2a8d304E0D21BD157183f7484a7d74B": "Hưng Hà Nội#9366",
  "0x94d490aD18943e81D4B2398F98d54ADE55b1ffc0": "You#7337",
  "0x6D515cca2fe21146786287361C4053DaF47d6A6a": "minicat#7650",
  "0x2Fcd965a6C86A42C87b5bf69D8b031A466b52245": "magnacarta#0416",
  "0x9739Ec677d0bDdEc9848f399661d6540E4Ab695A": "Jayy#6470",
  "0xAF53890720728A1907AEd7C8604Ce1CD945B853F": "💠NorthsideAbN++💠#8468",
  "0x9217d8B3a7036e507e9D04Ae1969E395ca308947": "Huyyy#0648",
  "0x53Ad5695943033a75878ae747ee613937D213c8a": "Phuonglee#2624",
  "0x0d96AD8611A92EA434585eeA619b3831AB971cEB": "Tairuc#2557",
  "0xD7DB97c051A8877827F96508872956B11c22ccE3": "HaiMai#5505",
  "0x50F7CE24D2D4E1D9CcC926E86d38528395CFdbDF": "Nightwatch#4781",
  "0x7C371650c4e09726aBD60A2acB67EAEEF4E4c973": "Xuuu#5135",
  "0xe405Ee05Bd35C9863600b077C66c660E24052667": "HoangLong#2393",
  "0x1D1569E8ECb88C0cb35F8920649f1aa66173A8Bc": "Nunuynungning#8021",
  "0x9e31D1E48dcFa8579c2321f3034E8587B3ef49CA": "Ohrin#0483",
  "0x667eEb11e3AdFC2f3A8e30C2787f74A2760eCCFe": "uzura#4238",
  "0x5969c80B79364665321588244AC0e947A83670D0": "Ranjan.Eth#6294",
  "0xC481e16b75664FF4fbdD373669BdC312B74fb0bA": "wenwei#8018",
  "0xF7068AC4765DC37703fe087bA5109d6990676bf6": "Emma24#0045",
  "0x04bD3f2E7067Bcb6FA3fBd9F3B63269FCb178c8d": "DENVER 💛💛#0900",
  "0x7Eb99BE8AD95b44D7e6C603dB6b6565E592BA77D": "Zion#4037",
  "0xf32422641A767d162345CF831Cdd3b87DfeB7c7B": "jeremyberros.eth#7288",
  "0xCc4068c550bFA4D4c28dc69D25EE9FCd7cd03a42": "Carliyke#3530",
  "0x076d3027c688115fdDF0Ab2E42057E8f732801Dc": "dimdimich.eth#7275",
  "0xE6cC5e3EBB07B5156ba3aF510B8c6cA19804d88E": "小林#4343",
  "0x66633Cc6B84CD127A0bb84864c1a4eA0172469A6": "ibO#9214",
  "0xCb12102493E513eD5e32FBC36864569bAd2812Bd": "rehe#3482",
  "0x232fe3a7c0b29a2D143fF73A5e75A2514e625cCA": "EpicHuntur#7408",
  "0x808627790E51b8f5Af4e27066A3F282DD46c5992": "JCW#4843",
  "0x5B9bA25810320349964a9F0b2f2429aF9B9d4518": "popeye#7218",
  "0xCEdf866187680e70296a8b9EEce22A09b4F05798": "chompk.eth#9502",
  "0x1F8f66e4442eeab82616f203e735BE1f687EE790": "The Reply Guy#4145",
  "0x954021052072c6B6d8E1fEb5FA2C093CBA72a344": "Shadowfax | AFK#6442",
  "0x758f2CB83536b055CAF08B8C2879Ee9800065222": "NaufalNc#8550",
  "0xcb33e46104c36AF37a3BD59F49F2534573Bc19a5": "iphone8888#8626",
  "0x43d93D13EAe9f45dC5D3429cAde891145591CD95": "caniegg2#4820",
  "0x6C432C2eD5Af0bc996b771A42374a8334d08b100": "Gentlemen#4995",
  "0x6893d441C432e78774a4241994FD9A09d2399298": "VCVCOMEDY#7492",
  "0xdBD85D3373cE7c3954bDDeE710ED1A682b064b07": "guantabean#5022",
  "0xFaf6bb8F1a300130fB9dc582DC8363a50841882D": "cunha#0897",
  "0xd99F500aEFaD2Dc73Ed6FA8334ddCDE1AF40E78A": "flingz#1641",
  "0x42197cA20498AA02067E4EA90C681cA86B8D1299": "fmbot#5108",
  "0x5F8e076429ff938e5730CFB2035Df213F2FA7aCC": "ALEX#4165",
  "0x5F399AaF88796f51207aaAe3F4c4eC9546C95517": "Rusha#5564",
  "0xA412e7dF933694D609ae608056119350974897Aa": "Integ#7864",
  "0x85D1784838Ca65C9a40131d84AF77f72b801F77D": "Aleksish#7530",
  "0xd6d9097ff875B15Ec9Cd992c7174B04375984dB4": "ANYvay#1479",
  "0x98040039964ce0c8D3d670c445e5236fE8C45734": "satori#7087",
  "0xd818105E4590A318208bc1e93D90A4e936286ef5": "fikus#9895",
  "0x6aF6115ecc5D55A5310a96A1dd13e52647E2d0Bf": "itori#2254",
  "0x54FdcE2387E0eD8CC34e6d2E7DD7A09B098bAc55": "Giosssssss#3203",
  "0xCabeA9e7d0137E415b27Ac8e0C8d2Eb1bFFC0520": "PRAISE 🍁 YGPZ#8858",
  "0xFA0e9f4D799990905749e33bAdEC5eb0FF518617": "fairu90 ♒#7550",
  "0x452F73ae79b9faD0eB53c888cE51f678C0c14273": "singhjee#5910",
  "0x85fF4746cA2AFb184c654c442F7a75d3F96A1e96": "Jerry66#8924",
  "0xfE667f848521Fa56873EeDBCB4B06489b3a165B6": "zaderet#1402",
  "0x45d0522A4a524521e7278Cc9F83eb3730715a43c": "Viviana#6630",
  "0xDca46f42f3E8B19C0496d1cDF66e1201bd4d995D": "yar#0058",
  "0x0E075DFaaF5868a13d0D6d5bC751bB958B410879": "Dreaml#3091",
  "0xc52f7cF4Ac6C05dec4829244eE9c55d870cCBae3": "Ilyakartel96 |shrst|#5906",
  "0x12b0E1B718b33d16512BD7A81C3680fAEe3C7e3f": "Vlad B#3211",
  "0xDd46CEd5C3F422C6E3A816B50b472BE80ED6F7DC": "DevinHoang#4836",
  "0xE6A5C296214830D5Ab45527b83780AeF065E3A2c": "Ryanhere#9927",
  "0x1844490Eb4A11be77D2fe75219c327c1f264f74E": "Gametofu#8856",
  "0x6dc4C65095113d50f4358070e3065dEd6680Cf58": "Kid8#9473",
  "0x579689E7e25E49d85B60aD3b6eE6fBfb4bAC0229": "iSonGoku | Suited Apes#7134",
  "0x9CF7aDeB14c2884a37546De3F15cBdD832D4E05D": "vadimka2021#1960",
  "0x02659749Ef7984256C4F2c6fd68a0DBd24E3b40d": "JODU#1919",
  "0xB1c41450CB8a11A7e605e8Be5ca30A4c9C9231c9": "Ayanami#9999",
  "0x8b99F3458986Ab8e39cfDf286184701701132Aa8": "Bon1kk#2894",
  "0xdaccce559a0571083556f39d05b177579613d83b": "0xbot1337#6356",
  "0x07bCEc513720237f0528826458F9dc98311315cF": "Red Queen#3196",
  "0xb8d21023dfc681f24d927bb7bf35da98a9e0740b": "Bagg#0815",
  "0x8316B461fFbE4Fd7B489363e45eB939771B0fe23": "Dread#8210",
  "0xeD1d5DA0059f42ad19fb9837f0BA7fDC674Fe877": "0x1YFN#9601",
  "0x387afC424f012BE2B45efc05CAC6DFD64BF772A8": "SkrongMeat#6563",
  "0x225a8343AD3557AF4DAD2e747eC238E38Ed59E09": "Shinymew58#9100",
  "0xc0c25993b7bB84f0875df0F4549a709c8baC6360": "den13#3529",
  "0x295C5A220E1eB0D7471D458462Ded316e35A8c9f": "Dreams#8886",
  "0x2a36A7411575E6583d4EDc3657a7dB0D41e1eb89": "trankoutfit#0599",
  "0xb4EE962f90fC1ba210611Ea294E28CA3dC719c4F": "lirrato#3010",
  "0xB1e216335d010B76d107C7EB185737f01bFA1732": "!Awesome#1807",
  "0x98Dc3c457667c2935A17007115056aaFC3084E09": "adrd#6805",
  "0xb200663fbEAE3C28D898453Fb4Fd9898cF0Bcbd8": "donaldp#5828",
  "0x84A3DcD3D287cDc655Cb05fD39D42F54b8d9863A": "NevY#5148",
  "0xdbc2c8d9e8B2fB44d95750DB1BE97dad4ab57005": "TomSuper#2870",
  "0xFeC103dE85A1F86640a10F820C0f3B881e4fE55f": "Pray2Earn 🙏#0572",
  "0x3A65eC820722905D65262B0A8feB7359Bc1E7516": "M I A M I#9687",
  "0xA41DCEe235F7F8Ab2C7d8a3e36fdC63704c142ae": "fvsvy#0636",
  "0x3B1b3edB62b527a134E5a7541F8231A3840486e1": "meji#2669",
  "0x8BAd9676be501bED290A505E0Bd8549d3B1eE6c7": "iamfitz#6213",
  "0x807FF00d177fE2Dc683a34cB2Bc9e79A3Eb8DAc6": "qwantro#8214",
  "0xd14D32F30b184983d3360c6F4b6593d41eD834F4": "xx_sky#9356",
  "0x0e3E500a26F817ca961853A6e0D08A2191DC76Ab": "Ruzanna#7359",
  "0x5F8cfD3F2aA1CaDA3631E3b61f1d4C66eBcC2Cf2": "El Gato | Delovoy DAO#3461",
  "0x51dFD12B6C49011096cAC7090e2C14B6Dc0b7ADB": "Las01#3711",
  "0x9C0075ed4dE522Cc3B395eB2794c7685F8B55374": "bocha1911#0610",
  "0x55c16a31387740395bCd5A6Ad893b5CA6e7d937C": "George#0526",
  "0x46cE0A2cf70Cd72773d8aA2a7B309295Ab27b366": "Firuvirg#9999",
  "0x837E8503c208fc57558BdeC6e46690029887c0DA": "Helen Worm#6874",
  "0x8B9916967988c84064e83280359b8d2D7D03CAfE": "ivanjan#4551",
  "0x7C95a955b65bb68728c2c33c7B1881e5a06f2352": "Iam_Hidee#0929",
  "0x129b72758d421C36682DA113cb3A7F180231059C": "FightLegendp#9765",
  "0x1262c0eaD0D58e04A90E1E654877c6Ddfa5C76B9": "edick_fnd#2977",
  "0x132d0dEE3E54F796747850bc4C64b4341713c240": "deliamayami#3355",
  "0x9767e34D3078aA592F28d18Ca60776A5b0311332": "Flad#7986",
  "0x44DDAEB8d4703751b3438F926aAf9747D2bd2E1B": "Amcet#3931",
  "0x0539D8A753808a56C0BD66cc969EF246dE4fD5D3": "GuoGuo#5351",
  "0x57c1A5436F679eF9B6D5e3dC511463E9c99B6e8C": "Grdn#2003",
  "0xC83894b4d9fB103227dE2b627F51Bd0B292d5372": "SonnyRobinson#0887",
  "0x4579e110E060Ab6DD0F6f73500f29BC3be8e8ccb": "Caine#5094",
  "0x2D750F37415EA2Cd039b8e0b20E37671E742B05F": "Miyoool#4744",
  "0x4aC4B956207b33B0d188Aa111fBC629B7A8fc2e9": "Namdinh.eth#6789",
  "0xab2ea973d3abE1C223dA0C85dC1962E2Daa2064A": "pirate#3175",
  "0x2e0b0D80d401cbDAC7564510a4f0E30b025f8AA2": "Saulong#8888",
  "0x36D8b45636275eEBf58e47f5d0c48A3a9490C1cd":
    "moneylikewater | HODL & Retire#1529",
  "0xf946CDAEcF5A15440e500E61A0c1d179A93652ad": "Il_Il | Delovoy DAO#9036",
  "0x92f44A009a4dF1597dF9249d9F21F5087755Eba0": "0xphash#3819",
  "0xcC07Cb7253A9af00291Fdfd05842Fe6b27964995": "UMANN#1122",
  "0xee4005B5096bC1D5caE784229A2e048CE41C144D": "Fleizz#9667",
  "0x5664400251E5a6862dc09F2FBC0104C4233563DA": "Ahmet..#3837",
  "0x8B2345B2BDeD3c2353E630A77a9b850A89b83206": "sahh#8039",
  "0x1EAB4AD157587c7d864aB6a23a37655ae56e384f": "mart07Alex#2415",
  "0x9F65b4fA7D241fFcCFbC2734B602B27D4D02e8d8": "Sardar#8443",
  "0x897C91E7C677D6932bF62299d318504c7176d0A8": "! .wunwun38#8571",
  "0x27017E401a67BA3a92cAD5Ee8f4b3621D6bdFD75": "Rango#9891",
  "0x23d85F17eb37E59eF23ef04209C1db76d15467ce": "the_Mitchell#8766",
  "0x6F9968393601c83ab5b639f8457aA0C2A41124C2": "FisherM#1700",
  "0x0cE8786E033c44B3b338F30bE9dc1fF67eBAf003": "PlebeyBob#4717",
  "0x82c854504F266dAd4a7eA9E448Ca7B1572AebF1C": "calicocat#8307",
  "0x75A81f84cF5F8f7343aC010710e67878A74aE64B": "Danila#3508",
  "0x23EAe9A5a26F550071ef8Cc86e74721F13FBEbEd": "Rel3ase#3368",
  "0x6E84FEA51C989E7456b7Ec5D3fC9F0E3Efd55A61": "Jennie_nft#4061",
  "0xEC97CD3771b5f1fdbe4673c597b06f4c7ac389E3": "CryptB⚡#4665",
  "0x1bE2e5c277f77679888B11c9311680fe873d3a3b": "JPEGG#6666",
  "0x0c1d3db79E0580696b577f29EeAe5E72A1d8bd9C": "mila lila | IO#0901",
  "0x28c7c46aF5640C8442Ad4beeac7dc140F231be5c": "NONONONONONNO#1232",
  "0x4f35F7EC953d61D77329E2cC82D032c332F84473": "Achamaru#5094",
  "0xf32Fc6A0dE35046f3ac0f179F39871764cC6Aaac": "Thanh#7686",
  "0x007241E945b3FDDCb427F3b421149e83994EC174": "diidii#6220",
  "0x75c873B2eb1C34E57D259363a539B13b823b254A": "Gombo#9226",
  "0xC05da9ee7E393CeD9f91f05aFBF32a1CA56c3249": "PlutoTysin#5898",
  "0x70c4C63f7A2CAb84b5fE92614EBf378b50315cBb": "cataction#8843",
  "0x28F5923B4603dD826de8f183dd5FEdf27DdAF8e9": "morkovnyy_sok#9748",
  "0xcA77380D0caa2D2EB484A4C0C92926764CA9587b": "vammac#1595",
  "0xB16E5B468c85654Fe7e37B1C5cB3eF802dDA0933": "Jhonatan Kent#6998",
  "0xC92f2e9629084e7747789E02a46A0F50D9454D6D": "gambit18#3971",
  "0xC460b9bc529eb51B3008AAb230b7a8BB1dA23864": "ss044.eth#0001",
  "0x9C69598977DD3161Fb665AD9afC2A026FC34d9CF": "someBody#8422",
  "0xf88006636611e16122D94055568d8C4f4E0C85FF": "mandelbrot#4426",
  "0x389242923bd38d0920Be319660eb331fE3E71313": "Uelrong#0610",
  "0xE418acD494c30639ff0B8861194F041115065bBF": "DRY#6706",
  "0xbc1d5F35Ce8D85E179d65B143d7C4c4B955366A1": "APE#6822",
  "0x14C20AC57126e436F464b1123a6C326bDdc486F6": "-s1ren#0433",
  "0x74d4bDaC83c98Eb49B380ba5720EE8F39C20647A": "Tydolla#4552",
  "0x555326e079eBA8d5B38F4CcC036CABf2D57A1c8a": "Durkio#2155",
  "0x2841B0731D0946934002c1a37379B9243EAE64DF": "Zebra#5097",
  "0xE7842814852479F6ebcA74a5c87d2B89997F878E": "wealth.420#4948",
  "0x9BA82C668ACd8175076F88A12Fb234853Da49222": "chamberlain#5097",
  "0x428795A32cbD5C431fEd3DdCC9Ca2aFCB31B8B84": "nefor228#2873",
  "0x379B520bc2717e7Fc25335697D4dF4b125A19836": "🌍🍭 Obito Uch.#8971",
  "0x58563C2Deb69154b571a74Ae2fA1200dBeaE99fc": "!Tae Ho.eth#2185",
  "0x721B9541fDcb1CEE2b500c84e5dbDFAF9F909736": "Quinnton.AI#7308",
  "0x657766e684a6B2201fFe33D1abe4ed213904E14B": "alesha#6947",
  "0x20D67fB04f9673cC71023cb256B35Ca0A08ef61E": "Saltzpyre#5920",
  "0xF2F83d8B9a34E3d05995585B4F939708eD515f5d": "theJester#0540",
  "0x2a3c275D29D0123553734Cf071225578f70B53dd": "ВеЗНuКа#7283",
  "0x1f969B59a1F901CF6ab71e351b04ed32BDe97CeB": "heytos#8411",
  "0xd7df40f7581e008E6fA98625d482407E5664Dfc3": "niklub#6456",
  "0x402feD0B8070fDb97bF122a47e10cA2cea8c8AF1": "hastos#4514",
  "0x6419Ec2B6BA7D03f918a2FD186471317e7E2A9d8": "F1exx#3175",
  "0x0D571Ea38028d456d9A25fde7541981e1989f9eF": "rayKon#5269",
  "0x78bc586c08214b94E2AA8Ac468bbF2BB15781bc3": "LEMONADE DA GREAT#5780",
  "0x5cc041F7f484f318AfA4284cDE1C9044F59a296A": "plumper#2739",
  "0x250862e4f63a93a9140cbf53dD287167a2F58BCA": "REKTerino#4489",
  "0x9eFccBb91e5AF85487B1948e0ff9b66454fF816F": "orangina#9151",
  "0x7912111ae75019Af011909dc4dd8d36b8576FA0B": "pоrfi#6812",
  "0xaCD6cf12E6FC2177bbE394577ff993AfbFd5d3b0": "buzkill#8002",
  "0x7fB1863641762A3Db73c40Fd50B34B52529f4c9e": "osips#7242",
  "0x9D08C594597343fFbD383c4A7C291f92C00C8d1b": "baboon#4315",
  "0xd39E17194c2852BEc3a5C710269a6AF7A76Adf35": "NftDad#6665",
  "0x6Ef6265b3A2866aAb07aaAD3338cd034481f9cf7": "babyBox#5555",
  "0x6Ed5D6f66916B393335D8568f64f0029b3A2d911": "Andrey#4720",
  "0x1875bB2306b31f7E7eb1CD4377224a7468c48B58": "nayacht.eth#1607",
  "0x04F4EAcc7414361e7C6EC2f738A256dbe0F4dcEc": "Kasik#6267",
  "0x519D0Ee973649cE2C220080A34ca68C7A3A5515B": "Vadym#2131",
  "0x04fd3c5e3d190751679f73c0b9830689eb0c735d":
    "babojlorenzo | Elixir🔮🦉#9218",
  "0xcE0F797044B70a392779c14Db84C63EB0F9eaFB4": "JEEMBO#8748",
  "0xa6C63AAa7008321eFd7693806FEFb928e82A28D2": "Kirieshka#0626",
  "0xeff766e48ed36249ee88a6f1a0001b64c9a8e88c": "Muram#8654",
  "0xBab48F3b108395b90aF0D5E9ff8a4Eb938fa6cA8": "FERZANO#8725",
  "0x0Df24735410f4559dB7726Fc9157E25dC665d44a": "hichori#9549",
  "0xA60096FE256468691B9726d2A64F90a9E3BdD288": "MellowSurf#9354",
  "0x4D27834E1e3138c7cA175578cA5011C4B920AF1E": "soldrugg#5430",
  "0x2C0e7Eb821597B8cA070780B609fD8766bbCf3bf": "BE POSITIVE#6155",
  "0x0e21Da34D52C05AfD6279466c6638c3c9C921fC7": "gyram#9395",
  "0xc8371Cf5FE127BFab19301802EfB8B321E4E43ae": "RybiinGood#1174",
  "0x56a17041E0b0907b1b5E4caF16e60BE5f1D8EC0d": "Serian Zaret#0577",
  "0x6E5BbEBB3Ed596b3d40fd11863D539610a6AB21D": "frizi#4926",
  "0x8DAf46F435B05A91347e320A365B53839C143de2": "avanpost#9387",
  "0x3DCa991a6E817D3C5246e8Ae75b9351B9c5E724E": "Bahtiar Safin#6503",
  "0x4BB66194c2bD9eB64b9999C1365ee5761CD65E1c": "TruongTomato#7903",
  "0xB0261152Cf8a9Bb27eecF64cec07C6E8dd23Cb1C": "wandou#4786",
  "0xd8D9FC5Ef5B510d96eE126D77eaCF253a7dD4Db1": "Stenzel🔮#5344",
  "0xA3deB012Dca06b12a7e9AE06738fCCB4D39a6324": "noka#4588",
  "0x69A3FB99De7EfD79D17CBC65E7AaAbC9942144D0": "Bo#2944",
  "0x9763771312dfED5BD8F14c224626be2aF6c4102A": "supbobap#2205",
  "0xBe20B35CF9Af2201651AAEbcE65878f33768A4CD": "kaijupepe#8264",
  "0xF8e14268123D04971F9184b296eA5d285b33506D": "Otto#7212",
  "0x567fe7A2c7957E490FF03E13Aed730Df4133373a": "Take#5468",
  "0x154E788403879B0Af847920e0C5179C25a8e1E43": "NicholsJose#0729",
  "0x6FeD0030fA2e9c81C0E2a4acf79C9235Ec298C26": "XannY#2636",
  "0x78b5b27891f079Cb32F40A8727299f05e8da3bb1": "izi#1676",
  "0xc14Ac4Bd04932Db4C522bd6C78BB57BdcE64F233": "kyu#8482",
  "0x990822dED1885d4a5789E5296dBc4F9B5d939C64": "TounsiHeaty#3832",
  "0xa7016f2A3E16F9faedbCC867554e56CfF3c409De": "ezoptionc#5358",
  "0xbd1149556925859CdCc9DD377653b6dB40153e36": "Hopee#0260",
  "0x487Cc3D8b0b12c578496F4E3f60C906BC4F7d1ea": "DanyaBel🔓#3612",
  "0x57F3b960229E45958f073Cda61d220132CF9B9E4": "Lupich#8993",
  "0x078E2c5c7dE1d478AD3BD1695369EcDe7e245123": "AWER#6419",
  "0x4EE7e0cA12A463668E6B19cC32a61AF7f57F0e95": "Konstanta#8641",
  "0x3709690Af8ed8905a87918f1932CbDF5cE7D7E83": "MRXXX#5508",
  "0x8B3C00782aE2B5048C5F512FD1Ae0ef3c41b87a4": "AndAnd#5413",
  "0x24337B9fe776C99E91A29Ccfd50195b11C35E974": "RIT🔓#2664",
  "0xa6869cCf639d702b830Dfd30cE74511521aE3BD9": "Nxnja#0719",
  "0x18D072a783FaE1AC81Af576276DC1561cfba1013": "This is a demon#2286",
  "0xFb3a60e22c71bA8730431264195e264dF240D9F4": "LeviButler#8371",
  "0x23b4FB5BedC49e465cc009017b367592F5D48347": "TequilaP#2426",
  "0xE3CFc808a6F2E5B7470B98a333f1E01F7A1f56a0": "Slatty#6270",
  "0x0b7a3f9Bce82Cab2A2Afad006949De353524b290": "DonaldJacks#1737",
  "0xe5e01254AaE93DAce33a8Fa747717F4F14cbD71f": "Keiii#6564",
  "0x94b0001EBaD0fe14367200DAeCcF6e76ccF83309": "eason#9714",
  "0x01Fc82918629CEb70563a11b8E93b8228F44bD71": "NFT Lover#1477",
  "0xBd31683659d01916102a717ee8AE955cA3505FfC": "Up&Up#5508",
  "0x5a8978B580299378c75bab85863410cCe641F2b5": "jedimastergrogu#6856",
  "0x9755e3d858a3310e9915e490104435ed4f0D7547":
    "SnakeDoctor | ContributionDAO#6654",
  "0x8C9BcA3387211260C2B9cBC3a3D44ABD8EA80ed0": "Lubna ali#4517",
  "0x2cd4108ADE226E30Ee0c1A0f3Fad133204e6b810": "Liljoshy99🩸#2200",
  "0x9C0401eE46B6CE5BF6fF0689868123399283a61d": "L1ksss#3551",
  "0x17F41c0df99Cba57aC7122D118C49A95afE63799": "firsssyan#5504",
  "0x712Ad10Efd87224DF0FD2e3B24cAf35458F5Fd52": "Milamber#3402",
  "0x0AD14ACB2A5E65798A5D6d2513899175193Ea9f3": "baltazar#4230",
  "0x4262aee75228282F2C14fAea29529b21d92a052f": "ruleside#8146",
  "0x679E6f1734F8BD51209e8Da6F79Fb1b148ED8E00": "YASTA94#0126",
  "0x58A44fC6C5AEDC3Db7da03BFcB1729c7f7BD2bfd": "javelin#7439",
  "0x875A2Ab34484E4a4dA38598B294875829a790Aad": "Marysya#0761",
  "0x4975b5AeF773EC0Da1DB62d67f63F0BbCA8dB295": "Zugar#7314",
  "0x54BDBB547c31B7B20EFe828A3386e2571fDF60b0": "Siratius#5673",
  "0xf4dDB124EB2638BF7f4e8d9c6f80b427452F1Ea9": "Dibei#8819",
  "0x20BE4F8A4FC24c19c30f65d42b18a37910Cab17d": "Lyni#0129",
  "0x5e311C3C18A21C873bC3aF89368c51e916D7da53": "Bruclinski#3072",
  "0xa043F3544E95232789B5D8cF15E815e1D98BE3aC": "Magni#5968",
  "0x7C47E22b481D81168eF1C324A55EB79a73eCC55d": "Chark#0167",
  "0x7E3c20788C23D603833Ba3C85Fd41C939bc946b3": "DufGas#0454",
  "0xeed20DBf942b901C27Da9E77aD71F37fC56a4d6b": "glib229#3861",
  "0x86d9eb05BdD41f563e9976881A07D7Bb01bA8C1e": "RODDY#4271",
  "0x6302e181A4101ADC9F0ab17909D91acF32cDf29E": "Geeskee#2233",
  "0x21361f2B1B46A5DAd5c9053A7f2a5f3BB75E441c": "Song#4355",
  "0xA1326b2d260EC56b981C69605104704f5A8A17Cd": "4real#4864",
  "0x5189f760440d458bA73805e87B5F45b33eB0d7e8": "Dresd#1917",
  "0x11d8d80147C58b0F89FCc592cbc1d061Bd2D3517": "ketra // re#4832",
  "0x86A580e1BFeE03aD9f2db1951a4DDF87B0513D01": "Donny#1845",
  "0x19615162481f7A3fDB68e8C9959B831C5B194704": "evkotec#4286",
  "0x98e8494DEb48f06Ad426555312284055623aa9cF": "blindboost#0624",
  "0xB41FBD338D258e675615c9fb96b48D10D1B60B9d": "BIGWARZ#5498",
  "0xd65F453adF1A16f0F1760f614f67069AD28f55e4": "UndeadSwoosh#3229",
  "0x03520BA4BC13f9a70F792E012450f3C6bd83C4c9": "SOLOMON#7777",
  "0x265C328BF0Cad7F6FCddD375A4c02754963E3a2e": "hujiawei#7633",
  "0x497aFeAa28934011B7372EF35e12856e9555452C": "Kinase#7777",
  "0xC9909df08b4Fd56aBD998b2C3abFF54Af0C9378b":
    "thecryptoworld | ContributionDAO#3222",
  "0xa883569009ccb6dDC6331B2D1670136B3A130222": "Vag#9816",
  "0xaA9D23D0D82DBAfe8e92638A14acb6F354A2D7E8": "bobbynft#7411",
  "0xf53708fdbE8C2026601cBc6c779715DD14c71cE5": "Tednet#7064",
  "0x82F885F1aDCA2175C7F62DcB85dB83289C03F69C": "StickMan#0058",
  "0x98756B34d1678a077636cC2f105c9aD1C1a72d4D": "TheSmith#6359",
  "0x54B8C0be8DA3075A19b35b1E74fC922226bf4147": "Someone69#3699",
  "0x8533895dC490E35C9cbd2481e955b3776fAf6a18": "Heghegy#9311",
  "0x3AEDA9419f484847ed7ff2B2160714F99C849288": "herm#3419",
  "0x96884f677fC1af19C7ddC29B6F9C813C0Cd5eDB8": "Saffo#2631",
  "0xce63c5152D9b50B5c5E7b6E6DEC974057E5ff4c7": "Torgud#7467",
  "0xdb1f6fcBCeFf9fE6A41738A513556602b6923558": "Viktorviktorovich#4897",
  "0x75D9B1ac82a51F45eB0144B4a59bA8ACD397E436": "Sonti#8733",
  "0xCCBa69125C09901aB0e9A5bEF6ec9F0c6f138C77": "mivls#9573",
  "0xCE17cB7423F8CDebdA42Db6B535DD97B13138d8a": "z442#1191",
  "0xFBc337E411a311B35d8A7de40E67c4061CD92D42": "LorenziGeraldina#9321",
  "0x82Db0d32FDF0C975Fa244ce14c27b37D2Ca281a1": "illest#1000",
  "0x4A5E7C8C33f1306f24C923e2FDD984584dD25cEA": "kommersant#0891",
  "0x43936D1eBC65F423707CD18ab98EDb98001D7525": "!Nikita! $_&#9860",
  "0x717C04155697D2f5a878C9Ad7c8E40322C1dECF8": "Fizerok#5701",
  "0x38a4aF18d0Cbc405aAe0c8Db17aa16fCFfa066E2": "Ahaka#9094",
  "0xAC3F4f024d7Ccc4A40Cf174f3641178EAcffD114": "Oleey#8884",
  "0xac0D3F725500786bE15DE3D5487d8E547E98D10F": "Kemiks#2845",
  "0x9a6185CDb0D83eD8319b6E01F791b8DFcB9425Df": "edotoday#6983",
  "0xa6C11f4DeFFC6b06D0bB7A64244cB9E59a7D6718": "Vitamine#2964",
  "0x42e356d39e9f49c2aeF711cF6675799403788315": "ArtemM#6725",
  "0x6A66Fafd732AdaDFc45A75a9cf13C9991BE087ca": "Minister#8763",
  "0xc315e4C2c24C1919b7fFf28180e45CE663702Ac3": "calderone#8836",
  "0x68341D57f652fb1844cf86948f10d8D806656DA6": "oooevolve#1437",
  "0xfd208Bb92E87Af1E1F199D28f1D2c5E6b6628843": "Harlen Termin#9054",
  "0x230d9D7d5e0894ABe19194C287D86CE921c0d272": "unsuccessfuljanis#4089",
  "0x5Bf206DE868b14f1b4ff4047a5be07B8062916E7": "dwivolvo#6579",
  "0xa9A30F17F4782395329b0589898Ac96E0a808c9b": "panelmods#6732",
  "0x5D0fe6822E5a15DA8A449EFf88c3348F440c5Db5": "camerawidescreen#4146",
  "0x9C70a89B84aD5f66e4caD11e3F50123339d779C2": "nipplemotors#1169",
  "0x5dC5Fd0aD6b9b6A5ee8f0E455C026F883A529ddC": "incorporatessky#4168",
  "0x6aEe7BDEcEFc5aB23684052D41cDaAA64762C478": "deadbmp#6763",
  "0x34F83604F5D9687478adb878FA72720f9Dfa9797": "regimeshan#1352",
  "0x78F3b4f28e96ae1801377E370536504Ad587A05B": "gregoryhammer#5667",
  "0x40C0a345564803fadDDf59C0383A9906D688219d": "ambitiousnominees#9110",
  "0xB446A7aD857EEea9B1861E08C445210a2Ad6bd5f": "congregationbrighter#5195",
  "0xAc6471411Dc4E0545bA3c7B18f7EB0B9e20ff9AB": "chipsetundoubtedly#2379",
  "0x66A51Dc26A55481007763Ba65CF939b6E39A1F31": "LANGERIUS#0001",
  "0x7A8E3FA872D54e04ceB5af0Df98D2Bdc874A0FEc": "structuresmates#3015",
  "0x79D68cedD224a735f7c9988c1a3a3E666e049e5a": "Joker Bad#8503",
  "0x025327fb2c733ca6f636cdE8918FBEa5E044Caeb": "gutsroll#1167",
  "0x662D79f59D2F242C48C90bdCD361212f611A215e": "marthenatad#8399",
  "0x469ea08100ef03316755181718fa0F60983AC764": "tastingflick#3873",
  "0xA045BB2c43bfb1D4286E88CA60355e7A0D9746cB": "!kuzy7772#7397",
  "0x8d4b451293F116d8d8fb80B07a4Bd6cDAD25960f": "Alex-7#6485",
  "0x5B9413F33784db3eB998299e6989FcD159173A03":
    "Emush/The Northern Nightmare#9095",
  "0xF7c04C78BE9686cF516CF49D7FE1f984eF10D3F3": "Rambel/Simmple#0817",
  "0xC8e316CD4F755dd99DE16b26e31d05043a51B7F3": "Zimmer | AI ARENA#9858",
  "0x0e2e1Ef2E93c8879EBDb5949876560dad13a0C27": "saymaname#2656",
  "0xA7F09908578D9e26DD3c7346a2a4597D7a49f5Ba": "mclover#5079",
  "0x0AC94Fc93433bb78c088daEF1CBAA65f43b3eA88": "lookatme#2900",
  "0x37c7FE67f0Fa3C92d224f0E4270195e0fE1D9Ed9": "doodos#4953",
  "0x135d69c6D5b2CE9b73E7BdF6F54Fa28c2CE3d22C": "vodich#5777",
  "0x9F50183812BF7DAD0967e6CB42aa041919c13026": "simpET#6635",
  "0x687699a8fE88aF5B8F485e3827C6d4Da4db08249": "No homo#6712",
  "0x968b0a8f8d2af93c153c79b8befd48c717459f08": "amallama.eth#2200",
  "0x799049734208ff2db9d7ac7cc8b3f50ecfb8fa5c": "Emi.cr#2173",
  "0xb3572ed0e29fb74bb1d9ede62e1e8d8113d8c6e7": "Grim653#6566",
  "0x01ffaf01926465331c4f8ee5c0f04859050b8f0f": "pellosophical#9376",
  "0x401e8451c4d91D027711ed56Ee568c0a1144DE1e": "?Lissandro#5176",
  "0xeBF20F951D5a9e524AF0C4025D401F7a54c28aba": "pinokolado#9768",
  "0x739f73Fa6FfEe472039afeFaEa67F1158509A2da": "Licy Fox#2196",
  "0x9124554Cbfe5ec995DeC1d7437fea8dFf50e5CCC": "taras.shevelev#8847",
  "0x12812B5956897274ced8142e73D0d0213942098A": "MadMax9937឵឵#7676",
  "0x62d532175b8fC0ed9d9cCCb1291c96CC33b502F8": "lincoln5#2482",
  "0xc4018BCe077a6201FbD24D0B557010dc242e9572": "Antonio=)#0961",
  "0xF97EEBd2cECBe8CE2cD653310FA85f984eA59Fc8": "Sammie💎#4349",
  "0xb2bfc941a3bb4e3131b6042f14730af4c2a83099": "WalterMoody#3809",
  "0xd2843a9612eb11f5932d6f75c12f29db086d0fdf": "Kuck#4918",
  "0x009db3ebbeae57f691ba4d54148f189ae66d944f": "Mikky#1278",
  "0x156a86636856ab9eb80eeee50e7ffd8c20f5e7f0": "Kerry#9953",
  "0xf71419c0245528726b19fad1cea248ec3749c94e": "SavardMonique#7692",
  "0x91A30F4Ac30f2Be615df63Cb80d6864BbFB2Dbd5": "checo#1893",
  "0xB5bE8485C43AD021E0c18fA92C7Ee8E5d0812831": "Sweety#8113",
  "0xDca9A7d4a7c103f0608f3596A89c97907FE0Ec13": "KATLAVAN#5500",
  "0x7a61c7e857B6C27296B3CDef4EFA8EB2e9C9F729": "gEM Hunter#7887",
  "0xCB87EAf8db5d96e2722DB0240DB35111cFAea7C2": "fearless#6102",
  "0xBd4F594062028bFBa110FA4f0BcAeec96B69a4d4": "Ower#1700",
  "0x99561Ef2e7A5e23Ee433d75aC443D22E372c7162": "real#7973",
  "0x8f5419c8797cbDeCaF3f2F1910d192f4306D527D": "travis#5879",
  "0xBF96e5cf8Ab4d69B7165B47Ad133F2f39F9F866e": "Manera#3625",
  "0x7e92Df08751062DDA8eE87Ef16dd8b22AaC1f427": "bururik#6073",
  "0xb30D3f2C97aA24a1f353906227d321e1379e1Bfd": "shadowdance#2905",
  "0xDb8c9f8b07f62D8e33C966196546d3CF0f5d9Bd9": "koteek.eth#0140",
  "0x881063A599db04de1a6E5D7cB097BCEd6Fe63c20": "Bashka#3888",
  "0xCf1e5ad34db6a294920b11d81f70DA0DcC323B82": "NftTrader#7639",
  "0x9890b9970EDcbd96ae89008aDbA2f7319eECd7e3": "fruefrue#2491",
  "0x6B1aDc7D9615a15dc8E6493117fb2395A11dfb46": "alina_tyan#0307",
  "0xCF3933D6F8B188c9d48D28Bf4c657F3475bFEd7d": "Karinaa.ya#7300",
  "0xD05F7844BfcAa02fCcb398BCCE73117EB410796D": "Ruslana274#8573",
  "0x3c04A7B0A5Ae1529dbc79DB2B4B5de03Bc19abE5": "Paapany#4967",
  "0xDe7A2f2c2207d1e2e3FC106457EF9EFbd1935885": "liliagreez#6433",
  "0x544cf82f0fB460c27A182bBf212EbbCe4bEB9DbC": "Skyders#5885",
  "0xd9627fca82B5d52C8187C1A20d2F8F19F7B94565": "dotaplayer.#3078",
  "0x85C123D5Bd9eD080Bd98420Dd2A58152861722A3": "Rucker#6892",
  "0xdc22eF23E8Ff1642b41A6dAD3cD2b94De3330362": "Cryptogirl#3496",
  "0x54f5e4281Ef0175BbAD7280c7BFe0cC2CD5d9E05": "kilao/SImmple#2918",
  "0x4a99b8640De00913F00b53f6Cad1ade28A785097": "Donald Trumpovich#7771",
  "0x35829b2EebA3006DfE8dFC9d2086201b64aad9b5": "GreenGeorge.eth#8135",
  "0x2d39186b9c3D42533F82C42DA7AF00D7A039f5Ea": "KilahBennet#8570",
  "0xB754859fe0ff9b179e5e248A8c11D3E70C16b938": "DripTooHard#5208",
  "0xC628A8DED94F00F04eb00462AE02c62f6cA96519": "OG Chief APE#8976",
  "0x90b96F18E31a3A918DFf7775DA8f8eCd9A0FCe59": "_DuBai_#9999",
  "0x7ff3354256f9A5c877467D2b590A92b414aB495A": "GB#3296",
  "0x9Ed36Ae520DA0726EAC5D47ffb8E7b2EcD3Bf69d": "carskyy#2740",
  "0x8443fc8631C3D6cB5Db7feFD9deD8bC5531EB3Ca": "Tizzy#1438",
  "0xA1491395aBe23aa93b98aDa679338e481436eEc2": "grejk#3883",
  "0xC4127EFF178C0d5e65502d82Ef1Db077d895695c": "LΞNS#8880",
  "0x17EC46Dfe1f765b793D65F6797454904f3c40A14": "Influesii#5877",
  "0x29f46A92078f6011A47dF128aF735208782579bF": "Black fish#5827",
  "0x7227fF860eCa62DCE5f78f8063492CFA92B87B51": "Origin#4784",
  "0x96e1B172e0bAe16b4CABecc7E676baA95E1Cd91a": "alkopatymaster#3689",
  "0xCD61d40fb1Cf0E4B1bFA26860AC4E1ce6C931484": "ruleGamburg#1920",
  "0xd669ccE1335664c906EF4D129493678240837411": "Oracle#7337",
  "0x25F0dF0BD96419Adc1F25d4d31cDcb599ED0Be5d": "ArgentinaMan#8836",
  "0x64Dc0420C6D0e78844284b73c83D5fD10CE2cFc9": "k0rnlagher#9185",
  "0x1A03E546BCF4D387AFa4F62cB01303f68b92CBd8": "Better in this world#5622",
  "0x5575884eb391D1E4ac82529F31C7B646eE10d0d1": "Torkkum#8922",
  "0xCFE414948A0Ab9436CC2E90e7b607FeBCB1364fE": "KoliK#9474",
  "0x2e3c480e6A7467C3bc8022957aEed5D39D5E1fAc": "Lak |#1789",
  "0xc547319ffB2576f92542D5Bc2B11093eD70b3f60": "Reci#5841",
  "0xeC9d73a9391d2458a20e6b0f29fa8E39De7fa14D": "fly#6177",
  "0x941a670788D8448F164037D47e582A337f83946B": "Jon X#6390",
  "0x03890660C1b6470c908d1b7cCfFE395b700726D0": "Skalala#7121",
  "0x161181B1905845D5F6e1C610B1403abD0E7c4d37": "Yu En#7495",
  "0x466d334be3E25eB87cEFD924de923e324Ed8765e": "fear me#9192",
  "0xDb14b03745f63a99b8BdA09a9fe8be14b7403c84": "APEXXTHR33 [GOONIEZ]#6489",
  "0xdFb16033e5EF88d603E50cDd605F216965a208B1": "Lesic10#0454",
  "0xBEa7725BdAB61ccAB40C69d6dE44186e17eC4BDf": "Sazon2000#4955",
  "0xf3ffd0B01c309196B06E6dE25d20740892c4FC97": "😤😤😤#0729",
  "0x103483AE4f2685D6904D75Bb4BCebc6662a39901": "BekzhanX#6283",
  "0x26a6f0b4D99Ee65303e2304A418e493B121E17c8": "browwwn#5971",
  "0x64f6DA613359E4a7A8cad5000d83Cf5A344FC5D0": "Irsquader.eth#1646",
  "0x1606059D724a104FB2406E355a0b4BC742BA1fE7": "lord stiven#3718",
  "0x1689479B118470eE6401FDa07340F89EE4De17B5": "xoliday girl {313}#9196",
  "0x290a3c11b965d7e3c85d4dbfb83e58022c283ff8": "77thESO#2381",
  "0xf666d9cedc3e9eafeb685c37c96d49a37ac69b24": "DIVNNFT🦅#9306",
  "0xd2eB4C244889132773C6AC0aE8f79224c842D2aD": "Abdi#7789",
  "0xe3524Df1d592045419D95d3dEA7206635Cd8Eca3": "lovit#4281",
  "0x2322f9f82d7f2db1501e1510ac288fcd122d80c3": "Afrobeat4eva| Woop#7734",
  "0x7A822cD1C9E09B3514f3Dda97b85b7746CeDeA23": "lena1818#3991",
  "0x05c6BadBa2EAAfD659feCBd74b3E776d3D1b7Ae8": "kodack#6666",
  "0x0D57f506F15d469427B5c7Ca1fB785A6A5014E6f": "fedor090#3487",
  "0xA07fA4E3aD5F75c548f56ebCa69a8Ece7a5b8a27": "hellokitty#4658",
  "0x73F2A6549F2f26a77884fbDcF6685C2c991Cc264": "nahelmnahel#0865",
  "0xB0981Bdb066a70E47d9F55c52532ccD574Aa4356": "marigemarige#3994",
  "0xdC82E234eCA75848ff4Ab167dA758702942bb22A": "hasad#9064",
  "0x91eA01E13B5EF4e17cC0f9908ace303F6B71dAd5": "Максим Худяков#6173",
  "0xF031e6071BBb571B5D636a2B40A57be3ED21aF05": "fazan#0885",
  "0x153E77b408C0b4B3a4297A48140B384FF6107344": "sashs#0453",
  "0x6e44C59CbC18F7061D200272Bd119e263b6a08C8": "Alpha Kong | Pyposa#2065",
  "0xaAFD5e10ac2E77cbc13978E856069cb161b8833b": "InesAlarcon#9312",
  "0x222F20d265dd268F09BF2A05F8999F316D735F6b": "dasa#1842",
  "0xc1f7c3cc390c130A5e56c5049Ec16428502f1f4A": "gassd#7142",
  "0x45D563321CAdA5439266E65D55FF7B2f044d31c9": "BlakeHunt#4172",
  "0x5eD3136bc100C238C1bF724152D4a68EA5557f81": "AgustinVazquez#3021",
  "0xccf12cD16b57E52C49660dC6C5c5C792296Eec17": "RodriguezDorothy#8813",
  "0xDB9D28D5a9829692569DB8bE3aF8E572F760d6ef": "𝐁𝐫𝐢𝐚𝐧#5520",
  "0x27A5868B40b59708eDc6a78D5D3c0D0C56e9879b": "Irinanft#2713",
  "0x012AD1C658Ed61c528B23100b1570D90b3960Ec2": "MAEP#4054",
  "0x53928aFd352Eb1b3Aa1b35D4FCD0f546cBB6Cfef": "Anisa#1446",
  "0xB6fC6D756A8EA7E8F6ba386aeA7F6BA364592B52": "Starufa#6663",
  "0x2Fa2DA68D887CbFB41E455F448D7c8Afb25EcD41": "NemesiS#7615",
  "0x93023590cAf6ae00b11107e9312a916De9244b30": "Classmethod#0272",
  "0x580c136F7C0378FB0C5fBe036Ba1f5FaB5F9e76B": "Herryson#8869",
  "0x8D8Ccaa5567cCcFFF90ADDdC5AF2AafC42AaA06C": "Blackone#6655",
  "0xf5A0D568F34b925eA830Ea80F5867609B01A5daA": "DimaKirufa#9516",
  "0xa3cf3Cff4B6264e58901D312B94458B5E8ABb060": "SashaKabluk#6133",
  "0x1261C3993b7012d942654F2738CD70753Cc46A93": "Lenar#7669",
  "0xe74a12e1bEFb0d65a399db1A6e231abD8Cf4E746": "BitMade#9932",
  "0xD03cFF3C2F4a66e331402419eBB2a25eB5513D00": "McLaug#5908",
  "0x9295ee94Fd7Efe448488BF3F2cA553B9a4FbCc0c": "PERESHKOWSKY#7917",
  "0x9D131269A488bE7B7AEF8321Ea82241D2E27d76B": "cryptogame#2510",
  "0x0a2ee23E8172E5dd4E5Ba48f413dAE74A0db6F21": "olegoleg#4433",
  "0xc88D4b1C777176F235AFF0714CCD44830c02957C": "danisimo#2226",
  "0x5fFf08Af4eE8F8e50ee163102327Ed829Adc9C5D": "goong#3299",
  "0xdCE8DdFfadECa5f26D64B895c04F47aa1CfF004E": "qawa#4259",
  "0x1FD08fa1B094f7eb2e15F4527005C99316B3552D": "Olas#2583",
  "0xDC448192baf23a5AF13d6FD44a3Cb781cAF2B5D8": "LEWWWA#8368",
  "0x84064BE50BF51BD5a81E190Ec21D1c7970E05697": "lordus#2753",
  "0xE21bC45599e0F5d4B2383215749e4d0BB30A62E4": "AurorePepin#8907",
  "0xAa2dDb0D01e0b5162d70FD7936C3B6E3D4F95515": "JaredWilliams#9517",
  "0x53E4Bbd2419b6A5A9B0C903f3E88cdc2Ee95cEA1": "Piter Parker#6478",
  "0x4a438782E1C8a9501D568edd1843e019D9ab61CB": "MassaErico#3130",
  "0xBBE49bDeebA69808D8531c032Ad6C0e588907e2D": "collectingnewspaper#7775",
  "0x626369ec48BF8af2178e4aE2F2984E05B62B94cE": "Prado#7413",
  "0x40f134a644092A5624442ED4eF58fc63291b277C": "CasaQ#8928",
  "0x32F5d4E3a77B0129d6cF159d3E13d69014597434": "rotatingburnt#4815",
  "0x71C0E88D0bb157BfC9f3c144C4D45d87cFc4dbF6": "gynchiii.vue#3406",
  "0x24B62964d6dc18e3239c1D5BF939645a492d81B9": "pipelinesminerva#4161",
  "0x37CbF240F37bDA36A28966232633D96CaF851a29": "violettein#3007",
  "0x9C3F8dd9deFb271538C1bbBcFe2d3Bd6FA7ed62E": "robinsonworn#9403",
  "0x09c9F91e2e89b9795C3231ddA63F33D2c11fB937": "clergyelectrode#1394",
  "0x0711BC8407AffB42B2Ca934E8cf104E2069b8f77": "verticalsevere#4519",
  "0xebAF348759F0531Db6077f8adb0b89876FE96b0A": "monastrains#1713",
  "0x752732451f96Cc443b8EF45D9c50A4c036BEe077": "culturallyhypotheses#7342",
  "0xb4141B7186D2E552C315998C207775a805Bf05dD": "oddaccidentally#3427",
  "0x4a7809F853f961dd10c4316153d26Cac66c45920": "pintradisson#1544",
  "0x8fF6D96B7616cd202af43BecBe3480E1F9FF1F8f": "florapursuing#7350",
  "0x8DE6594F4181e584B8cDFf6F70aD7d1650BCdAF8": "NPF#7289",
  "0xC1636Cf644E3ae4ceB8F1bfa1A64cf22013DCcA3": "neoplasmsfocal#4426",
  "0xe1c0C6bCBcD85E800847218bBa6c64F622671368": "arobec#5149",
  "0xaCD5Be55b34d84259a9D74c9Aea43Bb694661819": "droughtnero#4873",
  "0xB30244bcc979962393cE15276B03A78799EdCe66": "Archi Frank#4292",
  "0x3001EC904f63A6F1aec2543aF4Ea5323b12e671E": "Artik Monkey#1830",
  "0xFd093c4EF01103c26472e2444b271b1A2ff0FDF3": "Walker Grind#0142",
  "0x3D2840DBC35e00CfaC4e24e73A4B71131e8dAF69": "failingshemale#3085",
  "0xc122E1A5946288C9Fafc58b3ee8dF442f569F6a9": "marcussailors#4709",
  "0x48DA861212a06eC2bDC1b6057B0fd36f39ad16ec": "0xAndyS( ͝° ͜ʖ͡°)#5536",
  "0xB7AfD74637f0d96a04187Aa035c750F29eED8cD2": "slnilild#2745",
  "0x6fE0894bb0a2D505B3b0deF1B58D5aAF93006ab4": "boda112#5042",
  "0x0c4133B49fB1FC7c14fC6BE62B0f04885ac7fe2f": "Maggy#6515",
  "0x79adB35cd093a6456Ca6A71F27039c6Eab477F1C": "dobryakilieviller#4597",
  "0x6597Cb2A196bff57228F7fe6C92B141AABA743fe": "RockTheBest#7451",
  "0xd160d5C2e8E5FE25355926D5224b3F44dA478088": "!! Phoenix#8196",
  "0x69CD9ACd35F1be66b3a85077930ED7A2CD0764e4": "stepforthestep#7196",
  "0x17A1b7700136B9BFDB4b3A4865dde1B940B8562A": "pavlickpolucopPPP#3992",
  "0x439dc5D05b0090F926D235543A0763D7F58a3f69": "alfamenALFRD#2485",
  "0xeB483F271B94B5216a00690F25a0C3EA04aB57f3": "EmmaCapelli#4938",
  "0xBCdc8a39526664062f68F07dD4F60CF16c894246": "BiagiBeata#0868",
  "0x52F9e269D4a1e4fD02C72029d235939993D2E6Df": "AdamEth.sol#4390",
  "0x18bdB1Db541707b7ADC67a4f1EA50aA0Df3DB55D": "frik#2166",
  "0xbE9423D0F49850bB9147Ac83E6F9558e792787B8": "LillianStevens#0229",
  "0x561f42F652a22258db62E308eA020d278D713b1D": "Anna-KarinNystrom#3253",
  "0x75e55b456429A2fCd5e24Eb6C46f58D39335af02": "marysking/0#0286",
  "0x0CB376D29bf23bA9F528E3353287D8AB245A8dd6": "babuskaboy#4455",
  "0x6dfE76bA02ccec97829C40337db9133B970219E3": "aptos koala#5928",
  "0xa21af7Ce6a808cF6c4329B1589158e7C86621ff0": "stiven=)#3244",
  "0x2c58Af2Ea9C6D44E39126BB735EA9332E9EbbC2E": "PaezMontserrat#2944",
  "0xd33bc431B2EC112bd7718EB7348c45d70B0DdF67": "TheWhiteze#9712",
  "0x6F90a4996c5224E016Dff8e34b92099732A40AaF": "Dreylow#6616",
  "0x736A2dAb996c3A0d73F166660Be5b29385C77889": "Piggi#3501",
  "0x4c1285Adaa0Cde68D427f402a937dEd523FB84F7": "Jiemi#9520",
  "0x4425e36E96ef4A47bFA39C65174ac4Cc93dB6829": "Shane95#3395",
  "0x615BcF9adEE28CA7097E54414F69dDC45C6AD28c": "Skyxemperor#4768",
  "0xb3A889FbBD71531591f14F1f0cEAC2485c854DFd": "Amogus#0659",
  "0x02C07322Bfd5DBc6aa58dD1Ea55EB9BBfFfdd11b": "italian_josh#3309",
  "0xD8C9d486d515cf30689520aF3ee6A40769Df4eFc": "Toma#0637",
  "0x5Fc6f621d6e68736A3Fc9075b691a721A96AaC55": "ayo1x1#1935",
  "0xebc212c8f416b6c2e8a252c83A56A3A955Dc6402": "Daniconelx#0272",
  "0x5Ea81a84FC14a73c7A9b18b72fF41a270C2441CF": "Carlan | READY#3815",
  "0x1a387793d05B98F3eda6529453D8D6FB8f08EE1C": "GaspodeWD#7793",
  "0x5003Eadf3DBf954fe45ef7685B75D7664C493279": "Theonething#2808",
  "0xd08E770332F66b1753C0f1536328D8C0B9464BEF": "DorothyKaren#9990",
  "0xeDAa54654E242eb3Eae9afE97db945f5e6b80A7B": "jenniferlOlit#7306",
  "0x74a878dFf1B1591b66DED56220F214cc71529B66": "dorothyyyes#4350",
  "0x8255eF4Bcc6874aEcC3983185aB3467dA8624FCd": "linkedlnMONSTER#4742",
  "0xd1fD544f0eE1187730667dB26ff6951B4808F00f": "Lays Crabs#3841",
  "0xcF61B6de27aa0a5fef3979A16E955a251eC784d5": "Dovgy#3055",
  "0xEA6D82C5aC15ca958fba6F1793a6567bFA0dFd7A": "Sanguine | Delovoy DAO#7298",
  "0xcbECbFBABB83b5C6951213b44e257290fA5A7Cf2": "Lisaa#4466",
  "0x40b96780e64121D387E91eC0B29b0cdB22abE2D7": "Snowman18#4901",
  "0x596B95df352BBA53e1B2623414f14fEdFd76E00d": "allex#8214",
  "0x323d8f31373CbA9b69F6F4879dcee65B6F2cC199": "LilCrazy#9169",
  "0x7b4Db86B9c497ef68f6D956350B35d9857154042": "Vanhouse#2017",
  "0xff11D4B93cD990fD93233f61A32509ce70ba11Cf": "Dp#2473",
  "0x20e18ffAd9D8E17a1a12D93393eFD5ef248b2651": "Niccki123#4637",
  "0xe3707fb9671Fb21fd7bcBd78B753007d61762dFD": "Drjulia#3043",
  "0x37631Ad927b5E91ab9770f6313906b5092525044": "MaxBob#5977",
  "0xb000681ecab168C730b4DAA24EF03d37384b6A80": "avatar123#0866",
  "0x1A02645dcae300a96F73dE3088D52a1514C1c2bC": "AfanOfrosim#3107",
  "0x8a471451A1518D97a6d6b83d45059694520a3640": "IlyaVars#7643",
  "0x36d65ef5fd435aca8C4573F2183D6b5b05EB16e1": "IakovLun#3117",
  "0x6BD4FeC33B3C5e6DEcB787a26160419754F31A0c": "JLa#2494",
  "0xBf4F6Ff8c0f828ba13F0d271eD0b714A900aF219": "Yiğido#3843",
  "0x469e278cB67c43635113f2fc8D9B0f750eA9d923": "refresh#9232",
  "0xd904139A500B8bf24AC78B38A3Bd7d533656e310": "ElizabethTurner#4462",
  "0xB9c7cd9Bda16EA97eE99Dc63f17546D5E159eFeD": "Darzon#4137",
  "0x18706BD79307495251184d97Eee110D003DdAC15": "Ciko#7006",
  "0xb4317dCE072261ACd55479F0b9A6f0826f2289db": "Nicolla#7738",
  "0x84eaC1d9831e098c2E7f4CdEbca8e7E5A204f0Bc": "moe1no#9925",
  "0xe48e526089fc1898aD2E1C8a19D33f1AfC7d6e1f": "Shirubaerito#6919",
  "0xB6428926e60CDCd2222058d7D68C95b080079308": "Mershou#1374",
  "0xaCd2b0A03d7A9348130C3171DbfCd8951638777a": "r1m#0413",
  "0x48D6Ae0907aD143484e828993c145d334901817A": "mRsnik#7197",
  "0x330fAD81A3e7A2AFCBe71829cb2a42f11a02A871": "HelenRoberts#2468",
  "0x2005251ef6508fe840e5B34c06D8811552c3c3e1": "BlackV#7663",
  "0xCa63866e48b6985A279EDE4219Ec236fA747d440": "!Vlad8Kos♒#7977",
  "0x27f7477B149fBf99AF503C07aae1D2Ecd192D78f": "YAMOTORO#1144",
  "0xA73853485BC60C64BdB3cdf6fEAfbFe122fA6f36": "XZIBIT#7376",
  "0x3Bfbaf197495362C31126AB2bC51A8eb1A7d9F4b": "zevunya#8764",
  "0x0576c9EC8368046da18D1C406d1D5a00618a8D33": "impossible#1910",
  "0xC1acC407e20C2106db4C3a41377D18A813B39F39": "KarenWalker#7374",
  "0x0aa42C4A8A654aB778Bb77f44cBC1cd34891c890": "Donna Lewis#3167",
  "0x72F4b5a9b5f040ad5ab69a322E03D63a14676A99": "Nice One#7997",
  "0xA3EB594aBb6aDB85687590448c55f35457C5e1D9": "Boss_AI#1008",
  "0x458c21be5c8a48A069d778F824E91A2F7ED5061b": "2 0 0 2#9068",
  "0x6b179907b2DBFbe3EBbA4169bF96C8E28e357D77": "KevinBrown#6877",
  "0x47a59c92eb03ff3189207070d199cb33315dfb69": "-Solkinger-#5385",
  "0x1De0D70E1944cb6856E8Ed0AeE5828BCE6C8c338": "Raym#8986",
  "0x3bad965D6f75b4DcE643Ea4992BE9673e5357df2": "daS#4165",
  "0x52F206f2c59aD3351f19FDdBE4c60d7aa3791360": "lien#4992",
  "0x3a697db5E662D463391cb90d769603FdCe81c032": "ySno#2257",
  "0xd117674C1a1875d9b7DB1Cc3Af5CE174693ce458": "Guzm#1542",
  "0x2b8A6F300461c119d54953cfF393969be3801621": "BaliDAO#5278",
  "0xf6261a4e979DB5ad96ca53535fD9Ea2484D7C981": "Kebry#6795",
  "0x62824184b80D546ED740c5708DaFBE5c25274F88": "kou#0974",
  "0x6483ee59611e569cDA306c0806ff2dc8B39a9C12": "Dr Cat#1735",
  "0x7Aa767a2A31B11c65A7a2587AFb22c23Fcd60fC6": "Madara|[NOT a DAO]#8834",
  "0xFc95D89A9a6Aae60b17e730813EA46FcB384d35d": "Yahia#5229",
  "0xa420448B9FccCCB06c6c63D37ae468F9d20C02Aa": "Nekiiiiyman22#9786",
  "0x913ddc2A5c11625787700Bf83cB2eaE13dB1190c": "Hoakin#5101",
  "0xE48D78Df37dE3A724e3129B2CCC04262fd260fBf": "Aishiteru#8278",
  "0x4334D7971b20F7fcb63489F27AA9Fe12f61Bf917": "Brody James#8945",
  "0x0164277EA28785cD54CfFba3F41892e0cECa872f": "DeniEX#7425",
  "0xF14cFC2Af0A13F90Dc67fDD2087496A6917BD78b": "EthernetSilent#9692",
  "0xA5678AdAb88383c5bC2b58E53053b322a7DA1980": "!JDM ANDY!#2953",
  "0xBb2B9889aFA7BBcd2683dD79954B4140cb7aCbE7": "Rayan Gosling#2905",
  "0x18e006f7a00eCbcf82eb2a8dF0039dAc2a37f5b3": "Brazky#2122",
  "0x99F257D11D86B29487b92587B423Cd1805f51ad3": "UditSingh#4006",
  "0x47a873F4FaC4C0DB7335f2C7Ee5e5E2113Fa41A8": "Civvzy#3832",
  "0x9C3eD5dDd79dafA31a19d6a1eA0c3E6C6D3C2594": "findme#9275",
  "0x467B70bd612B485B0551C966c1e37342EE998E6C": "Bunbun#1449",
  "0x0B0E611Ad7c8750d3457EC9cfe20f39009B6c9e0": "SantoTrapt_eth#5668",
  "0xd78ACA14D96Ae2aC442DA1430E00f3ac648ce12E": "mahatirshiddiq#5341",
  "0x703F412F3bAf7E639C7594936fFC4EeCf3f4DF0b": "Alex Y#0343",
  "0x24176367b8cbc04057Cb90AA4Cc088cB3024613a": "Struggler.eth#7224",
  "0xA224935d47E2160CeDA9F68479f039036B3Dc7Ab": "Mak#9322",
  "0xB20B0A99562dA14Cb1ACf910C42d879398a755ea": "Ahmedovski#3329",
  "0x07cfE11144ae326Bc26C9a58082FABb12c260Fa2": "Sofia Boyko#1084",
  "0x1EbbAB3bcc773E009b881846db5fae1c1d9E3341": "Evangelina#0192",
  "0x7A3AF752AedC6185d25E535CA5896D094347034A": "Brandon Jordan#0561",
  "0xFB44877Cf79C93A9018ca28D892Bb65847F566F7": "Degen.NFT#5763",
  "0xc94Ce5F60A82F82CF5b0C45Cb5099833205988dA": "Leo Crypto#8917",
  "0x7D5471A2f6BE16567Ba17eE789c028fDaF39Ef57": "Ghost.sol#2854",
  "0x13b2478eBA2E566c6e747E962646a153Be76A664": "Lil sandro#2711",
  "0x8746980aF259907A9A61B416290B0E517BAf4EC1": "GEYmer#1674",
  "0xFbde59D084c44c3f9C7C4D2c44Ee50d5A5cE8126": "tochka1991#6610",
  "0xED47e9CeaA52aFD4699E1413bac3F60Af699b339": "Man121212#3292",
  "0xe8FAA5502a520eFaF213587cd4f7276f2E881Ed8": "Harvard#9319",
  "0x7d2a9C78Af92cfb548B68980ffa0F5889f3C1202": "QWERSIN#4179",
  "0x03aDd75ef5B57FCDd1103a46772E41850b675E4E":
    "Nikolay(-_-)  | Sui Bears#9951",
  "0x11d51D91751969f7e4A3B71E3d9F8E0c2027Ced7": "Ditrio#0085",
  "0xf8E5dfC64d1c1694aBe9F376588E435D1c3c0B9d": "neighbor#8843",
  "0xF724B197252824A299A30c7e9a82Dd0b1D4B1E12": "louis#9199",
  "0xefbBFaa81d573a0eeAE36152e28878e197c1D8BF": "AI tod#1501",
  "0x710ae01E43bB0B9E6A0165EE4687C0ee2EC3cdc8": "gateway#0908",
  "0x215eB56598e1DC4cF83d1A3C57D756eE5e95D9F6": "shaqima#9042",
  "0x83c3BE97C35B8C427374EB76925E720eE664f744": "jerry (CSO)#9415",
  "0x62a2f5e9f3dA9C3A1A63ed695C3c21057A067Cf0": "supper GG_/#8747",
  "0xb11c69f333d8AD829f03230a8f5c46D822ca9fB8": "average child#6969",
  "0x54d24315741b59C52eA9b565A8Eb0f4b0cD5fCa9": "INNOCENTzero#2276",
  "0x71F0Bf14334fECe5f9646dB18ffA80dC818605cd": "!NEO#4617",
  "0x766A0E20ab6E31DF6FBC4AfC1EDF720aAa496Eb2": "Sevark#4127",
  "0xF0dD3F48B58430abeEFB340066bF892B240d6022": "!🕺🎙Flutist💜#8522",
  "0xBcd2FA57Ed49694448dBc4F4BCE71FC7d48CF8Ce": "! Nike eth#2420",
  "0xDAB5859541BDC2E1Db76640F6839D13eB91529D7": "[A]lmira#9999",
  "0x7976E5Be57c3935220d5dfeAD8dAEb1ff6f5670A": "whatisadao.eth#8558",
  "0x7A5B55C22Be2119A880D173818e6df91dc2504eb": "LinerAndre#2629",
  "0x6c0457ad4409e98337D7C566Fe4ac94c7cfB2E68": "chime_szn#9006",
  "0x705158E717df46D7Be8BD0007F7D3e9c2D3178D2": "Gallana#4946",
  "0x75E3eDA998fe27613977Bd7EfBBa37bb045C2DF9": "EdwardWright#6003",
  "0x21a6b04f038e0735f4A7fCD64DD48CD96fdD3Df3": "MariaMary#7760",
  "0x80678256d273a9A71534437300a9B46711c9eFfF": "larakup#2225",
  "0xba599D1526952c14779e6A9D31D912C6A02f5B9C": "Nitin#1720",
  "0x179BE1aA01DE27F864a420281dd142E7e4196a7e": "! scarld#0139",
  "0xB4B6E364B808c970E5Cb3AfCcc2b8a04fBBBEFE7": "kenny_nft#7999",
  "0x5cf0e4eea53b383AA44E0a76B3a43b3688E3e40D": "Queen Nour👑#4992",
  "0xC555BB4544f9274C2D67bCb87187874076c284AB": "renq#1811",
  "0xb5E5fb46Efa3f7a49CA712Ac57a5E88db889Bc46": "Krinek#7626",
  "0x3cC457B4A89025f75d0d3079c423262A64F546c1": "HIGHROLLER#7529",
  "0xfa85624DDF379D24c68E79C418bA42aF289efd7b": "Acril | Surf Club#6905",
  "0x1e44341e7C349cf9C7F603ff165612d7C2bFb423": "fightformeford#9003",
  "0x52be3580601524652978648E872D0aA448aFC928": "MrDQ#0036",
  "0x378171F938B260997439C212B5604364F6f86E26": "MYSTERY#2821",
  "0xc7C433d41ec2172Aa726A6729fF1AfD43E037a4a": "sleeps#8026",
  "0xBc428193113c22519107A95D6876f0047B6b3E9A": "Radeylove#5602",
  "0x934cf2cCE2128554FC07e20212b72fFeB63a3166": "Degen#1024",
  "0x0Ff72ADe3DfEa15bd7443bf2F710f1783F769639": "Palpitate#6653",
  "0x42af845F92B815227201A51300E9DED0170CB193": "Ms.Pekabo🍓#7943",
  "0x5438fa17eedD8058B1AA29877AE8706aDbe2100e": "AndyGan#6530",
  "0x7B64ae1559346B2b2A8221D5684d1E2e276AfC7D": "MargaretRodriguez#0166",
  "0x19ebA8eD251bAd25D8C05D7a4736889D4cde779d": "Shithead#5508",
  "0x1f4b3584F9a2527D5c85448e6B2A595093A75A71": "lovertombert#0059",
  "0x4A458bf42248E6A1ba64ABe4Bd73AaEbfa980D0f": "aldente#2592",
  "0x2701677A1A6B83b1eab1E50D685fB37c757e19aB": "steve3#9316",
  "0x991721793085A9a1A860cdB3E59A99f59C9151E9": "! Fury#9388",
  "0x2aFF75aeB8acdd6866738B6236CfE6ce9B89870c": "banadhs#5491",
  "0x5AA6A1e52642F0236b1B1C8a850dda388Ed7F81b": "XxPROxX#6263",
  "0x5F6A6a07Da059F7f3f61C5EC5f47b969184D0286": "HaSleHaRD#4310",
  "0x72CB820F179B31C8de44fA17B899975B8F9B0cf8": "nikop#3258",
  "0x8A84417881949481BF5821c481FE9dE6A05E9CFA": "amanda |#9412",
  "0x16203152655A08D65E4770D7877F9d339D2E17f5": "Kaell#2402",
  "0xE85f74b31aCA5C5a67670b3Db3b56141543010F4": "😍😍 VICKY 😍😍#1257"
}

export default discordMapping
