import React from 'react'
import UserBehaviourSharedConfig from './user-behaviour-shared-config/UserBehaviourSharedConfig'
import ConfigurationPanel from '../../generic-components/dashboard/configuration/configuration-panel/ConfigurationPanel'
import userBehaviourConfigMap from '../../../helpers/user-behaviour-tracker/userBehaviourConfigMap'
import { atom } from 'jotai' 
import "./user-behaviour-config.css"

export const userBehaviourConfigurationsAtom = atom({
  sharedConfig: {
    shouldAggregateDaily: false,
    showTotalAggregate: true,
    addressQuery: "",
    selectedAddress: ""
  }
})

const UserBehaviourConfig = ({ tab, tag, aggregator, showConfig, setShowConfig }) => {
  const Config = userBehaviourConfigMap[tab]

  return (
    <ConfigurationPanel onClose={() => setShowConfig(false)}
      open={showConfig}
      title="Configure Chart">
      <UserBehaviourSharedConfig tag={tag} aggregator={aggregator}/>
      <Config aggregator={aggregator}/>
    </ConfigurationPanel>
  )
}

export default UserBehaviourConfig