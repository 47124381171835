import { ethers } from "ethers"

const buyFighter = async (contract, inputs) => {
  const { buyFighter: {inputValue} } = inputs
  const inputsArray = inputValue.split(",")
  let buyer = inputsArray[0]
  let tokenId = inputsArray[1]
  const buyFighterData = await contract.buyFighter(buyer, tokenId, {
    value: ethers.utils.parseEther("0.05")
  })
  buyFighterData.wait()
  console.log(buyFighterData)
  return buyFighterData
}

const allFighterMarketplaceFunctions = {
  buyFighter,
  
}

export {
  allFighterMarketplaceFunctions
}