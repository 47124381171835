const discordMappingsRaw = {
  "0xF13654d873BD0b8312521302BAdEB103EcE157FE": "Shirayuki#0333",
  "0xAd5d0DFc2d66947Dd5f565c3b4fDBD8518cEE6DF": "Khushas#6179",
  "0x5A1d993fF6E67aA214880C87B46255Ea32E24139": "LeonardC#1568",
  "0x6F3C12ad7C1B1D42254D4834b3f61EbA0707C29A": "USDrago#2848",
  "0x019B50e98ACf91101C05881fC7D00F498055e091": "liebunny#1006",
  "0xe642F9B086425a245764299B6dF6338e7De26687": "reges#1233",
  "0xF9177514ed35C854Af6684510D69F792666d1319": "Roronoa Zoro#4542",
  "0x488644615D30D320C7af1397FC1B89550ced0aC0": "onegogo66#3883",
  "0x6E549d0C0950f513d843C0500BAFC80385B18052": "Yoooooooo#2638",
  "0x2741C7A3159F2a01a19F53Cff8972a7812CF6418": "sisielkenny#7948",
  "0x50a120029683AC4dc0e0dd36e4634A806fef8Ef3": "ForZe#9675",
  "0x4ae146858189A053713CAF110cB602726632fe82": "Fogou#4076",
  "0x304F5A74479e5bbe9772bE094C5396036bB68363": "flavius#5267",
  "0xbe3dEA380A136bb8c8f25B6272E251D4aDbFB7b6": "akaponchik#9194",
  "0xda6b9A01A08259D5C4A271BEF3cf690cDd4044b5": "Alsael#2670",
  "0x3F624E4a0402d7aB627B7214e65b0a7C4A39Ef6E": "Trick#3571",
  "0x94DC07685C9e81757E979FE5CF1B85b2D2f0b358": "aSPY#8032",
  "0xF24137B4886a44d93d846337Fc7810243D4Ec38A": "L1B#6969",
  "0x0A1D7f3e0A5bf5B3497B7E7fDdFeBcA50469Ad75": "NRG#1986",
  "0xd171e53f2B06BE6a30f8854228C76D5424c587C4": "Wrecks#7673",
  "0x9e6c39f57E324fDA4D8397eE5a67E1A5c44a3f4e": "Yeshy#0315",
  "0xe4B9A82B5Ce11aa0F6BD3F95DE5eA6E635E483c2": "slattTophy#8164",
  "0xEa4AC3B51e75ff58de93d3ed6531D45C28a4C6C7": "Joezyv#3352",
  "0xD157F018c7A38849fB1816E4eEDb3C99EBed7a64": "Steel#0077",
  "0xEaCE06c09a26AA0B44FDD6Bcc730DB08E4711210": "Pain#2952",
  "0x1830c41eeDEA4F101688f1aa6b2C2eF9370AD48D": "kryptobkk#1414",
  "0x03F4E825479660AD98b345F840763F4b1495952D": "Archang#1895",
  "0x8DF6a2DadfaC1009442430CA40F8479d206f7673": "missingno | Suiswap#1755",
  "0x51663AE5742164944D1bb6cb2785Fdf839666Ed3": "Dworak#1689",
  "0xBceD1cA16A71bDF111c405fc8c3156cC64BF8ecE": "gkouvaki#1817",
  "0x1A32c6628ddAd52d66675DE6F9e0ABEB57a2e71B": "SlavkaVsMaxas#6305",
  "0x738512644257bD9269369322D18a0D92B0A64a2C": "Georman#1921",
  "0x1278c5Fa5061292F5E903bcF41D7267Ba1f877dd": "Mugatu#4099",
  "0x7AE7188d2175322db95A1323DFd53AD38583c70D": "Smol Preeminent#1984",
  "0xB6EA791Ee66c6813798743a5e5F11aFF170A1EDa": "t.3.c.h.n.i.c.a.l.r.o.o.m#1355",
  "0xF7AeE0C196c4EfcB7a16b5e17CdE8e6D5D0d01F3": "Citruzzz#1954",
  "0x4d38f31EFf248c76341fA9E4bd7Fd71521d10102": "bersezk#1470",
  "0x27929aC8b6d3761F86ead55AAFFc199414Bde04B": "Hantao#0001",
  "0xcE1284f0Aece933F43089c3077417d6BE30EC419": "cartjacked#3059",
  "0x2aa4dbf8462b3eec6e8b8134b6ba8f8cd84fd1cb": "Smol Phil (🐂,🔓)#3298",
  "0xb7176c03739ec10c4ec8e21a64ad64b228b8a5f2": "TheReaper235#8942",
  "0xc44788726D184FE8CE4f03d4C4cd54ef8464dE66": "SquareJordan#7041",
  "0xBBDC8E177B6eE005A7b858866cEDd1589540d3b8": "Broketopian | Treasure (✨,🧠)#7684",
  "0x6310a1c1Bd4e3460f35A7557068bA26fE5Fd38AE": "Grin#3153",
  "0x3060eB2baC7B4f8AB25Ef71858A7C729f421a326": "Tempest#5729",
  "0x42741DD36dB0Aa4ec6fa2b90AFe8250B1eC2e70a": "✨revlis#7560",
  "0x23C0954C4b997c58A1544717dE90C8E174eA194c": "mikeabundo#6223",
  "0x4C9E53Bc2551B8a3D785e0b59dFeEC02c9FF9708": "konikoni#4102",
  "0xe3CAdc86B296e57C55dCB7c9e90936e7517237b3": "BufoBats#3439",
  "0xCA432d0e2A7014679C3671597a6863892E511EbD": "FabsMac | Treasure#2766",
  "0xd98C255D11d7b928AC138F52a875B11A65844444": "zosk#8425",
  "0x585FCAc095302cdBC3a7B0CE4e1703913Fa3e804": "lookario07#6204",
  "0xB1E0A17F60aDf263fE79B1fa0d67A86c9770EF9b": "TreeeDHE#1423",
  "0x008d3AAd83462283a105fE590A42197864D0Fb7C": "3auserm#0396",
  "0xC99EA62138930D2Cab9781215dB0e56F887e4A6D": "Mario99#0301",
  "0x2552E53e6749ad306Fc44767550F812Ea0465Ea6": "cristacular#1096",
  "0xD6E2B9696dc45642ebB84Bb789ed68D2354D774d": "Kowl#8312",
  "0xf48Bc9240f513e763Fe7E0943Bc040c89Dd2DF8B": "HdOlivares#7777",
  "0x7f792Fa483e33cbFC71e8d7548D63Eb2d425F060": "Youngthugg007#3318",
  "0xe903eEfAA2BAE15b34C533eE41B053b629F63F5A": "N1N#9527",
  "0xde2972fD4Ee37Ff934218a0789a7222D43c41377": "step 13#1934",
  "0xEbcA3452fd22Bf4854265BEDf2b6E5AB86f1b549": "Toadster69#3216",
  "0xfC07765bb11b6Fd61Cd5a13bBBA1771783b35521": "matheusfamaral🐓#0858",
  "0xB7D0213AD8b8270E54Aadac21e6680886444A14F": "kprblzZ#7949",
  "0x31996Afc0B4A2Dc72b14f0029A2A75A78e86344B": "Shred#9771",
  "0x74Be98Ce482dF6f59B3c01FCfdCF9b9EddBB4A98": "robpepsi#3990",
  "0x8F90cbAe1bb37F7aA78a72A1Ea1523521dBCB14B": "Eddiee#4186",
  "0xC83d04A34B625c87d6cdfe819520e9Ec00Bb1D31": "CryptoTaxCat#6274",
  "0xd035dfE329F35946Edd486Ce51B383133F6b2f5a": "Big_Noodle#8619",
  "0x1a387793d05B98F3eda6529453D8D6FB8f08EE1C": "GaspodeWD#7793",
  "0xcbCfad57d07Fe1284ef1ac568d8B17AC52888Cc0": "Prawn#1390",
  "0x6Af7E27c3bFa6c3E999d9b1655B97886662DCA6c": "Theslicer#8963",
  "0x336119a76dAbe37B29c4f4cd69897D7959c6B56a": "juishdog#1133",
  "0xe15c92e15851b03677a04a9324618ed44cd0a2ac": "Abelgaming.eth I Suiswap#5849",
  "0xcd0eb48851856f64B7c3e0644C08c252876e6B05": "BardoftheEther#1338",
  "0xd26E23aAA39F29e07b299DA734C77765F6866A0E": "taxil#8577",
  "0xa1F8082B4A40a1d3f8d2ef15d598AcCFFffFd671": "sucka#3065",
  "0x7A8fAD9F9328b0F27d783286816701a711C13cEE": "Cartier | MHAC#1030",
  "0x51e0B720A8AAc60fdA28425B33aa95189981e111": "landursula#1463",
  "0xB3d2AeEB22c927Bfc29Cbd6885134a4c3D76A705": "berlinrobbery#7643",
  "0xE1682954aE4166E784bb3eC9D901E13949A1028e": "RED#3480",
  "0x8AC688EAeAa5C32867fA7E210D854207eE3Eb69b": "Judaworst#9569",
  "0x9444b0D4C3f7ed7731d93867e347748721EE1f0e": "Spike#2222",
  "0xef2f264fd6ff36249786b4baf5db95307e23d251": "MihaForward#4788",
  "0xb4074c34aa6a56671e4302c82eda72afef03b3ae": "masteradam19#6093",
  "0x89e890919271d193e064935907f35a7c5e670002": "Respectless#2624",
  "0x0442b8904718b22505f4fd474ace7dba6e1ede8a": "ark | tg - waxgamesruen#7319",
  "0x0762301bd516fe5d37a89c944515011be4fe1b6f": "Yummyraw#1852",
  "0x6c95b644b78147a716df3c2c4adfe494e55b871f": "homelesspleb#2960",
  "0x7a3d863e5109d9d379db944c2b45b2e258fb13ad": "Maxu555#6533",
  "0x00b8d4dc1fead8c00d3f298c363dc53cdbb359db": "Vermine#8574",
  "0x66f73f52142f976f6c0ed2c51f31df2099d526d6": "LittleDek#2980",
  "0x4e77e142ea26546acec0d38501fdcb823c392f8e": "Aks#2236",
  "0x81568B1959023032569547F77507651d78b5D37e": "SANCHA#6979",
  "0x575740f581038f792425670fa6bfebcc8e4dcdff": "flipdrago.eth#9784",
  "0x264af2ec440510f8e51dbe56079c6100d19d47d7": "Zelda_Boy#0123",
  "0x87db4ff8e0fff9e5b03ad8258c2a3b7cf002d787": "Castoor#0001",
  "0x4897Bf2D1DE43987F1EC9e9157A344599E5BfaD9": "Kostello#7436",
  "0xe47ADBE19B18899CC9D3860286EBF04A550D1486": "siluet#7147",
  "0xA045BB2c43bfb1D4286E88CA60355e7A0D9746cB": "!kuzy7772#7397",
  "0x88d7f7EC27F116F60b15ea66Bd210094d0db034b": "DustStorm#5033",
  "0x45650086C2688797bF9470258777396c87033B4a": "UMANN#1122",
  "0x09020cf073eac09c9c6d63166a482d0a75137fd5": "GangstaGeekXXX#1679",
  "0x60e4bF9876F184D75638FEda66123c0Ad06Fe112": "galo13.eth#2180",
  "0xb971F503517D75795b1Ca786f6f8926Bd80C4cdE": "subtl#1434",
  "0xFA0e9f4D799990905749e33bAdEC5eb0FF518617": "fairu90 ♒#7550",
  "0x7E655f4cf388267Be54ccd66302457569887C274": "Rosengdul#0001",
  "0x60Df4251C68e2e9d008BC079EEFaAF6DC5E43641": "D4nny#3926",
  "0x65a272B03690d72BA5D496a0faB06458C5628c28": "Celtixtime 🍙 | Rocket#0127",
  "0x95AF6EdF1eb3a823355088a1c80401165dE28287": "dikiyD#2841",
  "0x8Eb9Da17268EC8F66B9a3EB9f32917Db5308D11a": "cem#5290",
  "0x95DDad467801E292Ee9E7f9f040CeB54D5e30439": "Ruimtepak#6030",
  "0x87441744e4e510Ad90224d14Fd0C9d1e6541d043": "blaze215#2071",
  "0x1a5ca725fFc123444e1c6FB3a0F5E5CAD46DD402": "deniznft#9881",
  "0x8df3f68cC18e4E1aFeb039eA0EF376e45A5EB0a2": "Loym.btc#1978",
  "0xfD2aeE80cfB70ef2DdD89f86d6640248CD16F949": "JagerBomber | Rocket#2624",
  "0x0d90968868137dD25a6c88f2e9116D5BC4092d10": "mrifaie#6535",
  "0x346B11990eA0568FFcf5FEdB94f225E90B9EE4A5": "MATERIA#2549",
  "0xC6467bDB0b7B7e6743300937BC10e075581B78a7": "RodrigoA2Z#7475",
  "0xc445846fee6d6256f1add7a3326605b31a4a667c": "GreennMan#5090",
  "0xb40969F60BfA246A09593099DAce2dDD543254A3": "apix#8283",
  "0x974a49ceBDdF3dD85cA643B6a50d2c5ED8a8D605": "asx#4469",
  "0x5D64a0FD6af0D76A7Ed189d4061FFA6823FbF97e": "ClickClickEnvy#5722",
  "0x81F0401aAbE958b62a46cD84FeE445A90F1C58B2": "cryptonvwvw#4646",
  "0xDC7C6F367E4680D3B8e4ceAB1e65cB7E361314A1": "Desolute#6744",
  "0x0105c26c5d013bb32223dbb2a4Bbd98e9B0c320A": "Dr11235#9930",
  "0xC22596D84aC1EC18C911Ccb18edFffBC51a0869C": "GO_99Starz#8518",
  "0xe47a238367162E597E93aFF689C54555D350d455": "harokhali#3581",
  "0xC31df9D1b6928A7e60619D4F22736d3dEE7a5Ae1": "Lonky-Lonky#8424",
  "0xDe9362e8D09BD64B28D067e16B03843Aa7BCB406": "Marc8976#0268",
  "0xc942496E98B9f8DE243AF4226B8a9398A141913C": "MrLittleHans#0013",
  "0xEF58b1675d5De34FcA562fE06F571EFc35b06F76": "remynisce#9749",
  "0x9FfE162b9B7FBCa991840B936F95B40808A1a998": "sjr#3707",
  "0x05E205152deBdb21032B39E6aDAdf93b2123cCaC": "tkulbaco#2991",
  "0xcb35Af71E039b3c4A8Db795FcF7DBF152CDD9cf9": "Zato#6473",
  "0x68e33A587f1d697c5e0f8b8794cFA304B41a61e2": "Flye Kites Jay#2440",
  "0x348Ed2fc3c2E93608666263E50b9713839E760eb": "Bobigazda#9808",
  "0x4ABCAC6f90096c8a706de8ddB5DE5a9fE965aFC1": "Pearly P.#6909",
  "0x93f9108E5b5c0DF41c7598412A3558904225AbbC": "dyno#7777",
  "0x473bbC06D7fdB7713D1ED334F8D8096CaD6eC3f3": "zirs#5962",
  "0x753904c13c18F45670e9718943C975C0aa97a884": "B_Crypto&NFT#6246",
  "0x4A3F23974AE67D4F656720463bBd44223d9Cd078": "HotTubGuap#4254",
  "0x08a31368cC747621252ABB9029440C1Af6237Fc7": "hasbulla#8326",
  "0x4F61Bb5419D85F3263cE63336f465E3ebC559fa1": "UltiFighter#0730",
  "0x5c9ce53738967e4d422f0f6914d5be496f9edd2b": "YouDidntSeeAnything#5771",
  "0x032F84aEfF59ddEBC55797F321624826d873bF65": "wyze#5555",
  "0xd9EBc885674a93a7c119f37a6ABF9dBED84E1ff8": "YellowPanther#8888",
  "0x0De0e32Ad76887f0e58334cbf3e2f7932FadF5cA": "Clipz#5747",
  "0xDd7968044447c7e51136E9508cae3FF2B637c954": "kilobyte#7488",
  "0xc6AB9C48d66e1305e899F6Ba5Ea868265ad7AeD4": "Quexedrone#2640",
  "0x6e193F789800237146e57FBeb410E8dA39601a1A": "Sp3ctre#8180",
  "0x031720474d79E0d42894dfa663CBeAd0b8611211": "hildi#6403",
  "0xaE5952Ec7852DB63F0ED3607a6441E85B5F54356": "POWow#8777",
  "0x32ae2622A20C0C421a8d48B7202F6d4FE4132d11": "DeChunkz #1249",
  "0xED5b46F7f3d633B1dC7491ABB967431398bdaA01": "rauws#5865",
  "0x2e8200a85bf9eff64e33bf4f8d331d30f0b0b30e": "yagami#4742",
  "0x6152Ec47aCfc30160B5e3D34e38A140362e7471f": "BlekkiHax#4136",
  "0x240fF5E7a9124A3E9DF43A88e59F7cC92d38284a": "abhishekuco#2662",
  "0xebdD47AcEA792B4F5e9D2059Eab2a09Bf05cc294": "ManifestoR#4228",
  "0x55E81C489000c5661fb460af27E02CDCE7c94A18": "HelloSpank12#4274",
  "0xd104334A2D3BfbBB0CCC67ECB9B85568aA43e542": "Bonnifide#9886",
  "0xC64dFDb6521c5daF2602b4dF62AFBa5893C8155a": "thehucist#0775",
  "0xF05633fAE0F200064b12B2dfA9B17fBCcD91fD81": "MoonIcarus#2922",
  "0x7CC353433Ef9dF7676Fbb39da26FA0E278107409": "Aimfaria88#1150",
  "0x837Ffca0E3433F8F86f6b2F7612481D4E91431a7": "Healbot#8144",
  "0x45adA55492d3BfE4FaB2781c3358f925AFc04149": "FknHi#0420",
  "0xa5171632D7f9dcF9435E724ac3caE0e2bbfb636c": "<3 Bastian <3#1140",
  "0xDCF086E3f7954B38180DaAe1405569DA86588bfE": "Alex-7#6485",
  "0x6e44C59CbC18F7061D200272Bd119e263b6a08C8": "Alpha Kong | Pyposa#2065",
  "0x92101dAfDD9418A362d2bDD66aB2b0Ca45d24726": "AmberPoo#5976",
  "0xd441B9de021338786a4EFE6A409491409E57B3e4": "appa660#9087",
  "0x6Ef6265b3A2866aAb07aaAD3338cd034481f9cf7": "babyBox#5555",
  "0x160aCC42b7b274c5250c6b79950aa56D43bfC088": "Berlin#4051",
  "0xfa9B0A6e4F4a926905F3FE27c33c09183c8C033c": "Bing.L#6493",
  "0x00A58425bc36f813527D1C42e9B538B7ae1C4F17": "BouncyBear87#9986",
  "0xc93a77565EDfc2b057204Ff17C60154bA8952c8E": "Bound II#8088",
  "0xCc4068c550bFA4D4c28dc69D25EE9FCd7cd03a42": "Carliyke#3530",
  "0x10C7d2273D88Fe1E26D7b92fcBfB539B9E6557f6": "Choang#9999",
  "0xCEdf866187680e70296a8b9EEce22A09b4F05798": "chompk.eth#9502",
  "0x8fB38371c8B1107fdeEB14b03005580d4CDa746a": "Courtneyy#3761",
  "0x2fA08Fcc7e6FAf795417c3A73189939c7a818817": "crypstronomer#6950",
  "0x73fAFB1E5903b717e4fc3767aDb90E973D64Ab36": "crypto_natsu#4225",
  "0xdc22eF23E8Ff1642b41A6dAD3cD2b94De3330362": "Cryptogirl#3496",
  "0xebc212c8f416b6c2e8a252c83A56A3A955Dc6402": "Daniconelx#0272",
  "0x7b6E11FFA5916c49B06921427C2BC74953541870": "dirk#9021",
  "0x81450b4F7B76A9E5Fe366451068166b3907B680c": "edward-ln#5167",
  "0x4bEB50339c64f5901aEb99EbF973c0342964F98B": "ekko#8936",
  "0x7cccad31d6122af044ae0825a7d5a006aa01c2f8": "Eliz61#9603",
  "0x6b0785b831d89B71d260Fb396e328B24a0072a24": "Elrond#0133",
  "0x232fe3a7c0b29a2D143fF73A5e75A2514e625cCA": "EpicHuntur#7408",
  "0xD65A68c0e714CC0a166E56225B6409B4fA617830": "FernandoThompson#5714",
  "0x46cE0A2cf70Cd72773d8aA2a7B309295Ab27b366": "Firuvirg#3628",
  "0xd99F500aEFaD2Dc73Ed6FA8334ddCDE1AF40E78A": "flingz#1641",
  "0xeaA68E56a052bD80d38221953c139632cfE44702": "freakyfreaky520#1564",
  "0x72a30F3E45c0E677fDD340FEDDeFb4B61c14be98": "Gunslinger#5764",
  "0xC3FD6e0428f4F4f6f9669EC51B129045f9D9070F": "Hanoch#4634",
  "0x3cC457B4A89025f75d0d3079c423262A64F546c1": "HIGHROLLER#7529",
  "0x02C07322Bfd5DBc6aa58dD1Ea55EB9BBfFfdd11b": "italian_josh#3309",
  "0x5a8978B580299378c75bab85863410cCe641F2b5": "jedimastergrogu#6856",
  "0xFcEb8a974f85aCCfA6e287AbFca9c26dc4196b90": "JIA_B#2176",
  "0xBe20B35CF9Af2201651AAEbcE65878f33768A4CD": "kaijupepe#8264",
  "0xe5e01254AaE93DAce33a8Fa747717F4F14cbD71f": "Keii#6564",
  "0x5950f70268e6A3414DC1125EF39947902f3a2c4A": "Leon2#1569",
  "0x7D56e8ED62d12B03bDF8DBaDea4311a1386D006C": "linyx#7120",
  "0xf88006636611e16122D94055568d8C4f4E0C85FF": "mandelbrot#4426",
  "0xcA060F5a8F84dCD713D7f775ddE1780ADd48dF68": "martinelli hernadez#2436",
  "0x3b1b3edb62b527a134e5a7541f8231a3840486e1": "meji#2669",
  "0x1F417e8090b5c5Ab3e8197Af7f29cC637F8ee042": "mrzebaba1#9892",
  "0x5f128D272F4cAc0e2cce73bD516F4155f16f4275": "Nermoria#3132",
  "0x7B3893D8a1371B4E21c2864530033C59bDbE2590": "nikoT#1150",
  "0xA3deB012Dca06b12a7e9AE06738fCCB4D39a6324": "noka#4588",
  "0x9F9feB0495815709bED43B5a5903a5517001E124": "NONUM#9999",
  "0x53Ad5695943033a75878ae747ee613937D213c8a": "Phuonglee#2624",
  "0xab2ea973d3abE1C223dA0C85dC1962E2Daa2064A": "pirate#3175",
  "0x08C4d9D5fAa48837cfDd73C63De137ce88154226": "Poloskin#8737",
  "0x810Df411fDa8a1965a4d648e3436b6Bf2801cBd8": "pottyroo#3397",
  "0x3236E3df5587cFbe0d811fcE920EC92322bCEA24": "pum#5132",
  "0xc9F64274F5Bc7F97F02129406eb6191D31548663": "q2thec#1971",
  "0xE6A5C296214830D5Ab45527b83780AeF065E3A2c": "Ryanhere#9927",
  "0xbF23365269f1B75df4a0a71A0F8B64ecbf19E71B": "schick#3156",
  "0xC83894b4d9fB103227dE2b627F51Bd0B292d5372": "SonnyRobinson#0887",
  "0x4377E83eDb9Bb6905795802cCa6845d72c237b36": "Stankovich#7451",
  "0x4E731074C2B0199e7D391f8184D50D1f35aa4465": "Stone&J#2403",
  "0x0d96AD8611A92EA434585eeA619b3831AB971cEB": "Tairuc#2557",
  "0xaA21505A90F5A5D1e40c39BD719F1b3BEF843CbC": "Ter#6631",
  "0xf32Fc6A0dE35046f3ac0f179F39871764cC6Aaac": "Thanh#7686",
  "0x69795b791089c008970FeBefb3245C344A130E6b": "Tuohai#4079",
  "0xFD571192f963AA71E18a396cbA18dB12d9BB29F5": "Up&Up#5508",
  "0xcA77380D0caa2D2EB484A4C0C92926764CA9587b": "vammac#1595",
  "0x6b05d47649a664FED61f17eC973f3ed96147CA92": "vitalik cousin#7771",
  "0x5576Bf2b676858D5E2934FccEDc01B0088Ddb3D6": "VladManz | Delovoy DAO#6970",
  "0xb7b7942525343739674223061e437fFf87399312": "Weather#4107",
  "0x134F240827Dd4B047942589998a163A2A1002F1a": "zen123#9633",
  "0x42A3AF483E6C90560e0e539F427a677496d65bCD": "zzcap#2777",
  "0xE6cC5e3EBB07B5156ba3aF510B8c6cA19804d88E": "小林#4343",
  "0xd733a3fFE24DC1d5143A2444EB8313f0bC4f468c": "! ! Lion#4182",
  "0x124bcF206F1581C1D2d2a86310470825C8e65BeC": "?Lissandro#5176",
  "0x87b5f410B37D0DB5a4A558399dFC8286a3c09E36": "573F#4669",
  "0x1a02645dcae300a96f73de3088d52a1514c1c2bc": "AfanOfrosim#3107",
  "0x73bb185Df8c7Ddc130F594E0fCDc0297658B51CF": "allen-wok#8866",
  "0x5438fa17eedD8058B1AA29877AE8706aDbe2100e": "AndyGan#6530",
  "0x3945E8B38326B54612417449f86bE796Bb51F7fB": "annavie#8521",
  "0xe1c0C6bCBcD85E800847218bBa6c64F622671368": "arobec#5149",
  "0x1ce60c61e1D61E0a33eE446957006C42a8D12492": "Azeus#5524",
  "0x21d4f2D08DA6dA5DD6030e34ED29d3E9704ef242": "Benelux#7800",
  "0x572238a09B9Ce7d6AcAd34647323A0505D4CB6bd": "BERKLANDER#1111",
  "0xF2a477aBfb3E3eA370Ef1ece59411b5046fd966D": "binn#1825",
  "0x2005251ef6508fe840e5B34c06D8811552c3c3e1": "BlackV#7663",
  "0x1e5530e1a2406C13Cebd06c8Cb2E8A1d7125e347": "Booductran#9214",
  "0xBd799D98D5505F732DbE6b83b5207b194EDd27c9": "CarrJerome#4222",
  "0xAAf25ECa8ABBcC3893EAD4bB8D3d3387Fd095B5e": "Ceddy#5797",
  "0xff9d64bcf38fbdadcf22e89c47c2c922dd500cac": "Compound-I#5315",
  "0x9f974543388349617Dac74F4593ddD7896d537d3": "Cường 98#3546",
  "0xB2243F047E2087B2A930df6fF3011FCEe15e36ff": "Da_Eth_Guy#2625",
  "0xd1992817e258dDfa0B47Ae550027A0DdE1272233": "Demster#1826",
  "0x04bD3f2E7067Bcb6FA3fBd9F3B63269FCb178c8d": "DENVER 💛💛#0900",
  "0xDd46CEd5C3F422C6E3A816B50b472BE80ED6F7DC": "DevinHoang#4836",
  "0x73391c743b653ea9949ece4f42457b1d780d6c1d": "Dimastor#9853",
  "0x32ae8acfd7ff2bfeb09e819bd0479075268b3205": "DoogsZA#0147",
  "0xd08E770332F66b1753C0f1536328D8C0B9464BEF": "DorothyKaren#9990",
  "0xff11D4B93cD990fD93233f61A32509ce70ba11Cf": "Dp#2473",
  "0xE418acD494c30639ff0B8861194F041115065bBF": "DRY#6706",
  "0x1262c0eaD0D58e04A90E1E654877c6Ddfa5C76B9": "edick_fnd#2977",
  "0x0d1b737038101CF8F594D9dBE5f3D80343F0899c": "ElizabethJonson#4107",
  "0x5B9413F33784db3eB998299e6989FcD159173A03": "Emush/The Northern Nightmare#9095",
  "0x15bb1542d63e0f452541601690c93cbbade4cb2d": "Faraon#5723",
  "0xcfCb60795ac94944aee11CE3fFC91F59438fb9cB": "fazan#1381",
  "0x23244E77b13dc66c71C0A9DAbbEfEa5b9cbfFA24": "Finesse Papi#7408",
  "0x30458345938248d7ec8953d4cee9e0e202db6651": "fitfit1337#0469",
  "0x2E034566f91A5aDa0C32F012eF0aD1D775dcef3a": "For#3694",
  "0xfb9c065a59239f0B91F112dAb22BB2810A238D7e": "freakycre#2280",
  "0x705158E717df46D7Be8BD0007F7D3e9c2D3178D2": "Gallana#4946",
  "0x1844490Eb4A11be77D2fe75219c327c1f264f74E": "Gametofu#8856",
  "0x55c16a31387740395bCd5A6Ad893b5CA6e7d937C": "George#0526",
  "0x3aE75b0c134999ef73E5e77032521aE80a36eD68": "Ginz#2974",
  "0x9Eb3EeE59075658e70b5F1cfF88b6a2438b3eF34": "GOKU#5060",
  "0x8bC9755938a4a4e4Db09D023B00D30B563D678a7": "Golden Sauce#6479",
  "0x35829b2EebA3006DfE8dFC9d2086201b64aad9b5": "GreenGeorge.eth#8135",
  "0xe83A18ad7c299aaacbF52BbaF21ea592cf895C2f": "greyolisa#1771",
  "0xca2a640a45f74c404D4De26e65145F3c67820d69": "Grossei 3 6 1#0361",
  "0x837E8503c208fc57558BdeC6e46690029887c0DA": "Helen Worm#6874",
  "0xF5EAa3f532f56785A3676011438913D25721fE4e": "HungHaNoi#9366",
  "0x7C95a955b65bb68728c2c33c7B1881e5a06f2352": "ID#0929",
  "0xf946CDAEcF5A15440e500E61A0c1d179A93652ad": "Il_Il | Delovoy DAO#9036",
  "0xf2A1cF1aB0462E3c353A1e34Bf74eC51fb162D08": "JamesRic#9999",
  "0x3af0c8E44377ecBb7A4e4841658bCe227bDfbc33": "Jedida#9872",
  "0xeDAa54654E242eb3Eae9afE97db945f5e6b80A7B": "jenniferlOlit#7306",
  "0x85fF4746cA2AFb184c654c442F7a75d3F96A1e96": "Jerry66#8924",
  "0x4c1285Adaa0Cde68D427f402a937dEd523FB84F7": "Jiemi#9520",
  "0x6BD4FeC33B3C5e6DEcB787a26160419754F31A0c": "JLa#2494",
  "0x1352c12b56942131bA070335FF95fE6d322DAF37": "Kallerghs_7#6920",
  "0xF8e14268123D04971F9184b296eA5d285b33506D": "KOLA#8718",
  "0x3cbed818771c967805A85e4132532F1ce5b032D2": "kotoamotsukami#8226",
  "0x70439E55a846136FFCC86312a84Dd4638DE3bFc6": "kuraku5353#9279",
  "0xd1fD544f0eE1187730667dB26ff6951B4808F00f": "Lays Crabs#3841",
  "0xA308D42020236340B973E4ee4e50B1d636e7c379": "leedar#6406",
  "0xE1fA1A88C46F35d28E15b5C88Ae4BDbb5f6a6b5D": "Lehson1308#4184",
  "0x2cd4108ADE226E30Ee0c1A0f3Fad133204e6b810": "Liljoshy99ðŸ©¸#2200",
  "0xF589142c900963F2526a111C58D297c8029ff20A": "linxi#3128",
  "0xb4EE962f90fC1ba210611Ea294E28CA3dC719c4F": "lirrato#3010",
  "0xa688f5c5c0dad5dbede01e8fb0a0ca4a5fc19c3c": "LovelyG#2311",
  "0x1f4b3584F9a2527D5c85448e6B2A595093A75A71": "lovertombert#0059",
  "0x1512BB3b85696Fa4D85Cb4bbf78e9C4FE95DB90F": "LukeDaZo#2346",
  "0xA224935d47E2160CeDA9F68479f039036B3Dc7Ab": "Mak#9322",
  "0x94d07B17a4651dA0160d87ffe9F6f4CBE160198C": "MakaiWitch#1416",
  "0xa13f3E15a13761aC557A32816149F63019d365CF": "Marc’teryx#7387",
  "0x5ec4b3a9187C29a3152e6992B9b3F01957e353F1": "Matcha | Suiswap#3960",
  "0x37631ad927b5e91ab9770f6313906b5092525044": "MaxBob#5977",
  "0x13e2d56Ca035257Fb75a27b7e49a78ED03D213e9": "MCG#7810",
  "0xD03cFF3C2F4a66e331402419eBB2a25eB5513D00": "McLaug#5908",
  "0x36D8b45636275eEBf58e47f5d0c48A3a9490C1cd": "moneylikewater | HODL & Retire#1529",
  "0x7A39448D2B7D416e1d0006aB30fD949A26f718d2": "Moni_NFT#7922",
  "0x12d6AaC80A3357cFA88a3af56f4f7468341c0D66": "MoonLock#4475",
  "0xeff766e48ed36249ee88a6f1a0001b64c9a8e88c": "Muram#8654",
  "0x16B6e0460E8E60097A8EB50CDC8E0F20C4E31f68": "Myko_Lazaah#0097",
  "0xb4317dCE072261ACd55479F0b9A6f0826f2289db": "Nicolla#7738",
  "0x72CB820F179B31C8de44fA17B899975B8F9B0cf8": "nikop#3258",
  "0x1e354cbFb451A6dD73902B504f05c5C66b43A3Eb": "nnamdipremium#7161",
  "0x8DE6594F4181e584B8cDFf6F70aD7d1650BCdAF8": "NPF#7289",
  "0x0c869D219478d8a7debc49AE0079b3838FDf2A43": "nuwel99#6963",
  "0xEccF3Cb26Db51A6315a0b89823fFd27DF8fc4b83": "orrvan2.lens#2198",
  "0xa68d61cC389FC895AdcB1A70f12E7B91147451BF": "PARKER#0619",
  "0x9295ee94Fd7Efe448488BF3F2cA553B9a4FbCc0c": "PERESHKOWSKY#7917",
  "0xDA3963Df8e6230E7cBEb30Aa3e92b02aed96cD88": "Peter 300yo#0303",
  "0xc6eAAcAa6De1b7C98Ff558Da46E0Bbd62a333885": "Pinkz#8888",
  "0xd33F3eF1Ea28Ddb5315D9185fB7fFa007a8Dba4D": "Pray#7854",
  "0x1C86FA58C4234dD49F7b5972501489B7fd7fFC24": "Psih👺#4543",
  "0x6039C43478692525aBBc09Bf1dc4eC35B7CC19C5": "RaadikBlow#4141",
  "0x5969c80B79364665321588244AC0e947A83670D0": "Ranjan.Eth#6294",
  "0xaE72C4Ce400C93FAC7CB07C1F0d64CEc82Ff0e63": "RatiBack7#8801",
  "0x1De0D70E1944cb6856E8Ed0AeE5828BCE6C8c338": "Raym#8986",
  "0x3a77534558BB26A7b20dD29Bf66d6B3bD918962b": "Reddy#3034",
  "0xC555BB4544f9274C2D67bCb87187874076c284AB": "renq#1811",
  "0x15Dff6d27E56e5E63991Ba4F42f3d0FC3ECF1994": "Richard Millie Plain#0787",
  "0x5F399AaF88796f51207aaAe3F4c4eC9546C95517": "Rusha#5564",
  "0x30f4460b68c431f894b39364fe70f275d900b2fa": "Rvanan#9413",
  "0x1dCF67809C65699F51108EdC5bC14a0638e0A6fa": "saulongvang#8888",
  "0xe92f49965aB4F04d11420c7Ca6d3273D5CE2A0eF": "scara#6080",
  "0x506ce7f77c3825b78e9caa3319f3bb3886d9715f": "skripdv#5860",
  "0xC460b9bc529eb51B3008AAb230b7a8BB1dA23864": "ss044.eth#0001",
  "0x944158D2ab9B8cFC29C77BBfb7d3fc73E6870011": "SSVARGA#5706",
  "0x69CD9ACd35F1be66b3a85077930ED7A2CD0764e4": "stepforthestep#7196",
  "0x82F885F1aDCA2175C7F62DcB85dB83289C03F69C": "StickMan#0058",
  "0x6C07D54cf6b7061BFE96C08318044D80a7CEACeD": "Strawberry#5953",
  "0xc54DCd8F121C03Dd08be65F0a2a8e8c69aF1de4b": "supbobap#2205",
  "0x5D49Bd7f71C6A8f77a9706d4Bd149784c11F1B07": "Susie (0,1)#8497",
  "0x38DD64f8C926bCA121b00179c590eb2845683E83": "T.Dragon#6969",
  "0xe2724b88E93ee76d60E72c372317daDAdA0Db826": "tabgnuh#5015",
  "0xAE09b78Dc18D772F9398da858f31f202FD121467": "Tams#2618",
  "0xf87a8048Fc5dB261a39906c66524EdA556b746dF": "Tharwa#9262",
  "0x1F8f66e4442eeab82616f203e735BE1f687EE790": "The Reply Guy#4145",
  "0xADE7F2f901D5bF04b45A11C63b30f6f31b49B3B0": "tilled#7639",
  "0x0A29ED2e247e0456470fb306Bd797920CED31f11": "Tofu |#8888",
  "0x93243cD2D8DB0Abae1cE1096B2739712232Df8f5": "tot_samiy_chel#3573",
  "0x4BB66194c2bD9eB64b9999C1365ee5761CD65E1c": "TruongTomato#7903",
  "0x6438b160aD508e0296699Ed530AA5Fd489e50086": "Twophaze#7021",
  "0xbD5be705b96f858aA975D862dc017B4B9972393A": "Tyro#9999",
  "0x8d26B777e4191479F24356d5a2728A7FAC1DFb51": "UchihaMadara#2920",
  "0x7E5f2672Df12D9dDE49739447AAdA3362031dA66": "Uddes#6103",
  "0x7b4Db86B9c497ef68f6D956350B35d9857154042": "Vanhouse#2017",
  "0x27f7477B149fBf99AF503C07aae1D2Ecd192D78f": "YAMOTORO#1144",
  "0x18EAee3a9346ADFf22640470211F1F9cC61e0023": "yjcc23651#6312",
  "0x3a2833c8975687F5969D07F58FAfCd3473B5aCd4": "Younique#2233",
  "0x3Bfbaf197495362C31126AB2bC51A8eb1A7d9F4b": "zevunya#8764",
  "0x3BBAA076BA3DdFdC0cFE20d80Cc1809e1c065397": "Zhiyi#0560",
  "0xAF53890720728A1907AEd7C8604Ce1CD945B853F": "💠NorthsideAbN++💠#8468",
  "0xBfba6cCf295C5835eed74253309E1a0A2D478E1d": "KrzyAwAw#6979",
  "0xe72f0d3173a4f4ef1Ae682fd915EacF8b2f75076": "stick#0918",
  "0x90c4a3Ac277Fab9E56e27E17F6Aa59a2452016cb": "Tigre93Allan#0895",
  "0x4579e110E060Ab6DD0F6f73500f29BC3be8e8ccb": "Caine#5094",
  "0x34E3f9567aee97397Ac7A002dF2ef4f30193F1A6": "Dumb#4786",
  "0xB1c41450CB8a11A7e605e8Be5ca30A4c9C9231c9": "J.#9999",
  "0x6D256d526aA561F9331fd9F733A72ccF3b7e2467": "Nici#0717",
  "0xfe791B87F607AE6BE8449e64d393c91be46a1245": "man_1801#2452",
  "0x91bb7bE08728333B2E09De1d6cf17D0503768868": "Musk#4742",
  "0x0f926372d51a75AAE647974f8653Ec84e0bad863": "fakedepression#5974",
  "0xF01B3Cc10834F075fb62E37E3b6ed36CFEB1c88F": "isxus#2605",
  "0xFfa979266B39074aBED6BF03fD01370c2DF44541": "Olha#5927",
  "0xF1E693b8aFB4297b4316448a06241Ed1F5b8A795": "impeccable guy#2153",
  "0xE093540dBe149aab0376F0F2Bd07111E6723b769": "ezkara#7135",
  "0x53EBfAda4f289f9eCE3509854Ba92C0efCd20353": "quitter#5980",
  "0x076d3027c688115fdDF0Ab2E42057E8f732801Dc": "dimdimich.eth#7275",
  "0xcb3Ca5abFC40539EF0D1747443AE0ad8114111A1": "Shedywards#0250",
  "0x9E26e1B35164aFB1332592AF393edf5F2A2C7F51": "Amigoooo#6912",
  "0x9a18CF73f7117415d34a3cD321863757F5F82F31": "Coolguy#9564",
  "0x96acd324BFBfDD5c4b200b534620EF352F56f31D": "DC#5229",
  "0x21c69AB0962FAbd811b1f376eF85F5CAE5317eb2": "evgen#4041",
  "0xBA10031F3B6f5f49797e300A765F2f5D89ec0Fbf": "SleepycatS#8924",
}

const discordMappings = {}
Object.keys(discordMappingsRaw).forEach((address) => {
  discordMappings[address.toLowerCase()] = discordMappingsRaw[address]
})

export {
  discordMappings
}