import React from "react"
import Bar from "../../../generic-components/charts/bar/Bar"

const UserBehaviourBarChart = ({ aggregator, barData, id }) => {
  const getConfiguration = () => {
    return {
      scale: { 
        y: {
          tickFormat: (d) => {
            return aggregator.convertElapsedToString(d)
          }
        }
      },
      toolTip: {
        id: `${id}-tooltip`
      },
      label: {
        x: {
          text: "Location"
        },
        y: {
          text: "Time spent"
        }
      }
    }
  }

  return (
    <Bar 
      data={barData} 
      config={getConfiguration()} 
      className="user-behaviour-chart__chart-container" 
      id={id}/>
  )
}

export default UserBehaviourBarChart