import React from 'react'
import ConfigurationSelectRow from '../../../generic-components/dashboard/configuration/configuration-select-row/ConfigurationSelectRow'
import ConfigurationToggleRow from '../../../generic-components/dashboard/configuration/configuration-toggle-row/ConfigurationToggleRow'
import { userBehaviourConfigurationsAtom } from '../UserBehaviourConfig'
import { betaDiscordMappings } from '../../../../helpers/beta-wallets/beta-wallets'
import { useAtom } from 'jotai'

const UserBehaviourSharedConfig = ({ aggregator, tag }) => {
  const [config, setConfig] = useAtom(userBehaviourConfigurationsAtom)

  const updateConfig = (property, value) => {
    setConfig((prevState) => {
      const newSharedConfig = { ...prevState.sharedConfig }
      newSharedConfig[property] = value
      return {
        ...prevState,
        sharedConfig: newSharedConfig
      }
    })
  }

  return (
    <>
      <div className="user-behaviour-config__configuration-section">
        <ConfigurationToggleRow checked={config.sharedConfig.showTotalAggregate}
          label="Aggregate by"
          onToggle={() => {
            updateConfig("showTotalAggregate", !config.sharedConfig.showTotalAggregate)
          }}
          onLabel="Total"
          offLabel="Player"/>
      </div>
      <div className="user-behaviour-config__configuration-section">
        <ConfigurationToggleRow checked={config.sharedConfig.shouldAggregateDaily}
          label="Aggregate by day"
          onToggle={() => {
            updateConfig("shouldAggregateDaily", !config.sharedConfig.shouldAggregateDaily)
          }}/>
      </div>
        {
          !config.sharedConfig.showTotalAggregate && aggregator && aggregator.userData && (
            <div className="user-behaviour-config__configuration-section">
              <ConfigurationSelectRow 
                selected={betaDiscordMappings[tag][config.sharedConfig.selectedAddress.toLowerCase()]}
                options={Object.keys(aggregator.userData).map(address => { 
                  return { 
                    label: betaDiscordMappings[tag][address.toLowerCase()],
                    value: address 
                }})}
                onSelect={option => updateConfig("selectedAddress", option.value)}
                id="user-behaviour-config__address-select"
                label="Player"
                searchable={true}/>
            </div>
          )
        }
    </>
  )
}

export default UserBehaviourSharedConfig