import React, { useState, useEffect } from 'react'
import HistoricalElo from './historical-elo/HistoricalElo'
import { getFighterData } from '../../helpers/backend/get-requests/fighter-data'
import "./fighter-stats.css"

const FighterStats = () => {
  const [id, setId] = useState(45)
  const [fighterData, setFighterData] = useState(undefined)
  const [eloTimeseries, setEloTimeseries] = useState([])

  const handleIdChange = () => {
    const newId = parseInt(document.getElementById("fighter-stats__id-input").value)
    setId(newId)
  }

  useEffect(async () => {
    const data = await getFighterData()
    setFighterData(data)
  }, [])

  useEffect(() => {
    if (fighterData !== undefined) {
      const fighter = fighterData.filter((x) => x.id === id)[0]
      setEloTimeseries(fighter !== undefined ? fighter.historicalElo : [])
    }
  }, [id, fighterData])

  return (
    <div id="figher-stats__container">
      <input 
        id="fighter-stats__id-input" 
        type="number"
        placeholder="Enter the fighter ID" 
        onChange={() => handleIdChange()}/>
      <HistoricalElo eloTimeseries={eloTimeseries} />
    </div>
  )
}

export default FighterStats