import * as d3 from 'd3'
import * as _ from 'lodash'
import BaseChart from "./BaseChart"

const DEFAULT_CONFIGURATION = {
  margin: { top: 0, right: 0, bottom: 0, left: 0 },
  labelPadding: { x: 0, y: 0 },
  pie: {
    innerRadiusPadding: 0,
    outerRadiusPadding: 0.05,
    fills: [
      "red",
      "green",
      "blue",
      "yellow"
    ]
  },
  toolTip: {
    showTooltip: false,
    id: undefined
  },
  legend: {
    showLegend: true
  }
}

const valueMapping = d => d.value

class PieChart extends BaseChart {
  constructor(containerId, data, configuration, valueMap = valueMapping) {
    super(containerId, data, configuration)
    this.configuration = _.merge({}, DEFAULT_CONFIGURATION, this.configuration)
    this.valueMap = valueMap
  }

  renderChart() {
    const g = this.getChartContainer().select(".chart-container")
    const pie = d3.pie().value(this.valueMap)
    const radius = Math.min(this.chartDimensions.innerHeight, this.chartDimensions.innerWidth) / 2
    const arc = d3.arc()
      .innerRadius(radius * this.configuration.pie.innerRadiusPadding)
      .outerRadius(radius * (1 - this.configuration.pie.outerRadiusPadding))
    

    g.selectAll(".pie-container").remove()
    const pieSelections = g.append("g")
      .attr("class", "pie-container")
      .attr("transform", `translate(${this.chartDimensions.innerWidth / 2}, ${this.chartDimensions.innerHeight / 2})`)
      .selectAll("path")
      .data(pie(this.data))
      .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", (d, idx) => this.configuration.pie.fills[idx % this.configuration.pie.fills.length])

    if (this.configuration.toolTip.showTooltip === true) {
      this.setUpTooltip(pieSelections)
    }
  }

  getAxis() {
    const xScale = d3.scaleLinear()
    const yScale = d3.scaleLinear()
    const xAxis = d3.axisBottom(xScale).ticks(0).tickSize(0).tickFormat("")
    const yAxis = d3.axisLeft(yScale).ticks(0).tickSize(0).tickFormat("")
    return { scales: { xScale, yScale }, axes: { xAxis, yAxis } }
  }

  getLegendColors() {
    return this.configuration.pie.fills
  }

  getTooltipMetadata(d) {
    return d.data.metadata.toolTip
  }
}

export default PieChart