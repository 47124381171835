import React, { useEffect, useState } from "react";
import ChartDisplay from "../../generic-components/dashboard/chart-display/ChartDisplay";
import chartMap from "../../../helpers/playground-tracker/chartMap";
import { playerChartConfigAtom } from "../playground-side-panels/playground-player-side-panel/PlaygroundPlayerSidePanel";
import { camelCaseToRegular } from "../../../helpers/user-behaviour-tracker/dashboardHelpers";
import { useAtom } from "jotai";

const PlaygroundPlayerChart = ({ selectedPlayer }) => {
  const [chartConfiguration] = useAtom(playerChartConfigAtom)
  const [chartData, setChartData] = useState();
  const [displayedChartConfig, setDisplayedChartConfig] = useState()

  const isTimeSeriesChart = () => {
    return displayedChartConfig?.chartType === "timeseries"
  }

  const getLabel = () => {
    const { value, aggregate, aggregateGroup } = displayedChartConfig

    return (
      isTimeSeriesChart() ? 
      `${camelCaseToRegular(value)} Timeseries` :
      `${camelCaseToRegular(aggregateGroup)} Distribution - ${camelCaseToRegular(aggregate)}`
    )
  }

  const getChartConfig = () => {
    if (displayedChartConfig.chartType === "pie") return undefined

    const { value, aggregate, aggregateGroup } = displayedChartConfig
    return {
      label: {
        x: {
          text: isTimeSeriesChart() ? "Time" : camelCaseToRegular(aggregateGroup)
        },
        y: {
          text: isTimeSeriesChart() ? camelCaseToRegular(value) : camelCaseToRegular(aggregate)
        }
      }
    }
  }

  const shouldUpdateChart = () => {
    const hasChartAndAggregate = chartConfiguration.chartType && chartConfiguration.aggregateGroup
    if (chartConfiguration.chartType === "timeseries") {
      const isAggregating = chartConfiguration.aggregateGroup !== "n/a"

      return (
        isAggregating ? 
        hasChartAndAggregate && chartConfiguration.aggregate && chartConfiguration.value :
        hasChartAndAggregate && chartConfiguration.value
      )
    }

    return hasChartAndAggregate && chartConfiguration.aggregate
  }

  const updateTimeseriesData = () => {
    let filteredData = (
      chartConfiguration.aggregateGroup !== "n/a" ?
      selectedPlayer.data.filter(match => {
        return match[chartConfiguration.aggregateGroup] === chartConfiguration.aggregate
      })
      : selectedPlayer.data
    )

    const dataByTime = filteredData.sort((match1, match2) => match1.time - match2.time)

    const totalStats = {
      totalWins: 0,
      totalLosses: 0,
      totalMatches: 0,
      winPercentage: 0
    }

    const pointsMap = {}
    
    dataByTime.forEach(match => {
      totalStats.totalMatches += 1;
      const wonMatch = match.winner === 0
      const lostMatch = match.winner === 1
      totalStats.totalWins += wonMatch ? 1 : 0
      totalStats.totalLosses += lostMatch ? 1 : 0
      totalStats.winPercentage = Math.round(
        totalStats.totalWins / totalStats.totalMatches * 100
      )

      const date = new Date(match.time)

      pointsMap[date.toDateString()] = {...totalStats, date}
    })

    let data = []

    for (let date in pointsMap) {
      data.push(pointsMap[date])
    }
    data = data.sort((a, b) => a.date - b.date)
    setChartData(
      [
        {
          metadata: "Player timerseries",
          data: data.map(d => { return { x: d.date, y: d[chartConfiguration.value] } })
        }
      ]
    )
  }

  const updatePieData = () => {
    const grouping = `${chartConfiguration.aggregateGroup}Total`
    setChartData(Object.keys(selectedPlayer[grouping])
      .map(key => {
        return { 
          name: key, 
          value: selectedPlayer[grouping][key][chartConfiguration.aggregate],
          metadata: { name: camelCaseToRegular(key), toolTip: camelCaseToRegular(key) } 
        }
      }))
  }

  const updateBarData = () => {
    const grouping = `${chartConfiguration.aggregateGroup}Total`
    setChartData(Object.keys(selectedPlayer[grouping])
      .map(key => {
        return { 
          x: key, 
          y: selectedPlayer[grouping][key][chartConfiguration.aggregate],
          metadata: { toolTip: camelCaseToRegular(key) } 
        }
      }))
  }

  const dataUpdateMap = {
    timeseries: updateTimeseriesData,
    pie: updatePieData,
    bar: updateBarData
  }

  const updateChartData = () => {
    dataUpdateMap[chartConfiguration.chartType]()
  }
  
  useEffect(() => {
    if (shouldUpdateChart()) {
      updateChartData()
      setDisplayedChartConfig({...chartConfiguration})
    }
  }, [chartConfiguration])

  const Chart = chartMap[displayedChartConfig?.chartType]

  return (
    <>
      {
        chartData && displayedChartConfig &&     
        <ChartDisplay label={getLabel()}>
          <Chart 
            data={chartData} 
            id={`playground-tracker__player-${chartConfiguration.chartType}-chart`} 
            className="playground-tracker__chart-container" 
            config={getChartConfig()}/>
        </ChartDisplay>
      }
    </>
  )
}

export default PlaygroundPlayerChart