import React, { useState, useEffect } from 'react'
import UserBehaviourTimeseries from '../../user-behaviour-charts/user-behaviour-timeseries/UserBehaviourTimeseries'
import UserBehaviourPieChart from '../../user-behaviour-charts/user-behaviour-pie-chart/UserBehaviourPieChart'
import ChartDisplay from '../../../generic-components/dashboard/chart-display/ChartDisplay'
import { userBehaviourConfigurationsAtom } from "../../user-behaviour-config/UserBehaviourConfig"
import { 
  camelCaseToRegular, 
  shouldUpdateTimeseries, 
  updateTimeseriesData 
} from '../../../../helpers/user-behaviour-tracker/dashboardHelpers'
import { useAtom } from 'jotai'

const ConfigDashboard = ({ aggregator, tag }) => {
  const [config] = useAtom(userBehaviourConfigurationsAtom)
  const [timeSeriesData, setTimeseriesData] = useState([])
  const [pieData, setPieData] = useState()

  const getPieData = (property) => {
    const rawData = (
      config.sharedConfig.showTotalAggregate ? 
      aggregator.cumulativeData[property] :
      aggregator.userData[config.sharedConfig.selectedAddress][property]
    )

    return Object.keys(rawData).map(key => {
      return {
        name: key,
        value: rawData[key],
        metadata: { name: camelCaseToRegular(key), toolTip: camelCaseToRegular(key) }
      }
    })
  }

  const updatePieData = () => {
    setPieData([
      { label: "Cleaning Breakdown", data: getPieData("cleaning") },
      { label: "Config Difficulty Breakdown", data: getPieData("configDifficulty") },
      { label: "Intensity Breakdown", data: getPieData("intensity") }
    ])
  }

  useEffect(() => {
    if (aggregator && shouldUpdateTimeseries(config, "config")) {
      updateTimeseriesData(config, aggregator, "config", setTimeseriesData)
      updatePieData()
    }
  }, [config.sharedConfig, config.configConfig])

  return (
    <>
      <ChartDisplay  
        label={camelCaseToRegular(`${config.configConfig.aggregateProperty} Timeseries`)}>
        <UserBehaviourTimeseries 
            timeseriesData={timeSeriesData} 
            aggregator={aggregator}
            id="user-behaviour__config-timeseries-chart"
            tab="config"/>
      </ChartDisplay >
      {
        pieData && pieData.map((data, idx) => 
        {
          return (
            <ChartDisplay  label={data.label} 
              key={`user-behaviour__config-pie-chart-${idx}`}>
              <UserBehaviourPieChart 
                pieData={data.data} 
                id={`user-behaviour__config-pie-chart-${idx}`}/>
            </ChartDisplay >
          )
        })
      }
    </>
  )
}

export default ConfigDashboard