import beta2Wallets from "./beta2"
import discordNamesBeta2 from './beta-discord-2'
import { discordNamesBeta3 } from './beta-discord-3'
import { discordMappings } from "./demo-qualifiers-discord"

const betaWallets = {
  "Beta 2.0": beta2Wallets,
  "Demo Qualifiers": Object.keys(discordMappings).map((x) => x),
  "Treasure Cup": Object.keys(discordNamesBeta3).map((x) => x)
}
const betaDiscordMappings = {
  "Beta 2.0": discordNamesBeta2,
  "Demo Qualifiers": discordMappings,
  "Treasure Cup": discordNamesBeta3
}

export {
  betaWallets,
  betaDiscordMappings
}