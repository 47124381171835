import React from 'react'
import Dropdown from '../../../dropdown/Dropdown'
import "./configuration-select-row.css"

const ConfigurationSelectRow = ({ 
  label, 
  id, 
  selected, 
  onSelect, 
  options, 
  searchable = false,
  multiselect = false,
  onUnselect 
}) => {
  return (
    <div className="configuration-select-row__container">
      <div className="configuration-select-row__label">
        {label}
      </div>
      <Dropdown 
        id={id}
        options={options}
        selected={selected}
        onSelect={onSelect}
        searchable={searchable}
        width="30vh"
        multiselect={multiselect}
        onUnselect={onUnselect}/>
    </div>
  )
}

export default ConfigurationSelectRow