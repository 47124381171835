import React from "react"
import Scatter from "../../../generic-components/charts/scatter/Scatter"
import "./user-behaviour-scatter-plot.css"

const UserBehaviourScatterPlot = ({ aggregator, scatterPlotData, id }) => {
  const getConfiguration = () => {
    return {
      label: {
        x: {
          text: "Time Spent",
        },
        y: {
          text: "Elo",
        }
      },
      scale: { 
        x: {
          tickFormat: (d) => {
            return aggregator.convertElapsedToString(d)
          },
          ticks: 4,
          isLogScale: true
        }
      }
    }
  }

  return (
    <Scatter
      config={getConfiguration()} 
      id={id} 
      className="user-behaviour-chart__chart-container" 
      data={scatterPlotData}/>
  )
}

export default UserBehaviourScatterPlot