import React, { useEffect, useRef, useState } from "react"
import { AiOutlineReload } from 'react-icons/ai/index'
import { getMintPassTrackerData } from "../../helpers/blockchain/read-requests/mintpass"
import success from "../../local-assets/icons/success.png"
import pending from "../../local-assets/icons/hourglass.png"
import fail from "../../local-assets/icons/fail.png"
import Web3 from 'web3'
import './mintpass-tracker.css'

// mintpass claim tracker
// refresh update data
// list of what they can claim and list of who has already claimed
// table of balances and remainings
// stats: [more to claim (yellow), claimed all (green), claimed more than allocated(red),]

// Table consists of:
// table header: [name, balance, claimed, remaining, claimable]
// table content [0xonny#1263, balance, claimed, remaining, claimable]

const colorToImage = { "red": fail, "yellow": pending, "green": success }

const web3 = new Web3(`https://arbitrum-mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_URL}`)

const MintpassTracker = () => {
  const [tableData, setTableData] = useState([])
  const [cooldownTime, setCooldownTime] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const timer = useRef(null)
  const headers = [
    "Discord ID", 
    "Champion Claimed", 
    "Dendroid Claimed", 
    "Champion Remaining", 
    "Dendroid Remaining", 
    "Mint Status"
  ]
  const RELOAD_COOLDOWN = 60

  useEffect(() => {
    setIsLoading(true)
    getMintPassTrackerData(web3, setTableData, setIsLoading)
  }, [])

  useEffect(() => {
    if (cooldownTime === 0 && timer.current) {
      clearInterval(timer.current)
      timer.current = null
    }
  }, [cooldownTime])

  function handleRefresh() {
    setCooldownTime(RELOAD_COOLDOWN)
    setIsLoading(true)
    getMintPassTrackerData(web3, setTableData, setIsLoading)
    startCountdown()
  }

  const startCountdown = () => {
    timer.current = setInterval(() => {
      setCooldownTime(cooldownTime => cooldownTime - 1)
    }, 1000)
  }

  const remaining = tableData.map((x) => {
    return x.championRemaining + x.dendroidRemaining
  }).reduce((a, b) => a + b, 0)

  return (
    <div id="mintpass-tracker__container">
      <h1 style={{paddingTop:'4rem'}}>Mintpass Tracker</h1> 
      <p>Remaining: {remaining}</p>
      <button 
        className="mintpass-tracker__button" 
        disabled={cooldownTime > 0 || isLoading} 
        onClick={() => handleRefresh()}>
        {
          isLoading ? 
          <AiOutlineReload className="mintpass-tracker__loader--spinning" size={22}/> : 
          <AiOutlineReload size={22}/>
        }
      </button>
      {cooldownTime > 0 && <div>Please wait {cooldownTime} seconds before refreshing</div>}
      {tableData.length > 0 && (
         <table>
         <thead>
           <tr>
             {headers.map((header) => (
               <th key={header}>{header}</th>
             ))}
           </tr>
         </thead>
         <tbody>
          {
            tableData.map((data, idx) => (
            <tr key={`table-row--${idx}`}>
              <td>{data.discordId}</td>
              <td>{data.championClaimed}</td>
              <td>{data.dendroidClaimed}</td>
              <td>{data.championRemaining}</td>
              <td>{data.dendroidRemaining}</td>
              <td>
                <img src={colorToImage[data.color]} />
              </td>
            </tr>
            ))
          }
         </tbody>
       </table>
      )}
    </div>
  )
}

export default MintpassTracker