import axios from 'axios'
require('dotenv').config()

const backend = process.env.REACT_APP_BACKEND

async function getBehaviourData(tag, whichData={}) {
  var url = `${backend}data-aggregation/get-user-behaviour?tag=${tag}`
  Object.keys(whichData).forEach((key) => {
    url += `&${key}=${whichData[key]}`
  })
  const { data } = await axios.get(url)
  return data
}

export {
  getBehaviourData
}
