import React, { useEffect, useRef } from "react"
import Tooltip from "../../tooltip/Tooltip"
import PieChart from "../../../../helpers/charting/PieChart"
import { CHART_COLORS } from "../../../../helpers/user-behaviour-tracker/dashboardHelpers"
import * as _ from "lodash"

const Pie = ({ data, config, id, className }) => {
  const chartRef = useRef()

  const getConfiguration = () => {
    const defaultConfig = {
      margin: { top: 15, bottom: 35 },
      legend: {
        showLegend: true
      },
      pie: {
        outerRadiusPadding: 0.4,
        fills: CHART_COLORS
      },
      toolTip: {
        showTooltip: true,
        id: `${id}-tooltip`
      },
    }

    return _.merge({}, defaultConfig, config)
  }

  useEffect(() => {
    chartRef.current = new PieChart(
      `#${id}`,
      data,
      getConfiguration()
    )
  }, [])

  useEffect(() => {
    if (chartRef && chartRef.current && data) {
      chartRef.current.updateData(data)
    }
  }, [data])

  return (
    <>
      <div id={id} className={className}></div>
      <Tooltip tooltipContent={""} tooltipId={`${id}-tooltip`}/>
    </>
  )
}

export default Pie