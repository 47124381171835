import React, { useState } from 'react';

const initialState = {
  web3: undefined,
  network: undefined,
  networkName: undefined,
  isCoreTeam: false,
  isFounder: false,
}

export const Context = React.createContext();

const Store = ({ children }) => {
  const [websiteContext, setWebsiteContext] = useState(initialState)

  return (
    <Context.Provider value={[websiteContext, setWebsiteContext]}>
      {children}
    </Context.Provider>
  )
}

export default Store;
