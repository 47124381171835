import { useState, useEffect } from "react"
import { authServer } from "../../../env-variables"

const useGetCSRFToken = () => {
  const [csrfToken, setCSRFToken] = useState(null)

  useEffect(() => {
    let isCancelled = false

    const getCSRFToken = async () => {
      try {
        const response = await fetch(`${authServer}api/auth/csrf`, {
          credentials: "include",
        })

        if (!response.ok) {
          console.error("Request failed with status: " + response.status)
          return
        }

        let data
        if (!isCancelled) {
          data = await response.json()
        }

        if (!isCancelled && data !== undefined) {
          setCSRFToken(data)
        }
      } 
      catch (error) {
        console.error(error)
      }
    }

    getCSRFToken()

    return () => {
      isCancelled = true
    }
  }, [])

  return { csrfToken }
}

export { useGetCSRFToken }
