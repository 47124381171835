import React, { useState } from 'react'
import ToggleLightDark from '../../toggle-light-dark/ToggleLightDark'
import DashboardSelection from '../dashboard-selection/DashboardSelection'
import GenericDashboard from '../generic-dashboard/GenericDashboard'
import './dashboard-container.css'

const DashboardContainer = () => {
  const [selection, setSelection] = useState("User")
  return (
    <>
      <ToggleLightDark></ToggleLightDark>
      <div id="dashboard__container">
        <DashboardSelection
          selection={selection}
          setSelection={setSelection}>
        </DashboardSelection>
        <GenericDashboard
          dashboardName={selection}>
        </GenericDashboard>
      </div>
    </>
  )
}

export default DashboardContainer
