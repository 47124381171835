import * as d3 from 'd3'
import * as _ from 'lodash'
import BaseChart from "./BaseChart"

const DEFAULT_CONFIGURATION = {
  margin: { top: 0, right: 0, bottom: 0, left: 0 },
  labelPadding: { x: 0, y: 0 },
  chartPadding: { x: 0, y: 0 },
  scale: { 
    x: {
      scale: d3.scaleLinear(),
      ticks: undefined,
      tickSize: undefined,
      tickFormat: undefined,
      isLogScale: false
    }, 
    y: {
      scale: d3.scaleLinear(),
      ticks: undefined,
      tickSize: undefined,
      tickFormat: undefined,
      isLogScale: false
    }
  },
  renderAxis: true,
  styling: {
    0: {
      fill: "black"
    }
  },
  toolTip: {
    showTooltip: false,
    id: undefined
  }
}

class ScatterPlot extends BaseChart {
  constructor(containerId, data, configuration, xMapping, yMapping) {
    super(containerId, data, configuration, xMapping, yMapping)
    this.configuration = _.merge({}, DEFAULT_CONFIGURATION, this.configuration)
  }

  renderChart() {
    const g = this.getChartContainer().select(".chart-container")
    g.selectAll(".scatter-point").remove()
    this.data.forEach((d, i) => {
      const circles = g.append("g")
        .attr("class", ".scatter-point")
        .selectAll("circle")
        .data(d.data)
        .enter()
          .append("circle")
          .attr("r", 3)
          .attr("cx", d => this.scales.xScale(this.xMapping(d)))
          .attr("cy", d => this.scales.yScale(this.yMapping(d)))
          .attr("fill", this.configuration.styling[i].fill)

      if (this.configuration.toolTip.showTooltip === true) {
        this.setUpTooltip(circles)
      }
    })
  }
}

export default ScatterPlot