import React from 'react'
import "./chart-display.css"

const ChartDisplay = ({ label, children }) => {
  return (
    <div className="chart-display__container">
      <h3 className="chart-display__title">
        {label}
      </h3>
      {children}
    </div>
  )
}

export default ChartDisplay