import React, { useEffect, useState } from "react";
import StatisticDisplay from "../../../generic-components/dashboard/statistic-display/StatisticDisplay";
import { 
  getMeanMedian, 
  getStatisticColor, 
  getStatisticsDisplay
} from "../../../../helpers/user-behaviour-tracker/dashboardHelpers";
import * as _ from 'lodash'

const ConfigStatistic = ({ aggregator, statisticSelected }) => {
  const [statistics, setStatistics] = useState()

  const generateStatistics = () => {
    const { statistics } = aggregator
    const newStatistics = [
      {
        metric: "Time configuring / session",
        statistics: {
          ...getMeanMedian(statistics["timeSpentConfiguring-perSession"], d => d.string)
        }
      },
      {
        metric: "Training attempts / session",
        statistics: {
          ...getMeanMedian(statistics["numTrainingAttempts-perSession"], d => _.round(d, 1))
        }
      }
    ]

    setStatistics(newStatistics)
  }

  useEffect(() => {
    generateStatistics()
  }, [aggregator])

  return (
    <>
      {statistics && getStatisticsDisplay(statistics, statisticSelected)
        .map(({metric, statistic}, idx) => {
          return (
            <StatisticDisplay
              key={`config-statistic-${metric}`} 
              label={metric} 
              stat={statistic} 
              color={getStatisticColor(idx)}/>
          )
        })}
    </>
  )
}

export default ConfigStatistic