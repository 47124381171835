import DataAggregator from "./data-aggregator"

// TO DO

// datapointsCollected (overall, amount 'thrown out' - restart or exit, and amount made through to config)
// average datapointsCollected per session (not thrown out) and aggregate
// do average per sesison for the time breakdown as well (thrown out and total)

export default class CollectionAggregator extends DataAggregator {
  constructor(data, tag) {
    super(data, tag)
    this.counterKeys = ["page", "playing", "collecting", "positioningOpponent"]
    this.timeseriesKeys = ["page", "playing", "collecting", "positioningOpponent"]
    this.reduceKeys = this.timeseriesKeys.filter((x) => x !== "page")
    this.exitKeys = ["abruptExit", "endEarly", "exitWithoutConfig"]
    this.createdTimeKey = "createdAt"
    this.timecutoff = {
      "Beta 2.0": 1676998800000, // 2023-02-21 12:00pm EST
    }
    this.retentionKey = "page"

    this.eloEffectTracker = {}

    this.initialize()
  }

  initializeExitActions() {
    const exitActions = {}
    this.exitKeys.forEach((key) => { exitActions[key] = 0 })
    return exitActions
  }

  userInitialization() {
    const initial = super.userInitialization()
    return {
      ...initial,
      numRetries: 0,
      stageName: {},
      opponent: {},
      exitActions: this.initializeExitActions()
    }
  }

  getStartTime(instance) {
    return new Date(instance[this.createdTimeKey]).getTime() - instance.timeBreakdown.page
  }

  valueTransform(instance, key) {
    let value = instance[key]
    if (this.reduceKeys.includes(key)) {
      value = instance[key].reduce((a, b) => a + b, 0)
    }
    return value
  }

  addToEloTracker(instance, identifier, startTime) {
    let eloDelta
    let cumulativeTraining = instance.timeBreakdown.page
    if (this.eloEffectTracker[identifier] === undefined) {
      this.eloEffectTracker[identifier] = []
      eloDelta = 0
    }
    const tracker = this.eloEffectTracker[identifier]
    if (eloDelta === undefined) {
      eloDelta = instance.elo - tracker[tracker.length - 1].elo
      if (eloDelta === 0) {
        cumulativeTraining += tracker[tracker.length - 1].cumulativeTraining
      }
    }
    tracker.push({ time: startTime, elo: instance.elo, cumulativeTraining })
  }

  customUserAggregationInline(instance, identifier) {
    this.incrementCounters(instance, identifier, this.userData[identifier].exitActions, this.exitKeys)
    this.incrementCounters(instance, identifier, this.userData[identifier], ["numRetries"])
    this.incrementDynamicCounter(instance, identifier, "stageName")
    this.incrementDynamicCounter(instance, identifier, "opponent")
  }

  customUserAggregation(instance, identifier) {
    const startTime = this.getStartTime(instance)
    this.incrementCounters(instance.timeBreakdown, identifier, undefined, undefined, startTime)
    this.addToTimeseries(instance.timeBreakdown, identifier, startTime)
    this.customUserAggregationInline(instance, identifier)
    this.addToEloTracker(instance, identifier, startTime)
  }

  getCumulativeObject(currentUserData, key) {
    if (this.cumulativeData[key] === undefined) {
      this.cumulativeData[key] = {}
    }
    Object.keys(currentUserData[key]).forEach((subKey) => {
      if (this.cumulativeData[key][subKey] === undefined) {
        this.cumulativeData[key][subKey] = 0
      }
      this.cumulativeData[key][subKey] += currentUserData[key][subKey]
    })
  }

  customCumulativeAggregation(user) {
    if (this.cumulativeData.numRetries === undefined) {
      this.cumulativeData.numRetries = 0
    }
    this.cumulativeData.numRetries += this.userData[user].numRetries

    this.getCumulativeObject(this.userData[user], "exitActions")
    this.getCumulativeObject(this.userData[user], "stageName")
    this.getCumulativeObject(this.userData[user], "opponent")
  }

  aggregate(verbose = false) {
    super.aggregate()
    
    if (verbose) {
      console.log(this.userData)
      console.log(this.eloEffectTracker)
      // console.log(this.cumulativeData)
      // console.log(this.statistics)
      // console.log(this.timeseriesData)
      // console.log(this.retentionMatrix)
    }
  }
}