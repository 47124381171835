import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { accessAtom } from '../../helpers/generic/setup'
import SignOutButton from '../sign-in-components/sign-out-button/SignOutButton'
import { useAtom } from 'jotai'
import './landing-page.css'

const LandingPage = () => {
  const history = useHistory()
  
  const hasAccess = useAtom(accessAtom)[0]
  const [routes, setRoutes] = useState([])
  
  const accessibleRoutes = {
    founder: [
      { label: "Founder Dashboard", path: "/founder-dashboard" }
    ],
    admin: [
      { label: "Smart Contracts", path: "/contracts-dashboard" }
    ],
    team: [
      { label: "Mintpass Tracker", path: "/mintpass-tracker" },
      { label: "Playground Tracker", path: "/playground-tracker" },
      { label: "Matches Tracker", path: "/matches-tracker" },
      { label: "User Behaviour Tracker", path: "/user-behaviour-tracker" },
      { label: "Fighter Stats", path: "/fighter-stats" },
    ],
  }

  useEffect(() => {
    const tempRoutes = []
    if (hasAccess.team || hasAccess.admin) {
      tempRoutes.push(...accessibleRoutes.team)
    }
    if (hasAccess.admin) {
      tempRoutes.push(...accessibleRoutes.admin)
    }
    if (hasAccess.founder) {
      tempRoutes.push(...accessibleRoutes.founder)
    }
    setRoutes(tempRoutes)
  }, [])

  return (
    <>
      <div>Welcome to the AI Arena Dashboard</div>
      <div id="landing-page__routes-container">
        {
          routes.map((route, idx) => {
            return (
              <div
                key={`landing-page-route--${idx}`} 
                className="landing-page__route" 
                onClick={() => history.push(route.path)}>
                {route.label}
              </div>
            )
          })
        }
      </div>
      <SignOutButton />
    </>
  )
}

export default LandingPage