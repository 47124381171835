import React, { useState, useEffect } from 'react'
import UserBehaviourConfig, { userBehaviourConfigurationsAtom } from './user-behaviour-config/UserBehaviourConfig'
import UserBehaviourStatistic from './user-behaviour-statistics/UserBehaviourStatistic'
import DashboardSectionHeader from '../generic-components/dashboard/dashboard-section-header/DashboardSectionHeader'
import NavigationLink from '../generic-components/dashboard/navigation/navigation-link/NavigationLink'
import NavigationPanel from '../generic-components/dashboard/navigation/navigation-panel/NavigationPanel'
import DashboardContainer from '../generic-components/dashboard/dashboard-container/DashboardContainer'
import Dropdown from '../generic-components/dropdown/Dropdown'
import Loader from '../generic-components/loader/Loader'
import { BsGearFill } from 'react-icons/bs'
import { VscWindow } from 'react-icons/vsc'
import { HiAdjustments, HiOutlineAdjustments, HiSearch } from 'react-icons/hi'
import { getBehaviourData } from '../../helpers/backend/get-requests/behaviour-data'
import SessionAggregator from '../../helpers/data-aggregation/session-data'
import InspectorAggregator from '../../helpers/data-aggregation/inspector-data'
import CollectionAggregator from '../../helpers/data-aggregation/collection-data'
import ConfigAggregator from '../../helpers/data-aggregation/config-data'
import { betaWallets } from '../../helpers/beta-wallets/beta-wallets'
import dashboardMap from '../../helpers/user-behaviour-tracker/dashboardMap'
import * as _ from 'lodash'
import { useAtom } from 'jotai'
import './user-behaviour-tracker.css'

const UserBehaviourTracker = () => {
  const [tag, setTag] = useState("Treasure Cup")
  const [currentTab, setCurrentTab] = useState("session")
  const [tabData, setTabData] = useState({})
  const [statisticSelected, setStatisticSelected] = useState("mean")
  const [showConfig, setShowConfig] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [config] = useAtom(userBehaviourConfigurationsAtom)

  const TABS = {
    session: { label: "Session", tab: "session", Icon: VscWindow }, 
    inspector: { label: "Inspector", tab: "inspector", Icon: HiSearch }, 
    collection: { label: "Collection", tab: "collection", Icon: HiAdjustments },
    config: { label: "Config", tab: "config", Icon: BsGearFill }
  }

  const TAGS = ["Treasure Cup", "Beta 2.0", "Demo Qualifiers"]
  const aggregators = {
    session: SessionAggregator,
    inspector: InspectorAggregator,
    collection: CollectionAggregator,
    config: ConfigAggregator
  }

  const isDataLoaded = () => {
    return !isLoading && tabData[currentTab] !== undefined
  }

  const isConfigLoaded = () => {
    return config[`${currentTab}Config`] !== undefined
  }

  const getStatisticsAvailable = () => {
    const availableStats = [
      {
        label: "Mean",
        value: "mean"
      },
      {
        label: "Median",
        value: "median"
      }
    ]
    if (currentTab === "config") {
      return availableStats
    }

    return [...availableStats, { label: "Cumulative", value: "cumulative" }]
  }

  const filterData = (data) => {
    let filteredData
    if (betaWallets[tag] !== undefined) {
      filteredData = data.filter((x) => betaWallets[tag].includes(x.identifier.toLowerCase()))
    }
    else {
      filteredData = data
    }
    return filteredData
  }

  const fetchData = async () => {
    if (tabData[currentTab]?.data?.[0]?.tag !== tag) {
      setIsLoading(true)
      const data = await getBehaviourData(tag, { [`${currentTab}Bool`]: true })
      // const filteredData = tag === "Beta 2.0" ? filterData(data[currentTab]) : data[currentTab]
      const filteredData = filterData(data[currentTab])
      const aggregator = new aggregators[currentTab](filteredData, tag)
      aggregator.aggregate(true)
      setTabData(prevState => {
        return {
          ...prevState,
          [currentTab]: {
            data: filteredData,
            aggregator
          }
        }
      })
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    if (currentTab === "config" && statisticSelected === "cumulative") {
      setStatisticSelected("mean")
    }
  }, [tag, currentTab])

  const Dashboard = dashboardMap[currentTab]

  return (
    <div id="user-behaviour__container">
      <NavigationPanel>
        <Dropdown 
          options={TAGS.map(tag => { return { label: tag, value: tag }})} 
          onSelect={tag => setTag(tag.value)} 
          selected={tag}
          id="user-behaviour__tag-select"/>

        {
          Object.keys(TABS).map((tab) => {
            if (tab !== "config" || tag !== "Demo Qualifiers") {
              const { label, Icon } = TABS[tab]
              return (
                <NavigationLink onClick={() => setCurrentTab(tab)}
                  label={label}
                  Icon={Icon}
                  active={currentTab === tab}
                  key={`user-behaviour__navigation-tab-${tab}`}/>
              )
            }
          })
        }
      </NavigationPanel>
      <DashboardContainer title={`${TABS[currentTab].label} Dashboard`}
        isRightPanelOpen={showConfig && isDataLoaded() && isConfigLoaded()}>
        <DashboardSectionHeader title="Analytics" className="user-behaviour__statistic-header">
          <Dropdown options={getStatisticsAvailable()}
              selected={statisticSelected}
              width="15vh"
              id="user-behaviour__statistic-dropdown"
              onSelect={option => setStatisticSelected(option.value)}/>
        </DashboardSectionHeader>
        <div className="user-behaviour__statistic-body">
          { isDataLoaded() ?
            <UserBehaviourStatistic 
              tab={currentTab} 
              aggregator={tabData[currentTab].aggregator}
              statisticSelected={statisticSelected}/> :
            <Loader size={36}/>
          }            
        </div>

        <DashboardSectionHeader title="Visuals">
          <div className="user-behaviour__configuration-toggle"
            onClick={()=> isDataLoaded() && setShowConfig(true)}>
            <HiOutlineAdjustments size={"2.5vh"}/>
          </div>
        </DashboardSectionHeader>

        <div className="user-behaviour__chart-section">
          { isDataLoaded() && isConfigLoaded() ?
            <Dashboard tag={tag} aggregator={tabData[currentTab].aggregator}/> :
            <Loader size={36}/>
          } 
        </div>
      </DashboardContainer>
      {
        isDataLoaded() && 
        <UserBehaviourConfig 
          tab={currentTab} 
          tag={tag} 
          aggregator={tabData[currentTab].aggregator}
          showConfig={showConfig}
          setShowConfig={setShowConfig}/>
      }
    </div>
  )
}

export default UserBehaviourTracker