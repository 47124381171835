const beta2Wallets = [
  "0x592b52C735c00CEF49e946fFC061976De53096E0", // "zai rocket#9697",
  "0x4af4ae3e68faE38F163291d241d9759960699505", // "sub.version#2844",
  "0x45e6e809B9181ACcCb823eB642f932C3609f2c41", // "Amiplexus#4071",
  "0xa5171632D7f9dcF9435E724ac3caE0e2bbfb636c", // "<3 Bastian <3#1140",
  "0xcF61B6de27aa0a5fef3979A16E955a251eC784d5", // "Dovgy#3055",
  "0xf32Fc6A0dE35046f3ac0f179F39871764cC6Aaac", // "Thanh#7686",
  "0xD77a6477114e3D1d34DC69eFc3a7e882e360034D", // "MisterKumar#8586",
  "0x72a30F3E45c0E677fDD340FEDDeFb4B61c14be98", // "Gunslinger#5764",
  "0x25129be5b96787d1De0861f9a0E8867F63735B58", // "Up&Up#5508",
  "0xD65A68c0e714CC0a166E56225B6409B4fA617830", // "FernandoThompson#5714",
  "0xf88006636611e16122D94055568d8C4f4E0C85FF", // "mandelbrot#4426",
  "0x94061E6dFF5E6474987be0D3B75975Db9b1A846C", // "life goes on🦇🔊#7497",
  "0xdBD85D3373cE7c3954bDDeE710ED1A682b064b07", // "guantabean#5022",
  "0xE6cC5e3EBB07B5156ba3aF510B8c6cA19804d88E", // "小林#4343",
  "0x2b738acc4b73ddfa811d935270398bef655c2e1a", // "tomaz#1030",
  "0xd99F500aEFaD2Dc73Ed6FA8334ddCDE1AF40E78A", // "flingz#1641",
  "0x10C7d2273D88Fe1E26D7b92fcBfB539B9E6557f6", // "Choang#9999",
  "0xcb3Ca5abFC40539EF0D1747443AE0ad8114111A1", // "Shedywards#0250",
  "0x8Eb9Da17268EC8F66B9a3EB9f32917Db5308D11a", // "cem#5290",
  "0x23EAe9A5a26F550071ef8Cc86e74721F13FBEbEd", // "Rel3ase#3368",
  "0xb7b7942525343739674223061e437fFf87399312", // "Weather#4107",
  "0x27eE8FA6a70a8F156008EDe28C7B8ea5F72fFdF3", // "KOBRA || The Freeroll#1960",
  "0x4deF74eB0A57Dd090DD2262B263a3A719c9E4bA5", // "Zera#3053",
  "0x1bE2e5c277f77679888B11c9311680fe873d3a3b", // "JPEGG#6666",
  "0x6AbC03c43E2757fe6fFB29842dA56CdC594788E2", // "Yoyo#6109",
  "0x4f1ba666004bbf74bFfd75DB29c52f029875f9d7", // "Yu En#7495",
  "0x1E0eF5e59cdf7e295fdD188196A3cc389A1bEc3b", // "aespa#0001",
  "0x5950f70268e6A3414DC1125EF39947902f3a2c4A", // "Leon2#1569",
  "0xEA6A732aFF5529e3127c9F16e792AEf77FccADf5", // "Chicken Soup#1128",
  "0xd01f54f84785eBA777736c1bFC8a8a38c98Ba266", // "60%Sixty#4740",
  "0xaA21505A90F5A5D1e40c39BD719F1b3BEF843CbC", // "Ter#6631",
  "0xeb3a4113907d38058955661faB0E81dD513761ED", // "Pepa#1572",
  "0xB25CD9449aFed2Fb8bD55657B7040ce15465E6fe", // "1mpal#1766",
  "0xD207180a5D36FEF7363f71c79726817aec8938b5", // "Jchen419#4967",
  "0x79BaD1563F01F767E907E66fbE5EA2729374b153", // "thangnd#5096",
  "0x9BBa9DF95D4c47D3964171bC47f1C98EFc98Bd66", // "slhley#8911",
  "0x81450b4F7B76A9E5Fe366451068166b3907B680c", // "edward-ln#5167",
  "0x5a6693b86d9a105A03828FF7d5DcaAB47a23d678", // "etetet123#9963",
  "0xf32422641A767d162345CF831Cdd3b87DfeB7c7B", // "jeremyberros.eth#7288",
  "0xFcEb8a974f85aCCfA6e287AbFca9c26dc4196b90", // "JIA_B#2176",
  "0x3b1b3edb62b527a134e5a7541f8231a3840486e1", // "meji#2669",
  "0xe6f1364e06Ec6C98D41D3d3D36512f6C68443041", // "MrPalvy#9054",
  "0x08C4d9D5fAa48837cfDd73C63De137ce88154226", // "Poloskin#8737",
  "0xCb12102493E513eD5e32FBC36864569bAd2812Bd", // "rehe#3482",
  "0x667eEb11e3AdFC2f3A8e30C2787f74A2760eCCFe", // "uzura#4238",
  "0x79d899379844D35A1A1F5D51D3185dd821f44Dc1", // "Jelo#9731",
  "0x4377E83eDb9Bb6905795802cCa6845d72c237b36", // "Stankovich#7451",
  "0x92f44A009a4dF1597dF9249d9F21F5087755Eba0", // "0xphash#3819",
  "0x02C07322Bfd5DBc6aa58dD1Ea55EB9BBfFfdd11b", // "italian_josh#3309",
  "0x52be3580601524652978648E872D0aA448aFC928", // "MrDQ#0036",
  "0xcB795b6bA83bf84D91829aFA588e2a30c0B3Bad2", // "4201024.eth#9027",
  "0x7287ba6e4276985776e5a14b432a150ca00688b3", // "Fuggi67#5870",
  "0x160aCC42b7b274c5250c6b79950aa56D43bfC088", // "Berlin#4051",
  "0x66633Cc6B84CD127A0bb84864c1a4eA0172469A6", // "ibO#9214",
  "0x906B2C6A6886Fd3aaeA88CBb9eB2b530Aa62c1Ac", // "DrJPEG#0777",
  "0x8422E1F19f551c09214C3f53fb9dfa7f22Eaf78F", // "Hustlepedia#7354",
  "0xb40969F60BfA246A09593099DAce2dDD543254A3", // "apix#8283",
  "0x5Ea81a84FC14a73c7A9b18b72fF41a270C2441CF", // "Carlan | READY#3815",
  "0x9fF26d7e160A52064b328E38793e3569acbcaE99", // "elisa8#0944",
  "0xa23116132bdcEA6FD70AE1d40BeB152fa1fA825c", // "Hunter Solaire#9075",
  "0xd7f52A3677d8d5499b0aFF1d4a23EC1a57192aB6", // "PatronJay | READY#0001",
  "0x1a387793d05B98F3eda6529453D8D6FB8f08EE1C", // "GaspodeWD#7793",
  "0x26a6f0b4D99Ee65303e2304A418e493B121E17c8", // "browwwn#5971",
  "0x8F09EeF679904F64752523f95dB664039dF278e2", // "Heyyawn#1025",
  "0x73fAFB1E5903b717e4fc3767aDb90E973D64Ab36", // "crypto_natsu#4225",
  "0xB1c41450CB8a11A7e605e8Be5ca30A4c9C9231c9", // "Ayanami#9999",
  "0x01ffaf01926465331c4f8ee5c0f04859050b8f0f", // "pellosophical#9376",
  "0xb3572ed0e29fb74bb1d9ede62e1e8d8113d8c6e7", // "Grim653#6566",
  "0x1689479B118470eE6401FDa07340F89EE4De17B5", // "xoliday girl {313}#9196",
  "0xe5e01254AaE93DAce33a8Fa747717F4F14cbD71f", // "Keiii#6564",
  "0x9739Ec677d0bDdEc9848f399661d6540E4Ab695A", // "Jayy#6470",
  "0xcC07Cb7253A9af00291Fdfd05842Fe6b27964995", // "UMANN#1122",
  "0xcA77380D0caa2D2EB484A4C0C92926764CA9587b", // "vammac#1595",
  "0x0d96AD8611A92EA434585eeA619b3831AB971cEB", // "Tairuc#2557",
  "0x9217d8B3a7036e507e9D04Ae1969E395ca308947", // "Huyyy#0648",
  "0xD7DB97c051A8877827F96508872956B11c22ccE3", // "HaiMai#5505",
  "0x53Ad5695943033a75878ae747ee613937D213c8a", // "Phuonglee#2624",
  "0x7C371650c4e09726aBD60A2acB67EAEEF4E4c973", // "Xuuu#5135",
  "0x717C04155697D2f5a878C9Ad7c8E40322C1dECF8", // "Fizerok#5701",
  "0xf2A1cF1aB0462E3c353A1e34Bf74eC51fb162D08", // "JamesRic#9999",
  "0xA3deB012Dca06b12a7e9AE06738fCCB4D39a6324", // "noka#4588",
  "0xeed20DBf942b901C27Da9E77aD71F37fC56a4d6b", // "glib229#3861",
  "0xCE17cB7423F8CDebdA42Db6B535DD97B13138d8a", // "z442#1191",
  "0x1262c0eaD0D58e04A90E1E654877c6Ddfa5C76B9", // "edick_fnd#2977",
  "0x6b0785b831d89B71d260Fb396e328B24a0072a24", // "Elrond#0133",
  "0x73391c743b653ea9949ece4f42457b1d780d6c1d", // "Dimastor#9853",
  "0x5F8cfD3F2aA1CaDA3631E3b61f1d4C66eBcC2Cf2", // "El Gato | Delovoy DAO#3461",
  "0x338C8bA36ab46E9DF84BCe741F903D01895ACAD5", // "uEugen#2654",
  "0x34E3f9567aee97397Ac7A002dF2ef4f30193F1A6", // "Dumb#4786",
  "0xe8179007766746285625c5C73B0365716CF4Acc6", // "Frozensense#5833",
  "0x50F7CE24D2D4E1D9CcC926E86d38528395CFdbDF", // "Nightwatch#4781",
  "0x49D1ad89ADB60241Bf2Cc003fe57bcfD4d3cfD95", // "Yotensei#0383",
  "0xDA3963Df8e6230E7cBEb30Aa3e92b02aed96cD88", // "Peter 300yo#8313",
  "0x4579e110E060Ab6DD0F6f73500f29BC3be8e8ccb", // "Caine#5094",
  "0x5a8978B580299378c75bab85863410cCe641F2b5", // "jedimastergrogu#6856",
  "0x232fe3a7c0b29a2D143fF73A5e75A2514e625cCA", // "EpicHuntur#7408",
  "0xc93a77565EDfc2b057204Ff17C60154bA8952c8E", // "Bound II#8088",
  "0xB6A3BCEddFDA728d3f1e6fdFc33ff649ae3F34Bf", // "MBC#1767",
  "0xBe20B35CF9Af2201651AAEbcE65878f33768A4CD", // "kaijupepe#8264", 

  // [EXTERNAL]
  "0x90c4a3Ac277Fab9E56e27E17F6Aa59a2452016cb", // "Tigre93Allan#0895",
  "0xC091A0A9E4bb4F620342bdc540b8ea327760b1C5", // "rendertimes#7474",
  "0xD7eBDA19d3F225d7712c10b98172A7E58E2186b3", // "CHAM#8581",
  "0x1b37C962D824E05666B190341662001290b53D16", // "Can#9597",
  "0xD1872cc73e7681a91a01196af8D39C5B3E209455", // "Doffy#8974",
  "0x11B8622B341d296944ba6b3c81Afca9fF466800f", // "Professor LP#0002",
  "0x9B436Ab930e9bd34547dA0c32beE186d37C31ed3", // "Jekkie#8126",
  "0x21Ae38F3aD9DcDcaD127f64e532E0fD76EAeFb52", // "newoc#6399",
  "0xCfF3fe59E9b5Dd6bCB0f45af4EFf9D823d189902", // "Thacel#2589",
  "0x9E0845F1c92A105eA2ABdC96234DAbad160c64f7", // "dyno#7777",
  "0x57f2534Ab098Fa1fEaDa913381D8069aE55EC9af", // "Anas#5388",
  "0x11A71b1c7F8668AAE7A44c5B667b744A9A9C780B", // "iAgonyii#0232",
  "0xbcb3225c86C0D815BFb4Fb2Bc613231516959bD4", // "elokratz#6396",
  "0xba2039974a2E088344B4Dc804Da8EF88AE00EDD9", // "Zrool#6980",
  "0xE500829Af407Be1585a9CB8b8E3940D9A9B95976", // "Flikk#1931",
  "0x83378c70B12Be0431A36416622efD2e430B04628", // "JPEG_Yakuza#7878",
  "0x497fE20C94D4Ba9247582462d6dcB945B160Fd20", // "openthefridge#7777",
  "0x0B27BFb649919bE208F62244E4a09A41b0f962D0", // "jaden.yan#7461",
  "0xd9EBc885674a93a7c119f37a6ABF9dBED84E1ff8", // "YellowPanther#8888",
  "0xc691452456fC46C1Ca7104b91B62785BaB919102", // "ROLLWROYCE#4776",
  "0x5004451E331fCA06a5479C3E05a05b5e3F72aD17", // "reeee#4458",
  "0xBbe3746ECB684B4cbf374Fc2Cba3849269d04709", // "0xAn#1133",
  "0xE120edDd8A1FEA7D73AaD75d8eD8406988B2C98D", // "Sibren#9238",
  "0xCDb7C9fD441fd975c41d70d46d08C30DD245279d", // "Ulas#3816",
  "0xbE957A475844c127DDD207B4ff1F63900FD13E57", // "SonOfLasG#4680",
  "0x4Fda751a89D1d392c246E2bB67fd1535Bc7EC7aC", // "OptimusPrime TTOO#8349",
  "0x78c4eb74405311620d66eaCc2a4D61e1925B7E3F", // "Raze | NFT Gaming#7322",
  "0x3306A692D92CB1e42301aD34928316E954444612", // "!elStabilo#0615",
  "0x995f5691d90a6A6e53012a9D862B41198e9D0A72", // "thedragun#8797",
  "0xE156c1d096FEc864FF641960a262b2D8929d4195", // "Voodoo#8582",
  "0x1fD33b23ef81fae63cb84c200621Ff81F955dCF3", // "Champ T#2948",
  "0xe5eDa1eabfC23075D010927bd0111E8E36C33Def", // "scone#1259",
  "0x9F3e77Cb89Df964003053aa5B438E5697C77F4F9", // "SteeperBasket#2002",
  "0x6C1c56fd4d9Fe92dD1b5D169df3a4516Efcb12b9", // "Humminq#4471",
  "0x0B9f9a5b60b70f50413cb94B3e3649F5f99db676", // "DapperDyl#0715",
  "0xEc8541d68a2f66690c2B45E8908Fde7E3B3e1bce", // "Killam#4655",
  "0x5951848719E95B92A665b044EF01C8769b024ed0", // "indigo#0666",
  "0xab59bcD21E84749F3d15deDa6ea4267E19a14f1d", // "Baba G#0422",
  "0x134F240827Dd4B047942589998a163A2A1002F1a", // "zen123#9633",
  "0x3F2D732a222C9ec2b8FA3beDA4A186F84940B7C0", // "YaMi#5456",
  "0x0aA9CF555CD90d8CFB1fA19eeB298ECB528102bF", // "s0uvenir",
  "0x0372Ca2bA5063252850cd1123Ba8b3E99Bf84fEE", // "sosboy",
  "0xD938722718F97F717297eFc904232FFB9dF919B4", // "Peace | SSS | SYN#8656",
  "0x244789fb5E4583389528EE696ADD6BaCa868e0c1", // "Craaguy#9790",
  "0xA40f1bFCBc5e3AF1D5Be533b1fa5F0870a51B357", // "the43rdSensei#3131",
  "0xc7F9acAc47e760cA5e3824630EE0D964af1f7769", // "ec#0546",
  "0x763D39179B63fE0ad612a7e7C963a6e69B2c9C95", // "c4ctus#8559",
  "0xb4f2DEA715A6Ee100e79b59d49ad4f9eE37498bA", // "tpsy#4833",
  "0x3C0c6C9670Dd6ea971fFEF7a1A77D341b8B3A4B3", // "Wale#2486",
  "0x19fd9bDbca89957F86CD39e2f166edBeEA9aEcd1", // "Risayer#9192",
].map((member) => { return member.toLowerCase() })

export default beta2Wallets