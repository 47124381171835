const createDashedLine = (g, lineGenerator, data, color) => {
  g.append('path')
    .attr('class', 'line-path')
    .style("fill", "none")
    .style('stroke-width', 2)
    .style('stroke-dasharray', ("3, 3"))
    .style('stroke', color)
    .style('opacity', 0.7)
    .attr('d', lineGenerator(data))
}

const addHorizontalSeperators = (g, seperatorData, xRange, yRange, lineGenerator, areaGenerator) => {
  var yBottom
  var yTop
  for (var i = 0; i < seperatorData.length; i++) {
    if (i === 0) {
      yBottom = yRange[0]      
    }
    else {
      yBottom = seperatorData[i-1].y
    }
    yTop = seperatorData[i].y
    g.append("path")
      .attr("fill", seperatorData[i].color)
      .attr("stroke", "none")
      .style("opacity", 0.1)
      .attr("d", areaGenerator([
        { x: xRange[0], yBottom: yBottom, yTop: yTop },
        { x: xRange[1], yBottom: yBottom, yTop: yTop }
      ]))

    createDashedLine(
      g,
      lineGenerator,
      [{ x: xRange[0], y: yBottom }, { x: xRange[1], y: yBottom }],
      seperatorData[i].bottomBorder
    )

    createDashedLine(
      g,
      lineGenerator,
      [{ x: xRange[0], y: yTop }, { x: xRange[1], y: yTop }],
      seperatorData[i].topBorder
    )
  }
}

export {
  addHorizontalSeperators
}