import React from 'react'
import './error.css'

const Error = () => {
  return (
    <div id="error__container">
      <p>Error</p>
    </div>
  )
}

export default Error
