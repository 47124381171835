import { ethers } from "ethers"

const isAdmin = async (contract, inputs) => {
  const { isAdmin: { inputValue } } = inputs
  const isAdmin = await contract.isAdmin(inputValue)
  console.log(isAdmin)
  return isAdmin
}

const addAdmin = async (contract, inputs) => {
  const { addAdmin: { inputValue } } = inputs
  const addAdminData = await contract.addAdmin(inputValue)
  return addAdminData
}

const setNeuronAddress = async (contract, inputs) => {
  const { setNeuronAddress: { inputValue } } = inputs
  const setNeuronAddressData = await contract.setNeuronAddress(inputValue)
  setNeuronAddressData.wait()
  console.log(setNeuronAddressData)
  return setNeuronAddressData
}

const setMergingPoolAddress = async (contract, inputs) => {
  const { setMergingPoolAddress: { inputValue } } = inputs
  const setMergingPoolAddressData = await contract.setMergingPoolAddress(inputValue)
  setMergingPoolAddressData.wait()
  console.log(setMergingPoolAddressData)
  return setMergingPoolAddressData
}

const setDelegatedAddress = async (contract, inputs) => {
  const { setDelegatedAddress: { inputValue } } = inputs
  const setDelegatedAddressData = await contract.setDelegatedAddress(inputValue)
  setDelegatedAddressData.wait()
  console.log(setDelegatedAddressData)
  return setDelegatedAddressData
}

const setNewRound = async (contract) => {
  const setNewRoundData = await contract.setNewRound()
  return setNewRoundData
}

const stakeNRN = async (contract, inputs) => {
  const { stakeNRN: { inputValue } } = inputs
  const stakeNRNData = await contract.stakeNRN(ethers.utils.parseEther(inputValue.toString()))
  stakeNRNData.wait()
  console.log(stakeNRNData)
  return stakeNRNData
}

const unstakeNRN = async (contract, inputs) => {
  const { unstakeNRN: { inputValue } } = inputs
  const unstakeNRNData = await contract.unstakeNRN(ethers.utils.parseEther(inputValue.toString()))
  unstakeNRNData.wait()
  console.log(unstakeNRNData)
  return unstakeNRNData
}

const claimNRN = async (contract, inputs) => {
  const { claimNRN: { inputValue } } = inputs
  const array = inputValue.split(",")
  const rounds = array.map(str => {
    return Number(str);
  });
  const claimNRNData = await contract.claimNRN(rounds)
  console.log(claimNRNData)
  return claimNRNData
}

const updateBattleRecord = async (contract, inputs) => {
  const { updateBattleRecord: { inputValue } } = inputs
  const array = inputValue.split(",")
  const formattedInputs = array.map(str => {
    return Number(str);
  });
  const tokenId = formattedInputs[0];
  const mergingPortion = formattedInputs[1];
  const battleResult = formattedInputs[2];
  const updateBattleRecordData = await contract.updateBattleRecord(tokenId, mergingPortion, battleResult)
  updateBattleRecordData.wait()
  console.log(updateBattleRecordData)
  return updateBattleRecordData
}

const getTotalBattles = async (contract) => {
  const getTotalBattlesData = await contract.getTotalBattles()
  return getTotalBattlesData
}

const getBattleRecord = async (contract, inputs) => {
  const { getBattleRecord: { inputValue } } = inputs
  const getBattleRecordData = await contract.getBattleRecord(Number(inputValue))
  console.log(getBattleRecordData)
  return getBattleRecordData
}

const getUnclaimedNRN = async (contract, inputs) => {
  const { getUnclaimedNRN: { inputValue } } = inputs
  const getUnclaimedNRNData = await contract.getUnclaimedNRN(ethers.utils.parseEther(inputValue.toString()))
  console.log(getUnclaimedNRNData)
  return getUnclaimedNRNData
}


const allRankedBattleFunctions = {
  isAdmin,
  addAdmin,
  setNeuronAddress,
  setMergingPoolAddress,
  setDelegatedAddress,
  setNewRound,
  stakeNRN,
  unstakeNRN,
  claimNRN,
  updateBattleRecord,
  getTotalBattles,
  getBattleRecord,
  getUnclaimedNRN
}

export {
  allRankedBattleFunctions
}