import axios from 'axios'
require('dotenv').config()

const backend = process.env.REACT_APP_BACKEND

async function getMatchData(query = "") {
  const { data } = await axios.get(`${backend}data-aggregation/get-match-stats?${query}`)
  return data
}

export {
  getMatchData
}
