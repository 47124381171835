import { Route, Switch } from "react-router-dom"
import GatedRoute from "./GatedRoute"

import FounderDashboard from './founder-dashboard/FounderDashboard'
import DashboardContainer from "./dashboard-components/dashboard-container/DashboardContainer"
import MintpassTracker from "./mintpass-tracker/MintpassTracker"
import MatchesTracker from './matches-tracker/MatchesTracker'
import PlaygroundTracker from "./playground-tracker/PlaygroundTracker"
import UserBehaviourTracker from "./user-behaviour-tracker/UserBehaviourTracker"
import FighterStats from "./fighter-stats-components/FighterStats"
import LandingPage from "./landing-page/LandingPage"
import Error from "./error/Error"

const baseAccess = {
  navigation: true,
  fighterStats: true, 
  tracker: true 
}

const adminAccess = { ...baseAccess, contracts: true, founder: true }

const allAccessPoints = {
  development: adminAccess,
  admin: adminAccess,
  team: baseAccess,
  noAccess: { noAccess: true },
}

const createRoutes = (accessKey, sessionExists) => {
  const accessPoint = allAccessPoints[accessKey]
  if (accessPoint === undefined) return <></>

  return <>
    <Switch>
      {/* Smart Contract routes */}
      <GatedRoute path="/founder-dashboard" access={accessPoint.founder} sessionExists={sessionExists}>
        <FounderDashboard />
      </GatedRoute>

      {/* Smart Contract routes */}
      <GatedRoute path="/contracts-dashboard" access={accessPoint.contracts} sessionExists={sessionExists}>
        <DashboardContainer />
      </GatedRoute>

      {/* Tracker routes */}
      <GatedRoute path="/mintpass-tracker" access={accessPoint.tracker} sessionExists={sessionExists}>
        <MintpassTracker />
      </GatedRoute>

      <GatedRoute path="/playground-tracker" access={accessPoint.tracker} sessionExists={sessionExists}>
        <PlaygroundTracker />
      </GatedRoute>

      <GatedRoute path="/matches-tracker" access={accessPoint.tracker} sessionExists={sessionExists}>
        <MatchesTracker />
      </GatedRoute>
      
      <GatedRoute path="/user-behaviour-tracker" access={accessPoint.tracker} sessionExists={sessionExists}>
        <UserBehaviourTracker />
      </GatedRoute>

      {/* Fighter Stats routes */}
      <GatedRoute path="/fighter-stats" access={accessPoint.fighterStats} sessionExists={sessionExists}>
        <FighterStats />
      </GatedRoute>

      <GatedRoute path="/" access={accessPoint.navigation} sessionExists={sessionExists}>
        <LandingPage />
      </GatedRoute>

      {/* Public Routes */}
      <Route path="*">
        <Error />
      </Route>
    </Switch>
  </>
}

export default createRoutes
