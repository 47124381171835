import { ethers } from "ethers"

const isAdmin = async (contract, inputs) => {
  const { isAdmin: { inputValue } } = inputs
  const isAdmin = await contract.isAdmin(inputValue)
  console.log(isAdmin)
  return isAdmin
}

const addAdmin = async (contract, inputs) => {
  const { addAdmin: { inputValue } } = inputs
  const addAdminData = await contract.addAdmin(inputValue)
  return addAdminData
}

const setNewDrop = async (contract, inputs) => {
  const { setNewDrop: { inputValue } } = inputs
  const array = inputValue.split(",")
  const formattedInputs = array.map(str => Number(str))
  const fightersAvailable = formattedInputs[0]
  const newPrice = formattedInputs[1] //do i need to us parseEther here for newPrice?
  const fighterType = formattedInputs[2]
  const setNewDropData = await contract.setNewDrop(fightersAvailable, newPrice, fighterType)
  setNewDropData.wait()
  console.log(setNewDropData)
  return setNewDropData
}

const setMarketplaceAddress = async (contract, inputs) => {
  const { setMarketplaceAddress: { inputValue } } = inputs
  const setMarketplaceAddressData = await contract.setMarketplaceAddress(inputValue)
  setMarketplaceAddressData.wait()
  console.log(setMarketplaceAddressData)
  return setMarketplaceAddressData
}

const setIntelligenceAddress = async (contract, inputs) => {
  const { setIntelligenceAddress: { inputValue } } = inputs
  const setIntelligenceAddressData = await contract.setIntelligenceAddress(inputValue)
  setIntelligenceAddressData.wait()
  console.log(setIntelligenceAddressData)
  return setIntelligenceAddressData
}

const setGameItemsAddress = async (contract, inputs) => {
  const { setGameItemsAddress: { inputValue } } = inputs
  const setGameItemsAddressData = await contract.setGameItemsAddress(inputValue)
  setGameItemsAddressData.wait()
  console.log(setGameItemsAddressData)
  return setGameItemsAddressData
}

const setMergingPoolAddress = async (contract, inputs) => {
  const { setMergingPoolAddress: { inputValue } } = inputs
  const setMergingPoolAddressData = await contract.setMergingPoolAddress(inputValue)
  setMergingPoolAddressData.wait()
  console.log(setMergingPoolAddressData)
  return setMergingPoolAddressData
}

const setAllowedVoltageSpenders = async (contract, inputs) => {
  const { setAllowedVoltageSpenders: { inputValue } } = inputs
  const setAllowedVoltageSpendersData = await contract.setAllowedVoltageSpenders(inputValue)
  setAllowedVoltageSpendersData.wait()
  console.log(setAllowedVoltageSpendersData)
  return setAllowedVoltageSpendersData
}

const setTokenURI = async (contract, inputs) => {
  const { setTokenURI: {inputValue} } = inputs
  const inputsArray = inputValue.split(",")
  let tokenId = Number(inputsArray[0])
  let tokenURI = inputsArray[1].toString()
  const setTokenURIData = await contract.setTokenURI(tokenId, tokenURI)
  setTokenURIData.wait()
  console.log(setTokenURIData)
  return setTokenURIData
}

const buyNewFighter = async (contract, inputs) => {
  const { buyNewFighter: {inputValue} } = inputs
  const inputsArray = inputValue.split(",")
  let fighterType = inputsArray[0]
  let neuralNetHash = inputsArray[1].toString()
  const buyNewFighterData = await contract.buyNewFighter(fighterType, neuralNetHash, {
    value: ethers.utils.parseEther("0.05")
  })
  buyNewFighterData.wait()
  console.log(buyNewFighterData)
  return buyNewFighterData
}

const setFighterPrice = async (contract, inputs) => {
  const { setFighterPrice: {inputValue} } = inputs
  const inputsArray = inputValue.split(",")
  let tokenId = inputsArray[0]
  let fighterPrice = inputsArray[1]
  const setFighterPriceData = await contract.setFighterPrice(tokenId, fighterPrice)
  setFighterPriceData.wait()
  console.log(setFighterPriceData)
  return setFighterPriceData
}

const transferFighterOwnership = async (contract, inputs) => {
  const { transferFighterOwnership: { inputValue } } = inputs
  const array = inputValue.split(",")
  const formattedInputs = array.map(str => Number(str));
  const owner = formattedInputs[0];
  const buyer = formattedInputs[1];
  const tokenId = formattedInputs[2];
  const transferFighterOwnershipData = await contract.transferFighterOwnership(owner, buyer, tokenId)
  transferFighterOwnershipData.wait()
  console.log(transferFighterOwnershipData)
  return transferFighterOwnershipData
}

const updateFighterStaking = async (contract, inputs) => {
  const { updateFighterStaking: {inputValue} } = inputs
  const inputsArray = inputValue.split(",")
  let tokenId = inputsArray[0]
  let stakingStatus = inputsArray[1]
  const updateFighterStakingData = await contract.updateFighterStaking(tokenId, stakingStatus)
  updateFighterStakingData.wait()
  console.log(updateFighterStakingData)
  return updateFighterStakingData
}

const doesTokenExist = async (contract, inputs) => {
  const { doesTokenExist: { inputValue } } = inputs
  const doesTokenExistData = await contract.doesTokenExist(inputValue)
  console.log(doesTokenExistData)
  return doesTokenExistData
}

const useVoltageBattery = async (contract, inputs) => {
  const { useVoltageBattery: { inputValue } } = inputs
  const useVoltageBatteryData = await contract.useVoltageBattery(inputValue)
  useVoltageBatteryData.wait()
  console.log(useVoltageBatteryData)
  return useVoltageBatteryData
}

const spendVoltage = async (contract, inputs) => {
  const { spendVoltage: {inputValue} } = inputs
  const inputsArray = inputValue.split(",")
  let spender = inputsArray[0]
  let voltageSpent = inputsArray[1]
  const spendVoltageData = await contract.spendVoltage(spender, voltageSpent)
  spendVoltageData.wait()
  console.log(spendVoltageData)
  return spendVoltageData
}

const mintFromMergingPool = async (contract, inputs) => {
  const { mintFromMergingPool: { inputValue } } = inputs
  const array = inputValue.split(",")
  const formattedInputs = array.map(str => Number(str));
  const to = formattedInputs[0];
  const neuralNetHash = formattedInputs[1].toString();
  const mintFromMergingPoolData = await contract.mintFromMergingPool(to, neuralNetHash)
  mintFromMergingPoolData.wait()
  console.log(mintFromMergingPoolData)
  return mintFromMergingPoolData
}

const transferFrom = async (contract, inputs) => {
  const { transferFrom: { inputValue } } = inputs
  const array = inputValue.split(",")
  const formattedInputs = array.map(str => Number(str));
  const from = formattedInputs[0];
  const to = formattedInputs[1];
  const tokenId = formattedInputs[2];
  const transferFromData = await contract.transferFrom(from, to, tokenId)
  transferFromData.wait()
  console.log(transferFromData)
  return transferFromData
}

const safeTransferFrom = async (contract, inputs) => {
  const { safeTransferFrom: { inputValue } } = inputs
  const array = inputValue.split(",")
  const formattedInputs = array.map(str => Number(str));
  const from = formattedInputs[0];
  const to = formattedInputs[1];
  const tokenId = formattedInputs[2];
  const safeTransferFromData = await contract.safeTransferFrom(from, to, tokenId)
  safeTransferFromData.wait()
  console.log(safeTransferFromData)
  return safeTransferFromData
}

const removeFighterPrice = async (contract, inputs) => {
  const { removeFighterPrice: { inputValue } } = inputs
  const removeFighterPriceData = await contract.removeFighterPrice(inputValue)
  removeFighterPriceData.wait()
  console.log(removeFighterPriceData)
  return removeFighterPriceData
}

const getFighterSaleInfo = async (contract, inputs) => {
  const { getFighterSaleInfo: { inputValue } } = inputs
  const getFighterSaleInfoData = await contract.getFighterSaleInfo(inputValue)
  console.log(getFighterSaleInfoData)
  return getFighterSaleInfoData
}

const getAllFighterInfo = async (contract, inputs) => {
  const { getAllFighterInfo: { inputValue } } = inputs
  const getAllFighterInfoData = await contract.getAllFighterInfo(inputValue)
  console.log(getAllFighterInfoData)
  return getAllFighterInfoData
}

const allFighterFarmFunctions = {
  isAdmin,
  addAdmin,
  setNewDrop,
  setMarketplaceAddress,
  setIntelligenceAddress,
  setGameItemsAddress,
  setMergingPoolAddress,
  setAllowedVoltageSpenders,
  setTokenURI,
  buyNewFighter,
  setFighterPrice,
  transferFighterOwnership,
  updateFighterStaking,
  doesTokenExist,
  useVoltageBattery,
  spendVoltage,
  mintFromMergingPool,
  transferFrom,
  safeTransferFrom,
  removeFighterPrice,
  getFighterSaleInfo,
  getAllFighterInfo
}

export {
  allFighterFarmFunctions
}