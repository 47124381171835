import { ethers } from "ethers"

const isAdmin = async (contract, inputs) => {
  const { isAdmin: { inputValue } } = inputs
  const isAdmin = await contract.isAdmin(inputValue)
  console.log(isAdmin)
  return isAdmin
}

const allowanceRemaining = async (contract, inputs) => {
  const { allowanceRemaining: {inputValue} } = inputs
  const allowanceRemainingInputs = inputValue.split(",")
  let account = allowanceRemainingInputs[0]
  let id = allowanceRemainingInputs[1]
  const allowanceRemainingData = await contract.allowanceRemaining(account, id)
  console.log(allowanceRemainingData)
  console.log(Number(allowanceRemainingData))
  return Number(allowanceRemainingData)
}

const dailyAllowanceReplenishTime = async (contract, inputs) => {
  const { dailyAllowanceReplenishTime: {inputValue} } = inputs
  const dailyAllowanceReplenishTimeInputs = inputValue.split(",")
  let account = dailyAllowanceReplenishTimeInputs[0]
  let id = dailyAllowanceReplenishTimeInputs[1]
  const dailyAllowanceReplenishTimeData = await contract.dailyAllowanceReplenishTime(account, id)
  console.log(dailyAllowanceReplenishTimeData)
  console.log(Number(dailyAllowanceReplenishTimeData))
  return Number(dailyAllowanceReplenishTimeData)
}


const addAdmin = async (contract, inputs) => {
  const { addAdmin: { inputValue } } = inputs
  const addAdminData = await contract.addAdmin(inputValue)
  return addAdminData
}

const mint = async (contract, inputs) => {
  const {mint: { inputValue }} = inputs
  const mintInputs = inputValue.split(",")
  const { itemPrice } = await contract.allGameItemAttributes(0)
  const tokenId = mintInputs[0]
  const quantity = mintInputs[1] 
  const value = (Number(itemPrice) * quantity) / 10**18
  const mintData = await contract.mint(tokenId, quantity , {
    value: ethers.utils.parseEther(value.toString()),
    gasLimit: 1000000,
    gasPrice: 15000000
  }) // do i use ethers for quantity
  const receipt = await mintData.wait()
  console.log(receipt)
  return receipt
}

const setAllowedBurningAddresses = async (contract, inputs) => {
  const { setAllowedBurningAddresses: { inputValue } } = inputs
  const setAllowedBurningAddressesData = await contract.setAllowedBurningAddresses(inputValue)
  return setAllowedBurningAddressesData
}

const createGameItem = async (contract, inputs) => {
  const {createGameItem: { inputData: { name, imageURI, finiteSupply, itemsRemaining, itemPrice, dailyAllowance}}} = inputs
  if (inputs.createGameItem.inputData !== {}) {
    const createGameItemData = await contract.createGameItem(name, imageURI, finiteSupply, itemsRemaining, itemPrice, dailyAllowance)
    console.log(createGameItemData)
    return createGameItemData
  }
}

const burn = async (contract, inputs) => {
  const { burn: {inputValue} } = inputs
  const burnInputs = inputValue.split(",")
  let account = burnInputs[0]
  let id = burnInputs[1]
  let amount = ethers.utils.parseEther(burnInputs[2].toString())
  const burnData = await contract.burn(account, id, amount)
  return Number(burnData)
}



const allGameItemsFunctions = {
  isAdmin,
  addAdmin,
  mint,
  setAllowedBurningAddresses,
  createGameItem,
  allowanceRemaining,
  dailyAllowanceReplenishTime,
  burn
}

export {
  allGameItemsFunctions
}