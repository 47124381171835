import React from 'react'

const InputSelector = ({ contractProps, inputs, keyIdentifier, setContractProps }) => {
  if (inputs[keyIdentifier]?.inputType === "text") {
    return (
      <input 
        type="text"
        value={contractProps.inputs[keyIdentifier].inputValue}
        id={inputs[keyIdentifier].inputId}
        onChange={(e) => {
          setContractProps((prevState) => {
            return {
              ...prevState,
              inputs: {
                ...prevState.inputs,
                [keyIdentifier]: {
                  ...prevState.inputs[keyIdentifier],
                  inputValue: e.target.value
                }
              }
            }
          })
        }}
      />
    )
  } else if (inputs[keyIdentifier]?.inputType === "file"){

    const inputElement = document.getElementById(inputs[keyIdentifier]?.inputId)
    if (inputElement) {
      inputElement.addEventListener("change", function(e) {
        let file_to_read = document.getElementById(inputs[keyIdentifier].inputId).files[0];
        let fileread = new FileReader();
        fileread.onload = async function(e) {
          let content = e.target.result;
          let intern = JSON.parse(content);
          let argsObject;
          Object.keys(intern).map((s) => {
            argsObject = {
              ...argsObject,
              [s]: intern[s]
            }
          })

          if (argsObject !== undefined ) {
            setContractProps((prevState) => {
              return {
                ...prevState,
                inputs: {
                  ...prevState.inputs,
                  [keyIdentifier]: {
                    ...prevState.inputs[keyIdentifier],
                    inputData: argsObject
                  }
                }
              }
            })
          }
        };
        fileread.readAsText(file_to_read);
      });
    }
    return (
      <input type="file" style={{paddingLeft:"2rem"}}
        id={inputs[keyIdentifier].inputId}
      />
    )
  } else {
    return (
      <>
      </>
    )
  }
}

export default InputSelector