import * as d3 from 'd3'
import * as _ from 'lodash'
import BaseChart, { notNullOrUndefined } from "./BaseChart"

const DEFAULT_CONFIGURATION = {
  margin: { top: 0, right: 0, bottom: 0, left: 0 },
  labelPadding: { x: 0, y: 0 },
  chartPadding: { x: 0, y: 0 },
  scale: { 
    x: {
      scale: d3.scaleBand(),
      ticks: undefined,
      tickSize: undefined,
      tickFormat: undefined,
      paddingInner: undefined
    }, 
    y: {
      scale: d3.scaleLinear(),
      ticks: undefined,
      tickSize: undefined,
      tickFormat: undefined
    }
  },
  renderAxis: true,
  bar: {
    fill: "black"
  },
  toolTip: {
    showTooltip: false,
    id: undefined
  }
}

class BarChart extends BaseChart {
  constructor(containerId, data, configuration, xMapping, yMapping) {
    super(containerId, data, configuration, xMapping, yMapping)
    this.configuration = _.merge({}, DEFAULT_CONFIGURATION, this.configuration)
  }

  renderChart() {
    const g = this.getChartContainer().select(".chart-container")
    g.selectAll(".bar-container").remove()
    const barSelections = g.append("g")
      .attr("class", "bar-container")
      .selectAll("g")
      .data(this.data)
      .enter()
        .append("rect")
        .attr("x", (d) => { return this.scales.xScale(this.xMapping(d)) })
        .attr("y", (d) => { return this.scales.yScale(this.yMapping(d)) })
        .attr("width", this.scales.xScale.bandwidth())
        .attr("height", (d) => { 
          return this.chartDimensions.innerHeight - this.scales.yScale(this.yMapping(d)) 
        })
        .attr("fill", this.configuration.bar.fill)

    if (this.configuration.toolTip.showTooltip === true) {
      this.setUpTooltip(barSelections)
    }

  }

  getAxis() {
    const xDomain = this.data.map(this.xMapping)
    const yRange = d3.extent(this.data, this.yMapping)
    let xScale = this.configuration.scale.x.scale
      .domain(xDomain)
      .range([0, this.chartDimensions.innerWidth])

    if (notNullOrUndefined(this.configuration.scale.x.paddingInner)) {
      xScale = xScale.paddingInner(this.configuration.scale.x.paddingInner)
    }

    const yScale = this.configuration.scale.y.scale
      .domain([
        0, 
        yRange[1] * (1 + this.configuration.chartPadding.y)
      ])
      .range([this.chartDimensions.innerHeight, 0])
      .nice()
  
    let xAxis = d3.axisBottom(xScale)

    if (notNullOrUndefined(this.configuration.scale.x.tickSize)) {
      xAxis = xAxis.tickSize(this.configuration.scale.x.tickSize)
    }
    if (notNullOrUndefined(this.configuration.scale.x.tickFormat)) {
      xAxis = xAxis.tickFormat(this.configuration.scale.x.tickFormat)
    }

    let yAxis = d3.axisLeft(yScale)
    
    if (notNullOrUndefined(this.configuration.scale.y.ticks)) {
      yAxis = yAxis.ticks(this.configuration.scale.y.ticks)
    }
    if (notNullOrUndefined(this.configuration.scale.y.tickSize)) {
      yAxis = yAxis.tickSize(this.configuration.scale.y.tickSize)
    }
    if (notNullOrUndefined(this.configuration.scale.y.tickFormat)) {
      yAxis = yAxis.tickFormat(this.configuration.scale.y.tickFormat)
    }

    return { scales: { xScale, yScale }, axes: { xAxis, yAxis } }
  }
}

export default BarChart 