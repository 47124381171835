import CollectionStatistic from "../../components/user-behaviour-tracker/user-behaviour-statistics/collection-statistic/CollectionStatistic";
import ConfigStatistic from "../../components/user-behaviour-tracker/user-behaviour-statistics/config-statistic/ConfigStatistic";
import InspectorStatistic from "../../components/user-behaviour-tracker/user-behaviour-statistics/inspector-statistic/InspectorStatistic";
import SessionStatistic from "../../components/user-behaviour-tracker/user-behaviour-statistics/session-statistic/SessionStatistic";

export default {
  session: SessionStatistic,
  inspector: InspectorStatistic,
  collection: CollectionStatistic,
  config: ConfigStatistic
}