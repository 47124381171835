import React from 'react'
import ConfigurationSearchRow from '../../../generic-components/dashboard/configuration/configuration-search-row/ConfigurationSearchRow'
import ConfigurationSelectRow from '../../../generic-components/dashboard/configuration/configuration-select-row/ConfigurationSelectRow'
import ConfigurationToggleRow from '../../../generic-components/dashboard/configuration/configuration-toggle-row/ConfigurationToggleRow'
import { camelCaseToRegular } from '../../../../helpers/user-behaviour-tracker/dashboardHelpers'
import "./playground-total-side-panel.css"

const PlaygroundTotalSidePanel = ({
  query,
  setQuery, 
  filter, 
  setFilter,
  clearFilters, 
  FILTER_PROPERTIES, 
  BEASTMODE_FILTER_PROPERTIES 
}) => {

  const updateFilter = (property, value, isBeastmode, isSelect) => {
    const newFilter = {...filter}
    const fitlerObject = isBeastmode ? newFilter.beastmode : newFilter

    if (isSelect) {
      fitlerObject[property].push(value)
    } 
    else {
      fitlerObject[property] = fitlerObject[property].filter(filter => filter !== value)
    }

    setFilter(newFilter)
  }

  const toggleKnockoutWins = () => {
    const newFilter = {...filter}
    newFilter.onlyKnockoutWins = !newFilter.onlyKnockoutWins
    setFilter(newFilter)
  }

  const filterValueExists = (property, value, isBeastmode) => {
    return (
      isBeastmode ? 
      filter.beastmode[property].includes(value) :
      filter[property].includes(value)
    )
  }

  return (
    <>
      <ConfigurationSearchRow label="Search" query={query} setQuery={setQuery}/>
      <ConfigurationToggleRow label="Knockouts only" 
        checked={filter.onlyKnockoutWins}
        onToggle={toggleKnockoutWins}/>
      {
        Object.keys(FILTER_PROPERTIES).map(property => {
          return (
            <ConfigurationSelectRow
              key={`playground-total-configuration__filter-row-${property}`} 
              id={`playground-total-configuration__filter-row-${property}`}
              label={property === "winner" ? "Result" :camelCaseToRegular(property)}
              options={FILTER_PROPERTIES[property].map(value => { 
                return { label: camelCaseToRegular(value), value }
              })}
              selected={filter[property]}
              onSelect={({ value }) => {
                if (!filterValueExists(property, value, false)) {
                  updateFilter(property, value, false, true)
                }
              }}
              multiselect={true}
              onUnselect={value => {
                if (filterValueExists(property, value, false)) {
                  updateFilter(property, value, false, false)
                }
              }}/>
          )
        })
      }
      <h3 className="configuration-panel__sub-title">Beastmode</h3>
      {
        BEASTMODE_FILTER_PROPERTIES !== undefined &&
        Object.keys(BEASTMODE_FILTER_PROPERTIES).map(property => {
          return (
            <ConfigurationSelectRow
              key={`playground-total-configuration__beastmode-filter-row-${property}`} 
              id={`playground-total-configuration__beastmode-filter-row-${property}`}
              label={camelCaseToRegular(property)}
              options={BEASTMODE_FILTER_PROPERTIES[property].map(value => { 
                return { label: camelCaseToRegular(value), value}
              })}
              selected={filter.beastmode[property]}
              onSelect={({ value }) => {
                if (!filterValueExists(property, value, true)) {
                  updateFilter(property, value, true, true)
                }
              }}
              multiselect={true}
              onUnselect={value => {
                if (filterValueExists(property, value, true)) {
                  updateFilter(property, value, true, false)
                }
              }}/>
          )
        })
      }
      <button id="playground-total-side-panel__clear-filter-button" 
        onClick={() => clearFilters()}>Clear filters</button>
    </>
  )
}

export default PlaygroundTotalSidePanel