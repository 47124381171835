import React from 'react'
import ConfigurationSelectRow from '../../../generic-components/dashboard/configuration/configuration-select-row/ConfigurationSelectRow'
import { camelCaseToRegular } from '../../../../helpers/user-behaviour-tracker/dashboardHelpers'
import { atom, useAtom } from 'jotai'

const playerChartConfigAtom = atom({
  chartType: null,
  aggregateGroup: null,
  aggregate: null,
  value: null
})

const PlaygroundPlayerSidePanel = ({ selectedPlayer }) => {
  const [chartConfiguration, setChartConfiguration] = useAtom(playerChartConfigAtom)
  const CHART_OPTIONS = [
    "pie",
    "bar",
    "timeseries"
  ]

  const GROUPING_OPTIONS = [
    "opponent",
  ]

  const PROPERTY_OPTIONS = [
    "totalMatches",
    "totalWins",
    "totalLosses",
    "winPercentage"
  ]

  const getSelectOptions = (options) => {
    return options.map(option => {
      return {
        value: option,
        label: camelCaseToRegular(option)
      }
    })
  }

  const isTimeSeriesChart = () => {
    return chartConfiguration.chartType === "timeseries"
  }

  const shouldRenderAggregateSelect = () => {
    return (
      isTimeSeriesChart() ? 
      chartConfiguration.aggregateGroup && chartConfiguration.aggregateGroup !== "n/a" :
      chartConfiguration.aggregateGroup

    )
  }


  const getAggregateGroupingOptions = () => {
    const options = [...GROUPING_OPTIONS]

    if (isTimeSeriesChart()) {
      options.push("n/a")
    }

    return getSelectOptions(options)
  }

  const getAggregateOptions = () => {
    return (
      isTimeSeriesChart() ? 
      getSelectOptions(
        Object.keys(selectedPlayer[`${chartConfiguration.aggregateGroup}Total`])
      ) : 
      getSelectOptions(PROPERTY_OPTIONS)
    )
  }
  
  const updateChartType = (value) => {
    if (value !== chartConfiguration.chartType) {
      setChartConfiguration({
        chartType: value,
        aggregateGroup: null,
        aggregate: null,
        value: null
      })
    }
  }

  const updateAggregateGroup = (value) => {
    if (value !== chartConfiguration.aggregateGroup) {
      setChartConfiguration({
        chartType: chartConfiguration.chartType,
        aggregateGroup: value,
        aggregate: null,
        value: null
      })
    }
  }

  const updateChartConfig = (property, value) => {
    const newChartConfig = { ...chartConfiguration, [property]: value }
    setChartConfiguration(newChartConfig)
  }

  return (
    <>
      <ConfigurationSelectRow label="Chart"
        id="playground-player-configuration__chart-type"
        options={getSelectOptions(CHART_OPTIONS)}
        selected={chartConfiguration.chartType}
        onSelect={({ value }) => updateChartType(value)}/>
      <ConfigurationSelectRow label="Aggregate group"
        id="playground-player-configuration__aggregate-group"
        options={getAggregateGroupingOptions()}
        selected={chartConfiguration.aggregateGroup}
        onSelect={({ value }) => updateAggregateGroup(value)}/>
      { 
        shouldRenderAggregateSelect() && 
        <ConfigurationSelectRow label="Aggregate"
          id="playground-player-configuration__aggregate"
          options={getAggregateOptions()}
          selected={chartConfiguration.aggregate}
          onSelect={({ value }) => updateChartConfig("aggregate", value)}/>
      }
      { 
        isTimeSeriesChart() &&
        <ConfigurationSelectRow label="Property"
          id="playground-player-configuration__property"
          options={getSelectOptions(PROPERTY_OPTIONS)}
          selected={chartConfiguration.value}
          onSelect={({ value }) => updateChartConfig("value", value)}/>
      }
    </>
  )
}

export default PlaygroundPlayerSidePanel
export {
  playerChartConfigAtom
}