import React from 'react'
import './dashboard-selection.css'

const DashboardSelection = ({ selection, setSelection }) => {
  const selectionOptions = ["User", "Admin", "Founder", "Test"]
  return (
    <>
      <ul id="dashboard-selection__container">
        {
          selectionOptions.map((option, idx) => {
            return (
              <li
                key={`dashbord-option-${idx}`}
                className={
                  `dashboard-selection__dashboard-option${selection === option ?
                   " dashboard-selection__dashboard-option--selected" :
                    "" }`}
                onClick={() => setSelection(option)}>
                <a className='dashboard-selection__dashboard-link'>{option}</a>
              </li>
            )
          })
        }
      </ul>
    </>
  )
}

export default DashboardSelection
