import React from 'react'
import { MdOutlineSearch } from 'react-icons/md'
import "./searchbar.css"

const Searchbar = ({ id, query, setQuery, width = "20vh" }) => {

  return (
    <div className="search__container" style={{ width }} id={id}>
        <input 
          value={query} 
          onChange={e => setQuery(e.target.value)}/>
        <MdOutlineSearch/>
    </div>
  )
}

export default Searchbar