import React from 'react'
import PlaygroundTotalStatistic from '../../playground-statistics/playground-total-statistics/PlaygroundTotalStatistic'
import PlaygroundLeaderboardTable from '../../playground-leaderboard-table/PlaygroundLeaderboardTable'
import DashboardSectionHeader from '../../../generic-components/dashboard/dashboard-section-header/DashboardSectionHeader'
import Loader from '../../../generic-components/loader/Loader'
import { FaFilter } from 'react-icons/fa'

const PlaygroundTotalDashboard = ({ 
  isLoading,
  playerData,
  selectedPlayer,
  setSelectedPlayer,
  totalAggregate,
  setShowSidePanel,
  tableData 
}) => {
  const dataReady = () => {
    return !isLoading && playerData
  }

  return (
    <>
      <DashboardSectionHeader title="Analytics"/>
      {  
        dataReady() ?
        <PlaygroundTotalStatistic 
          setSelectedPlayer={setSelectedPlayer} 
          totalAggregate={totalAggregate}/> :
        <Loader size={36}/>
      }
      <DashboardSectionHeader title="Table">
        <div className="playground-tracker__side-panel-toggle"
          onClick={()=> setShowSidePanel(true)}>
          <FaFilter size={"1.5vh"}/>
        </div>
      </DashboardSectionHeader>
      {
        dataReady() ?
        <PlaygroundLeaderboardTable 
          tableData={tableData}
          selectedPlayer={selectedPlayer}
          setSelectedPlayer={setSelectedPlayer}/> :
        <Loader size={36}/>
      }
    </>
  )
}

export default PlaygroundTotalDashboard