import DataFormatter from "./data-formatter"

export default class DataAggregator extends DataFormatter {
  constructor(data, tag) {
    super()
    this.data = data
    this.tag = tag
    this.timeseriesBasedOnEntry = false
    this.perSesisonKeyAppend = "-perSession"
    
    this.userData = {}
    this.statistics = {}
    this.timeseriesData = {}
    this.cumulativeData = {}
    this.arrayData = {}

    this.counterKeys = []
    this.perSessionKeys = []
    this.timeseriesKeys = []
  }

  userInitialization() {
    const initial = { timeseries: {}, counters: {}, perSession: {} }
    this.counterKeys.forEach((key) => initial.counters[key] = 0)
    this.perSessionKeys.forEach((key) => initial.perSession[key] = { average: 0, n: 0 })
    this.timeseriesKeys.forEach((key) => initial.timeseries[key] = {})
    return initial
  }

  initialize() {
    this.counterKeys.forEach((key) => {
      this.cumulativeData[key] = 0
      this.arrayData[key] = []
    })
    this.perSessionKeys.forEach((key) => {
      this.arrayData[key + this.perSesisonKeyAppend] = []
    })
    this.timeseriesKeys.forEach((key) => {
      this.timeseriesData[key] = {}
    })
  }

  getRawKey(key) {
    return key.replace(this.perSesisonKeyAppend, "")
  }

  valueTransform(instance, key) { 
    return instance[key] 
  }

  updateCumulativeAverage(averageObject, newDataPoint) {
    const oldAverage = averageObject.average
    const newN = averageObject.n + 1
    return {
      average: oldAverage + (newDataPoint - oldAverage) / newN,
      n: newN
    }
  }

  getTime(time) {
    const dateTime = new Date(time)
    const year = dateTime.getFullYear()
    const month = dateTime.getMonth() + 1
    const day = dateTime.getDate()
    const hour = dateTime.getHours()
    const string = `${year}-${month}-${day} ${hour}:00`
    return {
      actualDate: dateTime,
      bucketDate: new Date(string),
      maxValue: this.hour,
      string: string
    }
  }

  msToNextHours(time, hoursAhead = 1) {
    return 3600000 * hoursAhead - time % 3600000
  }

  timeCutoffCheck(timestamp) {
    return this.timecutoff[this.tag] === undefined || timestamp <= this.timecutoff[this.tag]
  }

  getStartTime(instance) {
    return instance[this.createdTimeKey]
  }

  handleTotalData(user, key, perSessionBool=false) {
    const counterKey = perSessionBool ? "perSession" : "counters"
    const data = this.userData[user][counterKey][key]
    const value = perSessionBool ? data.average : data
    this.userData[user][counterKey][key] = this.formatTotal(value, key)
    const arrayKey = key + (perSessionBool ? this.perSesisonKeyAppend : "")
    this.arrayData[arrayKey].push(value)
    if (!perSessionBool) {
      this.cumulativeData[key] += value
    }
  }

  handleTotalTimeseries(user, key) {
    const timeseries = this.userData[user].timeseries[key]
    Object.keys(timeseries).forEach((timeKey) => {
      if (this.timeseriesData[key][timeKey] === undefined) {
        this.timeseriesData[key][timeKey] = 0
      }
      this.timeseriesData[key][timeKey] += timeseries[timeKey]
    })
  }

  incrementDynamicCounter(instance, identifier, key) {
    const value = instance[key]
    if (value !== undefined) {
      const counter = this.userData[identifier][key]
      if (counter[value] === undefined) {
        counter[value] = 0
      }
      counter[value] += 1
    }
  }

  counterValidityCheck(counters, instance, startTime) {
    if (counters !== undefined) {
      const instanceTimestamp = startTime !== undefined ? startTime : this.getStartTime(instance)
      if (this.timeCutoffCheck(instanceTimestamp)) {
        return true
      }
    }
    return false
  }

  incrementCounters(instance, identifier, counterObject, keys, startTime) {
    const counters = counterObject !== undefined ? counterObject : this.userData[identifier].counters
    if (!this.counterValidityCheck(counters, instance, startTime)) return
    
    keys = keys !== undefined ? keys : this.counterKeys
    keys.forEach((key) => {
      if (counters[key] === undefined) {
        counters[key] = 0
      }
      counters[key] += this.valueTransform(instance, key)
    })
  }

  incrementPerSessionCounters(instance, identifier, counterObject, keys, startTime) {
    const counters = counterObject !== undefined ? counterObject : this.userData[identifier].perSession
    if (!this.counterValidityCheck(counters, instance, startTime)) return

    keys = keys !== undefined ? keys : this.perSessionKeys
    keys.forEach((key) => {
      if (counters[key] === undefined) {
        counters[key] = { average: 0, n: 0 }
      }
      counters[key] = this.updateCumulativeAverage(
        this.userData[identifier].perSession[key], 
        instance[key],
      )
    })
  }

  addTimeseriesBucket(timeseries, time, key, value) {
    if (timeseries[key][time.bucketDate] === undefined) {
      timeseries[key][time.bucketDate] = 0
    }
    const proposedValue = timeseries[key][time.bucketDate] + value
    // timeseries[key][time.bucketDate] = Math.min(proposedValue, time.maxValue)
    timeseries[key][time.bucketDate] = proposedValue
  }

  addToTimeseries(instance, identifier, startTime) {
    const timeseries = this.userData[identifier].timeseries
    if (timeseries !== undefined) {
      this.timeseriesKeys.forEach((key) => {
        if (timeseries[key] === undefined) {
          timeseries[key] = {}
        }
        let instanceTimestamp = startTime !== undefined ? startTime : this.getStartTime(instance)
        if (this.timeCutoffCheck(instanceTimestamp)) {
          let value = this.valueTransform(instance, key)
          let timeRemaining = this.msToNextHours(instanceTimestamp)
          let time = this.getTime(instanceTimestamp)
          if (this.timeseriesBasedOnEntry) {
            this.addTimeseriesBucket(timeseries, time, key, value)
          }
          else {
            while (value > 0 && this.timeCutoffCheck(instanceTimestamp)) {
              if (value < timeRemaining) {
                this.addTimeseriesBucket(timeseries, time, key, value)
                value = 0
              }
              else {
                this.addTimeseriesBucket(timeseries, time, key, timeRemaining)
                value -= timeRemaining
              }
              instanceTimestamp += timeRemaining
              timeRemaining = this.msToNextHours(instanceTimestamp)
              time = this.getTime(instanceTimestamp)
            }
          }
        }
      })
    }
  }

  customUserAggregationInline() {}

  userDataAggregation() {
    this.data.forEach((instance) => {
      const identifier = instance.identifier.toLowerCase()

      if (this.customInitialization !== undefined) {
        this.customInitialization(instance, identifier)
      }
      else {
        if (this.userData[identifier] === undefined) {
          this.userData[identifier] = this.userInitialization()
        }
      }

      if (this.customUserAggregation !== undefined) {
        this.customUserAggregation(instance, identifier)
      }
      else {
        this.incrementCounters(instance, identifier)
        this.incrementPerSessionCounters(instance, identifier)
        this.addToTimeseries(instance, identifier)
        this.customUserAggregationInline(instance, identifier)
      }
    })
  }

  cumulativeDataAggregation() {
    Object.keys(this.userData).forEach((user) => {
      this.counterKeys.forEach((key) => {
        this.handleTotalData(user, key)
      })
      this.perSessionKeys.forEach((key) => {
        this.handleTotalData(user, key, true)
      })
      this.timeseriesKeys.forEach((key) => {
        this.handleTotalTimeseries(user, key)
      })
      if (this.customCumulativeAggregation !== undefined) {
        this.customCumulativeAggregation(user)
      }
    })
  }

  sortArray(array) {
    array.sort((a, b) => {
      if (a < b) return -1
      if (a > b) return 1
      return 0
    })
  }

  getStats(userArray, key, topN) {
    let tempData = [...userArray]
    this.sortArray(tempData)

    if (topN !== undefined) {
      tempData = tempData.slice(tempData.length - topN, tempData.length)
    }
    
    const cumulative = tempData.reduce((a, b) => a + b, 0)
    return {
      cumulative: this.formatTotal(cumulative, key),
      mean: this.formatTotal(cumulative / tempData.length, key),
      median: this.formatTotal(tempData[Math.round(tempData.length / 2)], key)
    }
  }

  statisticsAggregation(topN) {
    Object.keys(this.arrayData).forEach((key) => {
      const rawKey = this.getRawKey(key)
      if (this.counterKeys.includes(rawKey) || this.perSessionKeys.includes(rawKey)) {
        this.statistics[key] = this.getStats(this.arrayData[key], key, topN)
      }
    })
    if (this.customStatisticsAggregation !== undefined) {
      this.customStatisticsAggregation(topN)
    }
  }

  generateRetentionMatrix() {
    if (this.retentionKey !== undefined && this.timeseriesData[this.retentionKey] !== undefined) {
      this.retentionData = {}
      const oneDayInt = this.hour * 24
      Object.keys(this.userData).forEach((user) => {
        const timeseries = this.downsampleDaily(this.userData[user].timeseries[this.retentionKey])
        const dates = Object.keys(timeseries).map((x) => new Date(x))
        this.sortArray(dates)
        
        const startDate = dates[0]
        if (this.retentionData[startDate] === undefined) {
          this.retentionData[startDate] = {
            tracker: { max: 0, total: 0 }
          }
        } 
        
        this.retentionData[startDate].tracker.total += 1
        this.retentionData[startDate][user] = {}
        var lastDayIdx = -1
        dates.forEach((date, idx) => {
          const daysForward = idx === 0 ? 1 : Math.round((date - dates[idx - 1]) / oneDayInt)
          this.retentionData[startDate][user][`Day ${lastDayIdx + daysForward}`] = true
          lastDayIdx += daysForward
        })
        if (lastDayIdx > this.retentionData[startDate].tracker.max) {
          this.retentionData[startDate].tracker.max = lastDayIdx
        }
      })

      this.retentionMatrix = {}
      Object.keys(this.retentionData).forEach((startDate) => {
        const tracker = this.retentionData[startDate].tracker
        this.retentionMatrix[startDate] = { "Users In Cohort": tracker.total }
        for (var i = 0; i <= tracker.max; i++) {
          const dayKey = `Day ${i}`
          this.retentionMatrix[startDate][dayKey] = 0
          Object.keys(this.retentionData[startDate]).forEach((user) => {
            const retention = this.retentionData[startDate][user][dayKey] ? 1 : 0
            this.retentionMatrix[startDate][dayKey] += retention
          })
          const retentionProb = this.retentionMatrix[startDate][dayKey] / tracker.total
          this.retentionMatrix[startDate][dayKey] = this.formatProbability(retentionProb)
        }
      })
    }
  }

  downsampleDaily(timeseries) {
    const dailyTimeseries = {}
    Object.keys(timeseries).forEach((time) => {
      const dateObject = new Date(time)
      const date = `${dateObject.getFullYear()}-${dateObject.getMonth()+1}-${dateObject.getDate()}`
      if (dailyTimeseries[date] === undefined) {
        dailyTimeseries[date] = 0
      }
      dailyTimeseries[date] += timeseries[time]
    })
    return dailyTimeseries
  }

  aggregate() {
    this.userDataAggregation()
    this.cumulativeDataAggregation()
    this.statisticsAggregation()
    this.generateRetentionMatrix()
  }
}