import React, { useEffect } from 'react'
import { AiOutlineFileText } from 'react-icons/ai'
import './contract-component.css'
import FormatResponse from '../format-response/FormatResponse'
import InputSelector from '../input-selector/InputSelector'

const ContractComponent = ({ contractProps, setContractProps, toggledContract }) => {
  const { name, contract, functions, inputs  } = contractProps;


  async function handleClick(funcName, contract, inputs, func) {
    let inputElementValue;
    let expr = inputs[funcName].inputType;
    let response = undefined;
    switch (expr) {
      case "text":
        response = await func(contract, inputs)
        if (response !== undefined) {
          setContractProps((prevState) => {
            return {
              ...prevState,
              state: {
                ...prevState.state,
                [funcName]: response
              }
            }
          })
        }
        return response
        break;
      case "none":
        response = await func(contract)
        if (response.hasOwnProperty("_isBigNumber")) {
          response = Number(response)
        }
        if (response !== undefined) {
          setContractProps((prevState) => {
            return {
              ...prevState,
              state: {
                ...prevState.state,
                [funcName]: response
              }
            }
          })
        }
        return response
        break;
      case "file":
        inputElementValue = document.getElementById(inputs[funcName].inputId).value
        inputs[funcName].inputValue = inputElementValue
        response = await func(contract, inputs)
        if (response !== undefined) {
          setContractProps((prevState) => {
              return {
                ...prevState,
                state: {
                  ...prevState.state,
                  [funcName]: response
                }
              }
            })
        }
        return response
        break;
      default:
        console.log(`Sorry, we are out of ${expr}.`);
    }
  }


  return (
    <>
      <div id="contract-component__container">
        <div id="contract-component__title">
          <h2>
            <a className="dashboard-button" target="_blank" href={`https://testnet.arbiscan.io/address/${contract?.address}`}>
              {name}<AiOutlineFileText size={24} color="#11dbab"/>
            </a>
          </h2>
        </div>

        <div id="contract-component__functions">
          {
            functions.map((funcData, index) => {
              const [funcName, func] = funcData
              return (
                <div key={index} className="contract-component__subcontainer">
                  <p id={funcName} className="contract-component__subcontainer-title">
                    {funcName}: 
                    {
                      contractProps.state.hasOwnProperty(funcName) && contractProps.state[funcName] !== "" ? 
                      <FormatResponse obj={contractProps.state} keyIdentifier={funcName} /> : 
                      " "
                    }
                  </p>
                  <InputSelector inputs={inputs} contractProps={contractProps} keyIdentifier={funcName} setContractProps={setContractProps} />
                  <div 
                    onClick={() => handleClick(funcName, contract, inputs, func)}
                    >
                    <p className='dashboard-button'>{funcName}</p>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  )
}

export default ContractComponent