import React, { useState, useEffect } from 'react'
import UserBehaviourTimeseries from '../../user-behaviour-charts/user-behaviour-timeseries/UserBehaviourTimeseries'
import ChartDisplay from '../../../generic-components/dashboard/chart-display/ChartDisplay'
import { userBehaviourConfigurationsAtom } from "../../user-behaviour-config/UserBehaviourConfig"
import { 
  camelCaseToRegular, 
  shouldUpdateTimeseries, 
  updateTimeseriesData 
} from '../../../../helpers/user-behaviour-tracker/dashboardHelpers'
import { useAtom } from 'jotai'

const InspectorDashboard = ({ aggregator, tag }) => {
  const [config] = useAtom(userBehaviourConfigurationsAtom)
  const [timeSeriesData, setTimeseriesData] = useState([])

  useEffect(() => {
    if (aggregator && shouldUpdateTimeseries(config, "inspector")) {
      updateTimeseriesData(config, aggregator, "inspector", setTimeseriesData)
    }
  }, [config.sharedConfig, config.inspectorConfig])

  return (
    <ChartDisplay  
      label={camelCaseToRegular(`${config.inspectorConfig.aggregateProperty} Timeseries`)}>
      <UserBehaviourTimeseries 
        timeseriesData={timeSeriesData} 
        aggregator={aggregator}
        id="user-behaviour__collection-timeseries-chart"
        tab="inspector"/>
    </ChartDisplay >
  )
}

export default InspectorDashboard