import React, { useEffect, useRef } from "react"
import DensityPlot from '../../../../helpers/charting/DensityPlot'
import * as _ from "lodash"

const Distribution = ({ data, config, id, className }) => {
  const chartRef = useRef()

  const getConfiguration = () => {
    const defaultConfig =  {
        margin: { top: 15, left: 30, bottom: 35 },
        legend: { showLegend: true }
    }

    return _.merge({}, defaultConfig, config)
  }

  useEffect(() => {
    chartRef.current = new DensityPlot(
      `#${id}`,
      data,
      getConfiguration()
    )
  }, [])

  useEffect(() => {
    if (chartRef && chartRef.current && data && data.length > 0) {
      chartRef.current.updateConfiguration(getConfiguration())
      chartRef.current.updateData(data)
    }
  }, [data, config])

  return (
    <div id={id} className={className}></div>
  )
}

export default Distribution