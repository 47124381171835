import * as d3 from 'd3'
import * as _ from 'lodash'
import { closeTooltip, displayTooltip } from '../../components/generic-components/tooltip/Tooltip'

export const notNullOrUndefined = (value) => {
  return (value !== undefined) && (value !== null)
}

const DEFAULT_CONFIGURATION = {
  margin: { top: 0, right: 0, bottom: 0, left: 0 },
  labelPadding: { x: 0, y: 0 },
  chartPadding: { x: 0, y: 0},
  label: {
    x: {
      color: "black",
      text: "",
      font: {
        fontSize: "1.8vh",
        fontWeight: 500,
        fontFamily: "Arial, sans-serif"
      }
    },
    y: {
      color: "black",
      text: "",
      font: {
        fontSize: "1.8vh",
        fontWeight: 500,
        fontFamily: "Arial, sans-serif"
      }
    }
  },
  title: { 
    titleText: undefined, 
    titleColor: "black",  
    font: {
      fontSize: "2.5vh",
      fontWeight: 500,
      fontFamily: "Arial, sans-serif"
    } 
  },
  legend: { 
    showLegend: false, 
    legendSize: 10, 
    legendPadding: 20, 
    font: {
      fontSize: "1.2vh",
      fontWeight: 500,
      fontFamily: "Arial, sans-serif"
    } 
  },
  useSeperateScaling: {
    x: false,
    y: false
  },
  scaleToDisplay: {
    x: 0,
    y: 0
  }
}

const xMap= d => d.x
const yMap = d => d.y

class BaseChart {
  constructor(
    containerId, 
    data, 
    configuration={}, 
    xMapping = xMap, 
    yMapping = yMap, 
    skipAxisSetup=true
  ) {
    this.containerId = containerId
    this.data = data
    this.configuration = _.merge({}, DEFAULT_CONFIGURATION, configuration)
    this.xMapping = xMapping
    this.yMapping = yMapping

    this.updateChartDimensions()
    
    if (!skipAxisSetup) {
      const { scales, axes } = this.getAxis()
      this.scales = scales
      this.axes = axes
    }
  }

  updateChartDimensions(){
    const chartContainer = this.getChartContainer()
    const chartHeight = parseInt(chartContainer.style("height"))
    const chartWidth = parseInt(chartContainer.style("width"))
    const innerWidthPadding = (
      this.configuration.margin.left + 
      this.configuration.margin.right + 
      this.configuration.labelPadding.y
    )
    const innerHeightPadding = (
      this.configuration.margin.top + 
      this.configuration.margin.bottom + 
      this.configuration.labelPadding.x
    )
    const innerWidth = chartWidth - innerWidthPadding
    const innerHeight = chartHeight - innerHeightPadding
    this.chartDimensions = { chartWidth, chartHeight, innerWidth, innerHeight }
  }

  getChartContainer(){
    return d3.select(this.containerId)
  }

  addAxisText(yAxisG, xAxisG) {
    yAxisG.append('text')
      .attr('class', 'axis-label')
      .attr('y', -this.configuration.labelPadding.y)
      .attr('x', -this.chartDimensions.innerHeight / 2)
      .attr("font-size", this.configuration.label.y.font.fontSize)
      .attr("font-family", this.configuration.label.y.font.fontFamily)
      .attr("font-weight", this.configuration.label.y.font.fontWeight)
      .attr("stroke", "none")
      .attr("fill", this.configuration.label.y.color)
      .attr('transform', `rotate(-90)`)
      .attr('text-anchor', 'middle')
      .text(this.configuration.label.y.text)

    xAxisG.append('text')
      .attr('class', 'axis-label')
      .attr('y', this.configuration.labelPadding.x)
      .attr('x', this.chartDimensions.innerWidth / 2)
      .attr("font-size", this.configuration.label.x.font.fontSize)
      .attr("font-family", this.configuration.label.x.font.fontFamily)
      .attr("font-weight", this.configuration.label.x.font.fontWeight)
      .attr("stroke", "none")
      .attr("fill", this.configuration.label.x.color)
      .text(this.configuration.label.x.text)
  }

  addTitle() {
    this.getChartContainer()
    .select("svg")
    .append("text")
    .attr("class", "chart-title")
    .attr("x", this.chartDimensions.chartWidth / 2)
    .attr("fill", this.configuration.title.titleColor)
    .attr("font-size", this.configuration.title.font.fontSize)
    .attr("font-family", this.configuration.title.font.fontFamily)
    .attr("font-weight", this.configuration.title.font.fontWeight)
    .style("text-anchor", "middle")
    .text(this.configuration.title.titleText)
    .attr("y", () => {
      return this.configuration.margin.top
    })
  }

  getLegendColors() {
    return this.configuration.styling.map(styling => styling.stroke)
  }

  addLegend() {
    const legendColors = this.getLegendColors()
    const chartContainerHeight = this.getChartContainer().select('.chart-container').node().getBBox().height

    const legend = this.getChartContainer()
      .select("svg")
      .append("g")
      .attr("class", "chart-legend")
      .style("transform", `translate(${this.configuration.margin.left}px, ${chartContainerHeight + this.configuration.chartPadding.x + this.configuration.margin.top}px)`)

    const legendItems = legend.selectAll(".legend-item")
      .data(this.data)
      .enter()
      .append("g")
      .attr("class", "legend-item")

    legendItems.append("rect")
      .attr("width", this.configuration.legend.legendSize)
      .attr("height", this.configuration.legend.legendSize)
      .style("fill", (d, idx) => legendColors[idx % legendColors.length])

    legendItems.append("text")
      .attr("x", 3 + this.configuration.legend.legendSize)
      .attr("y", this.configuration.legend.legendSize)
      .attr("font-size", this.configuration.legend.font.fontSize)
      .attr("font-family", this.configuration.legend.font.fontSize)
      .attr("font-weight", this.configuration.legend.font.fontSize)
      .text((d, idx) => this.data[idx].metadata.name)

    let xTranslate = 0
    let yTranslate = 0

    legendItems
      .style("transform", (d, idx) => {
        if (idx === 0) {
          return "translate(0, 0)"
        }

        const currentLegend = legendItems.nodes()[idx].getBBox()
        const previousLegend = legendItems.nodes()[idx - 1].getBBox()

        xTranslate = previousLegend.width + xTranslate + this.configuration.legend.legendPadding

        if (xTranslate + currentLegend.width > this.chartDimensions.innerWidth) {
          xTranslate = 0;
          yTranslate = yTranslate + currentLegend.height + 5
        }
        return `translate(${xTranslate}px, ${yTranslate}px)`
      })
  }

  updateConfiguration(newConfig) {
    this.configuration = _.merge(this.configuration, newConfig)
  }

  getXRange() {
    var xRange = [Infinity, -Infinity]
    this.data.forEach((d) => {
      const currentRange =  d3.extent(d.data, this.xMapping)
      if (currentRange[0] < xRange[0]) xRange[0] = currentRange[0]
      if (currentRange[1] > xRange[1]) xRange[1] = currentRange[1]
    })
    return xRange
  }

  getYRange() {
    var yRange = [Infinity, -Infinity]
    this.data.forEach((d) => {
      const currentRange = d3.extent(d.data, this.yMapping)
      if (currentRange[0] < yRange[0]) yRange[0] = currentRange[0]
      if (currentRange[1] > yRange[1]) yRange[1] = currentRange[1]
    })

    return yRange
  }

  getScale() {
    const scale = this.configuration.scale
    if (scale.x.isLogScale === true) scale.x.scale = d3.scaleLog()
    if (scale.y.isLogScale === true) scale.y.scale = d3.scaleLog()
    return scale
  }

  getAxis() {
    let xScale
    let yScale
    const padding = this.configuration.chartPadding
    const scale = this.getScale()

    if (this.configuration.useSeperateScaling.x) {
      xScale = []
      this.configuration.scale.x.scale.forEach(({ scale }, idx) => {
        const xRange = d3.extent(this.data[idx].data, this.xMapping)
        xScale.push(
          scale
          .domain([xRange[0] * (1 - padding.x), xRange[1] * (1 + padding.x)])
          .range([0, this.chartDimensions.innerWidth])
          .nice()
        )
      })
    } 
    else {
      const xRange = this.getXRange()
      xScale = scale.x.scale
      .domain([xRange[0] * (1 - padding.x), xRange[1] * (1 + padding.x)])
      .range([0, this.chartDimensions.innerWidth])
      .nice()
    }

    if (this.configuration.useSeperateScaling.y) {
      yScale = []
      this.configuration.scale.y.scale.forEach(({ scale }, idx) => {
        const yRange = d3.extent(this.data[idx].data, this.yMapping)
        yScale.push(
          scale
          .domain([yRange[0] * (1 + padding.y), yRange[1] * (1 - padding.y)])
          .range([this.chartDimensions.innerHeight, 0])
          .nice()
        )
      })
    }
    else {
      const yRange = this.getYRange()
      yScale = scale.y.scale
      .domain([yRange[0] * (1 + padding.y), yRange[1] * (1 - padding.y)])
      .range([this.chartDimensions.innerHeight, 0])
      .nice()
    }
  
    const xScaleForAxis = (
      Array.isArray(xScale) ? 
      xScale[this.configuration.scaleToDisplay.x] : 
      xScale
    )
    const yScaleForAxis = (
      Array.isArray(yScale) ? 
      yScale[this.configuration.scaleToDisplay.y] : 
      yScale
    )

    let xAxis = d3.axisBottom(xScaleForAxis)
    if (notNullOrUndefined(scale.x.ticks)) xAxis = xAxis.ticks(scale.x.ticks)
    if (notNullOrUndefined(scale.x.tickSize)) xAxis = xAxis.tickSize(scale.x.tickSize)
    if (notNullOrUndefined(scale.x.tickFormat)) xAxis = xAxis.tickFormat(scale.x.tickFormat)

    let yAxis = d3.axisLeft(yScaleForAxis)
    if (notNullOrUndefined(scale.y.ticks)) yAxis = yAxis.ticks(scale.y.ticks)
    if (notNullOrUndefined(scale.y.tickSize)) yAxis = yAxis.tickSize(scale.y.tickSize)
    if (notNullOrUndefined(scale.y.tickFormat)) yAxis = yAxis.tickFormat(scale.y.tickFormat)

    return { scales: { xScale, yScale }, axes: { xAxis, yAxis } }
  }

  getTooltipMetadata(d) {
    return d.metadata.toolTip
  }

  setUpTooltip(selection) {
    selection.on("mouseover", (event, d) => {
      d3.select(`#${this.configuration.toolTip.id}`).node().innerHTML = this.getTooltipMetadata(d)
      const position = { top: `${event.layerY}px`, left: `${event.layerX}px`}
      displayTooltip(this.configuration.toolTip.id, position)

    })
    .on("mouseout", () => {
      closeTooltip(this.configuration.toolTip.id)
    })
  }

  setUpChart() {
    const { scales, axes } = this.getAxis()
    this.scales = scales
    this.axes = axes

    const chart = this.getChartContainer()
      .append("svg")
        .style("overflow", "visible")
        .attr("width", "100%")
        .attr("height", "100%")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr(
          "viewBox",
         `0 0 ${this.chartDimensions.chartWidth} ${this.chartDimensions.chartHeight}`
         )

    const xOffset = this.configuration.margin.left + this.configuration.labelPadding.y
    const yOffset = this.configuration.margin.top
    const g = chart.append('g')
      .attr("class", "chart-container")
      .attr("transform", `translate(${xOffset}, ${yOffset})`)

    const yAxisG = g.append('g')
      .attr("class", "y-axis")
      .call(this.axes.yAxis)
    const xAxisG = g.append('g')
      .attr("class", "x-axis")
      .call(this.axes.xAxis)
      .attr('transform', `translate(0,${this.chartDimensions.innerHeight})`)
    
    if (
      this.configuration.label.x.text !== undefined || 
      this.configuration.label.y.text !== undefined
    ) {
      this.addAxisText(yAxisG, xAxisG)
    }

    if (this.configuration.title.titleText !== undefined) {
      this.addTitle()
    }

    if (this.configuration.legend.showLegend === true && this.data.length > 1) {
      this.addLegend()
    }
  }

  renderChart() {}

  clearChart() {
    this.getChartContainer().select("svg").remove()
  }

  resize() {
    this.updateChartDimensions()
    this.clearChart()
    this.setUpChart()
    this.renderChart()
  }
  
  updateData(newData) {
    this.data = newData
    this.clearChart()
    this.setUpChart()
    this.renderChart()
  }
}

export default BaseChart