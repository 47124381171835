import React, { useEffect } from 'react'
import ConfigurationSelectRow from '../../../generic-components/dashboard/configuration/configuration-select-row/ConfigurationSelectRow'
import { userBehaviourConfigurationsAtom } from '../UserBehaviourConfig'
import { useAtom } from 'jotai'
import { camelCaseToRegular } from '../../../../helpers/user-behaviour-tracker/dashboardHelpers'

const UserBehaviourSessionConfig = ({ aggregator }) => {
  const [config, setConfig] = useAtom(userBehaviourConfigurationsAtom)
  
  const updateSessionConfig = (property, value) => {
    setConfig((prevState) => {
      const newSessionConfig = { ...prevState.sessionConfig }
      newSessionConfig[property] = value
      return {
        ...prevState,
        sessionConfig: newSessionConfig
      }
    })
  }

  useEffect(() => {
    if (aggregator && !config.sessionConfig) {
      updateSessionConfig("aggregateProperty", aggregator.timeseriesKeys[0])
    }
  }, [aggregator])

  return (
    <>
      {
        aggregator && config.sessionConfig && (
          <div className="user-behaviour-config__configuration-section">
            <ConfigurationSelectRow label="Aggregate property"
              selected={config.sessionConfig.aggregateProperty}
              options={aggregator.timeseriesKeys.map(key => { 
                return { label: camelCaseToRegular(key), value: key }
              })}
              onSelect={option => updateSessionConfig("aggregateProperty", option.value)}
              id="user-behaviour-config__session-aggregate-property"/>
          </div>
        )
      }
    </>
  )
}

export default UserBehaviourSessionConfig