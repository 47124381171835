import React from 'react'
import './tooltip.css'

const displayTooltip = (tooltipId, position) => {
  tooltipId = tooltipId !== undefined ? tooltipId : "tooltip"
  var tooltip = document.getElementById(tooltipId)
  if (tooltip !== null) {
    tooltip.style.display = "flex"
    tooltip.style.left = position.left
    tooltip.style.top = position.top
  }
}

const closeTooltip = (tooltipId) => {
  tooltipId = tooltipId !== undefined ? tooltipId : "tooltip"
  var tooltip = document.getElementById(tooltipId)
  if (tooltip !== null) {
    tooltip.style.display = "none"
  }
}

const Tooltip = ({ tooltipContent, tooltipId }) => {
  tooltipId = tooltipId !== undefined ? tooltipId : "tooltip"
  return (
    <>
      <div id={tooltipId} className="tooltip">
        {tooltipContent}
      </div>
    </>
  )
}

export default Tooltip
export {
  displayTooltip,
  closeTooltip
}
