import axios from 'axios'

export const addRequestInterceptor = (data) => {
  axios.interceptors.request.use(function (config) {
    if (config.method === 'get') {
      config.params = { ...config.params, ...data }
    }
    else if (config.method === 'post') {
      config.data = { ...config.data, ...data }
    }
    return config
  })
}