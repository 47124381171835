import React, { useState, useEffect } from 'react'
import lightIcon from '../../local-assets/icons/sun.svg'
import darkIcon from '../../local-assets/icons/moon.svg'
import * as d3 from 'd3'
import './toggle-light-dark.css'

const ToggleLightDark = () => {
  const [lightMode, setLightMode] = useState(false)

  useEffect(() => {
    const newClass = `${lightMode ? "light" : "dark"}-body`
    d3.select("body")
      .attr("class", "")
      .classed(newClass, true)
  }, [lightMode])

  return (
    <div 
      id="toggle-light-dark__container"
      onClick={() => setLightMode((prevState) => { return !prevState })}>
      <img src={lightMode ? lightIcon : darkIcon} alt="" />
    </div>
  )
}

export default ToggleLightDark
