import React from 'react'
import Searchbar from '../../../searchbar/Searchbar'
import "./configuration-search-row.css"

const ConfigurationSearchRow = ({ label, id, query, setQuery }) => {
  return (
    <div className="configuration-search-row__container">
      <div className="configuration-search-row__label">
        {label}
      </div>
      <Searchbar
        id={id}
        width="30vh"
        query={query}
        setQuery={setQuery}/>
    </div>
  )
}

export default ConfigurationSearchRow