import React, { useRef, useEffect } from 'react'
import LineChart from '../../../../helpers/charting/LineChart'
import { CHART_COLORS } from '../../../../helpers/user-behaviour-tracker/dashboardHelpers'
import * as d3 from 'd3'
import * as _ from 'lodash'

const Timeseries = ({ 
  id,
  className, 
  config,
  data
}) => {
  const chartRef = useRef()

  const getChartConfiguration = () => {
    const defaultConfig = {
      margin: { top: 15, left: 30 },
      labelPadding: { x: 30, y: 60 },
      scale: { 
        x: {
          scale: d3.scaleTime(),
          ticks: 5,
          tickFormat: (d) => {
            const days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
            return `${days[d.getDay()]} ${d.getDate()}`
          }
        }, 
        y: {
          scale: d3.scaleLinear()
        }
      },
      styling: [
        {
          stroke: CHART_COLORS[3],
        },
        {
          stroke: CHART_COLORS[0],
        }
      ]
    }

    return _.merge({}, defaultConfig, config)
  }

  const hasSufficentData = () => {
    let datapointCount = 0

    for (let lineData of data) {
      datapointCount = Math.max(lineData.data.length, datapointCount)
    }

    return datapointCount > 1
  }

  useEffect(() => {
    chartRef.current = new LineChart(
      `#${id}`,
      data,
      getChartConfiguration()
    )
  }, [])

  useEffect(() => {
    if (chartRef && chartRef.current && data.length > 0 && hasSufficentData()) {
      chartRef.current.updateConfiguration(getChartConfiguration())
      chartRef.current.updateData(data)
    }
  }, [data])

  return (
    <>
      <div style={{ display: hasSufficentData() ? undefined : "none"}}
        className={className} 
        id={id}></div> 
      {!hasSufficentData() && <div className="timeseries__error">Not enough data</div>}
    </>
  )
}

export default Timeseries