import UserBehaviourCollectionConfig from "../../components/user-behaviour-tracker/user-behaviour-config/user-behaviour-collection-config/UserBehaviourCollectionConfig";
import UserBehaviourConfigConfig from "../../components/user-behaviour-tracker/user-behaviour-config/user-behaviour-config-config/UserBehaviourConfigConfig";
import UserBehaviourInspectorConfig from "../../components/user-behaviour-tracker/user-behaviour-config/user-behaviour-inspector-config/UserBehaviourInspectorConfig";
import UserBehaviourSessionConfig from "../../components/user-behaviour-tracker/user-behaviour-config/user-behaviour-session-config/UserBehaviourSessionConfig";

export default {
  session: UserBehaviourSessionConfig,
  inspector: UserBehaviourInspectorConfig,
  collection: UserBehaviourCollectionConfig,
  config: UserBehaviourConfigConfig
}