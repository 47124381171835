import React from 'react'
import Distribution from '../../../generic-components/charts/distribution/Distribution'

const UserBehaviourDistribution = ({ id, distributionData }) => {
  return (
    <Distribution 
      data={distributionData} 
      id={id} 
      className="user-behaviour-chart__chart-container"/>
  )
}

export default UserBehaviourDistribution