import React, { useEffect, useState } from 'react'
import { MdArrowDropDown, MdOutlineSearch, MdOutlineClose } from 'react-icons/md'
import "./dropdown.css"

const MultiselectBlock = ({ onUnselect, label }) => {
  return (
    <div className="dropdown__multiselect">
      {label}
      <MdOutlineClose onClick={() => {
        onUnselect()
      }}/>
    </div>
  )
}

const Dropdown = ({
  id, 
  selected, 
  options, 
  onSelect, 
  width = "20vh", 
  searchable = false, 
  multiselect = false,
  onUnselect
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [query, setQuery] = useState("")
  const [optionToLabelMap, setOptionToLabelMap] = useState({})

  const getOptions = () => {
    if (!searchable) return options

    const searchTerms = query.split(",").map(filter => filter.trim())
    return options.filter(option => {
      for (let searchTerm of searchTerms) {
        if (option.label.includes(searchTerm)) return true
      }

      return false
    })
  }

  const handleClick = () => {
    setShowDropdown(false)
  }

  useEffect(() => {
    if (selected && searchable) {
      setQuery(selected)
    }
  }, [selected])

  useEffect(() => {
    const map = {}

    options.forEach(({ label, value}) => {
      map[value] = label
    })

    setOptionToLabelMap(map)
  }, [options])

  useEffect(() => {
    window.addEventListener("click", handleClick)
    return () => {
      window.removeEventListener("click", handleClick)
    }
  }, [])

  return (
    <div className="dropdown__container" style={{ width }} id={id}>
      { searchable ? 
      (
        <div className="dropdown__select">
          <input 
            value={query} 
            onChange={e => setQuery(e.target.value)}
            onClick={e => e.stopPropagation()}
            onFocus={e => {
              setQuery("")
              setShowDropdown(true)
            }}/>
          <MdOutlineSearch/>
        </div>
      ) : 
      (
        <div className="dropdown__select"
          onClick={(e) => {
            e.stopPropagation()
            setShowDropdown(!showDropdown)
          }}>
          {multiselect ? 
            <div className="dropdown__multiselect-container" 
              style={{ width: `calc(${width} - 2vh)`}}>
              {selected.map(value => {
                return <MultiselectBlock key={`dropdown-option-${id}-multiselect-${value}`}
                  label={optionToLabelMap[value]} 
                  onUnselect={() => onUnselect(value)}/>
              })}
            </div> :
            selected === null ? "select" : optionToLabelMap[selected]
          }
          <MdArrowDropDown/>
        </div>
      )}
      {showDropdown && <div className="dropdown__options" style={{ width }}>
          {getOptions().map(option => {
            return (
              <div 
                className="dropdown__option"
                key={`dropdown-option-${id}-${option.value}`} 
                onClick={() => {
                  onSelect(option)
                }}>{option.label}</div>
            )
          })}
        </div>}
    </div>
  )
}

export default Dropdown