import React from "react";
import "./toggle.css"

const Toggle = ({ 
  checked = false, 
  onToggle = () => {},
  height = 3,
  units = "vh",
  checkedColor = "#5c9cc4",
  uncheckedColor = "#ccc",
  thumbColor = "white"
}) => {

  const handleClick = () => {
    onToggle(!checked)
  }

  const thumbSize = height - 0.5
  const thumbPadding = (height - thumbSize) / 2

  return (
    <div 
      className="toggle__container" 
      onClick={() => handleClick()}
      style={{
        "--width": ((thumbSize * 2) + (thumbPadding * 2)) + units, 
        "--height": height + units, 
        "--thumb-size": thumbSize + units, 
        "--thumb-padding": thumbPadding + units,
        "--checked-color": checkedColor,
        "--unchecked-color": uncheckedColor,
        "--thumb-color": thumbColor 
      }}>
      <span className={`toggle__slider ${checked ? "toggle__slider--checked" : ""}`}/>
    </div>
  )
}

export default Toggle