import React from 'react'
import Toggle from ".././../../toggle/Toggle"
import "./configuration-toggle-row.css"

const ConfigurationToggleRow = ({ label, checked, onToggle, offLabel = "", onLabel = ""}) => {
  return (
    <div className="configuration-toggle-row__container">
      <div className="configuration-toggle-row__label">
        {label}
      </div>
      <Toggle checked={checked} onToggle={onToggle} height={2.5}/>
      <div className="configuration-toggle-row__toggle-label">
        {checked ? onLabel : offLabel}
      </div>
    </div>
  )
}

export default ConfigurationToggleRow