import { allNeuronFunctions } from './blockchain/write-requests/NeuronFunctions'
import { allRankedBattleFunctions } from './blockchain/write-requests/RankedBattleFunctions'
import { allFighterFarmFunctions } from './blockchain/write-requests/FighterFarmFunctions'
import { allFighterMarketplaceFunctions } from './blockchain/write-requests/FighterMarketplaceFunctions'
import { allGameItemsFunctions } from './blockchain/write-requests/GameItemsFunctions'
import { allIntelligenceFunctions } from "./blockchain/write-requests/IntelligenceFunctions"

const allSmartContractFunctions = {
  neuron: allNeuronFunctions,
  rankedBattle: allRankedBattleFunctions,
  fighterFarm: allFighterFarmFunctions,
  fighterMarketplace: allFighterMarketplaceFunctions,
  gameItems: allGameItemsFunctions,
  intelligence: allIntelligenceFunctions
}

export default allSmartContractFunctions

