import React, { useEffect, useState } from "react"
import PlaygroundTotalSidePanel from "./playground-side-panels/playground-total-side-panel/PlaygroundTotalSidePanel"
import PlaygroundTotalDashboard from "./playground-dashboards/playground-total-dashboard/PlaygroundTotalDashboard"
import PlaygroundIndividualDashboard from "./playground-dashboards/playground-individual-dashboard/PlaygroundIndividualDashboard"
import PlaygroundPlayerSidePanel from "./playground-side-panels/playground-player-side-panel/PlaygroundPlayerSidePanel"
import NavigationPanel from "../generic-components/dashboard/navigation/navigation-panel/NavigationPanel"
import Dropdown from "../generic-components/dropdown/Dropdown"
import NavigationLink from "../generic-components/dashboard/navigation/navigation-link/NavigationLink"
import DashboardContainer from "../generic-components/dashboard/dashboard-container/DashboardContainer"
import ConfigurationPanel from "../generic-components/dashboard/configuration/configuration-panel/ConfigurationPanel"
import { FaListAlt } from 'react-icons/fa'
import { BsPersonFill } from 'react-icons/bs'
import { getMatchData } from "../../helpers/backend/get-requests/match-data"
import { camelCaseToRegular } from "../../helpers/user-behaviour-tracker/dashboardHelpers"
import { discordMappings } from "../../helpers/beta-wallets/demo-qualifiers-discord"
import "./playground-tracker.css"

const INITIAL_FILTERS = {
  opponent: [],
  stageName: [],
  element: [],
  winner: [],
  weight: [],
  beastmode: {
    stageName: [],
    element: [],
    weight: []
  }
}

const MatchesTracker = () => {
  const [tag, setTag] = useState("Demo Qualifiers")
  const [isLoading, setIsLoading] = useState(false)
  const [selectedPlayer, setSelectedPlayer] = useState()
  const [totalAggregate, setTotalAggregate]  = useState({})
  const [showSidePanel, setShowSidePanel] = useState(false)
  const [filter, setFilter] = useState(INITIAL_FILTERS)
  const [matchData, setMatchData] = useState([])
  const [playerData, setPlayerData] = useState([])
  const [tableData, setTableData] = useState([])
  const [queryString, setQueryString] = useState("")
  const [currentTab, setCurrentTab] = useState("matches")

  const TAGS = ["Demo Qualifiers"]
  const TABS = {
    matches: { label: "Matches", tab: "matches", Icon: FaListAlt }, 
    player: { label: "Player", tab: "player", Icon: BsPersonFill }, 
  }

  const OPPONENT_MAP = {
    "Bo": 4,
    "Guppy": 3,
    "Artist": 2,
    "Gwei": 1,
  }
  const OPPONENTS = Object.keys(OPPONENT_MAP)

  const PANEL_TITLE = {
    matches: "Filter",
    player: "Visualize"
  }

  const initializeDashboard = async () => {
    setIsLoading(true)
    // const baseQuery = `tag=${tag}&gameMode=simulation&numMatches=${1000}`
    // const qualifierAddresses = Object.keys(discordMappings)
    // const fetchedData = []
    // const start = 0
    // const end = 5//qualifierAddresses.length
    // for (var i = start; i < end; i++) {
    //   const query = `${baseQuery}&identifier=${qualifierAddresses[i]}`
    //   const matchDataResult = await getMatchData(query)
    //   console.log(i, qualifierAddresses[i], matchDataResult.matchData)
    //   fetchedData.push(...matchDataResult.matchData)
    // }
    const baseQuery = `tag=${tag}&gameMode=simulation&numMatches=${25000}`
    const matchDataResult = await getMatchData(baseQuery)
    setMatchData(matchDataResult.matchData)
    const playersData = processMatchData(matchDataResult.matchData)
    setTotalAggregate(calculateTotals(matchDataResult.matchData, playersData))
    setIsLoading(false)
  }

  const calculateTotals = (data, playersData) => {
    const aggregate = calculateData(data)
    aggregate.totalPlayers = playersData.length
    aggregate.totalBeaten = {}
    playersData.forEach((player) => {
      OPPONENTS.forEach((opponent) => {
        if (aggregate.totalBeaten[opponent] === undefined) {
          aggregate.totalBeaten[opponent] = 0
        }
        if (player[`beat${opponent}`]) {
          aggregate.totalBeaten[opponent] += 1
        }
      })
    })

    return aggregate
  }

  const processMatchData = (data, updateTable = true) => {
    const aggregatedPlayerData = data.reduce((totalResult, matchData) => {
      if (totalResult[matchData.identifier]) {
        totalResult[matchData.identifier].push(matchData)
      }
      else {
        totalResult[matchData.identifier] = [matchData]
      }

      return totalResult
    }, {})

    const playerDataToProcess = {...aggregatedPlayerData}
    const playerData = []

    for (let player in playerDataToProcess) {
      playerDataToProcess[player] = calculateData(playerDataToProcess[player])
      playerDataToProcess[player].identifier = player
      playerDataToProcess[player].discord = discordMappings[player]
      playerData.push(playerDataToProcess[player])
    }
    // prepareForDownload(playerData)

    if (updateTable === true) {
      setPlayerData(playerData)
    }

    return playerData
  }

  // const prepareForDownload = (data) => {
  //   const cleanedData = data.map((x) => {
  //     return {
  //       identifier: x.identifier,
  //       discord: x.discord,
  //       gweiWins: x.wins.gwei,
  //       artistWins: x.wins.artist,
  //       guppyWins: x.wins.guppy,
  //       boWins: x.wins.bo,
  //       unknownWins: x.wins.undefined,
  //       totalWins: x.totalWins,
  //       totalMatches: x.totalMatches,
  //     }
  //   })
  //   var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(cleanedData));
  //   var downloadAnchorNode = document.createElement('a');
  //   downloadAnchorNode.setAttribute("href",     dataStr);
  //   downloadAnchorNode.setAttribute("download", "qualifiers-data.json");
  //   document.body.appendChild(downloadAnchorNode); // required for firefox
  //   downloadAnchorNode.click();
  //   downloadAnchorNode.remove();
  //   // console.log(cleanedData)
  // }

  const calculateData = (matchData) => {
    const totalStats = {
      data: matchData,
      totalWins: 0,
      totalLosses: 0,
      winPercentage: 0,
      opponentTotal: {},
      wins: { gwei: 0, artist: 0, guppy: 0, bo: 0, undefined: 0 },
      beatGwei: false,
      beatArtist: false,
      beatGuppy: false,
      beatBo: false,
    }

    totalStats.totalMatches = totalStats.data.length
    totalStats.data.forEach(match => {
      if (match.winner === 0 ) {
        totalStats.totalWins += 1
        totalStats.wins[match.opponent] += 1
      }
      else if (match.winner === -1) {
        totalStats.totalLosses += 1
      }
      else {
        totalStats.totalDraws += 1
      }

      accumulate(totalStats.opponentTotal, match.opponent, match)
    })
    
    totalStats.beatGwei = totalStats.wins.gwei > 0
    totalStats.beatArtist = totalStats.wins.artist > 0
    totalStats.beatGuppy = totalStats.wins.guppy > 0
    totalStats.beatBo = totalStats.wins.bo > 0
    totalStats.winPercentage = Math.round(totalStats.totalWins / totalStats.totalMatches * 100)
    return totalStats
  }

  const accumulate = (total, property, match) => {
    if (property === undefined) return total

    const wonMatch = match.winner === 0
    const lostMatch = match.winner === -1
    
    if (total[property] === undefined) {
      total[property] = {
        totalWins: 0,
        totalLosses: 0,
        winPercentage: 0,
        totalMatches: 0,
      }
    }

    total[property].totalWins += wonMatch ? 1 : 0
    total[property].totalLosses += lostMatch ? 1 : 0
    total[property].totalMatches += 1
    total[property].winPercentage = Math.round(
      total[property].totalWins / total[property].totalMatches * 100
    )
    return total
  }

  const getWinner = (winner) => {
    let matchStatus;
    if (winner === 0) matchStatus = "won"
    else if (winner === 1) matchStatus = "lost"
    else if (winner === -2) matchStatus = "tie"
    return matchStatus
  }

  const FILTER_PROPERTIES = {
    opponent: OPPONENTS,
    winner: ["won", "lost", "tie"]
  }

  const filterplayerData = () => {
    let filteredMatches = [...matchData]

    //regular filters
    Object.keys(FILTER_PROPERTIES).forEach(property => {
      if (filter[property].length > 0) {
        const validFilterValues = {}
        filter[property].forEach(value => validFilterValues[value] = true)

        if (property === "winner") {
          filteredMatches = filteredMatches.filter(match => {
            return validFilterValues[getWinner(match[property])] === true
          })
        }
        else {
          filteredMatches = filteredMatches.filter(match => {
            return validFilterValues[match[property]] === true
          })
        }
      }
    })

    //beast mode filters
    let playerData = processMatchData(filteredMatches, false)
    setPlayerData(playerData)
  }

  const clearFilters = () => {
    setFilter({
      opponent: [],
      stageName: [],
      element: [],
      winner: [],
      weight: [],
      beastmode: {
        stageName: [],
        element: [],
        weight: []
      }
    })
  }

  const filterByQuery = () => {
    if (queryString.length === 0) {
      setTableData(playerData)
      return;
    }
    else {
      const filters = queryString.split(",").map(filter => filter.trim().toLowerCase())
      let filteredData = [...playerData]
          
      filteredData = filteredData.filter(player => {
        for (let searchTerm of filters) {
          if (
            (player.discord && player.discord.toLocaleLowerCase().includes(searchTerm)) || 
            player.identifier.toLocaleLowerCase().includes(searchTerm)
          ) {
            return true
          }
        }

        return false
      })

      setTableData(filteredData)
    }
  }

  useEffect(() => {
    filterplayerData()
  }, [filter, matchData])

  useEffect(() => {
    filterByQuery()
  }, [queryString, playerData])

  useEffect(() => {
    initializeDashboard()
  }, [tag])

  return (
    <div className="playground-tracker__container">
      <NavigationPanel>
        <Dropdown options={TAGS} 
          onSelect={x => setTag(x)} 
          selected={tag}
          id="playground-tracker__round-select"/>
          { Object.keys(TABS).map((tab) => {
            const { label, Icon } = TABS[tab]
            return (
              <NavigationLink onClick={() => setCurrentTab(tab)}
                label={label}
                Icon={Icon}
                active={currentTab === tab}
                disabled={
                  (currentTab !== tab && isLoading) || 
                  (tab === "player" && !selectedPlayer)
                }
                key={`playground-tracker__navigation-tab-${tab}`}/>
            )
          }) }
      </NavigationPanel>
      <DashboardContainer isRightPanelOpen={showSidePanel} 
        title={
          currentTab === "player" ? 
          discordMappings[selectedPlayer.identifier] ?? selectedPlayer.identifier : 
          `${camelCaseToRegular(currentTab)} Dashboard`
        }>
          { 
            currentTab === "matches" ? 
            <PlaygroundTotalDashboard
              isLoading={isLoading}
              selectedPlayer={selectedPlayer}
              setSelectedPlayer={setSelectedPlayer}
              setShowSidePanel={setShowSidePanel}
              totalAggregate={totalAggregate}
              tableData={tableData}
              playerData={playerData}/> : 
            <PlaygroundIndividualDashboard
              isLoading={isLoading} 
              selectedPlayer={selectedPlayer}
              setShowSidePanel={setShowSidePanel}/>
          } 
      </DashboardContainer>
      <ConfigurationPanel 
        open={showSidePanel} 
        onClose={() => setShowSidePanel(false)}
        title={PANEL_TITLE[currentTab]}>
        { 
          currentTab === "matches" ? 
          <PlaygroundTotalSidePanel 
            query={queryString}
            setQuery={setQueryString}
            filter={filter}
            setFilter={setFilter}
            clearFilters={clearFilters} 
            FILTER_PROPERTIES={FILTER_PROPERTIES}/> :
          <PlaygroundPlayerSidePanel selectedPlayer={selectedPlayer}/>
        }
      </ConfigurationPanel>
    </div>
  )
}

export default MatchesTracker
