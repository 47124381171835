import React from 'react'
import { GrClose } from 'react-icons/gr'
import "./configuration-panel.css"

const ConfigurationPanel = ({ open, onClose, title, children }) => {
  return (
    <div className="configuration-panel__container" 
      style={{ display: open ? "flex" : "none"}}>
      <div className="configuration-panel__header">
        <GrClose size={"2vh"} onClick={onClose}/>
      </div>
      <h2 className="configuration-panel__title">{title}</h2>
      {children}
    </div>
  )
}

export default ConfigurationPanel