import CollectionDashboard from "../../components/user-behaviour-tracker/user-behaviour-dashboards/collection-dashboard/CollectionDashboard";
import ConfigDashboard from "../../components/user-behaviour-tracker/user-behaviour-dashboards/config-dashboard/ConfigDashboard";
import InspectorDashboard from "../../components/user-behaviour-tracker/user-behaviour-dashboards/inspector-dashboard/InspectorDashboard";
import SessionDashboard from "../../components/user-behaviour-tracker/user-behaviour-dashboards/session-dashboard/SessionDashboard";

export default {
  session: SessionDashboard,
  inspector: InspectorDashboard,
  collection: CollectionDashboard,
  config: ConfigDashboard
}