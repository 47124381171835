const inputTypes = {
  neuron: {
    isAdmin: "text",
    allowance: "text",
    getWeeklyInflation: "text",
    setupAirdrop: "file",
    claim: "text",
    mint: "text",
    addMinter: "text",
    addAdmin: "text",
    carryForwardRankedInflation: "text",
    setWeeklyInflation: "text",
    reloadTreasury: "text",
    burn: "text",
    approveAllowanceForSpender: "text",
    approveStaker: "text",
    burnFrom: "text"
  },
  rankedBattle: {
    isAdmin: "text",
    addAdmin: "text",
    setNeuronAddress: "text",
    setMergingPoolAddress: "text",
    setDelegatedAddress: "text",
    setNewRound: "none",
    stakeNRN: "text",
    unstakeNRN: "text",
    claimNRN: "text",
    updateBattleRecord: "text",
    getTotalBattles: "none",
    getBattleRecord: "text",
    getUnclaimedNRN: "text",
  },
  fighterFarm: {
    isAdmin: "text",
    addAdmin: "text",
    setNewDrop: "text",
    setMarketplaceAddress: "text",
    setIntelligenceAddress: "text",
    setGameItemsAddress: "text",
    setMergingPoolAddress: "text",
    setAllowedVoltageSpenders: "text",
    setTokenURI: "text",
    buyNewFighter: "text",
    setFighterPrice: "text",
    transferFighterOwnership: "text",
    updateFighterStaking: "text",
    doesTokenExist: "text",
    useVoltageBattery: "text",
    spendVoltage: "text",
    mintFromMergingPool: "text",
    transferFrom: "text",
    safeTransferFrom: "text",
    removeFighterPrice: "text",
    getFighterSaleInfo: "text",
    getAllFighterInfo: "text"
  },
  fighterMarketplace: {
    buyFighter: "text"
  },
  gameItems: {
    isAdmin: "text",
    addAdmin: "text",
    mint: "text",
    setAllowedBurningAddresses: "text",
    createGameItem: "file",
    burn: "text",
    allowanceRemaining: "text",
    dailyAllowanceReplenishTime: "text"
  },
  intelligence: {
    isAdmin: "text",
    addAdmin: "text",
    addNewEnvironmentModel: "text",
    updateModel: "text",
    getModelHash: "text",
    doesTokenExist: "text",
    getTotalNumTrained: "none",
    addNewEnvironment: "text",
    setDelegatedAddress: "text",
    createNewModel: "text",
    attachPhysicalModel: "text",
    detachPhysicalModel: "text",
    adjustUpdatability: "text",
    transferFromEnvironment: "file",
    transferFrom: "text",
    safeTransferFrom: "text",
    isSoulbound: "none"
  }
}

const getInputMapping = (contractName, functionNames) => {
  let inputMappings = {}
  functionNames.forEach((functionArr) => {
    let name = functionArr[0]
    const inputId = `${contractName}-${name}-input`
    const type = inputTypes[contractName][name]
    var inputObject = {
      inputType: type,
      inputId: inputId,
      inputValue: ""
    }
    if (type === "file") {
      inputObject = {...inputObject, inputData: {}}
    }
    inputMappings[name] = inputObject;
  })
  return inputMappings
}

export {
  inputTypes,
  getInputMapping
}