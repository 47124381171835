import React from 'react'
import "./dashboard-section-header.css"

const DashboardSectionHeader = ({ title, children, className = "" }) => {
  const containerClass = "dashboard-section-header__container " + className
  return (
    <div className={containerClass}>
      <h2 className="dashboard-section-header__title">{title}</h2>
      {children}
    </div>
  )
}

export default DashboardSectionHeader