import React from 'react'
import "./contract-toggle.css"

const ContractToggle = ({ toggledContract, setToggleContract, contracts }) => {
  return (
      <ul id="toggle-contract__list">
        {
          Object.keys(contracts).map((contract, i) => {
            return (
              <li key={i}>
                <a 
                  id={`${contract === toggledContract ? "current" : ""}`}
                  className={`${contract}-list__item`} 
                  onClick={() => setToggleContract(contract)}
                  href="#">
                  {contract}
                </a>
              </li>
            )
          })
        }
      </ul>
  )
}

export default ContractToggle