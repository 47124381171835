import React, { useEffect, useState } from "react";
import StatisticDisplay from "../../../generic-components/dashboard/statistic-display/StatisticDisplay";
import { 
  getMeanMedianCumulative, 
  getStatisticColor, 
  getStatisticsDisplay
} from "../../../../helpers/user-behaviour-tracker/dashboardHelpers";
import * as _ from 'lodash'

const InspectorStatistic = ({ aggregator, statisticSelected }) => {
  const [inspectorStatistics, setInspectorStatistics] = useState()

  console.log(inspectorStatistics)

  const addNewStat = (statObject, label, key, getter = d => _.round(d, 1)) => {
    if (statObject[key]) {
      return {
        metric: label,
        statistics: { ...getMeanMedianCumulative(statObject[key], getter) }
      }
    }
  }

  const generateStatistics = () => {
    const { statistics } = aggregator
    console.log(statistics)
    const newStatistics = [
      addNewStat(statistics, "Time spent", "timeSpent", d => d.string),
      addNewStat(statistics.modelUpdateActions, "Model finalizes", "finalize"),
      addNewStat(statistics.modelUpdateActions, "Model resets", "reset"),
      addNewStat(statistics.modelUpdateActions, "Model restores", "restore"),
      addNewStat(statistics.modelUpdateActions, "Model updates", "update")
    ].filter((x) => x !== undefined)
    
    setInspectorStatistics(newStatistics)
  }

  useEffect(() => {
    generateStatistics()
  }, [aggregator])

  return (
    <>
      {
        inspectorStatistics && 
        getStatisticsDisplay(inspectorStatistics, statisticSelected).map(({metric, statistic}, idx) => {
          return (
            <StatisticDisplay
              key={`inspector-statistic-${metric}`}  
              label={metric} 
              stat={statistic} 
              color={getStatisticColor(idx)}/>
          )
        })
      }
    </>
  )
}

export default InspectorStatistic