import React from 'react'
import "./dashboard-container.css"

const DashboardContainer = ({ isRightPanelOpen, title, children }) => {
  return (
    <div className="dashboard-container__container" 
      style={{ marginRight: isRightPanelOpen ? "44vh" : 0}}>
      <h1 className="dashboard-container__title">
        {title}
      </h1>
      {children}
    </div>
  )
}

export default DashboardContainer