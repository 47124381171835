import { ethers } from "ethers"

const isAdmin = async (contract, inputs) => {
  const { isAdmin: { inputValue } } = inputs
  const isAdmin = await contract.isAdmin(inputValue)
  console.log(isAdmin)
  return isAdmin
}

const addAdmin = async (contract, inputs) => {
  const { addAdmin: { inputValue } } = inputs
  const addAdminData = await contract.addAdmin(inputValue)
  return addAdminData
}

const setDelegatedAddress = async (contract, inputs) => {
  const { setDelegatedAddress: { inputValue } } = inputs
  const addAdminData = await contract.setDelegatedAddress(inputValue)
  return addAdminData
}

const addNewEnvironmentModel = async (contract, inputs) => {
  const { addNewEnvironmentModel: {inputValue} } = inputs
  const newEnvironmentInputs = inputValue.split(",")
  let tokenId = newEnvironmentInputs[0]
  let environmentId = newEnvironmentInputs[1].toString()
  let neuralNetHash = newEnvironmentInputs[2].toString()
  const addNewEnvironmentModelData = await contract.addNewEnvironmentModel(tokenId, environmentId, neuralNetHash)
  return addNewEnvironmentModelData
}

const updateModel = async (contract, inputs) => {
  const { updateModel: {inputValue} } = inputs
  const updateModelInputs = inputValue.split(",")
  let tokenId = updateModelInputs[0]
  let environmentId = updateModelInputs[1].toString()
  let neuralNetHash = updateModelInputs[2].toString()
  const updateModelData = await contract.updateModel(tokenId, environmentId, neuralNetHash)
  return updateModelData
}

const addNewEnvironment = async (contract, inputs) => {
  const { addNewEnvironment: {inputValue} } = inputs
  const addNewEnvironmentInputs = inputValue.split(",")
  let environmentId = addNewEnvironmentInputs[0].toString()
  let contractAddress = addNewEnvironmentInputs[1]
  const addNewEnvironmentData = await contract.addNewEnvironment(environmentId, contractAddress)
  return addNewEnvironmentData
}

const getModelHash = async (contract, inputs) => {
  const { getModelHash: {inputValue} } = inputs
  const getModelHashInputs = inputValue.split(",")
  let tokenId = getModelHashInputs[0]
  let environmentId = getModelHashInputs[1].toString()
  const getModelHashData = await contract.getModelHash(tokenId, environmentId)
  return getModelHashData
}

const doesTokenExist = async (contract, inputs) => {
  const { doesTokenExist: { inputValue } } = inputs
  const doesTokenExistData = await contract.doesTokenExist(inputValue)
  return doesTokenExistData
}


const getTotalNumTrained = async (contract) => {
  const getTotalNumTrainedData = await contract.getTotalNumTrained()
  console.log(getTotalNumTrainedData)
  return getTotalNumTrainedData
}

const createNewModel = async (contract, inputs) => {
  const { createNewModel: {inputValue} } = inputs
  const createNewModelInputs = inputValue.split(",")
  let modelOwner = createNewModelInputs[0]
  let environmentId = createNewModelInputs[1].toString()
  let neuralNetHash = createNewModelInputs[2].toString()
  const createNewModelData = await contract.createNewModel(modelOwner, environmentId, neuralNetHash)
  return createNewModelData
}

const attachPhysicalModel = async (contract, inputs) => {
  const { attachPhysicalModel: {inputValue} } = inputs
  const attachPhysicalModelInputs = inputValue.split(",")
  let tokenId = attachPhysicalModelInputs[0]
  let physicalModelId = attachPhysicalModelInputs[1]
  let environmentId = attachPhysicalModelInputs[2].toString()
  const attachPhysicalModelData = await contract.attachPhysicalModel(tokenId, physicalModelId, environmentId)
  return attachPhysicalModelData
}

const detachPhysicalModel = async (contract, inputs) => {
  const { detachPhysicalModel: {inputValue} } = inputs
  const detachPhysicalModelInputs = inputValue.split(",")
  let tokenId = detachPhysicalModelInputs[0]
  let environmentId = detachPhysicalModelInputs[1].toString()
  const detachPhysicalModelData = await contract.detachPhysicalModel(tokenId, environmentId)
  return detachPhysicalModelData
}


const adjustUpdatability = async (contract, inputs) => {
  const { adjustUpdatability: {inputValue} } = inputs
  const adjustUpdatabilityInputs = inputValue.split(",")
  let tokenId = adjustUpdatabilityInputs[0]
  let updatabilityStatus = adjustUpdatabilityInputs[1]
  let environmentId = adjustUpdatabilityInputs[2].toString()
  const adjustUpdatabilityData = await contract.adjustUpdatability(tokenId, updatabilityStatus, environmentId)
  return adjustUpdatabilityData
}

const transferFromEnvironment = async (contract, inputs) => {
  const {transferFromEnvironment: { inputData: { from, to, tokenId, environmentId }}} = inputs
  if (inputs.transferFromEnvironment.inputData !== {}) {
    const transferFromEnvironmentData = await contract.transferFromEnvironment(from, to, tokenId, environmentId.toString())
    console.log(transferFromEnvironmentData)
    return transferFromEnvironmentData
  }
}

const transferFrom = async (contract, inputs) => {
  const { transferFrom: {inputValue} } = inputs
  const transferFromInputs = inputValue.split(",")
  let from = transferFromInputs[0]
  let to = transferFromInputs[1]
  let tokenId = transferFromInputs[2]
  const transferFromData = await contract.transferFrom(from, to, tokenId)
  return transferFromData
}

const safeTransferFrom = async (contract, inputs) => {
  const { safeTransferFrom: {inputValue} } = inputs
  const safeTransferFromInputs = inputValue.split(",")
  let from = safeTransferFromInputs[0]
  let to = safeTransferFromInputs[1]
  let tokenId = safeTransferFromInputs[2]
  const safeTransferFromData = await contract.safeTransferFrom(from, to, tokenId)
  return safeTransferFromData
}

const isSoulbound = async (contract) => {
  const isSoulboundData = await contract.isSoulbound()
  console.log(isSoulboundData)
  return isSoulboundData
}


const allIntelligenceFunctions = {
  isAdmin,
  addAdmin,
  addNewEnvironmentModel,
  updateModel,
  getModelHash,
  doesTokenExist,
  getTotalNumTrained,
  addNewEnvironment,
  setDelegatedAddress,
  createNewModel,
  attachPhysicalModel,
  detachPhysicalModel,
  adjustUpdatability,
  transferFromEnvironment,
  transferFrom,
  safeTransferFrom,
  isSoulbound
}

export {
  allIntelligenceFunctions
}