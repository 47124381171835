import React from 'react'
import Timeseries from '../../../generic-components/charts/timeseries/Timeseries'
import { userBehaviourConfigurationsAtom } from '../../user-behaviour-config/UserBehaviourConfig'
import { camelCaseToRegular } from '../../../../helpers/user-behaviour-tracker/dashboardHelpers'
import * as _ from 'lodash'
import { useAtom } from 'jotai'

const UserBehaviourTimeseries = ({ 
  id, 
  aggregator, 
  timeseriesData, 
  tab, 
  chartConfig,
}) => {
  const [config] = useAtom(userBehaviourConfigurationsAtom)

  const getChartConfiguration = () => {
    const chartConfiguration = {
      label: {
        x: {
          text: "Time",
        },
        y: {
          text: camelCaseToRegular(config[`${tab}Config`]?.aggregateProperty),
        }
      },
      scale: { 
        y: {
          tickFormat: (d) => {
            return aggregator.convertElapsedToString(d)
          }
        }
      }
    }

    return _.merge({}, chartConfiguration, chartConfig)
  }

  return (
    <Timeseries
      config={getChartConfiguration()} 
      className="user-behaviour-chart__chart-container" 
      id={id} 
      data={timeseriesData}/>
  )
}

export default UserBehaviourTimeseries