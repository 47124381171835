import axios from 'axios'
require('dotenv').config()

const backend = process.env.REACT_APP_BACKEND

async function getFighterData(tag) {
  const fightersResult = await axios.get(`${backend}fighters/get-leaderboard-data?tag=${tag}`)
  return fightersResult.data
}

export {
  getFighterData,
}