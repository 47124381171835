import React, { useState, useEffect } from 'react'
import { cleanUpModels } from '../../helpers/backend/post-requests/intelligence'
import { useSignMessage } from 'wagmi'
import './founder-dashboard.css'

const FounderDashboard = () => {
  const message = "Clean Up Models"
  const { data: signature, signMessage } = useSignMessage({ message })
  const [tag, setTag] = useState("")

  const startModelCleanup = () => {
    signMessage()
  }

  useEffect(() => {
    if (signature !== undefined) {
      cleanUpModels(tag, message, signature)
    }
  }, [signature])

  return (
    <div id="founder-dashboard__container">
      <div className="founder-dashboard__operation">
        <input type="text" placeholder="Enter Tag" onChange={(e) => setTag(e.target.value)} />
        <button className="founder-dashboard__button" onClick={() => startModelCleanup()}>
          Clean Up Models
        </button>
      </div>
    </div>
  )
}

export default FounderDashboard