import * as d3 from 'd3'
import * as _ from 'lodash'
import BaseChart from "./BaseChart"

const DEFAULT_CONFIGURATION = {
  margin: { top: 0, right: 0, bottom: 0, left: 0 },
  labelPadding: { x: 0, y: 0 },
  chartPadding: { x: 0, y: 0 },
  scale: { 
    x: {
      scale: d3.scaleLinear(),
      ticks: undefined,
      tickSize: undefined,
      tickFormat: undefined,
      isLogScale: false
    }, 
    y: {
      scale: d3.scaleLinear(),
      ticks: undefined,
      tickSize: undefined,
      tickFormat: undefined,
      isLogScale: false
    }
  },
  renderAxis: true,
  styling: [
    {
      stroke: "red",
      curve: false
    },
    {
      stroke: "blue",
      curve: false
    }
  ]
}

class LineChart extends BaseChart {
  constructor(containerId, data, configuration, xMapping, yMapping) {
    super(containerId, data, configuration, xMapping, yMapping)
    this.configuration = _.merge({}, DEFAULT_CONFIGURATION, this.configuration)
  }

  renderChart() {
    const g = this.getChartContainer().select(".chart-container")
    g.selectAll(".line-path").remove()
    this.data.forEach((x, idx) => {
      const xScaleToUse = (
        this.configuration.useSeperateScaling.x ? 
        this.scales.xScale[idx] : 
        this.scales.xScale
      )
      const yScaleToUse = (
        this.configuration.useSeperateScaling.y ? 
        this.scales.yScale[idx] : 
        this.scales.yScale
      )

      let lineGenerator = d3.line()
      .x(d => xScaleToUse(this.xMapping(d)))
      .y(d => yScaleToUse(this.yMapping(d)))

      if (this.configuration.styling[idx].curve) {
        lineGenerator = lineGenerator.curve(d3.curveBasis)
      }

      g.append('path')
        .attr('class', 'line-path')
        .style("fill", "none")
        .style('stroke-width', 1.5)
        .style('stroke', this.configuration.styling[idx].stroke)
        .attr('d', lineGenerator(x.data))
    })
  }
}

export default LineChart