import React, { useState, useEffect } from 'react'
import UserBehaviourTimeseries from '../../user-behaviour-charts/user-behaviour-timeseries/UserBehaviourTimeseries'
import UserBehaviourDistribution from '../../user-behaviour-charts/user-behaviour-distribution/UserBehaviourDistribution'
import UserBehaviourBarChart from '../../user-behaviour-charts/user-behaviour-bar-chart/UserBehaviourBarChart'
import UserBehaviourScatterPlot from '../../user-behaviour-charts/user-behaviour-scatter-plot/UserBehaviourScatterPlot'
import ChartDisplay from '../../../generic-components/dashboard/chart-display/ChartDisplay'
import { userBehaviourConfigurationsAtom } from "../../user-behaviour-config/UserBehaviourConfig"
import { 
  camelCaseToRegular, 
  shouldUpdateTimeseries, 
  updateTimeseriesData 
} from '../../../../helpers/user-behaviour-tracker/dashboardHelpers'
import { betaDiscordMappings, betaWallets } from '../../../../helpers/beta-wallets/beta-wallets'
import { getFighterData } from '../../../../helpers/backend/get-requests/fighter-data'
import { useAtom } from 'jotai'

const SessionDashboard = ({ aggregator, tag }) => {
  const [config] = useAtom(userBehaviourConfigurationsAtom)
  const [timeSeriesData, setTimeseriesData] = useState([])
  const [distributionData, setDistributionData] = useState()
  const [barData, setBarData] = useState()
  const [scatterPlotData, setScatterPlotData] = useState()

  const updateDistributionData = () => {
    const hour = 1000 * 60 * 60
    const distribution = { sessionTime: [], focusedTime: [] }
    Object.values(aggregator.userData).forEach((x) => {
      distribution.sessionTime.push(x.counters.sessionTime.int / hour)
      distribution.focusedTime.push(x.counters.focusedTime.int / hour)
    })

    if (distribution.sessionTime && distribution.sessionTime.length > 0) {
      setDistributionData([
        { metadata: { name: "Session Time" }, data: distribution.sessionTime },
        { metadata: { name: "Focused Time" }, data: distribution.focusedTime }
      ])
    }
  }

  const updateBarData = () => {
    const locationData = (
      config.sharedConfig.showTotalAggregate ?
      aggregator.cumulativeData.locationTime :
      aggregator.userData[config.sharedConfig.selectedAddress].locationTime
    )

    const newBarData =  Object.keys(locationData).map(location => {
      return { 
        x: location, 
        y: aggregator.cumulativeData.locationTime[location].int, 
        metadata: { toolTip: location }}
    })

    setBarData(newBarData)
  }

  const loadFighterData = async () => {
    const fighterData = await getFighterData(tag)
    const betaFighters = tag === "Beta 2.0" ? fighterData.filter((fighter) => {
      return betaWallets[tag].includes(fighter.owner.toLowerCase()) 
    }) : fighterData
    betaFighters.sort((a, b) => (a.elo < b.elo) ? 1 : -1)
    betaFighters.forEach((fighter, idx) => { fighter.rank = idx + 1 })
    const newEloMappings = {}
    betaFighters.forEach(fighter => {
      newEloMappings[fighter.owner.toLowerCase()] = fighter.elo
    })

    return newEloMappings
  }

  const aggregatePlayerSessionTime = () => {
    const playerSessionTotal = Object.keys(aggregator.userData).map(player => {
      return { player, totalSessionTime: aggregator.userData[player].counters.sessionTime.int}
    })

    return playerSessionTotal
  }

  const updateScatterPlotData = async () => {
    const newEloMappings = await loadFighterData()
    const sessionTimes = aggregatePlayerSessionTime()

    const scatterPlotData = sessionTimes.map((sessionObject) => {
      return { 
        x: sessionObject.totalSessionTime, 
        y: newEloMappings[sessionObject.player],
        metadata: { 
          toolTip: (
            betaDiscordMappings[tag][sessionObject.player.toLowerCase()] ?? 
            sessionObject.player
          )
        }
      }
    })
    setScatterPlotData([{ metadata: { name: "Time vs elo" }, data: scatterPlotData }])
  }

  useEffect(() => {
    if (aggregator && shouldUpdateTimeseries(config, "session")) {
      updateTimeseriesData(config, aggregator, "session", setTimeseriesData)
      updateBarData()
    }
  }, [config.sharedConfig, config.sessionConfig])

  useEffect(() => {
    if (aggregator) {
      updateDistributionData()
      if (tag === "Beta 2.0") {
        updateScatterPlotData()
      }
    }
  }, [aggregator])

  return (
    <>
      <ChartDisplay  
        label={camelCaseToRegular(`${config.sessionConfig.aggregateProperty} Timeseries`)}>
        <UserBehaviourTimeseries 
          timeseriesData={timeSeriesData} 
          aggregator={aggregator}
          id="user-behaviour__session-timeseries-chart"
          tab="session"/>
      </ChartDisplay >
      {
        config.sharedConfig.showTotalAggregate && distributionData &&       
        <ChartDisplay label="Time Spent Distribution">
          <UserBehaviourDistribution
            id="user-behaviour__session-distribution-chart"
            distributionData={distributionData}/>
        </ChartDisplay >
      }
      <ChartDisplay label="Location Breakdown">
        <UserBehaviourBarChart
          id="user-behaviour__session-bar-chart"
          barData={barData}
          aggregator={aggregator}/>
      </ChartDisplay >
      {
        scatterPlotData && config.sharedConfig.showTotalAggregate &&        
        <ChartDisplay label="Elo vs Time Spent">
          <UserBehaviourScatterPlot
            id="user-behaviour__session-scatter-plot"
            scatterPlotData={scatterPlotData}
            aggregator={aggregator}/>
        </ChartDisplay >
      }
    </>
  )
}

export default SessionDashboard