export default class DataFormatter {
  constructor() {
    // Attributes for formatting time
    this.second = 1000
    this.minute = this.second * 60
    this.hour = this.minute * 60

    // Attributes for formatting probabilities
    this.probabilityPrecision = 2
  }

  convertElapsedToString(elapsed) {
    let remainingElapsed = elapsed
    const hoursElapsed = Math.floor(remainingElapsed / this.hour)
    remainingElapsed -= hoursElapsed * this.hour
    const minutesElapsed = Math.floor(remainingElapsed / this.minute)
    remainingElapsed -= minutesElapsed * this.minute
    var elapsedString = "Less than 1 minute"
    if (hoursElapsed > 0 || minutesElapsed > 0) {
      elapsedString = `${hoursElapsed}h ${minutesElapsed}min`
    } 
    return elapsedString
  }

  formatTime(timeInt) {
    return { int: timeInt, string: this.convertElapsedToString(timeInt) }
  }

  formatProbability(probability) {
    const denom = 10**this.probabilityPrecision
    return `${Math.round(probability * 100 * denom) / denom}%`
  }

  formatTotal(x, key) {
    return this.formatTime(x)
  }
}