import React, { useEffect } from 'react'
import ConfigurationSelectRow from '../../../generic-components/dashboard/configuration/configuration-select-row/ConfigurationSelectRow'
import { userBehaviourConfigurationsAtom } from '../UserBehaviourConfig'
import { camelCaseToRegular } from '../../../../helpers/user-behaviour-tracker/dashboardHelpers'
import { useAtom } from 'jotai'


const UserBehaviourCollectionConfig = ({ aggregator }) => {
  const [config, setConfig] = useAtom(userBehaviourConfigurationsAtom)
  
  const updateCollectionConfig = (property, value) => {
    setConfig((prevState) => {
      const newCollectionConfig = { ...prevState.collectionConfig }
      newCollectionConfig[property] = value
      return {
        ...prevState,
        collectionConfig: newCollectionConfig
      }
    })
  }

  useEffect(() => {
    if (aggregator && !config.collectionConfig) {
      updateCollectionConfig("aggregateProperty", aggregator.timeseriesKeys[0])
    }
  }, [aggregator])

  return (
    <div className="user-behaviour-config__tab-configuration-section">
    {
      aggregator && config.collectionConfig && (
        <div className="user-behaviour-config__configuration-section">
          <ConfigurationSelectRow label="Aggregate property"
            selected={config.collectionConfig.aggregateProperty}
            options={aggregator.timeseriesKeys.map(key => { 
              return { label: camelCaseToRegular(key), value: key }
            })}
            onSelect={option => updateCollectionConfig("aggregateProperty", option.value)}
            id="user-behaviour-config__collection-aggregate-property"/>
        </div>
      )
    }
  </div>
  )
}

export default UserBehaviourCollectionConfig