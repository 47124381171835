import React from 'react'

const FormatResponse = ({ obj, keyIdentifier }) => {
  console.log(obj, keyIdentifier)
  let expr;

  if (Array.isArray(obj[keyIdentifier]))  {
    expr = "array"
  } else {
    expr = typeof(obj[keyIdentifier])
  }


  switch (expr) {
    case "boolean":
    case "number":
    case "string":
    case "array":
      return (
        <>
          {" "}{obj[keyIdentifier].toString()}
        </>
      )
      break;
    case "bigint":
      return (
        <>
          {obj[keyIdentifier]}
        </>
      )
      break;
    case "object":
      return (
        <a style={{textDecoration:"none", color:"blue"}} href={`https://testnet.arbiscan.io/tx/${obj[keyIdentifier]?.hash}`}>{`Transaction 👀 `}</a>
      )
      break;
    default:
      console.log(`Sorry, we are out of ${expr}.`);
  }
}

export default FormatResponse