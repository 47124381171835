import DataAggregator from "./data-aggregator"

export default class ConfigAggregator extends DataAggregator {
  constructor(data, tag) {
    super(data, tag)
    this.counterKeys = ["timeSpentConfiguring", "numTrainingAttempts"]
    this.perSessionKeys = ["timeSpentConfiguring", "numTrainingAttempts"]
    this.timeseriesKeys = ["timeSpentConfiguring"]
    this.createdTimeKey = "createdAt"
    this.timecutoff = {
      "Beta 2.0": 1676998800000, // 2023-02-21 12:00pm EST
    }
    this.retentionKey = "timeSpentConfiguring"

    this.cleaningKeyToLabel = {
      none: "No Cleaning", 
      sparsity: "Remove Sparsity", 
      over: "Oversampling", 
      multi: "Multi-Stream", 
      sparsityOver: "Remove Sparsity + Oversampling",
      sparsityMulti: "Remove Sparsity + Multi-Stream"
    }

    this.initialize()
  }

  configStarterObject() {
    return {
      configDifficulty: { simple: 0, advanced: 0 },
      cleaning: { none: 0, sparsity: 0, over: 0, multi: 0, sparsityOver: 0, sparsityMulti: 0 },
      intensity: { low: 0, moderate: 0, high: 0 },
      featureFocus: { 
        simple: { numFeatures: {}, featureNames: {} },
        advanced: { numFeatures: {}, featureNames: {} }
      }
    }
  }

  userInitialization() {
    const initial = super.userInitialization()
    return {
      ...initial,
      ...this.configStarterObject()
    }
  }

  getStartTime(instance) {
    return new Date(instance[this.createdTimeKey]).getTime() - instance.timeSpentConfiguring
  }

  incrementDataCleaning(instance, identifier) {
    const cleaning = this.userData[identifier].cleaning
    const over = instance.oversamplingBool
    const sparsity = instance.removeSparsityBool
    const multi = instance.multistreamBool
    if (over && !sparsity) cleaning.over += 1
    else if (multi && !sparsity) cleaning.multi += 1
    else if (over && sparsity) cleaning.sparsityOver += 1
    else if (multi && sparsity) cleaning.sparsityMulti += 1
    else if (sparsity) cleaning.sparsity += 1
    else cleaning.none += 1
  }
  
  incrementFeatureFocus(instance, identifier) {
    const difficultyKey = instance.simpleConfigBool ? "simple" : "advanced"
    this.userData[identifier].configDifficulty[difficultyKey] += 1
    const featureFocus = this.userData[identifier].featureFocus[difficultyKey]
    instance.trainingFocusArea.forEach((feature) => {
      if (featureFocus.featureNames[feature] === undefined) {
        featureFocus.featureNames[feature] = 0
      }
      featureFocus.featureNames[feature] += 1
    })
    const featureCount = instance.trainingFocusArea.length
    if (featureFocus.numFeatures[featureCount] === undefined) {
      featureFocus.numFeatures[featureCount] = 0
    }
    featureFocus.numFeatures[featureCount] += 1
  }

  customUserAggregationInline(instance, identifier) {
    this.incrementDataCleaning(instance, identifier)
    this.incrementFeatureFocus(instance, identifier)
    if (instance.trainingIntensity !== undefined) {
      this.userData[identifier].intensity[instance.trainingIntensity] += 1
    }
  }

  getCumulativeObject(currentUserData, key) {
    if (this.arrayData[key] === undefined) {
      this.arrayData[key] = {}
    }
    if (this.cumulativeData[key] === undefined) {
      this.cumulativeData[key] = {}
    }
    Object.keys(currentUserData).forEach((subKey) => {
      if (this.cumulativeData[key][subKey] === undefined) {
        this.cumulativeData[key][subKey] = 0
        this.arrayData[key][subKey] = []
      }
      const value = currentUserData[subKey]
      this.cumulativeData[key][subKey] += value
      this.arrayData[key][subKey].push(value)
    })
  }

  incrementCumulativeFeatures(currentUserData) {
    if (this.cumulativeData.featureFocus === undefined) {
      this.cumulativeData.featureFocus = { 
        simple: { featureNames: {}, numFeatures: {} }, 
        advanced: { featureNames: {}, numFeatures: {} } 
      }
    }
    ["simple", "advanced"].forEach((difficulty) => {
      Object.keys(currentUserData.featureFocus[difficulty]).forEach((key) => {
        const featureFocusObject = currentUserData.featureFocus[difficulty][key]
        Object.keys(featureFocusObject).forEach((subKey) => {
          if (this.cumulativeData.featureFocus[difficulty][key][subKey] === undefined) {
            this.cumulativeData.featureFocus[difficulty][key][subKey] = 0
          }
          this.cumulativeData.featureFocus[difficulty][key][subKey] += featureFocusObject[subKey]
        })
      })
    })
  }

  customCumulativeAggregation(user) {
    if (this.cumulativeData.configDifficulty === undefined) {
      this.cumulativeData.configDifficulty = { simple: 0, advanced: 0 }
    }
    this.cumulativeData.configDifficulty.simple += this.userData[user].configDifficulty.simple
    this.cumulativeData.configDifficulty.advanced += this.userData[user].configDifficulty.advanced
    this.getCumulativeObject(this.userData[user].cleaning, "cleaning")
    this.getCumulativeObject(this.userData[user].intensity, "intensity")
    this.incrementCumulativeFeatures(this.userData[user])
  }

  customStatisticsAggregation(topN) {

  }

  formatTotal(x, key) {
    const rawKey = this.getRawKey(key)
    if (rawKey === "timeSpentConfiguring") {
      return this.formatTime(x)
    }
    else {
      return x
    }
  }

  aggregate(verbose = false) {
    super.aggregate()

    if (verbose) {
      console.log(this.userData)
      console.log(this.cumulativeData)
      console.log(this.statistics)
      console.log(this.retentionMatrix)
    }
  }
}