import React, { useState, useEffect } from 'react'
import UserBehaviourTimeseries from '../../user-behaviour-charts/user-behaviour-timeseries/UserBehaviourTimeseries'
import ChartDisplay from '../../../generic-components/dashboard/chart-display/ChartDisplay'
import { userBehaviourConfigurationsAtom } from "../../user-behaviour-config/UserBehaviourConfig"
import { 
  camelCaseToRegular, 
  shouldUpdateTimeseries, 
  updateTimeseriesData 
} from '../../../../helpers/user-behaviour-tracker/dashboardHelpers'
import { useAtom } from 'jotai'
import * as d3 from 'd3'

const CollectionDashboard = ({ aggregator, tag }) => {
  const [config] = useAtom(userBehaviourConfigurationsAtom)
  const [timeSeriesData, setTimeseriesData] = useState([])
  const [eloEffectData, setEloEffectData] = useState([])

  const updateEloEffectData = () => {
    if (!config.sharedConfig.showTotalAggregate && config.sharedConfig.selectedAddress) {
      const rawEloData = aggregator.eloEffectTracker[config.sharedConfig.selectedAddress]
      const processedEloData = rawEloData.map(data => {
        return { x: data.time, y: data.elo }
      })
      const processedTrainingData = rawEloData.map(data => {
        return { x: data.time, y: data.cumulativeTraining }
      })
      setEloEffectData([
        { metadata: { name: "elo"}, data: processedEloData },
        { metadata: { name: "training"}, data: processedTrainingData }
      ])
    }
  }

  const getEloChartConfig = (scaleToDisplay) => {
    return {
      margin: { top: 15, bottom: 20, left: 20 },
      labelPadding: { x: 30, y: 35 },
      label: {
        x: {
          text: "Time",
        },
        y: {
          text: scaleToDisplay === 0 ? "Elo" : "Training",
        }
      },
      scale: {
        y: {
          scale: [
            { scale: d3.scaleLinear() },
            { scale: d3.scaleLinear() }
          ],
          tickFormat: scaleToDisplay === 0 ? d => d : undefined 
        }
      },
      useSeperateScaling: {
        y: true
      },
      scaleToDisplay: {
        y: scaleToDisplay
      },
      legend: { showLegend: true}
    }
  }

  useEffect(() => {
    if (aggregator && shouldUpdateTimeseries(config, "collection")) {
      updateTimeseriesData(config, aggregator, "collection", setTimeseriesData)
      updateEloEffectData()
    }
  }, [config.sharedConfig, config.collectionConfig])

  return (
    <>
      <ChartDisplay 
        label={camelCaseToRegular(`${config.collectionConfig.aggregateProperty} Timeseries`)}>
        <UserBehaviourTimeseries 
            timeseriesData={timeSeriesData} 
            aggregator={aggregator}
            id="user-behaviour__collection-timeseries-chart"
            tab="collection"/>
      </ChartDisplay >
        {
          !config.sharedConfig.showTotalAggregate && eloEffectData.length > 0 && 
          <ChartDisplay  label="Elo Change vs Training Time">
            <UserBehaviourTimeseries
              timeseriesData={eloEffectData}
              aggregator={aggregator}
              id="user-behvaiour__collection-elo-chart"
              tab="collection"
              chartConfig={getEloChartConfig(0)}/>
          </ChartDisplay >
        }
    </>
  )
}

export default CollectionDashboard