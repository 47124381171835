const palette = {
  greyscale: [
    "#1a1c2b",
    "#31394e",
    "#5d6d87",
    "#94aabf",
    "#d1e6f9",
  ],
  typography: [
    "#ffffff",
    "#69e3f2",
    "#1e95bf",
    "#ff776f",
    "#ca4949"
  ],
  cool: [
    "#1c184a",
    "#042278",
    "#1e4cbc",
    "#3166c2",
    "#33c1d9"
  ],
  warm: [
    "#66112d",
    "#8a112d",
    "#a7112d",
    "#ff8e50",
    "#f5c868"
  ]
}

export {
  palette
}