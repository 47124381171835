import React from 'react'
import aiArenaLogo from '../../../../../local-assets/icons/ai-arena-logo.png'
import "./navigation-panel.css"

const NavigationPanel = ({ children }) => {
  return (
    <div className="navigation-panel__container">
      <img className="navigation-panel__logo" src={aiArenaLogo}/>
      {children}
    </div>
  )
}

export default NavigationPanel