import DataAggregator from "./data-aggregator"

export default class SessionAggregator extends DataAggregator {
  constructor(data, tag) {
    super(data, tag)
    this.counterKeys = ["focusedTime", "sessionTime"]
    this.timeseriesKeys = ["focusedTime", "sessionTime"]
    this.createdTimeKey = "createdAt"
    this.timecutoff = {
      "Beta 2.0": 1676998800000, // 2023-02-21 12:00pm EST
    }
    this.retentionKey = "sessionTime"

    this.transitions = {}

    this.validRoutes = [
      "/",
      "/ai-inspector",
      "/beta-leaderboard",
      "/beta-leaderboard/team",
      "/imitation-learning",
      "/locker-room",
      "/marketplace",
      "/playground",
      "/playground-replay",
      "/battle",
      "/battle-replay",
      "/upload-battle",
      "/simulation",
      "/tournament"
    ]

    this.initialize()
  }

  userInitialization() {
    const initial = super.userInitialization()
    return {
      ...initial,
      locationTime: {},
      visitCount: {}
    }
  }

  getStartTime(instance) {
    return new Date(instance[this.createdTimeKey]).getTime() - instance.sessionTime
  }

  cleanPath(path) {
    if (path.includes("/ai-inspector")) {
      path = "/ai-inspector"
    }
    if (!this.validRoutes.includes(path)) {
      path = "INVALID PATH"
    }
    return path
  }
  
  handlePathTransition(prevPath, currentPath){
    if (prevPath !== undefined) {
      if (this.transitions[prevPath] === undefined) {
        this.transitions[prevPath] = { [currentPath]: 0, count: 0 }
      }
      if (this.transitions[prevPath][currentPath] === undefined) {
        this.transitions[prevPath][currentPath] = 0
      } 
      this.transitions[prevPath][currentPath] += 1
      this.transitions[prevPath].count += 1
    }
  }
  
  handlePaths(storedData, pathSequence, sessionTime){
    var prevPath
    var totalPathTime = 0
    if (pathSequence.length === 0) {
      if (storedData.locationTime.UNKNOWN === undefined) {
        storedData.locationTime.UNKNOWN = 0
      }
      storedData.locationTime.UNKNOWN += sessionTime
    }
    else {
      for (var i = 0; i < pathSequence.length; i++) {
        const pathData = pathSequence[i]
        const cleanedPath = this.cleanPath(pathData.path)
        if (storedData.visitCount[cleanedPath] === undefined) {
          storedData.visitCount[cleanedPath] = 0
          storedData.locationTime[cleanedPath] = 0
        }
        const locationTime = (
          pathSequence[i+1] !== undefined ? 
          pathSequence[i+1].timeEntered - pathData.timeEntered :
          sessionTime - totalPathTime
        )
        if (locationTime < 0) alert("There was an error found in session data")
  
        storedData.visitCount[cleanedPath] += 1
        storedData.locationTime[cleanedPath] += locationTime
        this.handlePathTransition(prevPath, cleanedPath)
        totalPathTime += locationTime
        prevPath = cleanedPath
      }
    }
  }
  
  computePathProbabilities(){
    const pathProbabilities = {}
    Object.keys(this.transitions).forEach((startPath) => {
      if (startPath !== "count") {
        pathProbabilities[startPath] = {}
        Object.keys(this.transitions[startPath]).forEach((endPath) => {
          if (endPath !== "count") {
            const start = this.transitions[startPath]
            const rawProbability = start[endPath] / start.count
            pathProbabilities[startPath][endPath] = this.formatProbability(rawProbability)
          }
        })
      }
    })
    return pathProbabilities
  }

  customUserAggregationInline(instance, identifier) {
    const instanceTimestamp = new Date(instance[this.createdTimeKey]).getTime()
    if (this.timecutoff[this.tag] === undefined || instanceTimestamp <= this.timecutoff[this.tag]) {
      this.handlePaths(this.userData[identifier], instance.pathSequence, instance.sessionTime)
    }
  }

  customCumulativeAggregation(user) {
    if (this.cumulativeData.locationTime === undefined) {
      this.cumulativeData.locationTime = {}
    }
    Object.keys(this.userData[user].locationTime).forEach((path) => {
      if (this.cumulativeData.locationTime[path] === undefined) {
        this.cumulativeData.locationTime[path] = 0
      }
      this.cumulativeData.locationTime[path] += this.userData[user].locationTime[path]
    })
  }

  formatLocationTimes() {
    Object.keys(this.cumulativeData.locationTime).forEach((path) => {
      const value = this.cumulativeData.locationTime[path]
      const probability = value / this.cumulativeData.sessionTime
      this.cumulativeData.locationTime[path] = this.formatTime(value)
      this.cumulativeData.locationTime[path].percent = this.formatProbability(probability)
    })
  }

  aggregate(verbose = false) {
    super.aggregate()
    this.pathProbabilities = this.computePathProbabilities()
    this.formatLocationTimes()
    
    if (verbose) {
      // console.log(this.userData)
      // console.log(this.timeseriesData)
      // console.log(this.cumulativeData)
      // console.log(this.statistics)
      // console.log(this.pathProbabilities)
      console.log(this.retentionMatrix)
    }
  }
}