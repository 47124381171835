import React from 'react'
import userBehaviourStatisticMap from '../../../helpers/user-behaviour-tracker/userBehaviourStatisticMap'

const UserBehaviourStatistic = ({ tab, statisticSelected, aggregator }) => {
  const Statistic = userBehaviourStatisticMap[tab]
  return (
    <Statistic aggregator={aggregator} statisticSelected={statisticSelected}/>
  )
}

export default UserBehaviourStatistic