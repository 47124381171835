const userFunctionNames = {
  neuron: [
    "isAdmin",
    "allowance",
    "claim",
    "burn",
    "getWeeklyInflation"
  ],
  rankedBattle: [
    "isAdmin",
    "stakeNRN",
    "unstakeNRN",
    "claimNRN",
    "getTotalBattles",
    "getBattleRecord",
    "getUnclaimedNRN"
  ],
  fighterFarm: [
    "isAdmin",
    "setTokenURI",
    "buyNewFighter",
    "setFighterPrice",
    "transferFighterOwnership",
    "updateFighterStaking",
    "doesTokenExist",
    "useVoltageBattery",
    "spendVoltage",
    "removeFighterPrice",
    "getFighterSaleInfo",
    "getAllFighterInfo"
  ],
  fighterMarketplace: [
    "buyFighter",
  ],
  gameItems: [
    "isAdmin",
    "addAdmin",
    "mint",
    "allowanceRemaining",
    "dailyAllowanceReplenishTime"
  ],
  intelligence: [
    "isAdmin",
    "addNewEnvironmentModel",
    "updateModel",
    "getModelHash",
    "doesTokenExist",
    "getTotalNumTrained",
    "isSoulbound"
  ],
}

const adminFunctionNames = {
  neuron: [
    "reloadTreasury",
    "setupAirdrop",
    "setWeeklyInflation"
  ],
  rankedBattle: [
    "setNewRound"
  ],
  fighterFarm: [
    "setNewDrop",
    "setAllowedVoltageSpenders"
  ],
  gameItems: [
    "setAllowedBurningAddresses",
    "createGameItem"
  ],
  intelligence: [
    "addNewEnvironment",
  ],
}

const founderFunctionNames = {
  neuron: [
    "addAdmin",
    "addMinter"
  ],
  rankedBattle: [
    "addAdmin",
    "setNeuronAddress",
    "setMergingPoolAddress",
    "setDelegatedAddress"
  ],
  fighterFarm: [
    "addAdmin",
    "setMarketplaceAddress",
    "setIntelligenceAddress",
    "setGameItemsAddress",
    "setMergingPoolAddress",
  ],
  gameItems: [
    "addAdmin",
  ],
  intelligence: [
    "addAdmin",
    "setDelegatedAddress",

  ],
}

const testFunctionNames = {
  neuron: [
    "isAdmin",
    "carryForwardRankedInflation",
    "mint",
    "approveAllowanceForSpender",
    "approveStaker",
    "burnFrom"
  ],
  rankedBattle: [
    "isAdmin",
    "stakeNRN",
    "unstakeNRN",
    "claimNRN",
    "getTotalBattles",
    "getBattleRecord",
    "getUnclaimedNRN"
  ],
  fighterFarm: [
    "mintFromMergingPool",
    "transferFrom",
    "safeTransferFrom",
  ],
  gameItems: [
    "burn",
  ],
  intelligence: [
    "createNewModel",
    "attachPhysicalModel",
    "detachPhysicalModel",
    "adjustUpdatability",
    "transferFromEnvironment",
    "transferFrom",
    "safeTransferFrom"
  ],
}

const filterSmartContractFunctions = (allFunctions, contractName, dashboardName) => {
  var functionNames
  if (dashboardName === "User") functionNames = userFunctionNames
  else if (dashboardName === "Admin") functionNames = adminFunctionNames
  else if (dashboardName === "Founder") functionNames = founderFunctionNames
  else if (dashboardName === "Test") functionNames = testFunctionNames

  const contractFunctions = allFunctions[contractName]

  if (functionNames[contractName] !== undefined) {
    return Object.entries(contractFunctions).filter(([name, func]) => {
      return functionNames[contractName].includes(name)
    })
  }
  else {
    return []
  }
}

export {
  userFunctionNames,
  adminFunctionNames,
  founderFunctionNames,
  testFunctionNames,
  filterSmartContractFunctions
}