import React, { useEffect, useState } from "react";
import StatisticDisplay from "../../../generic-components/dashboard/statistic-display/StatisticDisplay";
import { 
  getMeanMedianCumulative, 
  getStatisticColor, 
  getStatisticsDisplay
} from "../../../../helpers/user-behaviour-tracker/dashboardHelpers";

const CollectionStatistic = ({ aggregator, statisticSelected }) => {
  const [statistics, setStatistics] = useState()

  const generateStatistics = () => {
    const { statistics } = aggregator
    const newStatistics = [
      {
        metric: "Time on page",
        statistics: {
          ...getMeanMedianCumulative(statistics.page, d => d.string)
        }
      },
      {
        metric: "Time playing",
        statistics: {
          ...getMeanMedianCumulative(statistics.playing, d => d.string)
        }
      },
      {
        metric: "Time collecting",
        
        statistics: {
          ...getMeanMedianCumulative(statistics.collecting, d => d.string)
        }
      },
      {
        metric: "Time positioning opponent",
        statistics: {
          ...getMeanMedianCumulative(statistics.positioningOpponent, d => d.string)
        }
      }
    ]
    
    setStatistics(newStatistics)
  }

  useEffect(() => {
    generateStatistics()
  }, [aggregator])

  return (
    <>
      {statistics && getStatisticsDisplay(statistics, statisticSelected)
        .map(({metric, statistic}, idx) => {
          return (
            <StatisticDisplay
              key={`collection-statistic-${metric}`} 
              label={metric} 
              stat={statistic} 
              color={getStatisticColor(idx)}/>
          )
        })}
    </>
  )
}

export default CollectionStatistic