const discordMappings = {
  "0xe642F9B086425a245764299B6dF6338e7De26687": "reges#1233",
  "0x5A1d993fF6E67aA214880C87B46255Ea32E24139": "LeonardC#1568",
  "0x3F624E4a0402d7aB627B7214e65b0a7C4A39Ef6E": "Trick#3571",
  "0x6E549d0C0950f513d843C0500BAFC80385B18052": "Yoooooooo#2638",
  "0x2741C7A3159F2a01a19F53Cff8972a7812CF6418": "sisielkenny#7948",
  "0x4ae146858189A053713CAF110cB602726632fe82": "Fogou#4076",
  "0xd171e53f2B06BE6a30f8854228C76D5424c587C4": "Wrecks#7673",
  "0x667eEb11e3AdFC2f3A8e30C2787f74A2760eCCFe": "uk#4238",
  "0x1bE2e5c277f77679888B11c9311680fe873d3a3b": "JPEGG#6666",
  "0x4E731074C2B0199e7D391f8184D50D1f35aa4465": "Stone&J#2403",
  "0xBe20B35CF9Af2201651AAEbcE65878f33768A4CD": "kaijupepe#8264",
  "0x6e44C59CbC18F7061D200272Bd119e263b6a08C8": "Alpha Kong | Pyposa#2065",
  "0x9Eb3EeE59075658e70b5F1cfF88b6a2438b3eF34": "GOKU#5060",
  "0xcA060F5a8F84dCD713D7f775ddE1780ADd48dF68": "martinelli hernadez#2436",
  "0x72a30F3E45c0E677fDD340FEDDeFb4B61c14be98": "Gunslinger#5764",
  "0x60Df4251C68e2e9d008BC079EEFaAF6DC5E43641": "D4nny#3926",
  "0x65a272B03690d72BA5D496a0faB06458C5628c28": "Celtixtime 🍙 | Rocket#0127",
  "0x45650086C2688797bF9470258777396c87033B4a": "UMANN#1122",
  "0xc445846fee6d6256f1add7a3326605b31a4a667c": "GreennMan#5090",
  "0x1a5ca725fFc123444e1c6FB3a0F5E5CAD46DD402": "deniznft#9881",
  "0x87441744e4e510Ad90224d14Fd0C9d1e6541d043": "blaze215#2071",
  "0x09020CF073EAc09C9c6d63166A482D0A75137fd5": "GangstaGeekXXX#1679",
  "0xC6467bDB0b7B7e6743300937BC10e075581B78a7": "RodrigoA2Z#7475",
  "0x52be3580601524652978648E872D0aA448aFC928": "MrDQ#0036",
  "0xe8179007766746285625c5C73B0365716CF4Acc6": "Frozensense#5833",
  "0xe6f1364e06Ec6C98D41D3d3D36512f6C68443041": "MrPalvy#9054",
  "0x3236E3df5587cFbe0d811fcE920EC92322bCEA24": "pum#5132",
  "0xC3FD6e0428f4F4f6f9669EC51B129045f9D9070F": "Hanoch#4634",
  "0xb7b7942525343739674223061e437fFf87399312": "Weather#4107",
  "0x02C07322Bfd5DBc6aa58dD1Ea55EB9BBfFfdd11b": "italian_josh#3309",
  "0xB6EA791Ee66c6813798743a5e5F11aFF170A1EDa": "t.3.c.h.n.i.c.a.l.r.o.o.m#1355",
  "0x7AE7188d2175322db95A1323DFd53AD38583c70D": "Smol Preeminent#1984",
  "0xBceD1cA16A71bDF111c405fc8c3156cC64BF8ecE": "gkouvaki#1817",
  "0x9e6c39f57E324fDA4D8397eE5a67E1A5c44a3f4e": "Yeshy#0315",
  "0x738512644257bD9269369322D18a0D92B0A64a2C": "Georman#1921",
  "0x27929aC8b6d3761F86ead55AAFFc199414Bde04B": "Hantao#0001",
  "0x79d899379844D35A1A1F5D51D3185dd821f44Dc1": "Jelo#9731",
  "0xf2A1cF1aB0462E3c353A1e34Bf74eC51fb162D08": "JamesRic#9999",
  "0x4579e110E060Ab6DD0F6f73500f29BC3be8e8ccb": "Caine#5094",
  "0xB2243F047E2087B2A930df6fF3011FCEe15e36ff": "Da_Eth_Guy#2625",
  "0xEc8541d68a2f66690c2B45E8908Fde7E3B3e1bce": "Killam#4655",
  "0x3945E8B38326B54612417449f86bE796Bb51F7fB": "annavie#8521",
  "0x69CD9ACd35F1be66b3a85077930ED7A2CD0764e4": "stepforthestep#7196",
  "0x1262c0eaD0D58e04A90E1E654877c6Ddfa5C76B9": "edick_fnd#2977",
  "0x5438fa17eedD8058B1AA29877AE8706aDbe2100e": "AndyGan#6530",
  "0x0762301bd516fe5d37a89c944515011be4fe1b6f": "Yummyraw#1852",
  "0x264af2ec440510f8e51dbe56079c6100d19d47d7": "Zelda_Boy#0123",
  "0x88d7f7EC27F116F60b15ea66Bd210094d0db034b": "DustStorm#5033",
  "0x81568B1959023032569547F77507651d78b5D37e": "SANCHA#6979",
  "0xA045BB2c43bfb1D4286E88CA60355e7A0D9746cB": "!kuzy7772#7397",
  "0x89e890919271d193e064935907f35a7c5e670002": "Respectless#2624",
  "0x78c4eb74405311620d66eaCc2a4D61e1925B7E3F": "Raze | NFT Gaming#7322",
  "0x5a6693b86d9a105A03828FF7d5DcaAB47a23d678": "etetet123#9963",
  "0xdBD85D3373cE7c3954bDDeE710ED1A682b064b07": "guantabean#5022",
  "0x6b0785b831d89B71d260Fb396e328B24a0072a24": "Elrond#0133",
  "0xfe791B87F607AE6BE8449e64d393c91be46a1245": "man_1801#2452",
  "0xab2ea973d3abE1C223dA0C85dC1962E2Daa2064A": "pirate#3175",
  "0x15bb1542d63e0f452541601690c93cbbade4cb2d": "Faraon#5723",
  "0x73fAFB1E5903b717e4fc3767aDb90E973D64Ab36": "crypto_natsu#4225",
  "0xAF53890720728A1907AEd7C8604Ce1CD945B853F": "💠NorthsideAbN++💠#8468",
  "0xE1682954aE4166E784bb3eC9D901E13949A1028e": "RED#3480",
  "0xcd0eb48851856f64B7c3e0644C08c252876e6B05": "BardoftheEther#1338",
  "0xe15c92e15851b03677a04a9324618ed44cd0a2ac": "Abelgaming.eth I Suiswap#5849",
  "0x9444b0D4C3f7ed7731d93867e347748721EE1f0e": "Spike#2222",
  "0x8AC688EAeAa5C32867fA7E210D854207eE3Eb69b": "Judaworst#9569",
  "0x5Ea81a84FC14a73c7A9b18b72fF41a270C2441CF": "Carlan | READY#3815",
  "0xE6A5C296214830D5Ab45527b83780AeF065E3A2c": "Ryanhere#9927",
  "0x5F8cfD3F2aA1CaDA3631E3b61f1d4C66eBcC2Cf2": "El Gato | Delovoy DAO#3461",
  "0x69795b791089c008970FeBefb3245C344A130E6b": "Tuohai#4079",
  "0x30458345938248d7ec8953d4cee9e0e202db6651": "fitfit1337#0469",
  "0x23244E77b13dc66c71C0A9DAbbEfEa5b9cbfFA24": "Finesse Papi#7408",
  "0xfa9B0A6e4F4a926905F3FE27c33c09183c8C033c": "Bing.L#6493",
  "0x7b4Db86B9c497ef68f6D956350B35d9857154042": "Vanhouse#2017",
  "0xB1c41450CB8a11A7e605e8Be5ca30A4c9C9231c9": "J.#9999",
  "0x12d6AaC80A3357cFA88a3af56f4f7468341c0D66": "MoonLock#4475",
  "0x32ae2622A20C0C421a8d48B7202F6d4FE4132d11": "DeChunkz #1249",
  "0x7CC353433Ef9dF7676Fbb39da26FA0E278107409": "Aimfaria88#1150",
  "0xd9EBc885674a93a7c119f37a6ABF9dBED84E1ff8": "YellowPanther#8888",
  "0xED5b46F7f3d633B1dC7491ABB967431398bdaA01": "rauws#5865",
  "0x0De0e32Ad76887f0e58334cbf3e2f7932FadF5cA": "Clipz#5747",
  "0xebc212c8f416b6c2e8a252c83A56A3A955Dc6402": "Daniconelx#0272",
  "0xDd46CEd5C3F422C6E3A816B50b472BE80ED6F7DC": "DevinHoang#4836",
  "0x0b7a3f9Bce82Cab2A2Afad006949De353524b290": "DonaldJacks#6982",
  "0xdbc2c8d9e8B2fB44d95750DB1BE97dad4ab57005": "TomSuper#2870",
  "0xff9d64bcf38fbdadcf22e89c47c2c922dd500cac": "Compound-I#5315",
  "0xa688f5c5c0dad5dbede01e8fb0a0ca4a5fc19c3c": "LovelyG#2311",
  "0xf88006636611e16122D94055568d8C4f4E0C85FF": "mandelbrot#4426",
  "0x32ae8acfd7ff2bfeb09e819bd0479075268b3205": "DoogsZA#0147",
  "0xa13f3E15a13761aC557A32816149F63019d365CF": "Marc’teryx#7387",
  "0x1e5530e1a2406C13Cebd06c8Cb2E8A1d7125e347": "Booductran#9214",
  "0x0105c26c5d013bb32223dbb2a4Bbd98e9B0c320A": "Dr11235#9930",
  "0xDe9362e8D09BD64B28D067e16B03843Aa7BCB406": "Marc8976#0268",
  "0xc942496E98B9f8DE243AF4226B8a9398A141913C": "MrLittleHans#0013",
  "0xDC7C6F367E4680D3B8e4ceAB1e65cB7E361314A1": "Desolute#6744",
  "0xb40969F60BfA246A09593099DAce2dDD543254A3": "apix#8283",
  "0x8F09EeF679904F64752523f95dB664039dF278e2": "Heyyawn#1025",
  "0x81450b4F7B76A9E5Fe366451068166b3907B680c": "edward-ln#5167",
  "0x87b5f410B37D0DB5a4A558399dFC8286a3c09E36": "573F#4669",
  "0x124bcF206F1581C1D2d2a86310470825C8e65BeC": "?Lissandro#5176",
  "0xADE7F2f901D5bF04b45A11C63b30f6f31b49B3B0": "tilled#7639",
  "0x73391c743b653ea9949ece4f42457b1d780d6c1d": "Dimastor#9853",
  "0x4377E83eDb9Bb6905795802cCa6845d72c237b36": "Stankovich#7451",
  "0x5576Bf2b676858D5E2934FccEDc01B0088Ddb3D6": "VladManz | Delovoy DAO#6970",
  "0x73bb185Df8c7Ddc130F594E0fCDc0297658B51CF": "allen-wok#8866",
  "0xbF23365269f1B75df4a0a71A0F8B64ecbf19E71B": "schick#3156",
  "0xB7176c03739ec10c4EC8E21a64aD64B228b8A5f2": "TheReaper235#8942",
  "0x585FCAc095302cdBC3a7B0CE4e1703913Fa3e804": "lookario07#6204",
  "0xd98C255D11d7b928AC138F52a875B11A65844444": "zosk#8425",
  "0xD6E2B9696dc45642ebB84Bb789ed68D2354D774d": "Kowl#8312",
  "0xf48Bc9240f513e763Fe7E0943Bc040c89Dd2DF8B": "HdOlivares#7777",
  "0xf32422641A767d162345CF831Cdd3b87DfeB7c7B": "jeremy.og#7288",
  "0xeaA68E56a052bD80d38221953c139632cfE44702": "freakyfreaky520#1564",
  "0x4c1285Adaa0Cde68D427f402a937dEd523FB84F7": "Jiemi#9520",
  "0xc9F64274F5Bc7F97F02129406eb6191D31548663": "q2thec#1971",
  "0xe5e01254AaE93DAce33a8Fa747717F4F14cbD71f": "Keii#6564",
  "0xe2724b88E93ee76d60E72c372317daDAdA0Db826": "tabgnuh#5015",
  "0x8bC9755938a4a4e4Db09D023B00D30B563D678a7": "Golden Sauce#6479",
  "0x132d0dEE3E54F796747850bc4C64b4341713c240": "deliamayami#3355",
  "0x7B3893D8a1371B4E21c2864530033C59bDbE2590": "nikoT#1150",
  "0xf946CDAEcF5A15440e500E61A0c1d179A93652ad": "Il_Il | Delovoy DAO#9036",
  "0x93f9108E5b5c0DF41c7598412A3558904225AbbC": "dyno#7777",
  "0x348Ed2fc3c2E93608666263E50b9713839E760eb": "Bobigazda#9808",
  "0x5c9ce53738967e4d422f0f6914d5be496f9edd2b": "YouDidntSeeAnything#5771",
  "0x4abcac6f90096c8a706de8ddb5de5a9fe965afc1": "Pearly P.#6909",
  "0x68e33A587f1d697c5e0f8b8794cFA304B41a61e2": "Flye Kites Jay#2440",
  "0x232fe3a7c0b29a2D143fF73A5e75A2514e625cCA": "EpicHuntur#7408",
  "0xFcEb8a974f85aCCfA6e287AbFca9c26dc4196b90": "JIA_B#2176",
  "0xd99F500aEFaD2Dc73Ed6FA8334ddCDE1AF40E78A": "flingz#1641",
  "0xd1992817e258dDfa0B47Ae550027A0DdE1272233": "Demster#1826",
  "0x30f4460b68c431f894b39364fe70f275d900b2fa": "Rvanan#9413",
  "0xcb3Ca5abFC40539EF0D1747443AE0ad8114111A1": "Shedywards#0250",
  "0x3a2833c8975687F5969D07F58FAfCd3473B5aCd4": "Younique#2233",
  "0x2Bdb2421baC3be56c2eC46dD3E88ccA8c72c77d1": "Raym#8986",
  "0xc54DCd8F121C03Dd08be65F0a2a8e8c69aF1de4b": "supbobap#2205",
  "0x3af0c8E44377ecBb7A4e4841658bCe227bDfbc33": "Jedida#9872",
  "0xe903eEfAA2BAE15b34C533eE41B053b629F63F5A": "N1N#9527",
  "0xC83d04A34B625c87d6cdfe819520e9Ec00Bb1D31": "CryptoTaxCat#6274",
  "0x8F90cbAe1bb37F7aA78a72A1Ea1523521dBCB14B": "Eddiee#4186",
  "0x7f792Fa483e33cbFC71e8d7548D63Eb2d425F060": "Youngthugg007#3318",
  "0x1a387793d05B98F3eda6529453D8D6FB8f08EE1C": "GaspodeWD#7793",
  "0xEA6D82C5aC15ca958fba6F1793a6567bFA0dFd7A": "Sanguine | Delovoy DAO#7298",
  "0x13e2d56Ca035257Fb75a27b7e49a78ED03D213e9": "MCG#7810",
  "0xE1fA1A88C46F35d28E15b5C88Ae4BDbb5f6a6b5D": "Lehson1308#4184",
  "0xf87a8048Fc5dB261a39906c66524EdA556b746dF": "Tharwa#9262",
  "0x134F240827Dd4B047942589998a163A2A1002F1a": "zen123#9633",
  "0xbfba6ccf295c5835eed74253309e1a0a2d478e1d": "KrzyAwAw#6979",
  "0xF2a477aBfb3E3eA370Ef1ece59411b5046fd966D": "binn#1825",
  "0xeDAa54654E242eb3Eae9afE97db945f5e6b80A7B": "jenniferlOlit#7306",
  "0x1e354cbFb451A6dD73902B504f05c5C66b43A3Eb": "nnamdipremium#7161",
  "0x10C7d2273D88Fe1E26D7b92fcBfB539B9E6557f6": "Choang#9999",
}

const discordNamesBeta3 = {}
Object.keys(discordMappings).forEach((address) => {
  discordNamesBeta3[address.toLowerCase()] = discordMappings[address]
})

// Temp hack to hide my email
discordNamesBeta3["brandon@aiarena.io"] = "Guppy's Secret"

export {
  discordNamesBeta3
}