import DataAggregator from "./data-aggregator"

export default class InspectorAggregator extends DataAggregator {
  constructor(data, tag) {
    super(data, tag)
    this.counterKeys = ["timeSpent"]
    this.timeseriesKeys = ["timeSpent"]
    this.createdTimeKey = "createdAt"
    this.timecutoff = {
      "Beta 2.0": 1676998800000, // 2023-02-21 12:00pm EST
    }
    this.retentionKey = "timeSpent"

    this.initialize()
  }

  userInitialization() {
    const initial = super.userInitialization()
    return {
      ...initial,
      exitAction: {},
      modelUpdateActions: { user: {} }
    }
  }

  getStartTime(instance) {
    return new Date(instance[this.createdTimeKey]).getTime() - instance.timeSpent
  }

  handleExitActions(instance, identifier) {
    const exitActionMapping = this.userData[identifier].exitAction
    if (exitActionMapping[instance.exitAction] === undefined) {
      exitActionMapping[instance.exitAction] = 0
    }
    exitActionMapping[instance.exitAction] += 1
  }

  handleModelUpdateActions(instance, identifier) {
    instance.modelUpdateActions.forEach((action) => {
      const actions = this.userData[identifier].modelUpdateActions
      if (actions[instance.id] === undefined) {
        actions[instance.id] = {}
      }
      if (actions[instance.id][action] === undefined) {
        actions[instance.id][action] = 0
      }
      if (actions.user[action] === undefined) {
        actions.user[action] = 0
      }
      actions[instance.id][action] += 1
      actions.user[action] += 1
    })
  }

  customUserAggregationInline(instance, identifier) {
    this.handleExitActions(instance, identifier)
    this.handleModelUpdateActions(instance, identifier)
  }

  getCumulativeObject(currentUserData, key) {
    if (this.arrayData[key] === undefined) {
      this.arrayData[key] = {}
    }
    if (this.cumulativeData[key] === undefined) {
      this.cumulativeData[key] = {}
    }
    Object.keys(currentUserData).forEach((subKey) => {
      if (this.cumulativeData[key][subKey] === undefined) {
        this.cumulativeData[key][subKey] = 0
        this.arrayData[key][subKey] = []
      }
      const value = currentUserData[subKey]
      this.cumulativeData[key][subKey] += value
      this.arrayData[key][subKey].push(value)
    })
  }

  customCumulativeAggregation(user) {
    this.getCumulativeObject(this.userData[user].modelUpdateActions.user, "modelUpdateActions")
    this.getCumulativeObject(this.userData[user].exitAction, "exitAction")
  }

  getNestedObjectStats(key, topN) {
    this.statistics[key] = {}
    Object.keys(this.arrayData[key]).forEach((subKey) => {
      this.statistics[key][subKey] = this.getStats(this.arrayData[key][subKey], subKey, topN)
    })
  }

  customStatisticsAggregation(topN) {
    this.getNestedObjectStats("modelUpdateActions", topN)
    this.getNestedObjectStats("exitAction", topN)
  }

  formatTotal(x, key) {
    if (this.counterKeys.includes(key)) {
      return this.formatTime(x)
    }
    else {
      return x
    }
  }

  aggregate(verbose = false) {
    super.aggregate()

    if (verbose) {
      console.log(this.userData)
      console.log(this.cumulativeData)
      console.log(this.statistics)
      console.log(this.retentionMatrix)
    }
  }
}