import axios from 'axios'
require('dotenv').config()

const backend = process.env.REACT_APP_BACKEND

async function getLastAbiUpdate() {
  const abisUpdateResult = await axios.get(`${backend}misc/last-abi-update`)
  return abisUpdateResult.data
}

async function getAbis() {
  const abisResult = await axios.get(`${backend}misc/contract-abis`)
  return abisResult.data
}

export {
  getLastAbiUpdate,
  getAbis
}