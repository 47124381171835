import React, { useEffect, useState } from "react"
import { AiOutlineArrowUp, 
  AiOutlineArrowDown,
  AiFillForward,
  AiFillBackward,
} from 'react-icons/ai'
import {
  IoCaretForwardSharp
} from 'react-icons/io5'
import "./playground-leaderboard-table.css"
import fail from "../../../local-assets/icons/fail.png"
import success from "../../../local-assets/icons/success.png"

const PlaygroundLeaderboardTable = ({tableData, setSelectedPlayer, selectedPlayer}) => {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10
  })
  const [sort, setSort] = useState({
    currentSort: "totalWins",
    ascending: false
  })
  const [sortedTableData, setSortedTableData] = useState([])

  const renderPlaygroundLeaderboard = () => {
    const startIndex = (pagination.page - 1) * pagination.pageSize
    const endIndex = startIndex + pagination.pageSize

    const pagedTableData = sortedTableData.slice(startIndex, endIndex)

    return pagedTableData.map(data => {
      return (
        <tr 
          key={data.identifier} 
          className={
            data.identifier === selectedPlayer.identifier ? 
            "playground-tracker__leaderboard-row--selected" : 
            ""
          }
          onClick={()=>{
            const playerData = tableData.find(player => player.identifier === data.identifier)
            setSelectedPlayer(playerData)
          }}>
          <td>{data.discord ?? "N/A"}</td>
          <td className="playground-tracker__leaderboard-table-address-cell">{data.identifier}</td>
          <td>
            <img src={data.highestDifficultyDefeated === "Beast Mode" ?
              success :
              fail}/>
          </td>
          <td>
            <img src={data.beatenAllWeights ?
              success :
              fail}/>
          </td>
          <td>
            <img src={data.beatenAllStagesAndWeights ?
              success :
              fail}/>
          </td>
          <td>{data.totalWins}</td>
          <td>{data.totalLosses}</td>
          <td>{data.totalMatches}</td>
          <td>{data.winPercentage}%</td>
        </tr>
      )
    })
  }
  
  const shouldDisablePagination = (isForward) => {
    if (isForward) {
      return pagination.page >= pagination.lastPage
    } 
    else {
      return pagination.page <= 1
    }
  }

  const handlePagination = (isDisabled, newPage) => {
    if (!isDisabled) {
      setPagination({...pagination, page: newPage})
    }
  }

  const sortTableData = (dataset) => {
    let sorted
    
    if (sort.currentSort === "highestDifficultyDefeated") {
      sorted = dataset.sort((data1, data2) => {
        const { highestDifficultyDefeated: data1Defeated } = data1
        const { highestDifficultyDefeated: data2Defeated } = data2
        return getOpponentDifficulty(data1Defeated) - getOpponentDifficulty(data2Defeated)
      })
    } 
    else {
      sorted = dataset.sort((data1, data2) => {
        return Number(data1[sort.currentSort]) - Number(data2[sort.currentSort])
      })
    }
    
    if (sort.ascending === false) {
      sorted = sorted.reverse()
    }

    return [...sorted]
  }

  const COLUMN_HEADERS = [
    "Discord ID",
    "Identifier",
    "Beast mode beaten",
    "All weight cleared",
    "All weight & stages cleared",
    "Total wins",
    "Total losses",
    "Total matches",
    "Win %"
  ]

  const renderColumnHeaders = () => {
    return COLUMN_HEADERS.map(header => {
      const shouldShowSort = isCurrentFilter(header) && header !== "Identifier"
      const sortIcon = sort.ascending ? <AiOutlineArrowUp/> : <AiOutlineArrowDown/>

      return (
        <th key={header} onClick={() => updateSort(header)}>
          {header}
          {shouldShowSort && sortIcon}
        </th>
      )
    })
  }

  const isCurrentFilter = (columnHeader) => {
    return HEADER_MAP[columnHeader] === sort.currentSort
  }

  const HEADER_MAP = {
    "Beast mode beaten": "highestDifficultyDefeated",
    "Total wins": "totalWins",
    "Total losses": "totalLosses",
    "Total matches": "totalMatches",
    "Win %": "winPercentage",
    "All weight cleared": "beatenWeights",
    "All weight & stages cleared": "beatenWeightsAndStages"
  }

  const OPPONENT_MAP = {
    "Beast Mode": 4,
    "Strategic": 3,
    "Aggressive": 2,
    "Dummy": 1,
    "None": 0
  }

  const getOpponentDifficulty = (opponent) => {
    return OPPONENT_MAP[opponent]
  }

  const getSort = (columnHeader) => {
    return HEADER_MAP[columnHeader]
  }

  const updateSort = (header) => {
    if (!HEADER_MAP[header]) {
      return
    }

    if (HEADER_MAP[header] === sort.currentSort) {
      setSort((prevState) => {
        return { currentSort: prevState.currentSort, ascending: !prevState.ascending }
      })
    }
    else {
      setSort({ currentSort: getSort(header), ascending: false })
    }
  }

  useEffect(()=>{
    const initialSortedTableData = sortTableData(tableData)
    setSortedTableData(initialSortedTableData)
    setSelectedPlayer(initialSortedTableData[0])
  }, [])

  useEffect(()=>{
    const newSortedTableData = sortTableData(tableData)
    setSortedTableData(newSortedTableData)
    const lastPage = Math.max(Math.ceil(newSortedTableData.length / pagination.pageSize), 1)
    const newPage = pagination.page > lastPage ? lastPage : pagination.page
    setPagination({...pagination, lastPage, page: newPage})

    if (
      !selectedPlayer || 
      !selectedPlayer.data || 
      !newSortedTableData.find(player => player.identifier === selectedPlayer.identifier)
    ) {
      setSelectedPlayer(newSortedTableData[0])
    }

  }, [tableData])

  useEffect(()=>{
    if (tableData.length && sort.currentSort) {
      const newSortedTableData = sortTableData(tableData)
      setSortedTableData(newSortedTableData)
    }
  }, [sort, sort.ascending])

  return (
    <>
      <table className="playground-tracker__leaderboard-table">
        <thead className="playground-tracker__leaderboard-header">
          <tr>
            {renderColumnHeaders()}
          </tr>
        </thead>
        <tbody>
          {renderPlaygroundLeaderboard()}
        </tbody>
      </table>
      <div className="playground-tracker__leaderboard-pagination-container">
        <div 
          className={`playground-tracker_leaderboard-pagination-icon
          ${shouldDisablePagination(false) ? 
          " playground-tracker_leaderboard-pagination-icon--disabled" : 
          ""}`}>
          <AiFillBackward 
            size={16} 
            onClick={()=>{handlePagination(shouldDisablePagination(false), 1)}}/>
        </div>
        <div 
          className={`playground-tracker_leaderboard-pagination-icon
          ${shouldDisablePagination(false) ? 
          " playground-tracker_leaderboard-pagination-icon--disabled" : 
          ""}`}>
          <IoCaretForwardSharp 
            size={12}
            style = {{transform: 'rotate(180deg)' }}
            onClick={()=>{
              handlePagination(shouldDisablePagination(false), pagination.page - 1)}
            }/>
        </div>
        <div className="playground-tracker_leaderboard-pagination-icon">
          {pagination.page}
        </div>
        <div 
          className={`playground-tracker_leaderboard-pagination-icon
          ${shouldDisablePagination(true) ? 
          " playground-tracker_leaderboard-pagination-icon--disabled" : 
          ""}`}>
          <IoCaretForwardSharp 
            size={12} 
            onClick={()=>{
              handlePagination(shouldDisablePagination(true), pagination.page + 1)}
            }/>
        </div>
        <div 
          className={`playground-tracker_leaderboard-pagination-icon
          ${shouldDisablePagination(true) ? 
          " playground-tracker_leaderboard-pagination-icon--disabled" : 
          ""}`}>
          <AiFillForward 
            size={16} 
            onClick={()=>{
              handlePagination(shouldDisablePagination(true), pagination.lastPage)}
            }/>
        </div>
      </div>
    </>
  )
}

export default PlaygroundLeaderboardTable