import axios from 'axios'
require('dotenv').config()

const backend = process.env.REACT_APP_BACKEND

const cleanUpModels = async (tag, message, signature) => {
  await axios.post(backend + 'ipfs-requests/model-cleanup', { tag, message, signature })
}

export {
  cleanUpModels
}