const discordMappings = {
  // Project Mayhem 
  "0x79d899379844D35A1A1F5D51D3185dd821f44Dc1": "Jelo#9731",
  "0xCEdf866187680e70296a8b9EEce22A09b4F05798": "chompk.eth | Contribution DAO#9502",
  "0xfa9B0A6e4F4a926905F3FE27c33c09183c8C033c": "Bing.L#6493",
  "0xC83894b4d9fB103227dE2b627F51Bd0B292d5372": "SonnyRobinson#0887",
  "0xe8179007766746285625c5C73B0365716CF4Acc6": "Frozensense#5833",
  "0xbF23365269f1B75df4a0a71A0F8B64ecbf19E71B": "schick#3156",
  "0x28F5923B4603dD826de8f183dd5FEdf27DdAF8e9": "morkovnyy_sok#9748",
  "0xd33F3eF1Ea28Ddb5315D9185fB7fFa007a8Dba4D": "Pray#7854",
  "0xDCF086E3f7954B38180DaAe1405569DA86588bfE": "Alex-7#6485",
  "0xC3FD6e0428f4F4f6f9669EC51B129045f9D9070F": "Hanoch#4634",
  // Arbistars
  "0x667eEb11e3AdFC2f3A8e30C2787f74A2760eCCFe": "uzura#4238",
  "0xe6f1364e06Ec6C98D41D3d3D36512f6C68443041": "MrPalvy#9054",
  "0x3b1b3edb62b527a134e5a7541f8231a3840486e1": "meji#2669",
  "0xf32422641A767d162345CF831Cdd3b87DfeB7c7B": "jeremyberros.eth#7288",
  "0x160aCC42b7b274c5250c6b79950aa56D43bfC088": "Berlin#4051",
  "0x81450b4F7B76A9E5Fe366451068166b3907B680c": "edward-ln#5167",
  "0x5a6693b86d9a105A03828FF7d5DcaAB47a23d678": "etetet123#9963",
  "0xCb12102493E513eD5e32FBC36864569bAd2812Bd": "rehe#3482",
  "0xFcEb8a974f85aCCfA6e287AbFca9c26dc4196b90": "JIA_B#2176",
  "0x08C4d9D5fAa48837cfDd73C63De137ce88154226": "Poloskin#8737",
  // Delevoy DAO
  "0xEA6D82C5aC15ca958fba6F1793a6567bFA0dFd7A": "sangvin#7298",
  "0x5F8cfD3F2aA1CaDA3631E3b61f1d4C66eBcC2Cf2": "El Gato#3461",
  "0x24176367b8cbc04057Cb90AA4Cc088cB3024613a": "Struggler.eth#7224",
  "0xebc212c8f416b6c2e8a252c83A56A3A955Dc6402": "Daniconelx#0272",
  "0x786Da7C483E802242ae6fd52d327E4686Bd296dA": "kinox#1242",
  "0x52d0Fc347560cC997f87984200F4a9344eCDC597": "Xandrell#9401",
  "0xE6A5C296214830D5Ab45527b83780AeF065E3A2c": "Ryanhere#9927",
  "0x02C07322Bfd5DBc6aa58dD1Ea55EB9BBfFfdd11b": "italian_josh#3309",
  "0xdbc2c8d9e8B2fB44d95750DB1BE97dad4ab57005": "TomSuper.eth#2870",
  "0x721B9541fDcb1CEE2b500c84e5dbDFAF9F909736": "Quinnton.AI#7308",
  // K Dash
  "0x913A786eEa7c15B22775e372E432EcCfB0d8a0A8": "brdg#4867",
  "0x1839FAEA698CBaE6EdE826C09D943DE1dba7DD96": "SohoJoe.eth#7861",
  "0x7D56e8ED62d12B03bDF8DBaDea4311a1386D006C": "linyx#7120",
  "0x5a8978B580299378c75bab85863410cCe641F2b5": "jedimastergrogu#6856",
  "0x06F398ce76aCc1fCE97B7Cab0eb2a96BA9230c9b": "MarsDAO#8888",
  "0xeaA68E56a052bD80d38221953c139632cfE44702": "freakyfreaky520#1564",
  "0x92b9B88a16B2159DeF61EbFab3bE6df18a7dA3c8": "Candles#5366",
  "0xFb3a60e22c71bA8730431264195e264dF240D9F4": "LeviButler#8371",
  "0x8F09EeF679904F64752523f95dB664039dF278e2": "Heyyawn#1025",
  "0x69795b791089c008970FeBefb3245C344A130E6b": "Tuohai#4079",
  // Azuki CN Garden
  "0x52be3580601524652978648E872D0aA448aFC928": "MrDQ#0036",
  "0x6b1B673E56f90e5848B022440115BC882cc40e86": "RayYayZzz#6451",
  "0xF86D5cD689E71260F0b3EAE4220dB89F9924BD13": "Rashomon | LSP#6183",
  "0x21BC65637Dd6e7b171F2F78a1845F9807BbE3148": "wakywu#8996",
  "0x5548584D74f7B5Bd5eD8016755bc1DE27AA35621": "0xKlaus#3825",
  "0x524d1154594e2aC7C4cF9c45dB68517Ae1228cEd": "Probably Something#4456",
  "0x4ae32281f22305dF1099D94328cCC91220F8dc4d": "Alpies#8888",
  "0x5B383EE3E3baA2b2d7aB33586d787b527408C493": "Leo | JCZZY#9026",
  "0x7b47503Ae6c88040328863c70BA71be322765Ca5": "leon_du#8284",
  "0x4730182324Fb7c0e2853A299896Ae351c8BC0897": "Momoko#4330",
  // Digicult
  "0xBe950949E2F550258908425F503754ab70096699": "Cryptah#7783",
  "0x4A45EBB91756ec74742609004781D291619ecc4B": "harang#8576",
  "0xe5FCFCA8519154aD9D73893E381Db99fCAFBF5af": "gambit18#3971",
  "0x6760E6B05f205af591487A49BC047567c541edc6": "Spaceman#6766",
  "0x1950c54dd2031ab2033f4c4557761dd4046156a4": "LarpyDart#2623",
  "0xb5eDccbAc9484d9888E944890f08ffD2478C043F": "pizzacat#8812",
  "0x08D816526BdC9d077DD685Bd9FA49F58A5Ab8e48": "Kiwi#7331",
  "0xc4051C9DA9A5C98F72dbb077e6F923f1393D1D76": "Brighty#6483",
  "0xcEdC420D2918B6D709De839725C45826880d7E90": "iiwii#2065",
  "0x20e9503A6BC31765d3648b4AB67d035AA67c65A6": "God-Level -#1412",
  // Illogics
  "0x068Bda63b22aC5495FD6a19aD42DC11fe920fdc0": "Dansrocks#2809",
  "0x858a23908538128786c7678653F1f8080b0a074f": "mg#8126",
  "0x7057b9eC157c6dB6553D20A7B4E2fD8636d29198": "estuardo.#0420",
  "0x2164f9F0b8d8A01448E19D6C95869136eD181ED0": "Seol#0839",
  "0x1695666d4F3582E56E1BF91Ad0077976E2B7ACc8": "tschöppeli#6473",
  "0x2D5f5BE6a648FA5b8bC2653BB5712e223079aC1e": "OxO.Oxygen#4113",
  "0x130Ebf09F40B3F64b99F1313C2e4B5878852b684": "Bjorn#7884",
  "0x4E731074C2B0199e7D391f8184D50D1f35aa4465": "Stone&J#2403",
  "0x232fe3a7c0b29a2D143fF73A5e75A2514e625cCA": "EpicHuntur#7408",
  "0xBe2c6fD365a09c9b552949444685A218751E67Bb": "M3xxXSoloXxx#5436",
  // Real Agency
  "0x264ce020b927389439c9416B64B37b5d0bEBD8B7": "GRAINYCOD#2239",
  "0xb40969F60BfA246A09593099DAce2dDD543254A3": "apix#8283",
  "0x1a387793d05B98F3eda6529453D8D6FB8f08EE1C": "GaspodeWD#7793",
  "0x77826782f13CC2CcF86556e7969344b21e7aB0cf": "Alisa#4406",
  "0x277077Cac3f753bddAAC4e97C1c3ee668D8C15F2": "Sense#8758",
  "0x5f128D272F4cAc0e2cce73bD516F4155f16f4275": "Nermoria#3132",
  "0x6B9B2D0EF03971ae9Ac1750e72b8d9Bd0312b640": "AKickingBull#3356",
  "0xA6C2f549E3CceE748A5926059098E456D8Bb1e78": "Bloo#7777",
  "0x50F7CE24D2D4E1D9CcC926E86d38528395CFdbDF": "Nightwatch#4781",
  "0xCc4068c550bFA4D4c28dc69D25EE9FCd7cd03a42": "Carliyke#3530",
  // Ready Player
  "0x64EBDE2E66d3101e12973f407D97FdaCbdb5c313": "Tmessi10 | READY#2674",
  "0xF34196597dF209A04Fc92B97071DCAAbbC63f0E8": "g$#2315",
  "0x18456ce597e8469472dCb76e9144A151681cDC52": "dangros#0001",
  "0xfd817709270C06e096015A8fa70A0010e78bdEC2": "therealcabrera | READY#0001",
  "0xfb554A656683DA8285a8c6a893B6D7e015221077": "Devious Titan | READY#6067" ,
  "0x7b9E1721aF68f94f63881E750d39F91BD04C93BA": "Danny C | Ready Player#6777",
  "0xe8AFa0f7B431F97b389f28839559bFC9f3DA63F9": "Zatakster#1668",
  "0xd7f52A3677d8d5499b0aFF1d4a23EC1a57192aB6": "PatronJay | READY#0001",
  "0x803F27dEaA1282653745C3abBD71Df11E758944b": "0xGunter#0001",
  "0xCd62a122529B87885aCF3D00De9722850188420E": "astroboy#8888",
  // FOGDAO
  "0xc7EB1916A4d9c7Ca782114eD365ccF6260afaB44": "Nofor#7027",
  "0x0D0fa8ac6eE64AbC3C491d6e49B8e9a8e2120952": "RareSecond#3128",
  "0xDBF2A22Cea950eaf98bceB57512DB3aA738Cbc8E": "Suksori#7684",
  "0x3CE630e9fC9567E49BA987B42CfF14F322044984": "elveebee#5717",
  "0x8529E722EB4F8f1eb8aee8C852106daDd95d63A4": "KostasZrk#1190",
  "0x175619a25A3650bED008ea2eEb27F125191e64AE": "Dante Sanchez#8815",
  "0x435d821Ee5b346850545cB18443ca9808A9d47D0": "nicabar#4764",
  "0x61a9cD6dfC86e12E69Ab098351fB785Fa3a2a743": "JACL#2778",
  "0xd7dfDeE2EFd7ae2f82bFC3a3B0472AEA8Fd7B731": "0xmisaka#1139",
  "0x12b0E1B718b33d16512BD7A81C3680fAEe3C7e3f": "Vlad B#3211",
  // Batch 2 minting
  "0x4f35F7EC953d61D77329E2cC82D032c332F84473":	"Achamaru#5094",
  "0xfa9B0A6e4F4a926905F3FE27c33c09183c8C033c":	"Bing.L#6493",
  "0x30d26d80fF203118f141Eb096698a7A16198C157":	"Cryptogirl#3496",
  "0x951e7e5b5Ca4c96821e6572323dAA1b946CF208F":	"DÆ°Æ¡ng QuÃ½ | Orient Dragon#5213",
  "0xcF61B6de27aa0a5fef3979A16E955a251eC784d5":	"Dovgy#3055",
  "0x8316B461fFbE4Fd7B489363e45eB939771B0fe23":	"Dread#8210",
  "0xF7068AC4765DC37703fe087bA5109d6990676bf6":	"Emma24#0045",
  "0xF54C55FF56a120FaAc4391036f3e6b0E48e767CF":	"FluffyBunnyZack#8039",
  "0xef9B8Fbbf9094feAC33FeCeE8803E7477a287d4a":	"Gabriel1991#2889",
  "0xb9832c93b2fD9C1E99Cd44A54CcCa0A8Ec7d7FFf":	"Goodluck#9107",
  "0xdBD85D3373cE7c3954bDDeE710ED1A682b064b07":	"guantabean#5022",
  "0xC3FD6e0428f4F4f6f9669EC51B129045f9D9070F":	"Hanoch#4634",
  "0x5a8978B580299378c75bab85863410cCe641F2b5":	"jedimastergrogu#6856",
  "0x1bE2e5c277f77679888B11c9311680fe873d3a3b":	"JPEGG#6666",
  "0x94061E6dFF5E6474987be0D3B75975Db9b1A846C":	"life goes onðŸ¦‡ðŸ”Š#7497",
  "0x2Fcd965a6C86A42C87b5bf69D8b031A466b52245":	"magnacarta#0416",
  "0x39C4373e0A5333B27f4F11AC6BB9ea3EC5C25350":	"MCG#7810",
  "0xD77a6477114e3D1d34DC69eFc3a7e882e360034D":	"MisterKumar#8586",
  "0x50FEfB456648d7d2Ac43ea60De364E6Db4e793A3":	"naive#2928",
  "0xeb3a4113907d38058955661faB0E81dD513761ED":	"Pepa#1572",
  "0x3C52cfcC17FB344e33636212B3718f063C8278c3":	"Peres#0064",
  "0x3236E3df5587cFbe0d811fcE920EC92322bCEA24":	"pum#5132",
  "0xc9F64274F5Bc7F97F02129406eb6191D31548663":	"q2thec#1971",
  "0x99cdb33Df8726F1952af7f60a02e70436241d499":	"tamao#0840",
  "0xaA21505A90F5A5D1e40c39BD719F1b3BEF843CbC":	"Ter#6631",
  "0x79BaD1563F01F767E907E66fbE5EA2729374b153":	"thangnd#5096",
  "0x69795b791089c008970FeBefb3245C344A130E6b":	"Tuohai#4079",
  "0x4f1ba666004bbf74bFfd75DB29c52f029875f9d7":	"Yu En#7495",
  "0x1ce60c61e1D61E0a33eE446957006C42a8D12492":	"Zeusâš¡#5524",
  // Batch 3 minting
  "0x78c4eb74405311620d66eaCc2a4D61e1925B7E3F": "Raze | NFT Gaming#7322",
  "0xa5171632D7f9dcF9435E724ac3caE0e2bbfb636c": "<3 Bastian <3#1140",
  "0x52be3580601524652978648E872D0aA448aFC928": "MrDQ#0036",
  "0xf2A1cF1aB0462E3c353A1e34Bf74eC51fb162D08": "JamesRic#9999",
  "0x5a6693b86d9a105A03828FF7d5DcaAB47a23d678": "etetet123#9963",
  "0x72a30F3E45c0E677fDD340FEDDeFb4B61c14be98": "Gunslinger#5764",
  "0x90c4a3Ac277Fab9E56e27E17F6Aa59a2452016cb": "Tigre93Allan#0895",
  "0x4579e110E060Ab6DD0F6f73500f29BC3be8e8ccb": "Caine#5094",
  "0x667eEb11e3AdFC2f3A8e30C2787f74A2760eCCFe": "uzura#4238",
  "0xBe20B35CF9Af2201651AAEbcE65878f33768A4CD": "kaijupepe#8264",
  "0x02C07322Bfd5DBc6aa58dD1Ea55EB9BBfFfdd11b": "italian_josh#3309",
  "0x34E3f9567aee97397Ac7A002dF2ef4f30193F1A6": "Dumb#4786",
  "0x79d899379844D35A1A1F5D51D3185dd821f44Dc1": "Jelo#9731",
  "0x5Ea81a84FC14a73c7A9b18b72fF41a270C2441CF": "Carlan | READY#3815",
  "0x73fAFB1E5903b717e4fc3767aDb90E973D64Ab36": "crypto_natsu#4225",
  "0xD7eBDA19d3F225d7712c10b98172A7E58E2186b3": "CHAM#8581",
  "0x3F2D732a222C9ec2b8FA3beDA4A186F84940B7C0": "YaMi#5456",
  "0xb40969F60BfA246A09593099DAce2dDD543254A3": "apix#8283",
  "0xB1c41450CB8a11A7e605e8Be5ca30A4c9C9231c9": "Ayanami#9999 (Now goes by J.)",
  "0x1262c0eaD0D58e04A90E1E654877c6Ddfa5C76B9": "edick_fnd#2977",
  "0xb4f2DEA715A6Ee100e79b59d49ad4f9eE37498bA": "tpsy#4833",
  "0xe6f1364e06Ec6C98D41D3d3D36512f6C68443041": "MrPalvy#9054",
  "0xA3deB012Dca06b12a7e9AE06738fCCB4D39a6324": "noka#4588",
  "0x1bE2e5c277f77679888B11c9311680fe873d3a3b": "JPEGG#6666",
  "0x21Ae38F3aD9DcDcaD127f64e532E0fD76EAeFb52": "newoc#6399",
  "0x9217d8B3a7036e507e9D04Ae1969E395ca308947": "Huyyy#0648",
  "0x338C8bA36ab46E9DF84BCe741F903D01895ACAD5": "uEugen#2654",
  "0x1a387793d05B98F3eda6529453D8D6FB8f08EE1C": "GaspodeWD#7793",
  "0x53Ad5695943033a75878ae747ee613937D213c8a": "Phuonglee#2624",
  "0x0d96AD8611A92EA434585eeA619b3831AB971cEB": "Tairuc#2557",
  "0xc93a77565EDfc2b057204Ff17C60154bA8952c8E": "Bound II#8088",
  "0x3C0c6C9670Dd6ea971fFEF7a1A77D341b8B3A4B3": "Wale#2486",
  "0x5951848719E95B92A665b044EF01C8769b024ed0": "indigo#0666",
  "0x8F09EeF679904F64752523f95dB664039dF278e2": "Heyyawn#1025",
  "0xe8179007766746285625c5C73B0365716CF4Acc6": "Frozensense#5833",
  "0x3b1b3edb62b527a134e5a7541f8231a3840486e1": "meji#2669",
  "0x1689479B118470eE6401FDa07340F89EE4De17B5": "xoliday girl {313}#9196",
  "0xcA77380D0caa2D2EB484A4C0C92926764CA9587b": "vammac#1595",
  "0xEc8541d68a2f66690c2B45E8908Fde7E3B3e1bce": "Killam#4655",
  "0x81450b4F7B76A9E5Fe366451068166b3907B680c": "edward-ln#5167",
  "0x8Eb9Da17268EC8F66B9a3EB9f32917Db5308D11a": "cem#5290",
  "0x6b0785b831d89B71d260Fb396e328B24a0072a24": "Elrond#0133",
  "0x10C7d2273D88Fe1E26D7b92fcBfB539B9E6557f6": "Choang#9999",
  "0xeb3a4113907d38058955661faB0E81dD513761ED": "Pepa#1572",
  "0x66633Cc6B84CD127A0bb84864c1a4eA0172469A6": "ibO#9214",
  "0x26a6f0b4D99Ee65303e2304A418e493B121E17c8": "browwwn#5971",
  "0xD65A68c0e714CC0a166E56225B6409B4fA617830": "FernandoThompson#5714",

  // Batch 4 Minting
  "0xBCA1d1eD24AbD025957B8f5D12Fb15c4bCa25CDc": "Killam#4655",
  "0x45e6e809B9181ACcCb823eB642f932C3609f2c41": "Amiplexus#4071",
  "0xab59bcD21E84749F3d15deDa6ea4267E19a14f1d": "Baba G#0422",
  "0x19fd9bDbca89957F86CD39e2f166edBeEA9aEcd1": "Beyond Alpha 1",
  // "0xD6d22320371D2E67dA49cCc38275A5173E77bcF6": "Beyond Alpha 2",
  "0xc7F9acAc47e760cA5e3824630EE0D964af1f7769": "Beyond Alpha 3",
  "0x763D39179B63fE0ad612a7e7C963a6e69B2c9C95": "c4ctus#8559",
  "0x1b37C962D824E05666B190341662001290b53D16": "Can#9597",
  "0x244789fb5E4583389528EE696ADD6BaCa868e0c1": "Craaguy#9790",
  "0xD1872cc73e7681a91a01196af8D39C5B3E209455": "Doffy#8974",
  "0x9E0845F1c92A105eA2ABdC96234DAbad160c64f7": "dyno#7777",
  "0x232fe3a7c0b29a2D143fF73A5e75A2514e625cCA": "EpicHuntur#7408",
  "0xd99F500aEFaD2Dc73Ed6FA8334ddCDE1AF40E78A": "flingz#1641",
  "0xEcd9a17d1bBDdDb1EE1A0a0458958EcF4B4bA65C": "Ghost Gamer Guild 1",
  "0xf778Bed4656bfE3f7E2A2a4e2d30b8fff74db06f": "Ghost Gamer Guild 2",
  "0xdBD85D3373cE7c3954bDDeE710ED1A682b064b07": "guantabean#5022",
  "0xD7DB97c051A8877827F96508872956B11c22ccE3": "HaiMai#5505",
  "0xf32422641A767d162345CF831Cdd3b87DfeB7c7B": "jeremyberros.eth#7288",
  "0xFcEb8a974f85aCCfA6e287AbFca9c26dc4196b90": "JIA_B#2176",
  "0x83378c70B12Be0431A36416622efD2e430B04628": "JPEG_Yakuza#7878",
  "0xe5e01254AaE93DAce33a8Fa747717F4F14cbD71f": "Keiii#6564",
  "0x27eE8FA6a70a8F156008EDe28C7B8ea5F72fFdF3": "KOBRA || The Freeroll#1960",
  "0x5950f70268e6A3414DC1125EF39947902f3a2c4A": "Leon2#1569",
  "0xB6A3BCEddFDA728d3f1e6fdFc33ff649ae3F34Bf": "MBC#1767",
  "0x75a4699940DAA2859928312911F053c010f8Ca2e": "Merit Circle 1",
  "0x1D7D1f503B9991180Df8271A2D0CFbAc7e3076A3": "Merit Circle 2",
  "0x50F7CE24D2D4E1D9CcC926E86d38528395CFdbDF": "Nightwatch#4781",
  "0xD938722718F97F717297eFc904232FFB9dF919B4": "Peace | SSS | SYN#8656",
  "0xDA3963Df8e6230E7cBEb30Aa3e92b02aed96cD88": "Peter 300yo#8313",
  "0x23EAe9A5a26F550071ef8Cc86e74721F13FBEbEd": "Rel3ase#3368",
  "0xC091A0A9E4bb4F620342bdc540b8ea327760b1C5": "rendertimes#7474",
  "0x6F10073D9154967B209F5c1cf578E42cd7A51b70": "Resaang#0001",
  "0x4377E83eDb9Bb6905795802cCa6845d72c237b36": "Stankovich#7451",
  "0x9F3e77Cb89Df964003053aa5B438E5697C77F4F9": "SteeperBasket#2002",
  "0xf32Fc6A0dE35046f3ac0f179F39871764cC6Aaac": "Thanh#7686",
  "0x25129be5b96787d1De0861f9a0E8867F63735B58": "Up&Up#5508",
  "0xb7b7942525343739674223061e437fFf87399312": "Weather#4107",
  "0x4f1ba666004bbf74bFfd75DB29c52f029875f9d7": "Yu En#7495",
  "0x134F240827Dd4B047942589998a163A2A1002F1a": "zen123#9633",
  "0xE6cC5e3EBB07B5156ba3aF510B8c6cA19804d88E": "kobayashi#4343",

  // Batch 5 Minting
  "0x7B3893D8a1371B4E21c2864530033C59bDbE2590": "nikoT#1150",
  "0x9Cd3846d6C02C2982Fc074CC81d00A6C3D99C3E5": "suite_bergamasque#1920",
  "0x6b05d47649a664FED61f17eC973f3ed96147CA92": "vitalik cousin#7771",
  "0x6Ef6265b3A2866aAb07aaAD3338cd034481f9cf7": "babyBox#5555",
  "0x0cE8786E033c44B3b338F30bE9dc1fF67eBAf003": "PlebeyBob#4717",
  "0x714BC2B6f1c5a6C608Ab45311A9e731d7f48A5f4": "pirate#3175",
  "0x3cC457B4A89025f75d0d3079c423262A64F546c1": "HIGHROLLER#7529",
  "0xcb3Ca5abFC40539EF0D1747443AE0ad8114111A1": "Shedywards#0250",
  "0x1F417e8090b5c5Ab3e8197Af7f29cC637F8ee042": "mrzebaba1#9892",
  "0xdDb105Ab74fEbeca4771728b51b9472F8037F48a": "Firuvirg#3628",
  "0xea6ea758a162eFC03cb9a1Dd212893687ADe1b61": "Hate&love#1568",
  "0xeeFb851bf9299976D1D586061a494Db5bf2Ba308": "Prinz_Salim#8878",
  "0xEdf346D0B173CE18b05A8819fbC0dbe781D09cDe": "pottyroo#3397",
  "0x7cccad31d6122af044ae0825a7d5a006aa01c2f8": "Eliz61#9603",
  "0xD6d22320371D2E67dA49cCc38275A5173E77bcF6": "mr.sauce | Suiswap#8188",

  // Batch 6 minting
  "0x0762301bd516fe5d37a89c944515011be4fe1b6f": "Yummyraw#1852",
  "0xe642F9B086425a245764299B6dF6338e7De26687": "reges#1233",
  "0x6e44C59CbC18F7061D200272Bd119e263b6a08C8": "Alpha Kong | Pyposa#2065  ",
  "0x30458345938248d7ec8953d4cee9e0e202db6651": "fitfit1337#0469",
  "0xfe791B87F607AE6BE8449e64d393c91be46a1245": "man_1801#2452",
  "0x3cf484ADE8E960cb96e1e5d32b0B47E5C1215f0a": "DonaldJacks#6982",
  "0xf946CDAEcF5A15440e500E61A0c1d179A93652ad": "Il_Il | Delovoy DAO#9036",
  "0x30f4460b68c431f894b39364fe70f275d900b2fa": "Rvanan#9413",
  "0x0105c26c5d013bb32223dbb2a4Bbd98e9B0c320A": "Dr11235#9930",
  "0xff9d64bcf38fbdadcf22e89c47c2c922dd500cac": "Compound-I#5315  ",
  "0xADE7F2f901D5bF04b45A11C63b30f6f31b49B3B0": "tilled#7639  ",
  "0x73391c743b653ea9949ece4f42457b1d780d6c1d": "Dimastor#9853",
  "0x2741C7A3159F2a01a19F53Cff8972a7812CF6418": "sisielkenny#7948",
  "0xE1fA1A88C46F35d28E15b5C88Ae4BDbb5f6a6b5D": "Lehson1308#4184 ",
  "0xf87a8048Fc5dB261a39906c66524EdA556b746dF": "Tharwa#9262",
  "0xd1992817e258dDfa0B47Ae550027A0DdE1272233": "Demster#1826",
  "0x15bb1542d63e0f452541601690c93cbbade4cb2d": "Faraon#5723",
  "0x7b4Db86B9c497ef68f6D956350B35d9857154042": "Vanhouse#2017",
  "0xE1682954aE4166E784bb3eC9D901E13949A1028e": "RED#3480",
  "0xab2ea973d3abE1C223dA0C85dC1962E2Daa2064A": "pirate#3175",
  "0x65a272B03690d72BA5D496a0faB06458C5628c28": "Celtixtime 🍙 | Rocket#0127",
  "0xB7176c03739ec10c4EC8E21a64aD64B228b8A5f2": "TheReaper235#8942",
  "0x68c406dab428fdBEADC2f670E52Fd179ef3E71CF": "MrPalvy#9054",
  "0x3a2833c8975687F5969D07F58FAfCd3473B5aCd4": "Younique#2233",
  "0x12d6AaC80A3357cFA88a3af56f4f7468341c0D66": "MoonLock#4475",
  "0xcA060F5a8F84dCD713D7f775ddE1780ADd48dF68": "martinelli hernadez#2436",
  "0x1e354cbFb451A6dD73902B504f05c5C66b43A3Eb": "nnamdipremium#7161  ",
  "0x5576Bf2b676858D5E2934FccEDc01B0088Ddb3D6": "VladManz | Delovoy DAO#6970",
  "0x9e6c39f57E324fDA4D8397eE5a67E1A5c44a3f4e": "Yeshy#0315",
  "0x32ae8acfd7ff2bfeb09e819bd0479075268b3205": "DoogsZA#0147  ",
  "0xc445846fee6d6256f1add7a3326605b31a4a667c": "GreennMan#5090",
  "0xF2a477aBfb3E3eA370Ef1ece59411b5046fd966D": "binn#1825",
  "0x348Ed2fc3c2E93608666263E50b9713839E760eb": "Bobigazda#9808  ",
  "0xe2724b88E93ee76d60E72c372317daDAdA0Db826": "tabgnuh#5015",
  "0x93f9108E5b5c0DF41c7598412A3558904225AbbC": "dyno#7777",
  "0x3F624E4a0402d7aB627B7214e65b0a7C4A39Ef6E": "Trick#3571",
  "0x7CC353433Ef9dF7676Fbb39da26FA0E278107409": "Aimfaria88#1150",
  "0xAF53890720728A1907AEd7C8604Ce1CD945B853F": "💠NorthsideAbN++💠#8468  ",
  "0x132d0dEE3E54F796747850bc4C64b4341713c240": "deliamayami#3355",
  "0x9Eb3EeE59075658e70b5F1cfF88b6a2438b3eF34": "GOKU#5060",
  "0x124bcF206F1581C1D2d2a86310470825C8e65BeC": "?Lissandro#5176",
  "0x32ae2622A20C0C421a8d48B7202F6d4FE4132d11": "DeChunkz #1249  ",
  "0xa688f5c5c0dad5dbede01e8fb0a0ca4a5fc19c3c": "LovelyG#2311",
  "0x4ae146858189A053713CAF110cB602726632fe82": "Fogou#4076  ",
  "0x3945E8B38326B54612417449f86bE796Bb51F7fB": "annavie#8521",
  "0x3af0c8E44377ecBb7A4e4841658bCe227bDfbc33": "Jedida#9872",
  "0x36BE564317aC93334a11060EcC341f3Cf756E531": "Finesse Papi#7408",
  "0x16B6e0460E8E60097A8EB50CDC8E0F20C4E31f68": "Myko_Lazaah#0097",
  "0x5A0e08b97bA249506794F491065A8782f49B6F5d": "<3 Bastian <3 (Second address)",
  "0x8d26B777e4191479F24356d5a2728A7FAC1DFb51": "UchihaMadara#2920",
  "0x9a18CF73f7117415d34a3cD321863757F5F82F31": "Coolguy#9564",
  "0x3511748c00b6B2dD9f77c1141ceC9C0BD7ADA5bE": "tofake",
  "0x17871C34Ac466189a2dFDb44530F0b41f870fca2": "AlexMasme#9997",
  "0x0aBDcDBE393b7D513914f198aCfeeC4BF7F24CBD": "MavisKeats#7240",
  "0x610451997dE363425E5db3F366535c6E2165d8aa": "doquang89#4580",
  "0x839876Fcf8733b41E3327172de7E2B16feed31E4": "potato#0288",
  "0x8b7F6673fA7598B061a1bEfdB2869Fadad1C87D0": "BroZhen#4058",
  "0x9617Cc14f9945915Eebe204c076de6c47B296DE4": "RyGameNico",
  "0x682AA10aE360Dd84AFF56E121D77bD6BE0ca04f3": "Casey",
  "0x8226Db091d6C976a1a2df9F2B10e6EbC1792b828": "Gametofu#8856",
  "0x2783117221A0C5cFE0C93E85f856d665ec73A067": "saobang#8302",
  "0x6de9E6Be2C0EAC7F143b1f86623F3218924D8707": "kvitkatania#3013",
  "0x4Fd49e5E34ABb59Cbf58efC44dB5dE1129a5E661": "hung01295#3780",
  "0x2C57372149b9e426F091dc01A52734E68f6fC71c": "Nachtigall#7126",

  // Icon Passes
  "0x45CA84fDd869beB43de588178634d695c9Fc113b": "soros_defi#8249",
  "0xD207180a5D36FEF7363f71c79726817aec8938b5": "Jchen419#4967",
  "0x4148CFf4A84DD59b115CC0a34d381b07D4EC27a5": "YG94#6134",
  "0x050eCA8247Ad00f2c617CC962A443fB3C646A608": "koala009#2087",
  "0xA75C1b14246B5aCEAd56Ff8cb37c41E1431dfE90": "djmounir#7722",
  "0x483576fcddfe299d21f7cf05b0d796c1eb11944e": "Sinny#5975",
  "0xA7bC5704e717b7B4883e23b84973d850e172f05E": "jben#6015",
  "0x9e5fE6c4eae3489Cf835b51A19a573b03249c1C0": "Rob R#5622",
  "0x758698d7d90D3dc87B498a549fc46bd4384FEd46": "Goobs#9626",
  "0x0Ca3f19cA4d08CAF4207D1FfB77cfBb59A6db854": "bsun#2032",
  "0xF541c9B367C595eC56318291a8872DFCdA0606b0": "worstatthisgame#7869",
  "0x904B01d2c1eacAFF8E6Ef24D4c71616590C0C5D4": "crunkybiscuit#0147",
  "0x0Ace42872f46753435994fD2F9191a28961182fc": "TechPorkChop#0516",
  "0x8DD3ad0844f8EF82BdFD00074da409820Feb213A": "roy#7602",
  "0xfcDa85C0E27bF19E7d6b5BAE76C53aFA1A1fB5d7": "Maz#1703",
  "0xAC537E290029B02D318C6F437247B33587C1bb9B": "Anchor#4728",
  "0x283Ec3513Ac175600188d343f0cAA28Bf472d995": "Will Robinson | Alliance#0001",
  "0xee79Ac62C52aDB6DEa8A43ecd3a290Dd644a8d3A": "bymyname#3735 ",
  "0xa5fdD1bD73B58840EbbD98e05DbCb813702b0915": "thisismyusrname",
  "0xa9a8BF55F609C9c4e6c74FDae57ecdD35AA6B8DB": "slowtwitch",

  // Batch 7 minting
  "0xeCeCe2325082ef5c6B31Acd0f348240346BC4267":	"0xraze",
  "0x102C5E7A30A27D0Ab7fE10063DF5cDCd465B05c8":	"tsukokawaii",
  "0xf88006636611e16122D94055568d8C4f4E0C85FF":	"0xmandelbrot",
  "0x753904c13c18F45670e9718943C975C0aa97a884":	"b_cryptonft",
  "0x4B70D9edfb7B2795460C215E37E74C32b10271c5":	"cagouzi",
  "0x9236A26fC03f26f7EEf584640c248B6C3fAB0745":	"camr_n",
  "0xF9538648f7EbC9ef59db9D3eEB17594b4B4cFEDa":	"Connie#5081",
  "0x7b6E11FFA5916c49B06921427C2BC74953541870":	"dirkakafatninja",
  "0x1830c41eeDEA4F101688f1aa6b2C2eF9370AD48D":	"kryptobkk",
  "0x28c1fA942d9fF0D8939f5c3AfCb270A5a8Daad76":	"rokkymeta",
  "0x9e29032be4F0CC085D75A41D1F7C53f62A1aAF0d":	"rosalynunderdown",
  "0xCD1204cAA34b7ACbe2CF52511930F1E21B5edd29":	"seesihking",

  // Batch 8 minting
  "0x489cD17670889498F22790981e46F2Dbfb0F5E04": "snatcha",
  "0xE3D86c406d4239b1E36D132196c11A840212eE59": "nodovod",
  "0x1C9842b445D5C6FcFAC4cf6A19411Ab29a4cC612": "_namasaki_",
  "0xA8Ef6dee123Dd9835Ef21347c27009b6Be503C5D": "kellquan",
  "0x5ac36a016b6A8a7025bdd65506fcD6322F02B69F": "phuongthao0512#7914",
  "0x49d1ad89adb60241bf2cc003fe57bcfd4d3cfd95": "dnlvskey",
  "0x0Cf66F0560357cE6D27B4a5c0367fb1b406f466b": "scottydk",
  "0x9f62A4232601d63A1FC819A7Ac49D698faEB3eD0": "0xconmunismMike#9200",
  "0x95a9B2250c006Be208c0EA07642E40056De888D4": "tofu2xxx",
  "0xE9FE2358E0c6b70EDb2944ec6ed5c82e8F6bAAf6": "Payton K | Wolves DAO",
  "0xb48eD686918f33e4cC2e71c530FF2DfBFc7d5634": "yimu#0735",
  "0x7862252A244E881950A8bb429C97696Aa327c140": "longsix",
  "0xf2892d847DCEf110b002C05b8F7555A664F4C23e": "norskfrugt_77039",
  "0xFBf4Ac7Ba1EE19846c8DE4032C36067A63E2B6b7": "kisss_lina",
  "0x72DbAf25cdd78ef7800De9c19fC375547583E16f": "lieonidas"
}

const discordNames = {}
Object.keys(discordMappings).forEach((address) => {
  discordNames[address.toLowerCase()] = discordMappings[address]
})

export {
  discordNames
}