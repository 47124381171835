import StatisticDisplay from "../../../generic-components/dashboard/statistic-display/StatisticDisplay"
import { getStatisticColor } from "../../../../helpers/user-behaviour-tracker/dashboardHelpers"

const PlaygroundIndividualStatistic = ({ selectedPlayer }) => {
  const STATS = [
    { label: "Matches", stat: selectedPlayer.totalMatches },
    { label: "Wins", stat: selectedPlayer.totalWins },
    { label: "Losses", stat: selectedPlayer.totalLosses },
    { label: "Win %", stat: `${selectedPlayer.winPercentage}%` }
  ]

  return (
    <div className="playground-tracker__statistics-container">
      {
        STATS.map(({ label, stat }, idx) => {
          return (
            <StatisticDisplay 
              key={`playground-individual-statistic--${idx}`}
              label={label} 
              stat={stat} 
              color={getStatisticColor(idx)}/>
          )
        })
      }
    </div>
  )
}

export default PlaygroundIndividualStatistic
