import React, { useEffect, useState } from "react"
import PlaygroundTotalSidePanel from "./playground-side-panels/playground-total-side-panel/PlaygroundTotalSidePanel"
import PlaygroundTotalDashboard from "./playground-dashboards/playground-total-dashboard/PlaygroundTotalDashboard"
import PlaygroundIndividualDashboard from "./playground-dashboards/playground-individual-dashboard/PlaygroundIndividualDashboard"
import PlaygroundPlayerSidePanel from "./playground-side-panels/playground-player-side-panel/PlaygroundPlayerSidePanel"
import NavigationPanel from "../generic-components/dashboard/navigation/navigation-panel/NavigationPanel"
import Dropdown from "../generic-components/dropdown/Dropdown"
import NavigationLink from "../generic-components/dashboard/navigation/navigation-link/NavigationLink"
import DashboardContainer from "../generic-components/dashboard/dashboard-container/DashboardContainer"
import ConfigurationPanel from "../generic-components/dashboard/configuration/configuration-panel/ConfigurationPanel"
import { FaListAlt } from 'react-icons/fa'
import { BsPersonFill } from 'react-icons/bs'
import { getPlaygroundData } from "../../helpers/backend/get-requests/playground-data"
import { camelCaseToRegular } from "../../helpers/user-behaviour-tracker/dashboardHelpers"
import discordMapping from './playground-discord-data'
import "./playground-tracker.css"

const INITIAL_FILTERS = {
  opponent: [],
  stageName: [],
  element: [],
  winner: [],
  weight: [],
  onlyKnockoutWins: false,
  beastmode: {
    stageName: [],
    element: [],
    weight: []
  }
}

const PlaygroundTracker = () => {
  const [playgroundRound, setPlaygroundRound] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedPlayer, setSelectedPlayer] = useState()
  const [totalAggregate, setTotalAggregate]  = useState({})
  const [showSidePanel, setShowSidePanel] = useState(false)
  const [filter, setFilter] = useState(INITIAL_FILTERS)
  const [matchData, setMatchData] = useState([])
  const [playerData, setPlayerData] = useState([])
  const [tableData, setTableData] = useState([])
  const [queryString, setQueryString] = useState("")
  const [currentTab, setCurrentTab] = useState("playground")

  const PLAYGROUND_ROUNDS = [
    { label: "All rounds", value: undefined },
    { label: "Round 1", value: 1 },
    { label: "Round 2", value: 2 },
    { label: "Round 3", value: 3 },
    { label: "Round 4", value: 4 }
  ]

  const TABS = {
    playground: { label: "Playground", tab: "playground", Icon: FaListAlt }, 
    player: { label: "Player", tab: "player", Icon: BsPersonFill }, 
  }

  const PANEL_TITLE = {
    playground: "Filter",
    player: "Visualize"
  }

  const initializeDashboard = () => {
    setIsLoading(true)
    getPlaygroundData(`roundNumber=${playgroundRound}`).then(({playgroundData})=>{
      setMatchData(playgroundData)
      const playersData = processMatchData(playgroundData)
      setTotalAggregate(calculateTotals(playgroundData, playersData))
      setIsLoading(false)
    })
  }

  const calculateTotals = (playgroundData, playersData) => {
    const aggregate = calculateData(playgroundData)
    aggregate.totalPlayers = playersData.length
    aggregate.totalBeastmodeBeaten = playersData.filter(player => {
      return player.highestDifficultyDefeated === "Beast Mode"
    }).length

    return aggregate
  }

  const processMatchData = (playgroundData, updateTable = true, knockoutWinsOnly = false) => {
    const aggregatedPlayerData = playgroundData.reduce((totalResult, matchData) => {
      if (totalResult[matchData.identifier]) {
        totalResult[matchData.identifier].push(matchData)
      }
      else {
        totalResult[matchData.identifier] = [matchData]
      }

      return totalResult
    }, {})

    const playerDataToProcess = {...aggregatedPlayerData}
    const playerData = []

    for (let player in playerDataToProcess) {
      playerDataToProcess[player] = calculateData(playerDataToProcess[player], knockoutWinsOnly)
      playerDataToProcess[player].identifier = player
      playerDataToProcess[player].discord = discordMapping[player]
      playerData.push(playerDataToProcess[player])
    }

    if (updateTable === true) {
      setPlayerData(playerData)
    }

    return playerData
  }

  const calculateData = (matchData, knockoutWinsOnly = false) => {
    const totalStats = {
      data: matchData,
      totalWins: 0,
      totalLosses: 0,
      winPercentage: 0,
      opponentTotal: {},
      stockDifference: {},
      elementTotal: {},
      weightTotal: {},
      stageNameTotal: {},
      highestDifficultyDefeated: "None",
      highestDifficultyKnockedout: "None"
    }

    totalStats.totalMatches = totalStats.data.length
    totalStats.data.forEach(match => {
      const wonMatch = (
        knockoutWinsOnly ? 
        match.winner === 0 && match.stocks.opponent === 0 : 
        match.winner === 0
      )

      if (match.winner === 1) {
        totalStats.totalLosses += 1
      } 
      else if (wonMatch) {
        totalStats.totalWins += 1

        const { highestDifficultyDefeated } = totalStats
        if (
          getOpponentDifficulty(highestDifficultyDefeated) < getOpponentDifficulty(match.opponent)
        ) {
          totalStats.highestDifficultyDefeated = match.opponent
        }

        if (
          getOpponentDifficulty(highestDifficultyDefeated) < getOpponentDifficulty(match.opponent)
        ) {
          totalStats.highestDifficultyDefeated = match.opponent
        }
      }

      accumulate(totalStats.opponentTotal, match.opponent, match, knockoutWinsOnly)
      accumulate(totalStats.elementTotal, match.element, match, knockoutWinsOnly)
      accumulate(
        totalStats.stockDifference, 
        match.stocks.opponent - match.stocks.you, 
        match, 
        knockoutWinsOnly
      )
      accumulate(totalStats.weightTotal, getWeight(match.weight.you), match, knockoutWinsOnly)
      accumulate(totalStats.stageNameTotal, match.stageName, match, knockoutWinsOnly)
    })
    
    totalStats.winPercentage = Math.round(totalStats.totalWins / totalStats.totalMatches * 100)

    totalStats.beatenWeights = 0
    totalStats.beatenStages = 0

    for (let weight of BEAST_MODE_FILTERS.weight) {
      if (
        totalStats.weightTotal[weight] && 
        totalStats.weightTotal[weight].highestDifficultyDefeated === "Beast Mode"
      ) {
        totalStats.beatenWeights += 1
      }
    }

    for (let stage of BEAST_MODE_FILTERS.stageName) {
      if (
        totalStats.stageNameTotal[stage] && 
        totalStats.stageNameTotal[stage].highestDifficultyDefeated === "Beast Mode"
      ) {
        totalStats.beatenStages += 1
      }
    }

    totalStats.beatenWeightsAndStages = totalStats.beatenWeights + totalStats.beatenStages
    totalStats.beatenAllWeights = totalStats.beatenWeights === BEAST_MODE_FILTERS.weight.length
    totalStats.beatenAllStages = totalStats.beatenStages === BEAST_MODE_FILTERS.stageName.length
    totalStats.beatenAllStagesAndWeights = (
      totalStats.beatenAllWeights && 
      totalStats.beatenAllStages
    )

    return totalStats
  }

  const accumulate = (total, property, match, knockoutWinsOnly = false) => {
    if (property === undefined) return total

    const wonMatch = (
      knockoutWinsOnly ? 
      match.winner === 0 && match.stocks.opponent === 0 : 
      match.winner === 0
    )
    const lostMatch = match.winner === 1
    
    if (total[property] === undefined) {
      total[property] = {
        totalWins: 0,
        totalLosses: 0,
        winPercentage: 0,
        totalMatches: 0,
        highestDifficultyDefeated: "None"
      }
    }

    total[property].totalWins += wonMatch ? 1 : 0
    total[property].totalLosses += lostMatch ? 1 : 0
    total[property].totalMatches += 1
    total[property].winPercentage = Math.round(
      total[property].totalWins / total[property].totalMatches * 100
    )

    const { highestDifficultyDefeated } = total[property]
    if (
      wonMatch &&
      getOpponentDifficulty(highestDifficultyDefeated) < getOpponentDifficulty(match.opponent)
    ) {
      total[property].highestDifficultyDefeated = match.opponent
    }
    
    return total
  }

  const OPPONENT_MAP = {
    "Beast Mode": 4,
    "Strategic": 3,
    "Aggressive": 2,
    "Dummy": 1,
    "None": 0
  }

  const getWeight = (weight) => {
    let weightClass;

    if (weight < 80) {
      weightClass = "light"
    }
    else if (weight === 80) {
      weightClass = "medium"
    }
    else {
      weightClass = "heavy"
    }

    return weightClass
  }

  const getWinner = (winner) => {
    let matchStatus;

    if (winner === 0) {
      matchStatus = "won"
    }
    else if (winner === 1) {
      matchStatus = "lost"
    } 
    else if (winner === -2) {
      matchStatus = "tie"
    }

    return matchStatus
  }

  const getOpponentDifficulty = (opponent) => {
    return OPPONENT_MAP[opponent]
  }

  const FILTER_PROPERTIES = {
    opponent: ["Dummy", "Aggressive", "Strategic", "Beast Mode"],
    stageName: ["shaolin", "castle", "colosseum"],
    element: ["fire", "water", "electric"],
    weight: ["light", "medium", "heavy"],
    winner: ["won", "lost", "tie"]
  }

  const BEAST_MODE_FILTERS = {
    stageName: ["shaolin", "castle", "colosseum"],
    element: ["fire", "water", "electric"],
    weight: ["light", "medium", "heavy"]
  }

  const filterplayerData = () => {
    let filteredMatches = [...matchData]

    //regular filters
    Object.keys(FILTER_PROPERTIES).forEach(property => {
      if (filter[property].length > 0) {
        const validFilterValues = {}
        filter[property].forEach(value => validFilterValues[value] = true)

        if (property === "weight") {
          filteredMatches = filteredMatches.filter(match => {
            return validFilterValues[getWeight(match[property].you)] === true
          })
        } 
        else if (property === "winner") {
          filteredMatches = filteredMatches.filter(match => {
            return validFilterValues[getWinner(match[property])] === true
          })
        }
        else {
          filteredMatches = filteredMatches.filter(match => {
            return validFilterValues[match[property]] === true
          })
        }
      }
    })

    //beast mode filters
    let playerData = processMatchData(filteredMatches, false, filter.onlyKnockoutWins)

    Object.keys(BEAST_MODE_FILTERS).forEach(filterProperty => {
      if (filter.beastmode[filterProperty].length > 0) {
        filter.beastmode[filterProperty].forEach(value => {
          playerData = playerData.filter(player => {
            return (
              player[`${filterProperty}Total`][value] && 
              player[`${filterProperty}Total`][value].highestDifficultyDefeated === "Beast Mode"
            )
          })
        })
      }
    })

    setPlayerData(playerData)
  }

  const clearFilters = () => {
    setFilter({
      opponent: [],
      stageName: [],
      element: [],
      winner: [],
      weight: [],
      onlyKnockoutWins: false,
      beastmode: {
        stageName: [],
        element: [],
        weight: []
      }
    })
  }

  const filterByQuery = () => {
    if (queryString.length === 0) {
      setTableData(playerData)
      return;
    }
    else {
      const filters = queryString.split(",").map(filter => filter.trim().toLowerCase())
      let filteredData = [...playerData]
          
      filteredData = filteredData.filter(player => {
        for (let searchTerm of filters) {
          if (
            (player.discord && player.discord.toLocaleLowerCase().includes(searchTerm)) || 
            player.identifier.toLocaleLowerCase().includes(searchTerm)
          ) {
            return true
          }
        }

        return false
      })

      setTableData(filteredData)
    }
  }

  useEffect(() => {
    filterplayerData()
  }, [filter, matchData])

  useEffect(() => {
    filterByQuery()
  }, [queryString, playerData])

  useEffect(() => {
    initializeDashboard()
  }, [playgroundRound])

  return (
    <div className="playground-tracker__container">
      <NavigationPanel>
        <Dropdown options={PLAYGROUND_ROUNDS} 
          onSelect={round => setPlaygroundRound(round.value)} 
          selected={playgroundRound}
          id="playground-tracker__round-select"/>
          { Object.keys(TABS).map((tab) => {
            const { label, Icon } = TABS[tab]
            return (
              <NavigationLink onClick={() => setCurrentTab(tab)}
                label={label}
                Icon={Icon}
                active={currentTab === tab}
                disabled={
                  (currentTab !== tab && isLoading) || 
                  (tab === "player" && !selectedPlayer)
                }
                key={`playground-tracker__navigation-tab-${tab}`}/>
            )
          }) }
      </NavigationPanel>
      <DashboardContainer isRightPanelOpen={showSidePanel} 
        title={
          currentTab === "player" ? 
          discordMapping[selectedPlayer.identifier] ?? selectedPlayer.identifier : 
          `${camelCaseToRegular(currentTab)} Dashboard`
        }>
          { 
            currentTab === "playground" ? 
            <PlaygroundTotalDashboard
              isLoading={isLoading}
              selectedPlayer={selectedPlayer}
              setSelectedPlayer={setSelectedPlayer}
              setShowSidePanel={setShowSidePanel}
              totalAggregate={totalAggregate}
              tableData={tableData}
              playerData={playerData}/> : 
            <PlaygroundIndividualDashboard
              isLoading={isLoading} 
              selectedPlayer={selectedPlayer}
              setShowSidePanel={setShowSidePanel}/>
          } 
      </DashboardContainer>
      <ConfigurationPanel 
        open={showSidePanel} 
        onClose={() => setShowSidePanel(false)}
        title={PANEL_TITLE[currentTab]}>
        { 
          currentTab === "playground" ? 
          <PlaygroundTotalSidePanel 
            query={queryString}
            setQuery={setQueryString}
            filter={filter}
            setFilter={setFilter}
            clearFilters={clearFilters} 
            FILTER_PROPERTIES={FILTER_PROPERTIES}
            BEASTMODE_FILTER_PROPERTIES={BEAST_MODE_FILTERS}/> :
          <PlaygroundPlayerSidePanel selectedPlayer={selectedPlayer}/>
        }
      </ConfigurationPanel>
    </div>
  )
}

export default PlaygroundTracker
