import React, { useState } from 'react'
import PlaygroundPlayerChart from '../../playground-player-chart/PlaygroundPlayerChart'
import PlaygroundIndividualStatistic from '../../playground-statistics/playground-individual-statistics/PlaygroundIndividualStatistics'
import PlaygroundDetailedStatistics from '../../playground-statistics/playground-detailed-statistic/PlaygroundDetailedStatistics'
import DashboardSectionHeader from '../../../generic-components/dashboard/dashboard-section-header/DashboardSectionHeader'
import Loader from '../../../generic-components/loader/Loader'
import Dropdown from '../../../generic-components/dropdown/Dropdown'
import { GrConfigure } from 'react-icons/gr'
import { camelCaseToRegular } from '../../../../helpers/user-behaviour-tracker/dashboardHelpers'

import "./playground-individual-dashboard.css"


const PlaygroundIndividualDashboard = ({ isLoading, selectedPlayer, setShowSidePanel }) => {
  const [selectedProperty, setSelectedProperty] = useState("opponent")
  const PROPERTIES = ["opponent"]

  const getPropertyAvailable = () => {
    return PROPERTIES.map(property => {
      return { value: property, label: camelCaseToRegular(property) }
    })
  }

  return (
    <>
      <DashboardSectionHeader title="Analytics"/>
      { 
        !isLoading && selectedPlayer ?
        <PlaygroundIndividualStatistic selectedPlayer={selectedPlayer}/> :
        <Loader size={36}/>
      }
      <DashboardSectionHeader title="Match Summary" className="playground-tracker__summary-header">
        <Dropdown options={getPropertyAvailable()}
          selected={selectedProperty}
          width="15vh"
          id="playground-tracker__summary-dropdown"
          onSelect={option => setSelectedProperty(option.value)}/>
      </DashboardSectionHeader>
      { 
        !isLoading && selectedPlayer ?
        <PlaygroundDetailedStatistics selectedPlayer={selectedPlayer} 
          property={selectedProperty}/> :
        <Loader size={36}/>
      }
      <DashboardSectionHeader title="Visuals">
        <div className="playground-tracker__side-panel-toggle"
          onClick={()=> setShowSidePanel(true)}>
          <GrConfigure size={"1.5vh"}/>
        </div>
      </DashboardSectionHeader>
      <PlaygroundPlayerChart selectedPlayer={selectedPlayer}/>
    </>
  )
}

export default PlaygroundIndividualDashboard