import React, { useEffect, useRef } from "react"
import Tooltip from "../../../generic-components/tooltip/Tooltip"
import ScatterPlot from "../../../../helpers/charting/ScatterPlot"
import * as _ from "lodash"

const Scatter = ({ data, config, id, className }) => {
  const chartRef = useRef()

  const getConfiguration = () => {
    const defaultConfig =  {
      margin: { top: 15, left: 15 },
      labelPadding: { x: 30, y: 40 },
      renderAxis: true,
      toolTip: {
        showTooltip: true,
        id: `${id}-tooltip`
      }
    }

    return _.merge({}, defaultConfig, config)
  }

  useEffect(() => {
    chartRef.current = new ScatterPlot(
      `#${id}`,
      data,
      getConfiguration()
    )
  }, [])

  useEffect(() => {
    if (chartRef && chartRef.current && data && data.length > 0) {
      chartRef.current.updateConfiguration(getConfiguration())
      chartRef.current.updateData(data)
    }
  }, [data, config])

  return (
    <>
      <div id={id} className={className}></div>
      <Tooltip tooltipContent={""} tooltipId={`${id}-tooltip`}/>
    </>
  )
}

export default Scatter