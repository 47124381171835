import React, { useEffect, useState } from "react"
import allSmartContractFunctions from "../../../helpers/allSmartContractFunctions"
import ContractComponent from "../contract-component/ContractComponent"
import ContractToggle from "../../contract-toggle/ContractToggle";
import { filterSmartContractFunctions } from "../../../helpers/dashboard-metadata/contract-functions";
import './generic-dashboard.css'
import { getInputMapping } from "../../../helpers/dashboard-metadata/input-types";
import { useAtom, atom } from 'jotai'
import { contractsAtom } from "../../app-data-components/BlockchainData"

const startingContract = "neuron"

const GenericDashboard = ({ dashboardName }) => {
  const [toggledContract, setToggleContract] = useState(startingContract)
  const [contractProps, setContractProps] = useState(undefined)
  const contractsObj = useAtom(contractsAtom)[0]

  useEffect(() => {
    if (contractsObj !== undefined) {
      const filteredFunctions = filterSmartContractFunctions(
        allSmartContractFunctions,
        toggledContract,
        dashboardName
      )
      const inputTypesObject = getInputMapping(toggledContract, filteredFunctions)
  
      setContractProps({
        name: toggledContract,
        toggledContract: toggledContract,
        contract: contractsObj?.[toggledContract],
        functions: filteredFunctions,
        inputs: inputTypesObject,
        state: {},
      })
    }
  }, [
    toggledContract,
    dashboardName
  ])

  useEffect(() => {
    if (contractsObj.fighterFarm !== undefined) {
      const filteredFunctions = filterSmartContractFunctions(
        allSmartContractFunctions,
        toggledContract,
        dashboardName
      )
      const inputTypesObject = getInputMapping(toggledContract, filteredFunctions)
      console.log(contractsObj?.[toggledContract])
      setContractProps({
        name: toggledContract,
        toggledContract: toggledContract,
        contract: contractsObj?.[toggledContract],
        functions: filteredFunctions,
        inputs: inputTypesObject,
        state: {},
      })
    } else {
      console.log("contracts are undefined")
    }
  }, [contractsObj])

  


  return (
    <>
      <div id="user-dashboard__container">
        {
          contractsObj !== undefined &&
          contractProps !== undefined &&
          <>
            <ContractToggle 
              contracts={contractsObj} 
              toggledContract={toggledContract}
              setToggleContract={setToggleContract} />
            <ContractComponent 
              setContractProps={setContractProps} 
              toggledContract={toggledContract}
              contractProps={contractProps}
              />
          </>
        }
      </div>
    </>
  )
}

export default GenericDashboard