import Main from "./components/index"
import Store from "./Store";
import { WagmiConfig, createClient, configureChains } from 'wagmi'
import { infuraProvider } from 'wagmi/providers/infura'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { arbitrum, arbitrumGoerli } from 'wagmi/chains'
import './index.css'
require("dotenv").config()

const { provider, webSocketProvider } = configureChains(
  [arbitrum, arbitrumGoerli],
  [alchemyProvider({
    apiKey:  process.env.REACT_APP_ALCHEMY_API_KEY,
    priority: 0,
   })
 ],
 [infuraProvider({ 
   apiKey: process.env.REACT_APP_INFURA_API_KEY,
   priority: 0
 })
 ],
)

const client = createClient({
  autoConnect: true,
  provider,
  connectors: [
    new MetaMaskConnector({
      chains: [arbitrumGoerli, arbitrum],
    }),
    new CoinbaseWalletConnector({
      chains: [arbitrumGoerli, arbitrum],
      options: {
        appName: 'Ai Arena',
        jsonRpcUrl: process.env.REACT_APP_ALCHEMY_RPC,
      },
    })
  ],
  webSocketProvider,
})

function App() {
  return (
    <WagmiConfig client={client}>
      <Store>
        <Main></Main>
      </Store>
    </WagmiConfig>
  );
}

export default App;
