import React, { useEffect, useRef } from "react"
import Tooltip from "../../tooltip/Tooltip"
import BarChart from "../../../../helpers/charting/BarChart"
import { CHART_COLORS } from "../../../../helpers/user-behaviour-tracker/dashboardHelpers"
import * as d3 from "d3"
import * as _ from "lodash"

const Bar = ({ data, config, id, className }) => {
  const chartRef = useRef()

  const getConfiguration = () => {
    const defaultConfig = {
      margin: { top: 15, left: 15 },
      labelPadding: { x: 20, y: 70 },
      scale: { 
        x: {
          scale: d3.scaleBand(),
          paddingInner: 0.2,
          ticks: 0,
          tickSize: 0,
          tickFormat: ""
        }, 
        y: {
          scale: d3.scaleLinear()
        }
      },
      renderAxis: true,
      bar: {
        fill: CHART_COLORS[2]
      },
      toolTip: {
        showTooltip: true,
        id: `${id}-tooltip`
      }
    }

    return _.merge({}, defaultConfig, config)
  }

  useEffect(() => {
    chartRef.current = new BarChart(
      `#${id}`,
      data,
      getConfiguration()
    )
  }, [])

  useEffect(() => {
    if (chartRef && chartRef.current && data && data.length > 0) {
      chartRef.current.updateConfiguration(getConfiguration())
      chartRef.current.updateData(data)
    }
  }, [data, config])

  return (
    <>
      <div id={id} className={className}></div>
      <Tooltip tooltipContent={""} tooltipId={`${id}-tooltip`}/>
    </>
  )
}

export default Bar