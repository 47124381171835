export const updateTimeseriesData = (config, aggregator, tab, setTimeseriesData) => {
  const { sharedConfig} = config
  let sourceData = (
    sharedConfig.showTotalAggregate ? 
    aggregator.timeseriesData[config[`${tab}Config`].aggregateProperty] : 
    aggregator.userData[sharedConfig.selectedAddress]
      .timeseries[config[`${tab}Config`].aggregateProperty]
  )

  sourceData = (
    sharedConfig.shouldAggregateDaily ?
    aggregator.downsampleDaily(sourceData) : 
    sourceData
  )

  const newTimeseriesData = Object.keys(sourceData)
    .map(date => { return { x: new Date(date), y: sourceData[date] }})
    .sort((data1, data2) => data1.x - data2.x)

  setTimeseriesData([
    { 
      metadata: camelCaseToRegular(config[`${tab}Config`].aggregateProperty), 
      data: newTimeseriesData 
    }
  ])
}

export const shouldUpdateTimeseries = (config, tab) => {
  if (
    !config.sharedConfig.showTotalAggregate && 
    config.sharedConfig.selectedAddress.length <= 0
  ) {
    return false
  }

  return config[`${tab}Config`] && config[`${tab}Config`].aggregateProperty !== ""
}

export const getTimeseriesDuration = (timeseries) => {
  const dateMap = {}
  let daysCount = 0
  Object.keys(timeseries).map(timeseries => {
    const date = new Date(timeseries)
    const hashKey = `${date.getFullYear()}/${date.getMonth()}/${date.getDate()}`
    if (dateMap[hashKey]) {
      return
    }

    dateMap[hashKey] = true
    daysCount++
  })

  return daysCount
}

export const getMeanMedian = (statistic, getter = d => d) => {
  return {
    mean: getter(statistic.mean),
    median: getter(statistic.median)
  }
}

export const getMeanMedianCumulative = (statistic, getter = d => d) => {
  return {
    cumulative: getter(statistic.cumulative),
    ...getMeanMedian(statistic, getter)
  }
}

export const getStatisticsDisplay = (statistics, statisticSelected) => {
  return statistics.map(({ metric, statistics, skipStatisticSelect }) => {
      return {
        metric,
        statistic: skipStatisticSelect ? statistics : statistics[statisticSelected]
      }
    })
}

const COLOR_PALETTE = [
  "#FEF6E3",
  "#E6EDFE",
  "#DBF3F1",
  "#FAE9E4"
]

export const CHART_COLORS = [
  "#ffd700",
  "#ffb14e",
  "#fa8775",
  "#ea5f94",
  "#cd34b5",
  "#9d02d7",
  "#0000ff"
]

export const getStatisticColor = (index) => {
  return COLOR_PALETTE[index % COLOR_PALETTE.length]
}

export const camelCaseToRegular = (str) => {
  return str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, function(str){ return str.toUpperCase(); })
}