import React from 'react'
import "./statistic-display.css"

const StatisticDisplay = ({ label, stat, color}) => {
  return (
    <div className="statistic-display__container" style={{ background: color}}>
      <div className="statistic-display__label">
        {label}
      </div>
      <div className="statistic-display__stat">
        {stat}
      </div>
    </div>
  )
}

export default StatisticDisplay