import { discordNames } from "../../../components/mintpass-tracker/discord-data"
import mintpassContractData from "../../../abis/AAMintPass.json"

const beta1WinnersWallets = {
  "0x79d899379844D35A1A1F5D51D3185dd821f44Dc1": { arx: 2, dendroid: 2 }, // Jelo#9731
  "0x52be3580601524652978648E872D0aA448aFC928": { arx: 0, dendroid: 1 }, // MrDQ#0036 (NEED TO SEND MINTPASS TO DQ)
  "0x160aCC42b7b274c5250c6b79950aa56D43bfC088": { arx: 0, dendroid: 1 }, // Berlin#4051
  "0x02C07322Bfd5DBc6aa58dD1Ea55EB9BBfFfdd11b": { arx: 1, dendroid: 0 }, // italian_josh#3309
  "0x667eEb11e3AdFC2f3A8e30C2787f74A2760eCCFe": { arx: 1, dendroid: 0 }, // uzura#4238
  "0xebc212c8f416b6c2e8a252c83A56A3A955Dc6402": { arx: 1, dendroid: 0 }, // Daniconelx#0272
  "0xEA6D82C5aC15ca958fba6F1793a6567bFA0dFd7A": { arx: 1, dendroid: 0 }, // sangvin#7298
  "0x12b0E1B718b33d16512BD7A81C3680fAEe3C7e3f": { arx: 1, dendroid: 0 }, // Vlad B#3211
  "0xe8179007766746285625c5C73B0365716CF4Acc6": { arx: 1, dendroid: 0 }, // Frozensense#5833
  "0xFb3a60e22c71bA8730431264195e264dF240D9F4": { arx: 1, dendroid: 0 }, // LeviButler#8371
  "0xd7f52A3677d8d5499b0aFF1d4a23EC1a57192aB6": { arx: 1, dendroid: 0 }, // PatronJay | READY#0001
  "0xe6f1364e06Ec6C98D41D3d3D36512f6C68443041": { arx: 1, dendroid: 0 }, // MrPalvy#9054
  "0xe5FCFCA8519154aD9D73893E381Db99fCAFBF5af": { arx: 1, dendroid: 0 }, // gambit18#3971
  "0xb40969F60BfA246A09593099DAce2dDD543254A3": { arx: 1, dendroid: 0 }, // apix#8283
  "0xf32422641A767d162345CF831Cdd3b87DfeB7c7B": { arx: 1, dendroid: 0 }, // jeremyberros.eth#7288
  "0xCc4068c550bFA4D4c28dc69D25EE9FCd7cd03a42": { arx: 1, dendroid: 0 }, // Carliyke#3530
  "0x786Da7C483E802242ae6fd52d327E4686Bd296dA": { arx: 1, dendroid: 0 }, // kinox#1242
  "0xCEdf866187680e70296a8b9EEce22A09b4F05798": { arx: 1, dendroid: 0 }, // chompk.eth | Contribution DAO#9502
  "0x6760E6B05f205af591487A49BC047567c541edc6": { arx: 1, dendroid: 0 }, // Spaceman#6766
  "0x6b1B673E56f90e5848B022440115BC882cc40e86": { arx: 1, dendroid: 0 }, // RayYayZzz#6451
  "0xA6C2f549E3CceE748A5926059098E456D8Bb1e78": { arx: 1, dendroid: 0 }, // Bloo#7777
  "0x7057b9eC157c6dB6553D20A7B4E2fD8636d29198": { arx: 1, dendroid: 0 }, // estuardo.#0420
  "0x8F09EeF679904F64752523f95dB664039dF278e2": { arx: 1, dendroid: 0 }, // Heyyawn#1025
  "0x232fe3a7c0b29a2D143fF73A5e75A2514e625cCA": { arx: 1, dendroid: 0 }, // EpicHuntur#7408
  "0xdbc2c8d9e8B2fB44d95750DB1BE97dad4ab57005": { arx: 1, dendroid: 0 }, // TomSuper.eth#2870
  "0x5F8cfD3F2aA1CaDA3631E3b61f1d4C66eBcC2Cf2": { arx: 1, dendroid: 0 }, // El Gato#3461
  "0xfd817709270C06e096015A8fa70A0010e78bdEC2": { arx: 1, dendroid: 0 }, // therealcabrera | READY#0001
  "0x435d821Ee5b346850545cB18443ca9808A9d47D0": { arx: 1, dendroid: 0 }, // nicabar#4764
  "0xbF23365269f1B75df4a0a71A0F8B64ecbf19E71B": { arx: 1, dendroid: 0 }, // schick#3156
  "0x24176367b8cbc04057Cb90AA4Cc088cB3024613a": { arx: 1, dendroid: 0 }, // Struggler.eth#7224
  "0x28F5923B4603dD826de8f183dd5FEdf27DdAF8e9": { arx: 1, dendroid: 0 }, // morkovnyy_sok#9748
  "0x08C4d9D5fAa48837cfDd73C63De137ce88154226": { arx: 1, dendroid: 0 }, // Poloskin#8737
  "0x803F27dEaA1282653745C3abBD71Df11E758944b": { arx: 1, dendroid: 0 }, // 0xGunter#0001
  "0x77826782f13CC2CcF86556e7969344b21e7aB0cf": { arx: 1, dendroid: 0 }, // Alisa#4406
  "0xF86D5cD689E71260F0b3EAE4220dB89F9924BD13": { arx: 1, dendroid: 0 }, // Rashomon | LSP#6183
  "0xFcEb8a974f85aCCfA6e287AbFca9c26dc4196b90": { arx: 1, dendroid: 0 }, // JIA_B#2176
  "0x20e9503A6BC31765d3648b4AB67d035AA67c65A6": { arx: 1, dendroid: 0 }, // G⭕d-Level -#1412
  "0x5a6693b86d9a105A03828FF7d5DcaAB47a23d678": { arx: 1, dendroid: 0 }, // etetet123#9963
  "0x068Bda63b22aC5495FD6a19aD42DC11fe920fdc0": { arx: 1, dendroid: 0 }, // Dansrocks#2809
  "0x4ae32281f22305dF1099D94328cCC91220F8dc4d": { arx: 1, dendroid: 0 }, // Alpies#8888
  "0x4E731074C2B0199e7D391f8184D50D1f35aa4465": { arx: 1, dendroid: 0 }, // Stone&J#2403
  "0x858a23908538128786c7678653F1f8080b0a074f": { arx: 1, dendroid: 0 }, // mg#8126
  "0x5f128D272F4cAc0e2cce73bD516F4155f16f4275": { arx: 1, dendroid: 0 }, // Nermoria#3132
  "0xC83894b4d9fB103227dE2b627F51Bd0B292d5372": { arx: 1, dendroid: 0 }, // SonnyRobinson#0887
  "0xE6A5C296214830D5Ab45527b83780AeF065E3A2c": { arx: 1, dendroid: 0 }, // Ryanhere#9927
  "0x1950c54dd2031ab2033f4c4557761dd4046156a4": { arx: 1, dendroid: 0 }, // LarpyDart#2623
  "0x08D816526BdC9d077DD685Bd9FA49F58A5Ab8e48": { arx: 1, dendroid: 0 }, // Kiwi#7331
  "0x721B9541fDcb1CEE2b500c84e5dbDFAF9F909736": { arx: 1, dendroid: 0 }, // Quinnton.AI#7308
  "0x1a387793d05B98F3eda6529453D8D6FB8f08EE1C": { arx: 1, dendroid: 0 }, // GaspodeWD#7793
  "0x7D56e8ED62d12B03bDF8DBaDea4311a1386D006C": { arx: 1, dendroid: 0 }, // linyx#7120
}

const batch2Minters = {
  "0x4f35F7EC953d61D77329E2cC82D032c332F84473":	{ arx: 1, dendroid: 0 }, //	Achamaru#5094
  "0xfa9B0A6e4F4a926905F3FE27c33c09183c8C033c":	{ arx: 1, dendroid: 0 }, //	Bing.L#6493
  "0x30d26d80fF203118f141Eb096698a7A16198C157":	{ arx: 1, dendroid: 0 }, //	Cryptogirl#3496
  "0x951e7e5b5Ca4c96821e6572323dAA1b946CF208F":	{ arx: 1, dendroid: 0 }, //	DÆ°Æ¡ng QuÃ½ | Orient Dragon#5213
  "0xcF61B6de27aa0a5fef3979A16E955a251eC784d5":	{ arx: 1, dendroid: 0 }, //	Dovgy#3055
  "0x8316B461fFbE4Fd7B489363e45eB939771B0fe23":	{ arx: 1, dendroid: 0 }, //	Dread#8210
  "0xF7068AC4765DC37703fe087bA5109d6990676bf6":	{ arx: 1, dendroid: 0 }, //	Emma24#0045
  "0xF54C55FF56a120FaAc4391036f3e6b0E48e767CF":	{ arx: 1, dendroid: 0 }, //	FluffyBunnyZack#8039
  "0xef9B8Fbbf9094feAC33FeCeE8803E7477a287d4a":	{ arx: 1, dendroid: 0 }, //	Gabriel1991#2889
  "0xb9832c93b2fD9C1E99Cd44A54CcCa0A8Ec7d7FFf":	{ arx: 1, dendroid: 0 }, //	Goodluck#9107
  "0xdBD85D3373cE7c3954bDDeE710ED1A682b064b07":	{ arx: 1, dendroid: 0 }, //	guantabean#5022
  "0xC3FD6e0428f4F4f6f9669EC51B129045f9D9070F":	{ arx: 2, dendroid: 0 }, //	Hanoch#4634
  "0x5a8978B580299378c75bab85863410cCe641F2b5":	{ arx: 1, dendroid: 0 }, //	jedimastergrogu#6856
  "0x1bE2e5c277f77679888B11c9311680fe873d3a3b":	{ arx: 1, dendroid: 0 }, //	JPEGG#6666
  "0x94061E6dFF5E6474987be0D3B75975Db9b1A846C":	{ arx: 1, dendroid: 0 }, //	life goes onðŸ¦‡ðŸ”Š#7497
  "0x2Fcd965a6C86A42C87b5bf69D8b031A466b52245":	{ arx: 1, dendroid: 0 }, //	magnacarta#0416
  "0x39C4373e0A5333B27f4F11AC6BB9ea3EC5C25350":	{ arx: 1, dendroid: 0 }, //	MCG#7810
  "0xD77a6477114e3D1d34DC69eFc3a7e882e360034D":	{ arx: 1, dendroid: 0 }, //	MisterKumar#8586
  "0x50FEfB456648d7d2Ac43ea60De364E6Db4e793A3":	{ arx: 1, dendroid: 0 }, //	naive#2928
  "0xeb3a4113907d38058955661faB0E81dD513761ED":	{ arx: 1, dendroid: 0 }, //	Pepa#1572
  "0x3C52cfcC17FB344e33636212B3718f063C8278c3":	{ arx: 1, dendroid: 0 }, //	Peres#0064
  "0x3236E3df5587cFbe0d811fcE920EC92322bCEA24":	{ arx: 1, dendroid: 0 }, //	pum#5132
  "0xc9F64274F5Bc7F97F02129406eb6191D31548663":	{ arx: 1, dendroid: 0 }, //	q2thec#1971
  "0x99cdb33Df8726F1952af7f60a02e70436241d499":	{ arx: 1, dendroid: 0 }, //	tamao#0840
  "0xaA21505A90F5A5D1e40c39BD719F1b3BEF843CbC":	{ arx: 1, dendroid: 0 }, //	Ter#6631
  "0x79BaD1563F01F767E907E66fbE5EA2729374b153":	{ arx: 1, dendroid: 0 }, //	thangnd#5096
  "0x69795b791089c008970FeBefb3245C344A130E6b":	{ arx: 1, dendroid: 0 }, //	Tuohai#4079
  "0x4f1ba666004bbf74bFfd75DB29c52f029875f9d7":	{ arx: 1, dendroid: 0 }, //	Yu En#7495
  "0x1ce60c61e1D61E0a33eE446957006C42a8D12492":	{ arx: 1, dendroid: 0 } //	Zeusâš¡#5524
}

const batch3Minters = {
  "0x78c4eb74405311620d66eaCc2a4D61e1925B7E3F": { arx: 2, dendroid: 1 }, // Raze | NFT Gaming#7322
  "0xa5171632D7f9dcF9435E724ac3caE0e2bbfb636c": { arx: 1, dendroid: 1 }, // <3 Bastian <3#1140
  "0x52be3580601524652978648E872D0aA448aFC928": { arx: 0, dendroid: 1 }, // MrDQ#0036
  "0xf2A1cF1aB0462E3c353A1e34Bf74eC51fb162D08": { arx: 1, dendroid: 0 }, // JamesRic#9999
  "0x5a6693b86d9a105A03828FF7d5DcaAB47a23d678": { arx: 1, dendroid: 0 }, // etetet123#9963
  "0x72a30F3E45c0E677fDD340FEDDeFb4B61c14be98": { arx: 1, dendroid: 0 }, // Gunslinger#5764
  "0x90c4a3Ac277Fab9E56e27E17F6Aa59a2452016cb": { arx: 1, dendroid: 0 }, // Tigre93Allan#0895
  "0x4579e110E060Ab6DD0F6f73500f29BC3be8e8ccb": { arx: 1, dendroid: 0 }, // Caine#5094
  "0x667eEb11e3AdFC2f3A8e30C2787f74A2760eCCFe": { arx: 1, dendroid: 0 }, // uzura#4238
  "0xBe20B35CF9Af2201651AAEbcE65878f33768A4CD": { arx: 1, dendroid: 0 }, // kaijupepe#8264
  "0x02C07322Bfd5DBc6aa58dD1Ea55EB9BBfFfdd11b": { arx: 1, dendroid: 0 }, // italian_josh#3309
  "0x34E3f9567aee97397Ac7A002dF2ef4f30193F1A6": { arx: 1, dendroid: 0 }, // Dumb#4786
  "0x79d899379844D35A1A1F5D51D3185dd821f44Dc1": { arx: 1, dendroid: 0 }, // Jelo#9731
  "0x5Ea81a84FC14a73c7A9b18b72fF41a270C2441CF": { arx: 1, dendroid: 0 }, // Carlan | READY#3815
  "0x73fAFB1E5903b717e4fc3767aDb90E973D64Ab36": { arx: 1, dendroid: 0 }, // crypto_natsu#4225
  "0xD7eBDA19d3F225d7712c10b98172A7E58E2186b3": { arx: 1, dendroid: 0 }, // CHAM#8581
  "0x3F2D732a222C9ec2b8FA3beDA4A186F84940B7C0": { arx: 1, dendroid: 0 }, // YaMi#5456
  "0xb40969F60BfA246A09593099DAce2dDD543254A3": { arx: 1, dendroid: 0 }, // apix#8283
  "0xB1c41450CB8a11A7e605e8Be5ca30A4c9C9231c9": { arx: 1, dendroid: 0 }, // Ayanami#9999
  "0x1262c0eaD0D58e04A90E1E654877c6Ddfa5C76B9": { arx: 1, dendroid: 0 }, // edick_fnd#2977
  "0xb4f2DEA715A6Ee100e79b59d49ad4f9eE37498bA": { arx: 1, dendroid: 0 }, // tpsy#4833
  "0xe6f1364e06Ec6C98D41D3d3D36512f6C68443041": { arx: 1, dendroid: 0 }, // MrPalvy#9054
  "0xA3deB012Dca06b12a7e9AE06738fCCB4D39a6324": { arx: 1, dendroid: 0 }, // noka#4588
  "0x1bE2e5c277f77679888B11c9311680fe873d3a3b": { arx: 1, dendroid: 0 }, // JPEGG#6666
  "0x21Ae38F3aD9DcDcaD127f64e532E0fD76EAeFb52": { arx: 1, dendroid: 0 }, // newoc#6399
  "0x9217d8B3a7036e507e9D04Ae1969E395ca308947": { arx: 1, dendroid: 0 }, // Huyyy#0648
  "0x338C8bA36ab46E9DF84BCe741F903D01895ACAD5": { arx: 1, dendroid: 0 }, // uEugen#2654
  "0x1a387793d05B98F3eda6529453D8D6FB8f08EE1C": { arx: 1, dendroid: 0 }, // GaspodeWD#7793
  "0x53Ad5695943033a75878ae747ee613937D213c8a": { arx: 1, dendroid: 0 }, // Phuonglee#2624
  "0x0d96AD8611A92EA434585eeA619b3831AB971cEB": { arx: 1, dendroid: 0 }, // Tairuc#2557
  "0xc93a77565EDfc2b057204Ff17C60154bA8952c8E": { arx: 1, dendroid: 0 }, // Bound II#8088
  "0x3C0c6C9670Dd6ea971fFEF7a1A77D341b8B3A4B3": { arx: 1, dendroid: 0 }, // Wale#2486
  "0x5951848719E95B92A665b044EF01C8769b024ed0": { arx: 1, dendroid: 0 }, // indigo#0666
  "0x8F09EeF679904F64752523f95dB664039dF278e2": { arx: 1, dendroid: 0 }, // Heyyawn#1025
  "0xe8179007766746285625c5C73B0365716CF4Acc6": { arx: 1, dendroid: 0 }, // Frozensense#5833
  "0x3b1b3edb62b527a134e5a7541f8231a3840486e1": { arx: 1, dendroid: 0 }, // meji#2669
  "0x1689479B118470eE6401FDa07340F89EE4De17B5": { arx: 1, dendroid: 0 }, // xoliday girl {313}#9196
  "0xcA77380D0caa2D2EB484A4C0C92926764CA9587b": { arx: 1, dendroid: 0 }, // vammac#1595
  "0xEc8541d68a2f66690c2B45E8908Fde7E3B3e1bce": { arx: 1, dendroid: 0 }, // Killam#4655
  "0x81450b4F7B76A9E5Fe366451068166b3907B680c": { arx: 1, dendroid: 0 }, // edward-ln#5167
  "0x8Eb9Da17268EC8F66B9a3EB9f32917Db5308D11a": { arx: 1, dendroid: 0 }, // cem#5290
  "0x6b0785b831d89B71d260Fb396e328B24a0072a24": { arx: 1, dendroid: 0 }, // Elrond#0133
  "0x10C7d2273D88Fe1E26D7b92fcBfB539B9E6557f6": { arx: 1, dendroid: 0 }, // Choang#9999
  "0xeb3a4113907d38058955661faB0E81dD513761ED": { arx: 1, dendroid: 0 }, // Pepa#1572
  "0x66633Cc6B84CD127A0bb84864c1a4eA0172469A6": { arx: 1, dendroid: 0 }, // ibO#9214
  "0x26a6f0b4D99Ee65303e2304A418e493B121E17c8": { arx: 1, dendroid: 0 }, // browwwn#5971
  "0xD65A68c0e714CC0a166E56225B6409B4fA617830": { arx: 1, dendroid: 0 }, // FernandoThompson#5714
}

const batch4Minters = {
  "0xBCA1d1eD24AbD025957B8f5D12Fb15c4bCa25CDc": { arx: 4, dendroid: 0 }, // Killam#4655
  "0x45e6e809B9181ACcCb823eB642f932C3609f2c41": { arx: 1, dendroid: 0 }, // Amiplexus#4071
  "0xab59bcD21E84749F3d15deDa6ea4267E19a14f1d": { arx: 1, dendroid: 0 }, // Baba G#0422
  "0x19fd9bDbca89957F86CD39e2f166edBeEA9aEcd1": { arx: 1, dendroid: 0 }, // Beyond Alpha 1
  // "0xD6d22320371D2E67dA49cCc38275A5173E77bcF6": { arx: 1, dendroid: 0 }, // Beyond Alpha 2
  "0xc7F9acAc47e760cA5e3824630EE0D964af1f7769": { arx: 1, dendroid: 0 }, // Beyond Alpha 3
  "0x763D39179B63fE0ad612a7e7C963a6e69B2c9C95": { arx: 1, dendroid: 0 }, // c4ctus#8559
  "0x1b37C962D824E05666B190341662001290b53D16": { arx: 1, dendroid: 0 }, // Can#9597
  "0x244789fb5E4583389528EE696ADD6BaCa868e0c1": { arx: 1, dendroid: 0 }, // Craaguy#9790
  "0xD1872cc73e7681a91a01196af8D39C5B3E209455": { arx: 1, dendroid: 0 }, // Doffy#8974
  "0x9E0845F1c92A105eA2ABdC96234DAbad160c64f7": { arx: 1, dendroid: 0 }, // dyno#7777
  "0x232fe3a7c0b29a2D143fF73A5e75A2514e625cCA": { arx: 1, dendroid: 0 }, // EpicHuntur#7408
  "0xd99F500aEFaD2Dc73Ed6FA8334ddCDE1AF40E78A": { arx: 1, dendroid: 0 }, // flingz#1641
  "0xEcd9a17d1bBDdDb1EE1A0a0458958EcF4B4bA65C": { arx: 1, dendroid: 0 }, // Ghost Gamer Guild 1
  "0xf778Bed4656bfE3f7E2A2a4e2d30b8fff74db06f": { arx: 1, dendroid: 0 }, // Ghost Gamer Guild 2
  "0xdBD85D3373cE7c3954bDDeE710ED1A682b064b07": { arx: 1, dendroid: 0 }, // guantabean#5022
  "0xD7DB97c051A8877827F96508872956B11c22ccE3": { arx: 1, dendroid: 0 }, // HaiMai#5505
  "0xf32422641A767d162345CF831Cdd3b87DfeB7c7B": { arx: 1, dendroid: 0 }, // jeremyberros.eth#7288
  "0xFcEb8a974f85aCCfA6e287AbFca9c26dc4196b90": { arx: 1, dendroid: 0 }, // JIA_B#2176
  "0x83378c70B12Be0431A36416622efD2e430B04628": { arx: 1, dendroid: 0 }, // JPEG_Yakuza#7878
  "0xe5e01254AaE93DAce33a8Fa747717F4F14cbD71f": { arx: 1, dendroid: 0 }, // Keiii#6564
  "0x27eE8FA6a70a8F156008EDe28C7B8ea5F72fFdF3": { arx: 1, dendroid: 0 }, // KOBRA || The Freeroll#1960
  "0x5950f70268e6A3414DC1125EF39947902f3a2c4A": { arx: 1, dendroid: 0 }, // Leon2#1569
  "0xB6A3BCEddFDA728d3f1e6fdFc33ff649ae3F34Bf": { arx: 1, dendroid: 0 }, // MBC#1767
  "0x75a4699940DAA2859928312911F053c010f8Ca2e": { arx: 1, dendroid: 0 }, // Merit Circle 1
  "0x1D7D1f503B9991180Df8271A2D0CFbAc7e3076A3": { arx: 1, dendroid: 0 }, // Merit Circle 2
  "0x50F7CE24D2D4E1D9CcC926E86d38528395CFdbDF": { arx: 1, dendroid: 0 }, // Nightwatch#4781
  "0xD938722718F97F717297eFc904232FFB9dF919B4": { arx: 1, dendroid: 0 }, // Peace | SSS | SYN#8656
  "0xDA3963Df8e6230E7cBEb30Aa3e92b02aed96cD88": { arx: 1, dendroid: 0 }, // Peter 300yo#8313
  "0x23EAe9A5a26F550071ef8Cc86e74721F13FBEbEd": { arx: 1, dendroid: 0 }, // Rel3ase#3368
  "0xC091A0A9E4bb4F620342bdc540b8ea327760b1C5": { arx: 1, dendroid: 0 }, // rendertimes#7474
  "0x6F10073D9154967B209F5c1cf578E42cd7A51b70": { arx: 1, dendroid: 0 }, // Resaang#0001
  "0x4377E83eDb9Bb6905795802cCa6845d72c237b36": { arx: 1, dendroid: 0 }, // Stankovich#7451
  "0x9F3e77Cb89Df964003053aa5B438E5697C77F4F9": { arx: 1, dendroid: 0 }, // SteeperBasket#2002
  "0xf32Fc6A0dE35046f3ac0f179F39871764cC6Aaac": { arx: 1, dendroid: 0 }, // Thanh#7686
  "0x25129be5b96787d1De0861f9a0E8867F63735B58": { arx: 1, dendroid: 0 }, // Up&Up#5508
  "0xb7b7942525343739674223061e437fFf87399312": { arx: 1, dendroid: 0 }, // Weather#4107
  "0x4f1ba666004bbf74bFfd75DB29c52f029875f9d7": { arx: 1, dendroid: 0 }, // Yu En#7495
  "0x134F240827Dd4B047942589998a163A2A1002F1a": { arx: 1, dendroid: 0 }, // zen123#9633
  "0xE6cC5e3EBB07B5156ba3aF510B8c6cA19804d88E": { arx: 1, dendroid: 0 }, // kobayashi#4343
}

const batch5Minters = {
  "0x667eEb11e3AdFC2f3A8e30C2787f74A2760eCCFe": { arx: 3, dendroid: 1 }, // uzura#4238
  "0x79d899379844D35A1A1F5D51D3185dd821f44Dc1": { arx: 2, dendroid: 1 }, // Jelo#9731
  "0xEA6D82C5aC15ca958fba6F1793a6567bFA0dFd7A": { arx: 3, dendroid: 0 }, // sangvin#7298
  "0x52be3580601524652978648E872D0aA448aFC928": { arx: 3, dendroid: 0 }, // MrDQ#0036
  "0x7B3893D8a1371B4E21c2864530033C59bDbE2590": { arx: 3, dendroid: 0 }, // nikoT#1150
  "0xdbc2c8d9e8B2fB44d95750DB1BE97dad4ab57005": { arx: 2, dendroid: 0 }, // TomSuper.eth#2870
  "0xe5FCFCA8519154aD9D73893E381Db99fCAFBF5af": { arx: 2, dendroid: 0 }, // gambit18#3971
  "0xebc212c8f416b6c2e8a252c83A56A3A955Dc6402": { arx: 2, dendroid: 0 }, // Daniconelx#0272
  "0xCc4068c550bFA4D4c28dc69D25EE9FCd7cd03a42": { arx: 1, dendroid: 0 }, // Carliyke#3530
  "0x9Cd3846d6C02C2982Fc074CC81d00A6C3D99C3E5": { arx: 1, dendroid: 0 }, // suite_bergamasque#1920
  "0x5a6693b86d9a105A03828FF7d5DcaAB47a23d678": { arx: 1, dendroid: 0 }, // etetet123#9963
  "0x6760E6B05f205af591487A49BC047567c541edc6": { arx: 1, dendroid: 0 }, // Spaceman#6766
  "0xbF23365269f1B75df4a0a71A0F8B64ecbf19E71B": { arx: 1, dendroid: 0 }, // schick#3156
  "0x6b05d47649a664FED61f17eC973f3ed96147CA92": { arx: 1, dendroid: 0 }, // vitalik cousin#7771
  "0x06F398ce76aCc1fCE97B7Cab0eb2a96BA9230c9b": { arx: 1, dendroid: 0 }, // MarsDAO#8888
  "0x6Ef6265b3A2866aAb07aaAD3338cd034481f9cf7": { arx: 1, dendroid: 0 }, // babyBox#5555
  "0x0cE8786E033c44B3b338F30bE9dc1fF67eBAf003": { arx: 1, dendroid: 0 }, // PlebeyBob#4717
  "0x714BC2B6f1c5a6C608Ab45311A9e731d7f48A5f4": { arx: 1, dendroid: 0 }, // pirate#3175
  "0x3cC457B4A89025f75d0d3079c423262A64F546c1": { arx: 1, dendroid: 0 }, // HIGHROLLER#7529
  "0x786Da7C483E802242ae6fd52d327E4686Bd296dA": { arx: 1, dendroid: 0 }, // kinox#1242
  "0xcb3Ca5abFC40539EF0D1747443AE0ad8114111A1": { arx: 1, dendroid: 0 }, // Shedywards#0250
  "0x1F417e8090b5c5Ab3e8197Af7f29cC637F8ee042": { arx: 1, dendroid: 0 }, // mrzebaba1#9892
  "0xD6d22320371D2E67dA49cCc38275A5173E77bcF6": { arx: 1, dendroid: 0 }, // mr.sauce | Suiswap#8188
  "0xeaA68E56a052bD80d38221953c139632cfE44702": { arx: 1, dendroid: 0 }, // freakyfreaky520#1564
  "0xdDb105Ab74fEbeca4771728b51b9472F8037F48a": { arx: 1, dendroid: 0 }, // Firuvirg#3628
  "0xe8179007766746285625c5C73B0365716CF4Acc6": { arx: 1, dendroid: 0 }, // Frozensense#5833
  "0xe6f1364e06Ec6C98D41D3d3D36512f6C68443041": { arx: 1, dendroid: 0 }, // MrPalvy#9054
  "0x3B1b3edB62b527a134E5a7541F8231A3840486e1": { arx: 1, dendroid: 0 }, // meji#2669
  "0x4E731074C2B0199e7D391f8184D50D1f35aa4465": { arx: 1, dendroid: 0 }, // Stone&J#2403
  "0xea6ea758a162eFC03cb9a1Dd212893687ADe1b61": { arx: 1, dendroid: 0 }, // Hate&love#1568
  "0xeeFb851bf9299976D1D586061a494Db5bf2Ba308": { arx: 1, dendroid: 0 }, // Prinz_Salim#8878
  "0x02C07322Bfd5DBc6aa58dD1Ea55EB9BBfFfdd11b": { arx: 1, dendroid: 0 }, // italian_josh#3309
  "0xEdf346D0B173CE18b05A8819fbC0dbe781D09cDe": { arx: 1, dendroid: 0 }, // pottyroo#3397
  "0x7cccad31d6122af044ae0825a7d5a006aa01c2f8": { arx: 1, dendroid: 0 }, // Eliz61#9603
  "0xFcEb8a974f85aCCfA6e287AbFca9c26dc4196b90": { arx: 1, dendroid: 0 }, // JIA_B#2176
  "0x8F09EeF679904F64752523f95dB664039dF278e2": { arx: 1, dendroid: 0 }, // Heyyawn#1025
  "0x913A786eEa7c15B22775e372E432EcCfB0d8a0A8": { arx: 1, dendroid: 0 }, // brdg#4867
}

const batch6Minters = {
  // Treasure Cup top 50%
  "0x0762301bd516fe5d37a89c944515011be4fe1b6f": { arx: 2, dendroid: 1 }, // Yummyraw#1852
  "0x73fAFB1E5903b717e4fc3767aDb90E973D64Ab36": { arx: 1, dendroid: 1 }, // crypto_natsu#4225
  "0xe642F9B086425a245764299B6dF6338e7De26687": { arx: 2, dendroid: 0 }, // reges#1233
  "0x6e44C59CbC18F7061D200272Bd119e263b6a08C8": { arx: 1, dendroid: 1 }, // Alpha Kong | Pyposa#2065 (leg box)
  "0xB1c41450CB8a11A7e605e8Be5ca30A4c9C9231c9": { arx: 0, dendroid: 1 }, // J.#9999
  "0x30458345938248d7ec8953d4cee9e0e202db6651": { arx: 1, dendroid: 0 }, // fitfit1337#0469
  "0xBe20B35CF9Af2201651AAEbcE65878f33768A4CD": { arx: 1, dendroid: 0 }, // kaijupepe#8264
  "0xfe791B87F607AE6BE8449e64d393c91be46a1245": { arx: 1, dendroid: 0 }, // man_1801#2452
  "0x3cf484ADE8E960cb96e1e5d32b0B47E5C1215f0a": { arx: 2, dendroid: 0 }, // DonaldJacks#6982 (leg box)
  "0xf946CDAEcF5A15440e500E61A0c1d179A93652ad": { arx: 1, dendroid: 0 }, // Il_Il | Delovoy DAO#9036
  "0x10C7d2273D88Fe1E26D7b92fcBfB539B9E6557f6": { arx: 2, dendroid: 0 }, // Choang#9999 (leg box)
  "0xe5e01254AaE93DAce33a8Fa747717F4F14cbD71f": { arx: 1, dendroid: 0 }, // Keii#6564
  "0xdbc2c8d9e8B2fB44d95750DB1BE97dad4ab57005": { arx: 2, dendroid: 0 }, // TomSuper#2870 (leg box)
  "0x30f4460b68c431f894b39364fe70f275d900b2fa": { arx: 1, dendroid: 0 }, // Rvanan#9413
  "0xc9F64274F5Bc7F97F02129406eb6191D31548663": { arx: 1, dendroid: 0 }, // q2thec#1971
  "0x79d899379844D35A1A1F5D51D3185dd821f44Dc1": { arx: 1, dendroid: 0 }, // Jelo#9731
  "0x4377E83eDb9Bb6905795802cCa6845d72c237b36": { arx: 1, dendroid: 0 }, // Stankovich#7451
  "0xf2A1cF1aB0462E3c353A1e34Bf74eC51fb162D08": { arx: 1, dendroid: 0 }, // JamesRic#9999
  "0xb40969F60BfA246A09593099DAce2dDD543254A3": { arx: 1, dendroid: 0 }, // apix#8283
  "0x0105c26c5d013bb32223dbb2a4Bbd98e9B0c320A": { arx: 1, dendroid: 0 }, // Dr11235#9930
  "0x72a30F3E45c0E677fDD340FEDDeFb4B61c14be98": { arx: 1, dendroid: 0 }, // Gunslinger#5764
  "0xff9d64bcf38fbdadcf22e89c47c2c922dd500cac": { arx: 1, dendroid: 0 }, // Compound-I#5315
  "0x78c4eb74405311620d66eaCc2a4D61e1925B7E3F": { arx: 1, dendroid: 0 }, // Raze | NFT Gaming#7322
  "0x667eEb11e3AdFC2f3A8e30C2787f74A2760eCCFe": { arx: 1, dendroid: 0 }, // uk#4238
  "0xADE7F2f901D5bF04b45A11C63b30f6f31b49B3B0": { arx: 1, dendroid: 0 }, // tilled#7639
  "0x73391c743b653ea9949ece4f42457b1d780d6c1d": { arx: 1, dendroid: 0 }, // Dimastor#9853
  "0x2741C7A3159F2a01a19F53Cff8972a7812CF6418": { arx: 2, dendroid: 0 }, // sisielkenny#7948 (leg box)
  "0xE1fA1A88C46F35d28E15b5C88Ae4BDbb5f6a6b5D": { arx: 1, dendroid: 0 }, // Lehson1308#4184
  "0x5a6693b86d9a105A03828FF7d5DcaAB47a23d678": { arx: 1, dendroid: 0 }, // etetet123#9963
  "0xf87a8048Fc5dB261a39906c66524EdA556b746dF": { arx: 1, dendroid: 0 }, // Tharwa#9262
  "0xd1992817e258dDfa0B47Ae550027A0DdE1272233": { arx: 1, dendroid: 0 }, // Demster#1826
  "0x15bb1542d63e0f452541601690c93cbbade4cb2d": { arx: 1, dendroid: 0 }, // Faraon#5723
  "0x7b4Db86B9c497ef68f6D956350B35d9857154042": { arx: 1, dendroid: 0 }, // Vanhouse#2017
  "0xE1682954aE4166E784bb3eC9D901E13949A1028e": { arx: 1, dendroid: 0 }, // RED#3480
  "0xab2ea973d3abE1C223dA0C85dC1962E2Daa2064A": { arx: 1, dendroid: 0 }, // pirate#3175
  "0x65a272B03690d72BA5D496a0faB06458C5628c28": { arx: 2, dendroid: 0 }, // Celtixtime 🍙 | Rocket#0127 (leg box)
  "0xB7176c03739ec10c4EC8E21a64aD64B228b8A5f2": { arx: 1, dendroid: 0 }, // TheReaper235#8942
  "0x68c406dab428fdBEADC2f670E52Fd179ef3E71CF": { arx: 2, dendroid: 0 }, // MrPalvy#9054 (leg box)
  "0x81450b4F7B76A9E5Fe366451068166b3907B680c": { arx: 2, dendroid: 0 }, // edward-ln#5167
  "0x3a2833c8975687F5969D07F58FAfCd3473B5aCd4": { arx: 1, dendroid: 0 }, // Younique#2233
  "0x12d6AaC80A3357cFA88a3af56f4f7468341c0D66": { arx: 1, dendroid: 0 }, // MoonLock#4475
  "0xcA060F5a8F84dCD713D7f775ddE1780ADd48dF68": { arx: 1, dendroid: 0 }, // martinelli hernadez#2436
  "0x1262c0eaD0D58e04A90E1E654877c6Ddfa5C76B9": { arx: 1, dendroid: 0 }, // edick_fnd#2977
  "0x1e354cbFb451A6dD73902B504f05c5C66b43A3Eb": { arx: 1, dendroid: 0 }, // nnamdipremium#7161
  "0x02C07322Bfd5DBc6aa58dD1Ea55EB9BBfFfdd11b": { arx: 1, dendroid: 0 }, // italian_josh#3309
  "0x5576Bf2b676858D5E2934FccEDc01B0088Ddb3D6": { arx: 1, dendroid: 0 }, // VladManz | Delovoy DAO#6970
  "0x9e6c39f57E324fDA4D8397eE5a67E1A5c44a3f4e": { arx: 1, dendroid: 0 }, // Yeshy#0315
  "0x32ae8acfd7ff2bfeb09e819bd0479075268b3205": { arx: 1, dendroid: 0 }, // DoogsZA#0147
  "0xebc212c8f416b6c2e8a252c83A56A3A955Dc6402": { arx: 1, dendroid: 0 }, // Daniconelx#0272
  "0xc445846fee6d6256f1add7a3326605b31a4a667c": { arx: 1, dendroid: 0 }, // GreennMan#5090
  "0xF2a477aBfb3E3eA370Ef1ece59411b5046fd966D": { arx: 1, dendroid: 0 }, // binn#1825
  "0x348Ed2fc3c2E93608666263E50b9713839E760eb": { arx: 1, dendroid: 0 }, // Bobigazda#9808
  "0x5Ea81a84FC14a73c7A9b18b72fF41a270C2441CF": { arx: 1, dendroid: 0 }, // Carlan | READY#3815
  "0xC3FD6e0428f4F4f6f9669EC51B129045f9D9070F": { arx: 1, dendroid: 0 }, // Hanoch#4634
  "0xe2724b88E93ee76d60E72c372317daDAdA0Db826": { arx: 1, dendroid: 0 }, // tabgnuh#5015
  "0x93f9108E5b5c0DF41c7598412A3558904225AbbC": { arx: 1, dendroid: 0 }, // dyno#7777
  "0x3F624E4a0402d7aB627B7214e65b0a7C4A39Ef6E": { arx: 1, dendroid: 0 }, // Trick#3571
  "0x7CC353433Ef9dF7676Fbb39da26FA0E278107409": { arx: 1, dendroid: 0 }, // Aimfaria88#1150
  "0xAF53890720728A1907AEd7C8604Ce1CD945B853F": { arx: 1, dendroid: 0 }, // 💠NorthsideAbN++💠#8468
  "0xcb3Ca5abFC40539EF0D1747443AE0ad8114111A1": { arx: 1, dendroid: 0 }, // Shedywards#0250
  "0x52be3580601524652978648E872D0aA448aFC928": { arx: 1, dendroid: 0 }, // MrDQ#0036
  "0x132d0dEE3E54F796747850bc4C64b4341713c240": { arx: 1, dendroid: 0 }, // deliamayami#3355
  "0x9Eb3EeE59075658e70b5F1cfF88b6a2438b3eF34": { arx: 1, dendroid: 0 }, // GOKU#5060
  "0x4E731074C2B0199e7D391f8184D50D1f35aa4465": { arx: 1, dendroid: 0 }, // Stone&J#2403
  "0x124bcF206F1581C1D2d2a86310470825C8e65BeC": { arx: 1, dendroid: 0 }, // ?Lissandro#5176
  "0x32ae2622A20C0C421a8d48B7202F6d4FE4132d11": { arx: 1, dendroid: 0 }, // DeChunkz #1249
  "0x5F8cfD3F2aA1CaDA3631E3b61f1d4C66eBcC2Cf2": { arx: 1, dendroid: 0 }, // El Gato | Delovoy DAO#3461
  "0xa688f5c5c0dad5dbede01e8fb0a0ca4a5fc19c3c": { arx: 1, dendroid: 0 }, // LovelyG#2311
  "0x4ae146858189A053713CAF110cB602726632fe82": { arx: 1, dendroid: 0 }, // Fogou#4076
  "0x1a387793d05B98F3eda6529453D8D6FB8f08EE1C": { arx: 1, dendroid: 0 }, // GaspodeWD#7793
  "0x3945E8B38326B54612417449f86bE796Bb51F7fB": { arx: 1, dendroid: 0 }, // annavie#8521
  "0x3af0c8E44377ecBb7A4e4841658bCe227bDfbc33": { arx: 1, dendroid: 0 }, // Jedida#9872
  "0x36BE564317aC93334a11060EcC341f3Cf756E531": { arx: 1, dendroid: 0 }, // Finesse Papi#7408
  "0xd99F500aEFaD2Dc73Ed6FA8334ddCDE1AF40E78A": { arx: 1, dendroid: 0 }, // flingz#1641
  "0xdBD85D3373cE7c3954bDDeE710ED1A682b064b07": { arx: 1, dendroid: 0 }, // guantabean#5022

  // Legendary Box Winner
  "0x16B6e0460E8E60097A8EB50CDC8E0F20C4E31f68": { arx: 1, dendroid: 0 }, // Myko_Lazaah#0097
  "0x5A0e08b97bA249506794F491065A8782f49B6F5d": { arx: 1, dendroid: 0 }, // <3 Bastian <3#1140
  "0x8d26B777e4191479F24356d5a2728A7FAC1DFb51": { arx: 1, dendroid: 0 }, // UchihaMadara#2920
  "0x9a18CF73f7117415d34a3cD321863757F5F82F31": { arx: 1, dendroid: 0 }, // Coolguy#9564
  "0x3511748c00b6B2dD9f77c1141ceC9C0BD7ADA5bE": { arx: 1, dendroid: 0 }, // tofake
  // "0x17871C34Ac466189a2dFDb44530F0b41f870fca2": { arx: 1, dendroid: 0 }, // AlexMasme#9997 (removed for sybil)
  // "0x0aBDcDBE393b7D513914f198aCfeeC4BF7F24CBD": { arx: 1, dendroid: 0 }, // MavisKeats#7240 (removed for sybil)
  "0x610451997dE363425E5db3F366535c6E2165d8aa": { arx: 1, dendroid: 0 }, // doquang89#4580
  "0x839876Fcf8733b41E3327172de7E2B16feed31E4": { arx: 1, dendroid: 0 }, // potato#0288
  // "0x8b7F6673fA7598B061a1bEfdB2869Fadad1C87D0": { arx: 1, dendroid: 0 }, // BroZhen#4058 (removed for sybil)
  "0x9617Cc14f9945915Eebe204c076de6c47B296DE4": { arx: 1, dendroid: 0 }, // RyGameNico

  // VC
  "0x682AA10aE360Dd84AFF56E121D77bD6BE0ca04f3": { arx: 2, dendroid: 0 }, // Casey

  // Janus / Fan Art
  "0x8226Db091d6C976a1a2df9F2B10e6EbC1792b828": { arx: 1, dendroid: 0 }, // Gametofu#8856
  "0x2783117221A0C5cFE0C93E85f856d665ec73A067": { arx: 1, dendroid: 0 }, // saobang#8302
  "0x6de9E6Be2C0EAC7F143b1f86623F3218924D8707": { arx: 1, dendroid: 0 }, // kvitkatania#3013
  "0x4Fd49e5E34ABb59Cbf58efC44dB5dE1129a5E661": { arx: 1, dendroid: 0 }, // hung01295#3780
  "0x2C57372149b9e426F091dc01A52734E68f6fC71c": { arx: 3, dendroid: 0 }, // Nachtigall#7126
}

const batchIconMinters = {
  "0x45CA84fDd869beB43de588178634d695c9Fc113b": { arx: 1, dendroid: 0 }, // soros_defi#8249
  "0xD207180a5D36FEF7363f71c79726817aec8938b5": { arx: 1, dendroid: 0 }, // Jchen419#4967
  "0x4148CFf4A84DD59b115CC0a34d381b07D4EC27a5": { arx: 1, dendroid: 0 }, // YG94#6134
  "0x050eCA8247Ad00f2c617CC962A443fB3C646A608": { arx: 1, dendroid: 0 }, // koala009#2087
  "0xA75C1b14246B5aCEAd56Ff8cb37c41E1431dfE90": { arx: 1, dendroid: 0 }, // djmounir#7722
  "0x483576fcddfe299d21f7cf05b0d796c1eb11944e": { arx: 1, dendroid: 0 }, // Sinny#5975
  "0xA7bC5704e717b7B4883e23b84973d850e172f05E": { arx: 1, dendroid: 0 }, // jben#6015
  // "0x9e5fE6c4eae3489Cf835b51A19a573b03249c1C0": { arx: 1, dendroid: 0 }, // Rob R#5622
  "0x758698d7d90D3dc87B498a549fc46bd4384FEd46": { arx: 1, dendroid: 0 }, // Goobs#9626
  // "0x0Ca3f19cA4d08CAF4207D1FfB77cfBb59A6db854": { arx: 1, dendroid: 0 }, // bsun#2032
  "0xF541c9B367C595eC56318291a8872DFCdA0606b0": { arx: 1, dendroid: 0 }, // worstatthisgame#7869
  "0x904B01d2c1eacAFF8E6Ef24D4c71616590C0C5D4": { arx: 1, dendroid: 0 }, // crunkybiscuit#0147
  // "0x0Ace42872f46753435994fD2F9191a28961182fc": { arx: 1, dendroid: 0 }, // TechPorkChop#0516
  "0xfcDa85C0E27bF19E7d6b5BAE76C53aFA1A1fB5d7": { arx: 1, dendroid: 0 }, // Maz#1703
  // "0xAC537E290029B02D318C6F437247B33587C1bb9B": { arx: 1, dendroid: 0 }, // Anchor#4728
  "0x283Ec3513Ac175600188d343f0cAA28Bf472d995": { arx: 1, dendroid: 0 }, // Will Robinson | Alliance#0001
  "0xee79Ac62C52aDB6DEa8A43ecd3a290Dd644a8d3A": { arx: 1, dendroid: 0 }, // bymyname#3735 
  "0xa5fdD1bD73B58840EbbD98e05DbCb813702b0915": { arx: 1, dendroid: 0 }, // thisismyusrname
  // "0xa9a8BF55F609C9c4e6c74FDae57ecdD35AA6B8DB": { arx: 1, dendroid: 0 }, // slowtwitch
}

const batch7Minters = {
  "0xf2A1cF1aB0462E3c353A1e34Bf74eC51fb162D08":	{ arx: 3, dendroid: 0 }, // JamesRic
  "0xdbc2c8d9e8B2fB44d95750DB1BE97dad4ab57005":	{ arx: 3, dendroid: 0 }, // tomsuper
  "0xeCeCe2325082ef5c6B31Acd0f348240346BC4267":	{ arx: 2, dendroid: 0 }, // 0xraze
  "0x81450b4F7B76A9E5Fe366451068166b3907B680c":	{ arx: 2, dendroid: 0 }, // edwardln
  "0xc9F64274F5Bc7F97F02129406eb6191D31548663":	{ arx: 2, dendroid: 0 }, // q2thec
  "0xe2724b88E93ee76d60E72c372317daDAdA0Db826":	{ arx: 2, dendroid: 0 }, // tabgnuh
  "0x102C5E7A30A27D0Ab7fE10063DF5cDCd465B05c8":	{ arx: 2, dendroid: 0 }, // tsukokawaii
  "0xf88006636611e16122D94055568d8C4f4E0C85FF":	{ arx: 1, dendroid: 0 }, // 0xmandelbrot
  "0xb40969F60BfA246A09593099DAce2dDD543254A3":	{ arx: 1, dendroid: 0 }, // apixtwts
  "0x753904c13c18F45670e9718943C975C0aa97a884":	{ arx: 1, dendroid: 0 }, // b_cryptonft
  "0x4B70D9edfb7B2795460C215E37E74C32b10271c5":	{ arx: 1, dendroid: 0 }, // cagouzi
  "0x9236A26fC03f26f7EEf584640c248B6C3fAB0745":	{ arx: 1, dendroid: 0 }, // camr_n
  "0xF9538648f7EbC9ef59db9D3eEB17594b4B4cFEDa":	{ arx: 1, dendroid: 0 }, // Connie#5081
  "0x7b6E11FFA5916c49B06921427C2BC74953541870":	{ arx: 1, dendroid: 0 }, // dirkakafatninja
  "0xC3FD6e0428f4F4f6f9669EC51B129045f9D9070F":	{ arx: 1, dendroid: 0 }, // hanoch
  "0x8F09EeF679904F64752523f95dB664039dF278e2":	{ arx: 1, dendroid: 0 }, // heyyawn
  "0x4Fd49e5E34ABb59Cbf58efC44dB5dE1129a5E661":	{ arx: 1, dendroid: 0 }, // hung01295#3780
  "0x1830c41eeDEA4F101688f1aa6b2C2eF9370AD48D":	{ arx: 1, dendroid: 0 }, // kryptobkk
  "0xEdf346D0B173CE18b05A8819fbC0dbe781D09cDe":	{ arx: 1, dendroid: 0 }, // pottyroo
  "0x28c1fA942d9fF0D8939f5c3AfCb270A5a8Daad76":	{ arx: 1, dendroid: 0 }, // rokkymeta
  "0x9e29032be4F0CC085D75A41D1F7C53f62A1aAF0d":	{ arx: 1, dendroid: 0 }, // rosalynunderdown
  "0x2783117221A0C5cFE0C93E85f856d665ec73A067":	{ arx: 1, dendroid: 0 }, // saobang
  "0xbF23365269f1B75df4a0a71A0F8B64ecbf19E71B":	{ arx: 1, dendroid: 0 }, // schicknet
  "0xCD1204cAA34b7ACbe2CF52511930F1E21B5edd29":	{ arx: 1, dendroid: 0 }, // seesihking
  "0x3511748c00b6B2dD9f77c1141ceC9C0BD7ADA5bE":	{ arx: 1, dendroid: 0 }, // tofake
  "0x0762301bd516fe5d37a89c944515011be4fe1b6f":	{ arx: 1, dendroid: 0 }, // Yummyraw
}

const batch8Minters = {
  "0xe8179007766746285625c5C73B0365716CF4Acc6": { arx: 4, dendroid: 0 }, // Frozensense
  "0x5A0e08b97bA249506794F491065A8782f49B6F5d": { arx: 3, dendroid: 0 }, // <3 Bastian <3
  "0x102C5E7A30A27D0Ab7fE10063DF5cDCd465B05c8": { arx: 3, dendroid: 0 }, // tsukokawaii
  "0xC3FD6e0428f4F4f6f9669EC51B129045f9D9070F": { arx: 2, dendroid: 0 }, // hanoch
  "0x72a30F3E45c0E677fDD340FEDDeFb4B61c14be98": { arx: 2, dendroid: 0 }, // truegunslinger
  "0x8F09EeF679904F64752523f95dB664039dF278e2": { arx: 2, dendroid: 0 }, // heyyawn
  "0xdbc2c8d9e8B2fB44d95750DB1BE97dad4ab57005": { arx: 1, dendroid: 0 }, // tomsuper
  "0x489cD17670889498F22790981e46F2Dbfb0F5E04": { arx: 1, dendroid: 0 }, // snatcha
  "0xE3D86c406d4239b1E36D132196c11A840212eE59": { arx: 1, dendroid: 0 }, // nodovod
  "0x1C9842b445D5C6FcFAC4cf6A19411Ab29a4cC612": { arx: 1, dendroid: 0 }, // _namasaki_
  "0xA8Ef6dee123Dd9835Ef21347c27009b6Be503C5D": { arx: 1, dendroid: 0 }, // kellquan
  "0x5ac36a016b6A8a7025bdd65506fcD6322F02B69F": { arx: 1, dendroid: 0 }, // phuongthao0512#7914
  "0x49d1ad89adb60241bf2cc003fe57bcfd4d3cfd95": { arx: 1, dendroid: 0 }, // dnlvskey
  "0x0Cf66F0560357cE6D27B4a5c0367fb1b406f466b": { arx: 1, dendroid: 0 }, // scottydk
  "0x9f62A4232601d63A1FC819A7Ac49D698faEB3eD0": { arx: 1, dendroid: 0 }, // 0xconmunismMike#9200
  "0xCc4068c550bFA4D4c28dc69D25EE9FCd7cd03a42": { arx: 1, dendroid: 0 }, // carliyke
  "0x95a9B2250c006Be208c0EA07642E40056De888D4": { arx: 1, dendroid: 0 }, // tofu2xxx
  "0xdBD85D3373cE7c3954bDDeE710ED1A682b064b07": { arx: 1, dendroid: 0 }, // guantabean
  "0xE9FE2358E0c6b70EDb2944ec6ed5c82e8F6bAAf6": { arx: 1, dendroid: 0 }, // Payton K | Wolves DAO
  // "0xb48eD686918f33e4cC2e71c530FF2DfBFc7d5634": { arx: 1, dendroid: 0 }, // yimu#0735
  "0xcb3Ca5abFC40539EF0D1747443AE0ad8114111A1": { arx: 1, dendroid: 0 }, // shedywards
  "0x7862252A244E881950A8bb429C97696Aa327c140": { arx: 1, dendroid: 0 }, // longsix
  "0x81450b4F7B76A9E5Fe366451068166b3907B680c": { arx: 1, dendroid: 0 }, // edwardln
  "0x1830c41eeDEA4F101688f1aa6b2C2eF9370AD48D": { arx: 1, dendroid: 0 }, // kryptobkk
  "0xf2A1cF1aB0462E3c353A1e34Bf74eC51fb162D08": { arx: 1, dendroid: 0 }, // JamesRic
  "0x1bE2e5c277f77679888B11c9311680fe873d3a3b": { arx: 1, dendroid: 0 }, // jpegg.
  "0xcA060F5a8F84dCD713D7f775ddE1780ADd48dF68": { arx: 1, dendroid: 0 }, // martinellihernadez
  "0xf2892d847DCEf110b002C05b8F7555A664F4C23e": { arx: 1, dendroid: 0 }, // norskfrugt_77039
  "0xFBf4Ac7Ba1EE19846c8DE4032C36067A63E2B6b7": { arx: 1, dendroid: 0 }, // kisss_lina
  "0x72DbAf25cdd78ef7800De9c19fC375547583E16f": { arx: 1, dendroid: 0 }, // lieonidas
  "0x5F8cfD3F2aA1CaDA3631E3b61f1d4C66eBcC2Cf2": { arx: 1, dendroid: 0 }, // El Gato#3461
}

const aggregateMintlist = (batches) => {
  const mintlist = {}
  batches.forEach((batch) => {
    Object.keys(batch).forEach((address) => {
      const lowercaseAddress = address.toLowerCase()
      if (mintlist[lowercaseAddress] === undefined) {
        mintlist[lowercaseAddress] = { arx: 0, dendroid: 0 }
      }
      mintlist[lowercaseAddress].arx += batch[address].arx
      mintlist[lowercaseAddress].dendroid += batch[address].dendroid
    })
  })
  return mintlist
}

const mintlist = aggregateMintlist([
  beta1WinnersWallets, 
  batch2Minters,
  batch3Minters,
  batch4Minters,
  batch5Minters,
  batch6Minters,
  batchIconMinters,
  batch7Minters,
  batch8Minters
])

const counter = { num: 0 }

const getColor = ({ championRemaining, dendroidRemaining }) => {
  if (championRemaining === 0 && dendroidRemaining === 0) {
    return "green"
  }
  else if (championRemaining < 0 || dendroidRemaining < 0) {
    return "red"
  }
  else  {
    return "yellow"
  }
}

const getTableData = (discordId, claimedData, remainingData) => {
  return { discordId, ...claimedData, ...remainingData }   
}

const addToBatch = (contract, batch, address, whichType, setTableData, setIsLoading) => {
  batch.add(contract.methods.passesClaimed(address, whichType).call.request(
    { from: '0x0000000000000000000000000000000000000000' }, (err, claimed) => {
      if (err) {
        console.log(err)
      }
      else {
        const discordId = discordNames[address.toLowerCase()]
        const typeKey = whichType === 0 ? "champion" : "dendroid"
        const mintlistKey = whichType === 0 ? "arx" : "dendroid"
        const claimedData = { [`${typeKey}Claimed`]: Number(claimed) }
        const remainingData = { [`${typeKey}Remaining`]: mintlist[address][mintlistKey] - Number(claimed) }
        setTableData((prevState) => {
          const currentData = prevState !== undefined ? prevState.filter((x) => x.discordId === discordId)[0] : undefined
          let tableData
          if (currentData === undefined) {
            tableData = getTableData(discordId, claimedData, remainingData)
          }
          else {
            tableData = {
              ...currentData,
              ...claimedData,
              ...remainingData
            }
          }
          tableData.color = getColor(tableData)
          if (tableData.championClaimed !== undefined && tableData.dendroidClaimed !== undefined) {
            tableData.allowactionOverflow = (
              tableData.championClaimed > mintlist[address].arx || 
              tableData.dendroidClaimed > mintlist[address].dendroid
            )
          }
          return [...prevState.filter((x) => x.discordId !== discordId), tableData]
        })

        counter.num -= 1
        if (counter.num === 0) {
          setIsLoading(false)
        }
      }
    }
  ))
}

const getClaimedData = async (web3, batch, address, chain, setTableData, setIsLoading) => {
  const contractData = mintpassContractData.networks[chain]
  const contract = new web3.eth.Contract(mintpassContractData.abi, contractData.address)
  addToBatch(contract, batch, address, 0, setTableData, setIsLoading)
  addToBatch(contract, batch, address, 1, setTableData, setIsLoading)
}

const getMintPassTrackerData = async (web3, setTableData, setIsLoading) => {
  const addresses = Object.keys(mintlist)
  counter.num = addresses.length * 2
  const arbitrumChainId = 42161
  const max_address_batch = 150

  let j = 0
  while (j < addresses.length) {
    const batch = new web3.BatchRequest()
    for (let i = j; i < Math.min((j + 1) * max_address_batch, addresses.length); i++) {
      getClaimedData(web3, batch, addresses[i], arbitrumChainId, setTableData, setIsLoading)
    }
    batch.execute()
    j += max_address_batch
  }
}


export {
  getMintPassTrackerData
}